import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { Linking } from 'react-native';

interface CoditionModalProps {
    modalVisible: boolean;
    setModalVisible: any;
    type: string;
    isPublishSite: boolean;
    userInfo: any;
}

export function ModalSend(props: CoditionModalProps) {
    const { modalVisible, setModalVisible, type, isPublishSite, userInfo } = props;

    const goToWhatsapp = () => {
        let infoUrl = 'https://api.whatsapp.com/send?phone=54991705802';
        if (isPublishSite && type === 'banner-site') {
            infoUrl = 'https://api.whatsapp.com/send?phone=54991705802&text=Olá, preciso de suporte para publicar o banner no meu site.';
        }
        if (isPublishSite && type !== 'banner-site') {
            infoUrl = 'https://api.whatsapp.com/send?phone=54991705802&text=Olá, gostaria de saber como faço para publicar os banner no meu site';
        }
        Linking.openURL(infoUrl);
    };
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!modalVisible) {
            return setProgress(0);
        }
        let startTime = Date.now();
        const intervalId = setInterval(() => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;

            let newProgress = (elapsedTime / 60000) * 100;

            if (newProgress >= 100) {
                newProgress = 100;
                clearInterval(intervalId);
            }
            setProgress(newProgress);
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [modalVisible]);

    useEffect(() => {
        if (progress >= 99) {
            setModalVisible(false);
        }
    }, [progress]);

    const renderTitle = () => {
        if (isPublishSite && type === 'banner-site') {
            return 'Estamos finalizando a publicação do seu banner!';
        }
        if (isPublishSite && type !== 'banner-site') {
            return 'Atenção!';
        }
        return 'Melhoramos nosso sistema de publicações!';
    };

    const renderDescription = () => {
        if (isPublishSite && type === 'banner-site') {
            return (
                <S.Description>
                    Prezado usuário, dentro de <S.CoverText> 60 segundo</S.CoverText>, seu banner estará disponível para publicação dentro do sistema
                    <S.CoverText> RevendaPRO</S.CoverText>. Você poderá acessar seus banners dentro do menu
                    <S.CoverText> Meu site</S.CoverText>, na opção Banners e ativar seu banner para que fique disponível no site da sua loja.
                </S.Description>
            );
        }
        if (isPublishSite && type !== 'banner-site') {
            return (
                <S.Description>
                    Só é possível publicar esse formato de arte no facebook e instagram. Para usar a ferramenta de publicar banner no site da sua loja
                    <S.CoverText> {userInfo?.user?.site || ''}</S.CoverText> entre contato com nosso suporte.
                </S.Description>
            )
        }
        return (
            <S.Description>
                Prezado usuário, pensando em melhorar sua experiência em nosso sistema, a partir de agora todas as publicações feitas pelo botão
                <S.CoverText> publicar agora</S.CoverText>, serão agendadas para serem publicadas em <S.CoverText>60 segundos</S.CoverText>.
                Sendo assim o sistema está liberado para você continuar criando suas publicações sem precisar esperar a publicação anterior ser publicada.
            </S.Description>
        );
    };

    return (
        <S.ModalArea isVisible={modalVisible}>
            <S.Container>
                <S.TouchableOpacity onPress={() => setModalVisible(false)}>
                    <S.IconNavigation name="closecircle" />
                </S.TouchableOpacity>
                <S.Title>
                    {renderTitle()}
                </S.Title>
                {renderDescription()}
                {/* <S.Description>
                    Caso deseje, você pode acompanhar os agendamentos clicando em <S.CoverText>Minhas artes</S.CoverText> e após em <S.CoverText>Agendadas</S.CoverText>.
                </S.Description> */}
                {type !== 'banner-site' && isPublishSite ? null : (
                    <S.Description>
                        Ficou com dúvidas? Entre em contato com nosso time de suporte!
                    </S.Description>
                )}
                <S.Button onPress={goToWhatsapp} style={{ flexDirection: 'row' }}>
                    <S.IconFontAwesome name="whatsapp" />
                    <S.TextButton>Whatsapp</S.TextButton>
                </S.Button>
                <S.ProgressBarContainer>
                    <S.ProgressBarFill style={{ width: `${progress}%` }} />
                </S.ProgressBarContainer>
            </S.Container>
        </S.ModalArea>
    );
}
