import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity`
    background-color: ${({ theme }) => theme.colors.white};
    margin-bottom: 32px;
`;

export const Title = styled.Text`
    color: ${({ theme }) => theme.colors.gray[800]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
    margin-top: 10px;
`;

export const SubTitle = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
`;

export const ImageCard = styled.Image`
    width: 100%;
    height: 180px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[25]}; ;
`;

export const PaymentIconArea = styled.View`
    bottom: 10px;
    position: absolute;
    right: 10px;
`;

export const ImageArea = styled.View``;

export const FavoriteButton = styled.TouchableOpacity`
    bottom: 10px;
    position: absolute;
    right: 10px;
`;
