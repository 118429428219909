// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

interface RightProps extends SvgProps {
    color: string;
}

const SvgComponent = (props: RightProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={8.414}
        height={14.828}
        {...props}>
        <Path
            {...props}
            d="m1.414 13.414 6-6-6-6"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        />
    </Svg>
);

export default SvgComponent;
