// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20.828} {...props}>
    <G
      data-name="arrow-left (1)"
      fill="none"
      stroke="#202125"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Path data-name="Linha 24" d="M19 10.387H1.29" />
      <Path data-name="Caminho 93" d="m10.027 19.414-9.027-9 9.027-9" />
    </G>
  </Svg>
)

export default SvgComponent
