import axios from 'axios';

export const getBaseUrl = (): string => {
  const isStaging = window.location.hostname.includes('staging');
  const isBeta = window.location.hostname.includes('beta.adm.midiacarros');
  const isProd = window.location.hostname.includes('adm.midiacarros');
  if (isBeta) {
    return 'https://beta.api.midiacarros.com.br';
  }
  if (isProd) {
    return 'https://api.midiacarros.com.br';
  }
  if (isStaging) {
    return 'https://staging.midiacarrosapi.dotcoding.com.br';
  }

  return 'https://api.midiacarros.com.br';
};

export const api = axios.create({
    baseURL: getBaseUrl(),
});
