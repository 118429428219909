import styled, { css } from 'styled-components/native';
import Feather from '@expo/vector-icons/Feather';
import ImageModal, { ImageDetail } from 'react-native-image-modal';

export interface ResponsiveProps {
    width: number;
    isInput?: boolean;
    validationInput?: boolean;
    grid?: boolean;
}

export const TemplateImage = styled.Image`
    height: auto;
    width: 100%;
`;

export const ImageContainer = styled.TouchableOpacity<ResponsiveProps>`
    height: auto;
    /* height: ${({ width }) => width <= 489 ? (width <= 384 ? '100px' : '150px') : '200px'}; */
    width: ${({ width }) => width <= 489 ? (width <= 384 ? '100px' : '150px') : '200px'};
    /* flex: 1; */
    border-radius: 6px;
    margin: 10px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    /* ${({ width, grid }) =>
        width <= 800 &&
        !grid &&
        css`
            height: 240px;
            width: 240px;
        `};
    ${({ width, grid }) =>
        width <= 1387 &&
        !grid &&
        css`
            height: 450px;
            width: 450px;
        `};
    ${({ width, grid }) =>
        width <= 1277 &&
        !grid &&
        css`
            height: 400px;
            width: 400px;
        `};
    ${({ width, grid }) =>
        width <= 1167 &&
        !grid &&
        css`
            height: 350px;
            width: 350px;
        `};
    ${({ width, grid }) =>
        width <= 1071 &&
        !grid &&
        css`
            height: 250px;
            width: 250px;
        `};
    ${({ grid }) =>
        grid &&
        css`
            height: 290px;
            width: 290px;
            margin-top: 20px;
        `} */
`;

export const ModalContent = styled(ImageModal)``;

export const ContainerClose = styled.TouchableOpacity<ResponsiveProps>`
    position: absolute;
    right: 3px;
    top: 3px;
    height: ${({ width }) => width < 400 ? 20 : 30};
    width: ${({ width }) => width < 400 ? 20 : 30};
    border-radius: 15px;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.red[300]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ContainerDownload = styled.TouchableOpacity<ResponsiveProps>`
    position: absolute;
    left: 3px;
    top: 3px;
    height: ${({ width }) => width < 400 ? 20 : 30};
    width: ${({ width }) => width < 400 ? 20 : 30};
    border-radius: 15px;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.green[100]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const IconClose = styled(Feather)<ResponsiveProps>`
    color: ${({ theme }) => theme.colors.gray[150]};
    font-size: ${({ width }) => width < 400 ? 13 : 20};
`;

export const ContainerArrow = styled.TouchableOpacity<ResponsiveProps>`
    position: absolute;
    right: 3px;
    bottom: 3px;
    height: ${({ width }) => width < 400 ? 15 : 20};
    width: ${({ width }) => width < 400 ? 15 : 20};
    border-radius: 15px;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const IconArrow = styled(Feather)<ResponsiveProps>`
    color: ${({ theme }) => theme.colors.gray[150]};
    font-size: ${({ width }) => width < 400 ? 12 : 16};
`;
