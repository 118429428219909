// @ts-nocheck
import React, { useState, useEffect } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { useWindowDimensions } from 'react-native';
import * as S from './styles';
import { Button } from '../Button';
import { Modal } from '../Modal';
import Theme from '../../global/styles/theme';
import { allTagsFixed } from './consts';
import { createDescription, createDescriptionUser, deleteDescription, deleteDescriptionUser, editDescription, editDescriptionUser, getDescription, getDescriptionUser } from '../../services/descriptions';
import theme from '../../global/styles/theme';

interface PropsDesc {
    type: 'adm' | 'client';
    title?: string;
    idUser?: number;
}

export function ConfigDescription(props: PropsDesc) {
    const { type, title, idUser } = props;

    const width = useWindowDimensions().width;
    const fontBlack = Theme.colors.lowBlack;

    const [openEditDescription, setOpenEditDescription] = useState(false);
    const [loading, setLoading] = useState(false);
    const [infoBox, setInfoBox] = useState(null);
    const [description, setDescription] = useState('');
    const [uppercase, setUppercase] = useState(false);
    const [positionActualKeyboard, setPositionActualKeyboard] = useState(undefined);
    const [allTags, setAllTags] = useState(allTagsFixed);
    const [tagsSelected, setTagsSelected] = useState([]);
    const [listDescription, setListDescription] = useState([]);
    const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
    const [descriptionForDeleted, setDescriptionForDeleted] = useState(null);

    // methods - api
    const getDescriptionList = async () => {
        const responseAdm = await getDescription();
        if (type === 'adm') {
            const list = (responseAdm?.result?.list || []).sort((a, b) => {
                if (a.position < b.position) return -1;
                if (a.position > b.position) return 1;
                return 0;
            });
            return setListDescription(list || []);
        }
        const responseUser = await getDescriptionUser(idUser);
        const listUser = responseUser?.result?.list;
        const listAdm = responseAdm?.result?.list;
        const formatList = [1,2,3].map((v) => {
            const getItemUser = listUser.find((e) => e.position === v);
            if (getItemUser?.id) {
                return {
                    ...getItemUser,
                    type: 'client',
                };
            }
            const getItemAdm = listAdm.find((e) => e.position === v);
            if (getItemAdm?.id) {
                return {
                    ...getItemAdm,
                    type: 'adm',
                };
            }
            return null;
        }).filter((e) => e).sort((a, b) => {
            if (a.position < b.position) return -1;
            if (a.position > b.position) return 1;
            return 0;
        });

        setListDescription([]);
        setListDescription(formatList || []);
    };

    const createNewDescription = async () => {
        try {
            if (type === 'adm') {
                if (infoBox?.id) {
                    const body = {
                        description,
                        position: infoBox.position,
                    };
                    setLoading(true);
                    await editDescription(infoBox?.id, body);
                    await getDescriptionList();
                    resetState();
                    setOpenEditDescription(false);
                } else {
                    if (listDescription.length < 3) {
                        const getAllPosition = listDescription.map((v) => v.position);
                        const verifyNumber = [1,2,3].filter((e) => !getAllPosition.includes(e));
                        const body = {
                            description,
                            position: verifyNumber[0],
                        };
                        setLoading(true);
                        await createDescription(body);
                        await getDescriptionList();
                        resetState();
                        setOpenEditDescription(false);
                    }
                }
            } else {
                if (infoBox?.id && infoBox?.type === 'client') {
                    const body = {
                        description,
                        idUser,
                        position: infoBox.position,
                        uppercase,
                    };
                    setLoading(true);
                    await editDescriptionUser(infoBox?.id, body);
                    await getDescriptionList();
                    resetState();
                    setOpenEditDescription(false);
                } else if (infoBox?.id) {
                    const body = {
                        description,
                        idUser,
                        position: infoBox.position,
                        uppercase,
                    };
                    setLoading(true);
                    await createDescriptionUser(body);
                    await getDescriptionList();
                    resetState();
                    setOpenEditDescription(false);
                }
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const deleteOneDescription = async (item: any) => {
        try {
            setLoading(true);
            if (type === 'adm') {
                await deleteDescription(item.id);
                await getDescriptionList();
            }
            if (type === 'client') {
                await deleteDescriptionUser(item.id);
                await getDescriptionList();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const resetState = () => {
        setLoading(false);
        setInfoBox(null);
        setDescription('');
        setTagsSelected([]);
    };

    function Checkbox(name: string) {
        const handleCheckboxPress = () => {
            setUppercase(!uppercase);
        }

        return (
            <>
                <S.CheckboxCircle
                    isMarked={uppercase}
                    onPress={handleCheckboxPress}
                >
                    <S.CheckboxCircleMarked isMarked={uppercase}/>
                </S.CheckboxCircle>
                <S.CheckboxText>{name}</S.CheckboxText>
            </>
        );
    }

    // useEffect
    useEffect(() => {
        getDescriptionList();
    }, []);

    let boxAreaRef = null;

    function addDataToInput(data: string, key: string) {
        if (typeof positionActualKeyboard?.start === 'number' && typeof positionActualKeyboard?.end === 'number') {
            const initialInput = description.substring(0, positionActualKeyboard.start);
            const finalInput = description.substring(positionActualKeyboard.start, description.length);
            const newDescription = `${initialInput}${data || ''}${finalInput}`;
            setDescription(newDescription);
            if (key) {
                setTagsSelected([...tagsSelected, key]);
            }
            boxAreaRef?.focus();
            const newPosition = {
                start: positionActualKeyboard.start + data.length,
                end: positionActualKeyboard.end + data.length,
            };
            setPositionActualKeyboard(newPosition)
        } else {
            setDescription(`${description}${data}`);
            if (key) {
                setTagsSelected([...tagsSelected, key]);
            }
        }
    }

    function getCurrentPositionActualKeyboard(event: NativeSyntheticEvent<TextInputSelectionChangeEventData>) {
        const { selection } = event.nativeEvent;
        setPositionActualKeyboard(selection)
    }

    function BoxDescription(v: any, i: number) {
        return (
            <S.Column key={v.id}>
                <S.TextDefault
                    style={{ fontFamily: theme.font.segoeUi.semiBold, fontSize: 16, marginBottom: 10 }}
                >
                    {`Descrição ${i + 1}`}
                </S.TextDefault>
                <S.Box>
                    <S.TextDefault>{v.description || ''}</S.TextDefault>
                </S.Box>
                <S.Row style={{ justifyContent: 'space-between' }}>
                    <Button
                        style={{ width: 100, height: 40, marginTop: 10 }}
                        onPress={() => {
                            setDescription(v.description || '');
                            setUppercase(v.uppercase || false);
                            setInfoBox({
                                ...v,
                                position: v.position || i + 1,
                            });
                            setOpenEditDescription(true);
                        }}
                        label="Editar"
                        loading={false}
                    />
                    {type === 'adm' || v?.type === 'client' ? (
                        <Button
                            style={{ width: 100, height: 40, marginTop: 10 }}
                            onPress={() => {
                                setDescriptionForDeleted(v);
                                setModalConfirmDelete(true);
                            }}
                            label={type === 'adm' ? 'Excluir' : 'Redefinir'}
                            loading={loading}
                        />
                    ) : null}
                </S.Row>
            </S.Column>
        );
    }

    function RenderArtInfo() {
        return (
            <>
                <S.ContainerAlign width={width}>
                    <S.Row style={{ justifyContent: 'space-between' }}>
                        <S.Title>{title}</S.Title>
                        {listDescription.length < 3 && type === 'adm' ? (
                            <Button
                                style={{ width: 150, marginBottom: 10 }}
                                onPress={() => {
                                    resetState();
                                    setOpenEditDescription(true);
                                }}
                                label="Criar novo"
                                loading={false}
                            />
                        ) : null}
                    </S.Row>
                    <S.Content width={width}>
                        {listDescription.map((v, i) => BoxDescription(v, i))}
                    </S.Content>
                </S.ContainerAlign>
            </>
        );
    }

    function closedModal() {
        setDescriptionForDeleted(null);
        setModalConfirmDelete(!modalConfirmDelete);
    }

    return (
        <S.Container style={{ flex: title === 'Descrições' ? 1 : undefined }}>
            {RenderArtInfo()}
            <Modal
                isVisible={modalConfirmDelete}
                closeModal={closedModal}
            >
                <S.ContainerModalConfirm width={width}>
                    <S.TextDefault
                        fontSize={25}
                        fontFamily="bold"
                        color={fontBlack} style={{ textAlign: 'center' }}
                    >
                        {`Tem certeza que deseja ${type === 'adm' ? 'excluir' : 'redefinir'} essa descrição?`}
                    </S.TextDefault>
                    <S.ButtonModalConfirm>
                        <S.ButtonModalArea
                            background="red"
                            onPress={closedModal}
                            style={{ width: width < 400 ? (width < 350 ? (width < 300 ? 75 : 100) : 125) : 150 }}
                        >
                            <S.ButtonModalText style={{ paddingLeft: 5, paddingRight: 5 }}>
                                Cancelar
                            </S.ButtonModalText>
                        </S.ButtonModalArea>
                        <S.ButtonModalArea
                            background="purple"
                            onPress={() => {
                                setModalConfirmDelete(false);
                                deleteOneDescription(descriptionForDeleted);
                            }}
                            style={{ width: width < 400 ? (width < 350 ? (width < 300 ? 75 : 100) : 125) : 150 }}
                        >
                            <S.ButtonModalText style={{ paddingLeft: 5, paddingRight: 5 }}>
                                Confirmar
                            </S.ButtonModalText>
                        </S.ButtonModalArea>
                    </S.ButtonModalConfirm>
                </S.ContainerModalConfirm>
            </Modal>
            <Modal
                isVisible={openEditDescription}
                closeModal={() => {
                    setOpenEditDescription(!openEditDescription);
                }}
            >
                <S.ContainerModalGallery>
                    <S.ScrollFilters>
                        <S.ContentEdit width={width}>
                            <S.Column style={{ height: 'auto' }}>
                                <S.CheckboxArea>
                                    {Checkbox('Aplicar letras maiúsculas')}
                                </S.CheckboxArea>
                                <S.BoxArea
                                    placeholder="Descrição da publicação"
                                    value={description}
                                    multiline
                                    numberOfLine={3}
                                    ref={(ref) => !boxAreaRef ? boxAreaRef = ref : boxAreaRef = boxAreaRef}
                                    autoFocus
                                    height={300}
                                    selection={positionActualKeyboard}
                                    onSelectionChange={getCurrentPositionActualKeyboard}
                                    onChangeText={(txt) => setDescription(txt)}
                                />
                            </S.Column>
                            <S.Column style={{ height: 'auto' }}>
                                <Picker data={data} onEmojiSelect={(emoji) => addDataToInput(emoji.native)} />
                            </S.Column>
                            <S.Column style={{ height: 'auto' }}>
                                {!allTags ? null : (
                                    <S.InputPickerContainer>
                                        {!allTags.headers[1]?.id ? null : (
                                            <S.FlowTags>
                                                <S.TouchableOpacityEmpty
                                                    onPress={() => addDataToInput(allTags.headers[1]?.id, allTags.headers[1]?.name)}
                                                    focus={tagsSelected.includes(allTags.headers[1]?.name)}
                                                >
                                                    <S.Tag>
                                                        {allTags.headers[1]?.name}
                                                    </S.Tag>
                                                </S.TouchableOpacityEmpty>
                                            </S.FlowTags>
                                        )}
                                        <S.TextDefault
                                            fontSize={15}
                                            fontFamily="500"
                                            color={fontBlack}
                                            margin={10}
                                        >
                                            Veículo
                                        </S.TextDefault>
                                        <S.FlowTags>
                                            {allTags.vehicle.map((v) => (
                                                <S.TouchableOpacityEmpty
                                                    onPress={() => addDataToInput(v.id, v.name, v.name)}
                                                    focus={tagsSelected.includes(v.name)}
                                                >
                                                    <S.Tag>
                                                        {v.name}
                                                    </S.Tag>
                                                </S.TouchableOpacityEmpty>
                                            ))}
                                        </S.FlowTags>
                                        <S.TextDefault
                                            fontSize={15}
                                            fontFamily="500"
                                            color={fontBlack}
                                            margin={10}
                                        >
                                            Vendedor
                                        </S.TextDefault>
                                        <S.FlowTags>
                                            {allTags.sellers.map((v) => (
                                                <S.TouchableOpacityEmpty
                                                    onPress={() => addDataToInput(v.id, v.name)}
                                                    focus={tagsSelected.includes(v.name)}
                                                >
                                                    <S.Tag>
                                                        {v.name}
                                                    </S.Tag>
                                                </S.TouchableOpacityEmpty>
                                            ))}
                                        </S.FlowTags>
                                        <S.TextDefault
                                            fontSize={15}
                                            fontFamily="500"
                                            color={fontBlack}
                                            margin={10}
                                        >
                                            Valores e Financiamentos
                                        </S.TextDefault>
                                        <S.FlowTags>
                                            {allTags.values.map((v) => (
                                                <S.TouchableOpacityEmpty
                                                    onPress={() => addDataToInput(v.id, v.name)}
                                                    focus={tagsSelected.includes(v.name)}
                                                >
                                                    <S.Tag>
                                                        {v.name}
                                                    </S.Tag>
                                                </S.TouchableOpacityEmpty>
                                            ))}
                                        </S.FlowTags>
                                        <S.TextDefault
                                            fontSize={15}
                                            fontFamily="500"
                                            color={fontBlack}
                                            margin={10}
                                        >
                                            Informações adicionais
                                        </S.TextDefault>
                                        <S.FlowTags>
                                            {allTags.info.map((v) => (
                                                <S.TouchableOpacityEmpty
                                                    onPress={() => addDataToInput(v.id, v.name)}
                                                    focus={tagsSelected.includes(v.name)}
                                                >
                                                    <S.Tag>
                                                        {v.name}
                                                    </S.Tag>
                                                </S.TouchableOpacityEmpty>
                                            ))}
                                        </S.FlowTags>
                                        <S.TextDefault
                                            fontSize={15}
                                            fontFamily="500"
                                            color={fontBlack}
                                            margin={10}
                                        >
                                            Opcionais
                                        </S.TextDefault>
                                        <S.FlowTags>
                                            {allTags.optionals.map((v) => (
                                                <S.TouchableOpacityEmpty
                                                    onPress={() => addDataToInput(v.id === true ? v.name : v.id, v.name)}
                                                    focus={tagsSelected.includes(v.name)}
                                                >
                                                    <S.Tag>
                                                        {v.name}
                                                    </S.Tag>
                                                </S.TouchableOpacityEmpty>
                                            ))}
                                        </S.FlowTags>
                                    </S.InputPickerContainer>
                                )}
                            </S.Column>
                        </S.ContentEdit>
                        <S.Row>
                            <Button
                                style={{ width: 150, marginBottom: 10, marginRight: 10 }}
                                onPress={() => {
                                    resetState();
                                    setOpenEditDescription(false);
                                }}
                                label="Cancelar"
                                loading={false}
                            />
                            <Button
                                style={{ width: 150, marginBottom: 10 }}
                                onPress={createNewDescription}
                                label="Salvar"
                                loading={loading}
                            />
                        </S.Row>
                    </S.ScrollFilters>
                </S.ContainerModalGallery>
            </Modal>
        </S.Container>
    );
}
