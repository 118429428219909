// @ts-nocheck
import React, { useRef, useState } from 'react';
import * as S from './styles';
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu';
import { useNavigation } from '@react-navigation/native';

import MoreOption from '../../../../../assets/images/svg/moreOption';
import PenIcon from '../../../../../assets/images/svg/penIcon';
import Theme from '../../../../../global/styles/theme';
import TrashIcon from '../../../../../assets/images/svg/trashIcon';
import PaymentIconGold from '../../../../../assets/images/svg/paymentIconGold';

import { DeleteModal } from '../DeleteModal';
import { Modal } from '../../../../../components/Modal';
import { deleteArt } from '../../../../../services/arts';
import { CoditionModal } from '../../../../../components/CoditionModal';
import { LoadingModal } from '../../../../../components/LoadingModal';
import { TouchableOpacity } from 'react-native';
import { ImageDetail } from 'react-native-image-modal';

interface ItemProps {
    id: number;
    title: string;
    date: string;
    thumb: string;
    type: string,
}

interface ModalInfoProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    buttonFunction: () => void,
}

interface CardProps {
    item: ItemProps;
    refreshArtList: () => void,
}

export function Card(props: CardProps) {
    const { item: { type, thumb, title, id, user_name, countArts }, refreshArtList } = props;
    const uri = { uri: thumb };
    const navigation = useNavigation();

    const [modalVisible, setModalVisible] = useState(false);
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);
    const [loadingModal, setLoadingModal] = useState(false);

    const imageModalRef = useRef<ImageDetail>(null);

    function handleOpenModal() {
        imageModalRef.current?.setState({
            isOpen: true,
            origin: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            },
        });
    }

    function RenderOptionArea(
        optionName: string,
        OptionIcon: any,
        onPressFunction?: () => void,
    ) {
        return (
            <MenuOption onSelect={onPressFunction}>
                <S.OptionArea>
                    <OptionIcon />
                    <S.OptionText>{optionName}</S.OptionText>
                </S.OptionArea>
            </MenuOption>
        );
    }

    const changeModalVisible = () => {
        setModalVisible(!modalVisible);
    };

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    async function handleDelete() {
        setLoadingModal(true);
        const { message, status } = await deleteArt(id);
        changeModalVisible();

        if (status === 200) {
            setLoadingModal(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'success',
                titleModal: 'Sucesso',
                descriptionModal: message,
                buttonText: 'Continuar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: refreshArtList,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        } else {
            setLoadingModal(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        }
    }

    function handleEditPress() {
        navigation.navigate('CreateArtScreen', { type: 'edit', item: props.item });
    }

    return (
        <S.Container>
            <TouchableOpacity onPress={handleOpenModal}>
                <S.ImageCard onContextMenu={(e) => e.preventDefault()} resizeMode='center' source={uri} />
            </TouchableOpacity>
            <S.InfoArea>
                <S.AdjustTitles>
                    <S.TitleArea>
                        <S.TitleArt>{title}</S.TitleArt>
                    </S.TitleArea>
                    <S.TitleArea>
                        <S.Title>{`${type} / ${String(id).padStart(6, "0")} / ${countArts}x`}</S.Title>
                    </S.TitleArea>
                </S.AdjustTitles>
                <S.MoreOptionButton>
                    <Menu>
                        <MenuTrigger text="" style={S.buttonStyle}>
                            <MoreOption />
                        </MenuTrigger>
                        <MenuOptions
                            optionsContainerStyle={S.optionsContainerStyle}>
                            {RenderOptionArea(
                                'Editar',
                                PenIcon,
                                handleEditPress,
                            )}
                            {RenderOptionArea('Excluir', TrashIcon, changeModalVisible)}
                        </MenuOptions>
                    </Menu>
                </S.MoreOptionButton>
            </S.InfoArea>
            {!user_name ? null : (
                <S.AdjustSubTitle>
                    <S.SubTitle>{user_name}</S.SubTitle>
                    <PaymentIconGold style={{ marginLeft: 10 }} />
                </S.AdjustSubTitle>
            )}
            <CoditionModal
                titleModal={modalInfo.titleModal}
                descriptionModal={modalInfo.descriptionModal}
                typeModal={modalInfo.typeModal}
                buttonText={modalInfo.buttonText}
                modalVisible={modalInfo.modalVisible}
                setModalVisible={modalInfo.setModalVisible}
                buttonFunction={modalInfo.buttonFunction}
            />
            <Modal isVisible={modalVisible} closeModal={changeModalVisible}>
                <DeleteModal
                    handleCancelPress={changeModalVisible}
                    handleConfirmPress={handleDelete}
                />
            </Modal>
            <LoadingModal
                visible={loadingModal}
                titleLoading='Excluindo arte...'
            />
            <S.ModalContent
                ref={imageModalRef}
                modalRef={imageModalRef}
                resizeMode="contain"
                imageBackgroundColor="transparent"
                source={uri}
            ></S.ModalContent>
        </S.Container>
    );
}
