// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Circle, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={55} height={55} {...props}>
    <G
      data-name="info (1)"
      transform="translate(-.5 -.5)"
      fill="none"
      stroke="#0e66d0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <Circle
        data-name="Elipse 45"
        cx={26}
        cy={26}
        r={26}
        transform="translate(2 2)"
      />
      <Path data-name="Linha 363" d="M28 38V28" />
      <Path data-name="Linha 364" d="M28 18h0" />
    </G>
  </Svg>
)

export default SvgComponent
