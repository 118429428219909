import styled, { css } from 'styled-components/native';
import Feather from '@expo/vector-icons/Feather';

import { ResponsiveProps } from '../../global/types/index';

interface FocusedProps {
    isFocused: boolean;
}

interface NavigationProps extends ResponsiveProps, FocusedProps {}

export const Container = styled.ScrollView<ResponsiveProps>`
    ${({ width }) =>
        width <= 860 &&
        css`
            width: 100%;
            max-width: 100%;
        `}
    ${({ width }) =>
        width > 860 &&
        css`
            width: 235px;
            max-width: 235px;
        `}
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    height: 100%;
    margin-right: 7px;
    margin-bottom: -40px;
    padding: 40px 11px 0px 9px;
`;

export const UserContainer = styled.TouchableOpacity`
    flex-direction: row;
    padding-left: 17px;
`;

export const UserImage = styled.Image`
    width: 40px;
    height: 40px;
    border-radius: 20px;
`;

export const UserInfoContainer = styled.View`
    margin-left: 12px;
`;

export const UserName = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    color: ${({ theme }) => theme.colors.gray[800]};
    font-size: 16px;
`;

export const UserType = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    color: ${({ theme }) => theme.colors.gray[800]};
    font-size: 12px;
    margin-top: 3px;
`;

export const UserPlanContainer = styled.TouchableOpacity<ResponsiveProps>`
    ${({ width }) =>
        width <= 653 &&
        css`
            width: 100%;
        `}
    flex-direction: row;
    align-items: center;
    height: 49px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[200]};
    padding: 14px 20px;
    margin-top: 19px;
`;

export const UserPlanTitle = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    color: ${({ theme }) => theme.colors.gray[800]};
    font-size: 14px;
    margin-left: 20px;
`;

export const NavigationContainer = styled.View`
    margin-top: 15px;
`;

export const ButtonNavigation = styled.TouchableOpacity<NavigationProps>`
    ${({ width }) =>
        width <= 653 &&
        css`
            width: 100%;
        `}
    flex-direction: row;
    align-items: center;
    padding: 16px;
    background-color: ${({ theme, isFocused }) =>
        isFocused && theme.colors.purple.rgba[30]};
    border-radius: 6px;
`;

export const ButtonNavigationIconContainer = styled.View`
    width: 41px;
`;

export const ButtonNavigationIcon = styled(Feather)<FocusedProps>`
    font-size: 20px;
    color: ${({ theme, isFocused }) =>
        isFocused ? theme.colors.purple[500] : theme.colors.gray[150]};
`;

export const ButtonNavigationText = styled.Text<FocusedProps>`
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    color: ${({ theme, isFocused }) =>
        isFocused ? theme.colors.purple[500] : theme.colors.gray[150]};
    font-size: 16px;
`;
