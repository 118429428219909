import { api } from './api';

export async function connectionsList(page: number, searchTxt?: string, status?: string, idPlan?: string) {
    let baseQuery = `/adm/connections?page=${page}&limit=10`;
    baseQuery += searchTxt ? `&searchTxt=${searchTxt}` : '';
    baseQuery += status ? `&status=${status}` : '';
    baseQuery += idPlan ? `&idPlan=${idPlan}` : '';
    try {
        const { data } = await api.get(baseQuery);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}
