// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Circle, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.414}
    height={20.414}
    {...props}
  >
    <G
      transform="translate(-2 -2)"
      fill="none"
      stroke="#6746c8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      {...props}
    >
      <Circle
        data-name="Elipse 1"
        cx={8}
        cy={8}
        r={8}
        transform="translate(3 3)"
      />
      <Path data-name="Linha 5" d="m21 21-4.35-4.35" />
    </G>
  </Svg>
)

export default SvgComponent
