export const statusPlans = [
    { label: 'Selecionar', value: '0' },
    { label: 'Ativo', value: 'active' },
    { label: 'Inativo', value: 'inactive' },
];

export const conditionCar = [
    { label: 'Selecionar', value: '0' },
    { label: 'Novo', value: 'Novo' },
    { label: 'Seminovo', value: 'Seminovo' },
];

export const typeFuel = [
    { label: 'Selecionar', value: '0' },
    { label: 'Álcool', value: 'Álcool' },
    { label: 'Elétrico', value: 'Elétrico' },
    { label: 'Gasolina', value: 'Gasolina' },
    { label: 'GNV', value: 'GNV' },
];

export const typeGear = [
    { label: 'Selecionar', value: '0' },
    { label: 'Automático', value: 'Automático' },
    { label: 'Manual', value: 'Manual' },
];

export const yesOrNot = [
    { label: 'Selecionar', value: '0' },
    { label: 'Sim', value: 'Sim' },
    { label: 'Não', value: 'Não' },
];

export const listColors = [
    'Amarelo',
    'Azul',
    'Branco',
    'Cinza',
    'Laranja',
    'Lilas',
    'Preto',
    'Rosa',
    'Roxo',
    'Verde Água',
    'Verde',
    'Vermelho',
];
