import { addDays, lightFormat } from 'date-fns';
import React from 'react';

// styles
import * as S from './styles';

// interfaces
interface DataProps {
    age: number;
    birth_date: string;
    city: string;
    id: number;
    name: string;
    is_send?: boolean;
}

interface CardProps {
    data: DataProps;
    handleSentBirthday: () => void;
}

export function Card(props: CardProps) {
    const { data, handleSentBirthday } = props;

    function renderBallon() {
        if (!data.is_send) {
            return null;
        }

        return (
            <S.BalloonSent />
        );
    }

    const disabled = !data?.birth_date?.includes(lightFormat(Date.now(), 'dd/MM'));
    return (
        <S.Opacity disabled={disabled}>
            <S.Container sent={data.is_send}>
                {renderBallon()}
                <S.Title>{data?.name}</S.Title>
                <S.Content>
                    <S.Label>
                        Data: <S.Info>{data?.birth_date}</S.Info>
                    </S.Label>
                    <S.Label>
                        Idade: <S.Info>{data.age} anos</S.Info>
                    </S.Label>
                    <S.Label>
                        Cidade: <S.Info>{data.city}</S.Info>
                    </S.Label>
                </S.Content>
                <S.Button sent={data.is_send} onPress={handleSentBirthday}>
                    <S.ButtonText>Parabenizar</S.ButtonText>
                </S.Button>
            </S.Container>
        </S.Opacity>
    );
}
