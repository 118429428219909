import React, { useEffect, useState } from 'react';
import {
    createNativeStackNavigator,
    NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Theme from '../../global/styles/theme';

import CloseIcon from '../../assets/images/close.png';
import { HomeScreen } from '../../screens/ClientScreen/HomeScreen';
import { SavedArts } from '../../screens/ClientScreen/SavedArts';
import { SavedArtsList } from '../../screens/ClientScreen/SavedArtsList';
import { EditPerfilScreen } from '../../screens/DashboardScreen/EditPerfilScreen';
import { CreateArtScreen } from '../../screens/DashboardScreen/CreateArtScreen';
import { VehicleScreen } from '../../screens/DashboardScreen/VehicleScreen';
import { PaymentControl } from '../../screens/ClientScreen/PaymentControl';
import { PlanScreen } from '../../screens/ClientScreen/PlanScreen';
import { BirthDays } from '../../screens/ClientScreen/BirthDays';
import * as S from './styles';

import { DrawerClients } from '../../components/DrawerClients';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getArtAlertError } from '../../services/arts';
import toast from 'react-hot-toast';
import { getPages } from '../../services/social';

type RootStackParamList = {
    HomeScreen: undefined;
    SavedArts: undefined;
    EditPerfilScreen: undefined;
    SavedArtsList: undefined;
    CreateArtScreen: undefined;
    VehicleScreen: undefined;
    PaymentControl: undefined;
    PlanScreen: undefined,
    BirthDays: undefined,
};

export interface NavigationProps {
    navigation: NativeStackNavigationProp<RootStackParamList, any>;
}

const { Navigator, Screen } = createNativeStackNavigator<RootStackParamList>();

export function ClientDrawerNavigation() {
    const screenOptions = { headerShown: false };
    const initialPageParams = { initialTab: 'social' };
    const navigation = useNavigation();
    const userInfo = useSelector((state: any) => state.user.user);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    async function getTypeBirthScreen(configsUser) {
        const typeBirthScreen = await AsyncStorage.getItem(
            '@midiacarros:birthday_routes',
        );
        const idVehicle = await AsyncStorage.getItem(
            '@midiacarros:publication_routes',
        );

        if (typeBirthScreen) {
            return navigation.navigate('BirthDays');
        }
        const sessionExpired = configsUser === '{}' ? true : !configsUser?.expired_at ? true : new Date() > new Date(configsUser?.expired_at);
        if (idVehicle && (!sessionExpired || configsUser === '{}')) {
            return navigation.navigate('HomeScreen', { idVehicle, title: 'Publicações' });
        }
    }

    function handleOpenDrawer() {
        setDrawerIsOpen(!drawerIsOpen);
    }

    useEffect(() => {
        getConfigs();
    }, []);

    async function getConfigs() {
        const configs = await getPages(userInfo?.user?.id);
        getTypeBirthScreen(JSON.stringify(configs?.result) === '{}' ? JSON.stringify(configs?.result) : configs?.result.configuration || null);
    }

    const getAlertLogs = async (isRefresh) => {
        const response = await getArtAlertError(userInfo?.user?.id);
        if (response?.result?.idsPublishs?.length) {
            toast.custom(
                <div
                    style={{
                        borderRadius: '10px',
                        background: Theme.colors.red[500],
                        color: '#fff',
                        fontFamily: 'Segoe UI',
                        height: '80px',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 10,
                        paddingTop: 20,
                        position: 'fixed',
                        top: 20,
                        right: 20,
                        zIndex: 999999999,
                    }}
                >
                    <S.CloseButton
                        onPress={() => {
                            toast.dismiss();
                            getAlertLogs(true);
                        }}
                    >
                        <S.CloseIcon resizeMode="contain" source={CloseIcon} />
                    </S.CloseButton>
                    <text>Uma de suas publicações retornou erro ao ser publicada.</text>
                    <div
                        style={{ backgroundColor: 'white', color: Theme.colors.red[500], width: 200, borderRadius: 3, marginTop: 10, marginBottom: 10, padding: 3, cursor: 'pointer' }}
                        onClick={() => {
                            let initialParams: any = {
                                title: 'Minhas artes',
                                currentPageInitial: response?.result?.redirectPublish === 'publication-now' ? 'published' : 'scheduled',
                            };
                            if (response?.result?.idsPublishs?.length === 1) {
                                initialParams = {
                                    ...initialParams,
                                    idPublish: response?.result?.idsPublishs[0],
                                };
                            }
                            navigation.navigate('SavedArtsList', initialParams);
                            setCurrentPage(response?.result?.redirectPublish === 'publication-now' ? 2 : 1);
                            toast.dismiss();
                            getAlertLogs(true);
                        }}
                    >
                        Clique aqui e republique-a.
                    </div>
                </div>,
                {...Theme.toast, duration: 3600000 }
            );
            return null;
        }
        if (isRefresh) {
            setTimeout(() => {
                getAlertLogs(true);
            }, 10000);
        }
    };

    useEffect(() => {
        if (userInfo?.user?.id) {
            getAlertLogs(true);
        }
    }, [userInfo?.user?.id]);

    function DrawerNavigation() {
        return (
            <DrawerClients currentPage={currentPage} setCurrentPage={setCurrentPage} drawerIsOpen={drawerIsOpen} setDrawerIsOpen={setDrawerIsOpen} handleOpenDrawer={handleOpenDrawer}>
                <Navigator screenOptions={screenOptions}>
                    <Screen name="EditPerfilScreen" component={EditPerfilScreen} initialParams={initialPageParams} />
                    <Screen name="SavedArtsList" component={SavedArtsList} />
                    <Screen name="HomeScreen" component={(component) => HomeScreen(component, handleOpenDrawer, drawerIsOpen)} />
                    <Screen name="SavedArts" component={SavedArts} />
                    <Screen name="CreateArtScreen" component={CreateArtScreen} />
                    <Screen name="VehicleScreen" component={VehicleScreen} />
                    <Screen name="PaymentControl" component={PaymentControl} />
                    <Screen name="PlanScreen" component={PlanScreen} />
                    <Screen name="BirthDays" component={BirthDays} />
                </Navigator>
            </DrawerClients>
        );
    }

    return DrawerNavigation();
}
