// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, Circle, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    data-name="Componente 35 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    {...props}
  >
    <Circle data-name="Elipse 41" cx={7.5} cy={7.5} r={7.5} fill="#44b556" />
    <G fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <Path data-name="Linha 50" d="M7.279 10.203V4.562" />
      <Path data-name="Caminho 106" d="m4.954 7.383 2.365-2.949 2.365 2.949" />
    </G>
  </Svg>
)

export default SvgComponent
