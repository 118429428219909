// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { VictoryPie } from 'victory';

import * as S from './styles';
import theme from '../../../../../global/styles/theme';

import { PopupOption } from '../PopupOption';
import { getPlansDashboard } from '../../../../../services/dashboard';

interface PlansListProps {
    name?: string,
    total_signature?: string,
    percentage?: string,
}
interface SignedPlansInfo  {
    plansList?: Array<PlansListProps>
}

interface PopupOptionProps {
    nameOption: string,
    option: 'today' | 'week' | 'month',
}

export function SignedPlansChart(props: SignedPlansInfo) {
    const { plansList } = props;
    const colors = ['#44B556', '#F29423', '#1473E6', '#FF00FF', '#F2238B'];
    const padding = { left: 10, top: 40, right: 10, bottom: 40 };
    const pieLabelStyle = { labels: { fill: theme.colors.lowBlack, fontSize: 10 }};
    const filterList = plansList?.filter((el) => Number(el.total_signature) > 0);
    const pieInfoGraph = filterList?.map((el) => {
        const newPieList = {
            x: `${el.name} \n ${Number(el.percentage).toFixed(2)} ${el.total_signature}`,
            y: Number(el.total_signature),
        };
        return newPieList;
    });

    const [popupOption, setPopupOption] = useState<PopupOptionProps>({} as PopupOptionProps);
    const [plansPeriodList, setPlansPeriodList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (popupOption.option) {
            setLoading(false);
            getPlansPeriod();
        } else {
            setPlansPeriodList(pieInfoGraph);
        }
    }, [popupOption]);

    async function getPlansPeriod() {
        const { result } = await getPlansDashboard(popupOption.option);
        const filterPlansArray = result.list.filter((el) => Number(el.total_signature) > 0);

        const newArrayPlans = filterPlansArray.map((el) => {
            const formatedArrayChart = {
                x: `${el.name} \n ${Number(el.percentage).toFixed(2)} ${el.total_signature}`,
                y: Number(el.total_signature),
            };

            return formatedArrayChart;
        });
        setPlansPeriodList(newArrayPlans);
        setLoading(true);
    }

    function RenderOptionName() {
        if (!popupOption.option) {
            return null;
        }

        return (
            <S.OptionNameArea>
                <S.TextDefault
                    fontSize={10}
                    fontFamily='regular'
                >
                    {popupOption.nameOption}
                </S.TextDefault>
            </S.OptionNameArea>
        );
    }

    function RenderCoditionInfo() {
        if (!loading) {
            return (
                <S.Loading />
            );
        }

        return RenderChart();
    }

    function RenderChart() {
        if (!plansPeriodList.length) {
            return (
                <S.VoidTextArea>
                    <S.TextDefault
                        fontSize={14}
                        fontFamily='semi'
                    >
                        Não há dados!
                    </S.TextDefault>
                </S.VoidTextArea>
            );
        }

        return (
            <S.ChartArea>
                <VictoryPie
                    height={210}
                    data={plansPeriodList}
                    innerRadius={55}
                    padding={padding}
                    colorScale={colors}
                    labelPosition='centroid'
                    style={pieLabelStyle}
                />
            </S.ChartArea>
        );
    }

    return (
        <S.SignedChartArea>
            <S.TitleArea>
                <S.TextDefault
                    fontSize={14}
                    fontFamily='semi'
                >
                    Planos assinados
                </S.TextDefault>
                <S.OptionArea>
                    {RenderOptionName()}
                    <PopupOption setOption={setPopupOption} />
                </S.OptionArea>
            </S.TitleArea>
           {RenderCoditionInfo()}
        </S.SignedChartArea>
    );
}
