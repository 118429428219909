import styled from 'styled-components/native';
import Balloon from '../../../../../assets/images/svg/balloon';
import theme from '../../../../../global/styles/theme';

// interface
interface sentProps {
    sent?: boolean;
}

function getBackgroundCard(props: any) {
    if (props.sent) {
        return props.theme.colors.green[100];
    }
    return props.theme.colors.gray[350];
}

function getBackgroundButton(props: any) {
    if (props.sent) {
        return props.theme.colors.green[450];
    }
    return props.theme.colors.purple[500];
}

interface OpacityInterface {
    disabled: boolean
}

export const Opacity = styled.View<OpacityInterface>`
    opacity: ${({ disabled }) => disabled ? 0.5 : 1};
`;

export const Container = styled.View<sentProps>`
    position: relative;
    width: 230px;
    height: 163px;
    padding: 12px;
    background-color: ${(props) => getBackgroundCard(props)};
    justify-content: space-between;
    margin-right: 20px;
    margin-bottom: 20px;
`;

export const Title = styled.Text`
    color: ${(props) => props.theme.colors.gray[800]};
    font-size: 18px;
    font-family: ${(props) => props.theme.font.segoeUi.bold};
`;

export const Content = styled.View`
    width: 100%;
`;

export const Label = styled.Text`
    color: ${(props) => props.theme.colors.gray[800]};
    font-size: 14px;
    font-family: ${(props) => props.theme.font.segoeUi.bold};
`;

export const Info = styled.Text`
    color: ${(props) => props.theme.colors.gray[800]};
    font-size: 14px;
    font-family: ${(props) => props.theme.font.segoeUi.regular};
`;

export const Button = styled.TouchableOpacity<sentProps>`
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${(props) => getBackgroundButton(props)};
`;

export const ButtonText = styled.Text`
    color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    font-family: ${(props) => props.theme.font.segoeUi.regular};
`;

export const BalloonSent = styled(Balloon)`
    position: absolute;
    right: 12px;
`;
