import styled, { css } from 'styled-components/native';
import { TextInputMask } from 'react-native-masked-text';
import { Picker } from '@react-native-picker/picker';

import { ResponsiveProps } from '../../global/types';
import SearchIcon from '../../assets/images/svg/searchIcon';
import themeGlobal from '../../global/styles/theme';
import theme from '../../global/styles/theme';

interface AttachTextProps {
    type: 'button' | 'text',
}

interface PickerProps {
    currentvalue: number;
}

interface ContainerSearchProps extends ResponsiveProps {
    searchOpen: boolean;
}

interface InputPickerContainerProps {
    isCheckbox?: boolean,
    isError?: boolean,
}

export const HeaderArea = styled.View<ResponsiveProps>`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    height: 76;
    padding-left: ${({ width }) => (width < 600 ? '8px' : '36px')};
    padding-right: ${({ width }) => (width < 600 ? '8px' : '40px')};
    width: 100%;
`;

export const AdjustPickers = styled.View<ResponsiveProps>`
    align-items: flex-start;
    flex-direction: ${({ width }) => (width < 1200 && width > 900 ? 'row' : 'column')};
    margin-top: 10px;
    justify-content: space-between;
    min-width: ${({ width }) => (width < 1200 ? '100%' : '24%')};
`;

export const AdjustPickersLogs = styled.View<ResponsiveProps>`
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
    justify-content: space-between;
    min-width: 100%;
`;

interface ResponsiveHeaderProps {
    width?: any;
    title: string;
}

export const TitleHeader = styled.Text<ResponsiveHeaderProps>`
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: ${({ width, title }) => (title === 'Acompanhamento das publicações' ? (width < 600 ? '14px' : '18px') : (width < 600 ? '20px' : '28px'))};
`;

export const InputPickerContainer = styled.View<InputPickerContainerProps>`
    flex-direction: row;
    padding: 10px;
    margin-top: 6px;
    height: 40px;
    border: 1px solid ${(props) => props.isError ? props.theme.colors.red[500] : props.theme.colors.gray[300]};
    border-radius: 6px;
    background-color: ${({ theme, isCheckbox }) => isCheckbox ? theme.colors.white : theme.colors.gray[300]};
    align-items: center;
    width: 100%;
`;

export const InputCommon = styled.TextInput.attrs({
    placeholderTextColor: theme.colors.gray[400],
})`
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    outline-width: 0px;
    width: 100%;
`;

export const InputArea = styled.View<ResponsiveProps>`
    margin-bottom: 16px;
    width: ${({ width }) => width};
`;

export const SearchListArea = styled.ScrollView`
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.gray[150]};
    border-radius: 6px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    max-height: 120px;
    width: 100%;
`;

export const SearchList = styled.TouchableOpacity`
    padding: 12px;
`;

export const SearchListText = styled.Text`
    color: ${({ theme }) => theme.colors.lowBlack};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 13px;
`;

export const Label = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const InputMask = styled(TextInputMask).attrs({
    placeholderTextColor: themeGlobal.colors.gray[400],
})`
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    outline-width: 0px;
    width: 100%;
`;

export const PickerContainer = styled(Picker)<PickerProps>`
    background-color: ${({ theme }) => theme.colors.gray[300]};
    border-width: 0px;
    width: 100%;
    outline-width: 0px;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    ${({ theme, currentvalue }) =>
        currentvalue === '0' && `color: ${theme.colors.gray[400]}`};
`;

export const ButtonContainer = styled.View`
    align-items: center;
`;

export const AdjustFilters = styled.View<ResponsiveProps>`
    align-items: center;
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    flex-direction: column;
`;

export const ScrollFilters = styled.ScrollView`
    max-width: 70%;
    max-height: 70%;
    border-radius: 5px;
    background-color: white;
`;

export const RequestFilterButton = styled.TouchableOpacity`
    margin: 5px;
`;

export const NewClientButton = styled.TouchableOpacity<ResponsiveProps>`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 6px;
    padding: 10px 20px;
    flex-direction: row;
    margin-left: ${({ width }) => (width < 600 ? '8px' : '0px')};
`;

export const OptionsContainer = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    position: fixed;
    width: 180px;
    top: 67px;
`;

export const ButtonOptions = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    height: 19px;
    margin-left: 12px;
    margin-top: 18px;
`;

export const SecondButtonOptions = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    height: 19px;
    margin-left: 12px;
    margin-top: 18px;
    margin-bottom: 18px;
`;

export const ButtonOptionsText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
    margin-left: 6px;
`;

export const AddClientText = styled.Text<ResponsiveProps>`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: ${({ width }) => (width < 600 ? '14px' : '16px')};
    margin-left: 10px;
`;

export const TitleHeaderArea = styled.View`
    align-items: center;
    flex-direction: row;
`;

export const ArrowButton = styled.TouchableOpacity`
    margin-right: 16px;
`;

export const ContainerSearch = styled.View<ContainerSearchProps>`
    flex-direction: row;
    align-items: center;
    ${({ searchOpen }) =>
        searchOpen &&
        css`
            width: 100%;
            justify-content: flex-end;
        `}
`;

export const AdjustHeader = styled.View`
    flex-direction: row;
    align-items: center;
    height: 76px;
    width: 100%;
    justify-content: space-between;
`;

export const InputContainer = styled.View<ContainerSearchProps>`
    height: 40px;
    border: ${({ theme }) => theme.colors.gray[150]} 1px;
    border-radius: 6px;
    flex-direction: row;
    align-items: center;
    padding: 11px 20px;
    width: ${({ width }) => width || 340};
    ${({ searchOpen }) =>
        searchOpen &&
        css`
            flex: 1;
            justify-content: flex-end;
        `}
`;

export const SearchIconInput = styled(SearchIcon).attrs({
    stroke: theme.colors.gray[150],
})`
    width: 50px;
`;

export const InputSearch = styled.TextInput.attrs({
    placeholderTextColor: theme.colors.gray[150],
})`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    color: ${({ theme }) => theme.colors.gray[800]};
    font-size: 16px;
    width: 100%;
    outline-width: 0px;
`;

export const DropdownArea = styled.View<ResponsiveProps>`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    min-height: 120px;
    /* margin-left: ${({ width }) => width > 1100 ? 16 : '0px'}; */
    padding: 16px 0px 16px 20px;
    width: 250px;
`;

export const DropdownOption = styled.TouchableOpacity`
    padding-bottom: 16px;
`;

export const DropdownOptionText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
`;

export const ButtonColor = styled.TouchableOpacity`
    flex-direction: row;
    padding: 10px;
    margin-top: 6px;
    height: 40px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    align-items: center;
    width: 100%;
`;

export const AttachText = styled.Text<AttachTextProps>`
    color: ${({ theme, type }) => type === 'button' ? theme.colors.white : theme.colors.gray[700]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
`;

interface NewClientButtonInterfaces {
    width: number;
    openFilters?: boolean;
}

export const NewClientButtonFilter = styled.TouchableOpacity<NewClientButtonInterfaces>`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 6px;
    padding: 14px 20px;
    flex-direction: row;
    height: 40px;
    margin-top: ${({ openFilters }) => openFilters ? '20px' : '0px'};
    margin-right: ${({ openFilters }) => openFilters ? '0px' : '5px'};
`;
