import { converterCurrency, MaskPhone } from "../../global/utils/functions";

export const formatTags = (list) => {
    return {
        headers: [
            {
                id: list.default_text,
                name: 'Inserir texto padrão',
            },
            {
                id: list.vehicles[0].title,
                name: 'Título Mídia',
            },
        ],
        vehicle: [
            {
                id: list.vehicles[0].board,
                name: 'Placa',
            },
            {
                id: list.vehicles[0].board_mask,
                name: 'Placa com máscara',
            },
            {
                id: converterCurrency((list?.vehicles[0]?.km || 0).toFixed(0) || 0).replaceAll(',', '').replace('R$', '').slice(0, -2) || '',
                name: 'Quilometragem',
            },
            {
                id: list.vehicles[0].condition,
                name: 'Condição',
            },
            {
                id: list.vehicles[0].type,
                name: 'Tipo',
            },
            {
                id: list.vehicles[0].bodywork,
                name: 'Carroceria',
            },
            {
                id: list.vehicles[0].brand,
                name: 'Marca',
            },
            {
                id: list.vehicles[0].model,
                name: 'Modelo',
            },
            {
                id: list.vehicles[0].version,
                name: 'Versão',
            },
            {
                id: list.vehicles[0].year_manufacturer,
                name: 'Ano de Fabricação',
            },
            {
                id: list.vehicles[0].year_model,
                name: 'Ano do Modelo',
            },
            {
                id: list.vehicles[0].fuel,
                name: 'Combustível',
            },
            {
                id: list.vehicles[0].doors,
                name: 'Portas',
            },
            {
                id: list.vehicles[0].gearshift,
                name: 'Câmbio',
            },
            {
                id: list.vehicles[0].color,
                name: 'Cor',
            },
            {
                id: list.vehicles[0].traction,
                name: 'Tração',
            },
            {
                id: list.vehicles[0].description,
                name: 'Descrição',
            },
        ],
        sellers: [
            {
                id: list.vehicles[0].seller_name,
                name: 'Nome do vendedor',
            },
            {
                id: MaskPhone(list.vehicles[0].seller_whatsapp.replace(/[^0-9]/g, '')),
                name: 'Whatsapp do vendedor',
            },
        ],
        values: [
            {
                id: converterCurrency(list.vehicles[0].price),
                name: 'Preço da venda',
            },
            {
                id: converterCurrency(list.vehicles[0].exchange_price),
                name: 'Preço da troca',
            },
            {
                id: converterCurrency(list.vehicles[0].promotional_price),
                name: 'Preço promocional',
            },
            {
                id: list.vehicles[0].type_financing,
                name: 'Tipo de financiamento',
            },
            {
                id: converterCurrency(list.vehicles[0].input_value),
                name: 'Valor de entrada',
            },
            {
                id: converterCurrency(list.vehicles[0].installment_value),
                name: 'Valor da parcela',
            },
            {
                id: list.vehicles[0].number_installments,
                name: 'Número de parcelas',
            },
        ],
        info: [
            {
                id: list.vehicles[0].is_trade,
                name: 'Aceita troca',
            },
            {
                id: list.vehicles[0].is_pay_ipva,
                name: 'IPVA pago',
            },
            {
                id: list.vehicles[0].is_owner_unique,
                name: 'Único dono',
            },
            {
                id: list.vehicles[0].is_full_tank,
                name: 'Tanque cheio',
            },
            {
                id: list.vehicles[0].is_free_transfer,
                name: 'Transferência grátis',
            },
            {
                id: list.vehicles[0].is_abaixo_da_fipe,
                name: 'Abaixo da fipe',
            },
            {
                id: list.vehicles[0].is_alienado,
                name: 'Alienado/Financiado',
            },
            {
                id: list.vehicles[0].is_entrega_a_domicilio,
                name: 'Entrega a domocílio',
            },
            {
                id: list.vehicles[0].is_garantia_de_fabrica,
                name: 'Garantia de fábrica',
            },
            {
                id: list.vehicles[0].is_licenciado,
                name: 'Licenciado',
            },
            {
                id: list.vehicles[0].is_quitado,
                name: 'Quitado',
            },
            {
                id: list.vehicles[0].is_revisoes_em_concessionaria,
                name: 'Revisões em concessionária',
            },
            {
                id: list.vehicles[0].is_test_drive_a_domicilio,
                name: 'Test drive a domicílio',
            },
        ],
        optionals: list.vehicles[0].tags?.split('|')?.map((v) => {
            return {
                id: v,
                name: v,
            }
        }),
    };
};
