// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as S from './styles';
import { NavigationProps } from '../../../routes/index';
import { schemaCreateClients, schemaCreateVehicles } from '../../../global/utils/formsSchems';

import { DashboardHeader } from '../../../components/DashboardHeader';
import { VehicleInfo } from './components/VehicleInfo';
import { GeneralInfo } from './components/GeneralInfo';

interface NewClientScreen extends NavigationProps {}

interface CardOptionProps {
    option: 'generalInfo' | 'vehicle';
}

interface NewPhotoProps {
    name: string;
    type: string;
    base64: string;
}

interface ButtonProps {
    vehicleButtonValidity?: boolean,
    clientButtonValidity?: boolean,
}

export function NewClientScreen(props: NewClientScreen) {
    const window = useWindowDimensions().width;
    const resolver = { resolver: yupResolver(schemaCreateClients), mode: 'onChange'  };
    const { control, handleSubmit, watch, formState: { errors, isValid } } = useForm(resolver);
    const { route } = props;
    const { type, item } = route.params;

    const [cardOption, setCardOption] = useState<CardOptionProps>({
        option: 'generalInfo',
    });
    const [clientInfo, setClientInfo] = useState(null);
    const [image, setImage] = useState(item?.photo || '');
    const [logoList, setLogoList] = useState<any[]>(item?.images || []);
    const [framesList, setFramesList] = useState<any[]>(item?.frames || []);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validityButton, setValidityButton] = useState<ButtonProps>({} as ButtonProps);

    useEffect(() => {
        if (isValid && cardOption.option === 'generalInfo') {
            const passwordCodition = type === 'editClient' ? true : password.length >= 6 && password === confirmPassword;
            const clientValidity = isValid && image && logoList.length && passwordCodition;
            setValidityButton({ clientButtonValidity: clientValidity });
        }
    }, [isValid, image, logoList, password, confirmPassword, cardOption.option]);

    function handleOptionPress() {
        const coditionCard = cardOption.option === 'generalInfo' ? 'vehicle' : 'generalInfo';
        const newOption = { option: coditionCard }
        setCardOption(newOption);
    }

    function goToBack() {
        props.navigation.navigate('ManageClientsScreen');
    }

    function renderTitle() {
        if (type === 'editClient') {
            return 'Editar Cliente';
        }

        if (type === 'newClient') {
            return 'Novo Cliente';
        }

        return 'Cliente';
    }

    function RenderOption(
        nameOption: string,
        optionId: 'generalInfo' | 'vehicle',
    ) {
        const handleOptionPress = () => {
            setCardOption({
                option: optionId,
            });
        }
        const coditionPress = cardOption.option === 'generalInfo' && type === 'newClient' ;

        return (
            <S.ButtonOptionArea
                actualOption={cardOption.option}
                option={optionId}
                onPress={handleOptionPress}
                disabled={coditionPress}
                type={type}
            >
                <S.ButtonOptionText
                    actualOption={cardOption.option}
                    option={optionId}
                    width={window}
                >
                    {nameOption}
                </S.ButtonOptionText>
            </S.ButtonOptionArea>
        );
    }

    function RenderInfoContent() {
        if (cardOption.option === 'vehicle') {
            return (
                <VehicleInfo
                    clientInfo={item}
                    typeScreen={type}
                />
            );
        }

        return (
            <GeneralInfo
                idUser={item?.id}
                info={item}
                setScreenName={handleOptionPress}
                typeScreen={type}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                image={image}
                setImage={setImage}
                logoList={logoList}
                setLogoList={setLogoList}
                framesList={framesList}
                setFramesList={setFramesList}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                isValid={validityButton.clientButtonValidity}
                getValues={watch}
            />
        );
    }

    function RenderVehicleOption() {
        if (type === 'newClient') {
            return null;
        }

        return RenderOption('Veículo', 'vehicle');
    }

    return (
        <S.Container>
            <DashboardHeader
                title={renderTitle()}
                typeHeader="home"
                goToScreen={goToBack}
                goBack
                disableInputSearch
            />
            <S.ScrollView>
                <S.ContainerAlign>
                    <S.HeaderOptionArea>
                        {RenderOption('Informações Gerais', 'generalInfo')}
                        {RenderVehicleOption()}
                    </S.HeaderOptionArea>
                    <S.ContainerInfo width={window}>
                        {RenderInfoContent()}
                    </S.ContainerInfo>
                </S.ContainerAlign>
            </S.ScrollView>
        </S.Container>
    );
}
