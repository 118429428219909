import styled from 'styled-components/native';
import { Animated } from 'react-native';

import { ResponsiveProps } from '../../../global/types';

export const Container = styled.ImageBackground`
    flex: 1;
`;

export const ContainerShawdow = styled.View`
    background-color: rgba(0, 0, 0, 0.33);
    align-items: center;
    justify-content: center;
    padding: 27px 0px;
    flex: 1;
`;

export const ContentBox = styled(Animated.View)<ResponsiveProps>`
    width: ${({ width }) => (width > 768 ? `416px` : `95%`)};
    background-color: ${({ theme }) => theme.colors.white};
    padding: 40px 48px;
    border-radius: 8px;
`;

export const Form = styled.View`
    margin: 30px 0;
`;
