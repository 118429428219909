// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Circle, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    data-name="Componente 41 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    {...props}
  >
    <G data-name="Elipse 56" fill="#fff" stroke="#6746c8">
      <Circle cx={20} cy={20} r={20} stroke="none" />
      <Circle cx={20} cy={20} r={19.5} fill="none" />
    </G>
    <Path
      data-name="chevron-right (4)"
      d="m17 30 10-10-10-10"
      fill="none"
      stroke="#6746c8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
)

export default SvgComponent
