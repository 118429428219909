import { api } from './api';

interface CreateArtsProps {
    title: string;
    storeName: string;
    colors: Array<string>;
    use: string;
    type: string;
    idEvents: Array<number>;
    isAllPlans: boolean;
    attachment: string;
    idPlan: number;
    price?: number;
    isFree: boolean;
    idAdm?: number;
    idUser?: number;
}

interface CreateUsedArtProps {
    dateSend: string;
    isFinished: boolean;
    idArt: number;
    idUser: number;
    idVehicle: number;
    isSend?: boolean;
    wasPublishedFromStock: boolean;
    type: string;
}

interface CreateUsedArtPropsWhatsapp {
    idArt: number;
    idUser: number;
    message: string;
}

interface UpdateUsedArtProps {
    dateSend: string;
    isFinished: boolean;
    idUser: number;
    idVehicle: number;
    isSend?: boolean;
    wasPublishedFromStock: boolean;
    type: string;
}

export async function getArtList(
    page: number,
    type: 'all' | 'favorite' | 'bought' | 'editing' | 'published' | 'scheduled',
    idUser?: number,
    searchTxt?: string,
    artType?: string,
    idEvent?: number,
    idsColors?: string,
    idAdm?: string,
    use?: string,
    status?: string,
    typeMolduras?: 'default' | 'logotipos' | 'datas',
    idArtUsed?: number,
    errorAt?: true | false | null | undefined,
) {
    let baseQuery = `/arts?page=${page}&limit=50&type=${type}`;
    baseQuery += idUser ? `&idUser=${idUser}` : '';
    baseQuery += idArtUsed ? `&idArtUsed=${idArtUsed}` : '';
    baseQuery += idAdm ? `&idAdm=${idAdm}` : '';
    baseQuery += artType ? `&artType=${artType}` : '';
    baseQuery += idEvent ? `&idEvent=${idEvent}` : '';
    baseQuery += idsColors ? `&idsColors=${idsColors}` : '';
    baseQuery += use
        ? `&used=${
              use === 'Público' ? 'public' : use === 'Privado' ? 'private' : ''
          }`
        : '';
    baseQuery += status
        ? `&status=${
              status === 'Ativado'
                  ? 'actived'
                  : status === 'Bloqueado'
                  ? 'blocked'
                  : ''
          }`
        : '';
    if (artType === 'capa') {
        baseQuery += typeMolduras ? `&typeCapa=${typeMolduras}` : '';
    }
    if (artType !== 'capa') {
        baseQuery += typeMolduras ? `&typeMolduras=${typeMolduras}` : '';
    }

    const body = {
        searchTxt,
        errorAt,
    };

    try {
        const { data } = await api.patch(baseQuery, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getArtDownload(
    page: number,
    idUser: number,
    idPlan: number,
    searchTxt: string,
    periodStart: string,
    periodEnd: string,
) {
    let baseQuery = `/arts/downloads?page=${page}&limit=50`;
    baseQuery += idUser ? `&idUser=${idUser}` : '';
    baseQuery += idPlan ? `&idPlan=${idPlan}` : '';
    baseQuery += searchTxt ? `&searchTxt=${searchTxt}` : '';
    baseQuery += periodStart ? `&periodStart=${periodStart}` : '';
    baseQuery += periodEnd ? `&periodEnd=${periodEnd}` : '';

    try {
        const { data } = await api.get(baseQuery);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getArtLogs(
    page: number,
    periodType: 'published_at' | 'date_send' | 'created_at' | 'error_at',
    idUser: number,
    idPlan: number,
    searchTxt: string,
    periodStart: string,
    periodEnd: string,
    idArtUsed?: number,
) {
    let baseQuery = `/arts/used/search?page=${page}&limit=50&periodType=${periodType}`;
    baseQuery += idUser ? `&idUser=${idUser}` : '';
    baseQuery += idPlan ? `&idPlan=${idPlan}` : '';
    baseQuery += searchTxt ? `&searchTxt=${searchTxt}` : '';
    baseQuery += periodStart ? `&periodStart=${periodStart}` : '';
    baseQuery += periodEnd ? `&periodEnd=${periodEnd}` : '';
    baseQuery += idArtUsed ? `&idArtUsed=${idArtUsed}` : '';

    try {
        const { data } = await api.get(baseQuery);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getArtAlertError(idUser: number) {
    let baseQuery = `/arts/used/alertError?idUser=${idUser}`;

    try {
        const { data } = await api.get(baseQuery);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getArtListBirthDay(
    page: number,
    type: 'all' | 'favorite' | 'bought' | 'editing' | 'published' | 'scheduled',
    idUser?: number,
    searchTxt?: string,
    artType?: string,
    idEvent?: number,
    idsColors?: string,
    idAdm?: string,
    use?: string,
    status?: string,
) {
    let baseQuery = `/arts/birthday?page=${page}&limit=50&type=${type}`;
    baseQuery += searchTxt ? `&searchTxt=${searchTxt}` : '';
    baseQuery += idUser ? `&idUser=${idUser}` : '';
    baseQuery += idAdm ? `&idAdm=${idAdm}` : '';
    baseQuery += artType ? `&artType=${artType}` : '';
    baseQuery += idEvent ? `&idEvent=${idEvent}` : '';
    baseQuery += idsColors ? `&idsColors=${idsColors}` : '';
    baseQuery += use
        ? `&used=${
              use === 'Público' ? 'public' : use === 'Privado' ? 'private' : ''
          }`
        : '';
    baseQuery += status
        ? `&status=${
              status === 'Ativado'
                  ? 'actived'
                  : status === 'Bloqueado'
                  ? 'blocked'
                  : ''
          }`
        : '';

    try {
        const { data } = await api.get(baseQuery);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function createNewArt(body: CreateArtsProps) {
    try {
        const { data } = await api.post('/arts', body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function updateArt(idArt: number, body: CreateArtsProps) {
    try {
        const { data } = await api.put(`/arts?idArt=${idArt}`, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function deleteArt(idArt: number) {
    try {
        const { data } = await api.delete(`/arts?idArt=${idArt}`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getFavoritesArts(id: number) {
    try {
        const { data } = await api.get(`/arts/view?idUser=${id}`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getArtsRecommended(idArt: number) {
    try {
        const { data } = await api.get(`/arts/recommended?idArt=${idArt}`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function userImportArt(attachment: string, idUser: number) {
    const body = {
        attachment,
        idUser,
    };

    try {
        const { data } = await api.post(`/arts/import`, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getArtsPending(idUser: number) {
    try {
        const { data } = await api.get(
            `/arts/orders?page=1&limit=50&idUser=${idUser}&status=pending`,
        );
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function updateStatusArts(
    idArtOrder: number,
    status: 'aproved' | 'canceled',
) {
    try {
        const { data } = await api.patch(
            `/arts/orders/status?idArtOrder=${idArtOrder}&status=${status}`,
        );
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function createUsedArt(body: CreateUsedArtProps) {
    try {
        const { data } = await api.post(`/arts/used`, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function createUsedArtWhatsapp(body: CreateUsedArtPropsWhatsapp) {
    try {
        const { data } = await api.post(`/arts/used/birthday`, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getTemplatePreview(
    idArt: number,
    idUser: number,
    idVehicle?: number,
    logoClient?: string,
    photoVehicle?: Array<{ url: string; removeBg: boolean }>,
    message?: string,
    birthDay?: string,
    firstName?: string,
    isEnabledRemoveBg?: boolean,
    texts?: Array<string>,
    titleVehicle?: string,
    // photosVehicles?: Array<string>,
    // photoVehicleSelectedRemoveBg?: string,
) {
    try {
        let baseQuery = `/arts/used/view?idArt=${idArt}&idUser=${idUser}`;
        baseQuery += idVehicle ? `&idVehicle=${idVehicle}` : '';
        baseQuery += logoClient ? `&logoClient=${logoClient}` : '';
        baseQuery += birthDay ? `&birthDay=${birthDay}` : '';
        baseQuery += firstName ? `&firstName=${firstName}` : '';
        // baseQuery += photoVehicleSelectedRemoveBg ? `&photoVehicleSelectedRemoveBg=${photoVehicleSelectedRemoveBg}` : '';

        let body = {
            // photosVehicles,
            photoVehicle,
            texts,
            message,
            isRemoveBg: isEnabledRemoveBg,
            titleVehicle: titleVehicle || '',
        };
        if (!texts?.length) {
            delete body.texts;
        }
        // if (!photosVehicles?.length) {
        //     delete body.photosVehicles;
        // }

        const { data } = await api.patch(baseQuery, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function updateEditArt(
    idArtUsed: number,
    body: UpdateUsedArtProps,
) {
    try {
        const { data } = await api.put(
            `/arts/used?idArtUsed=${idArtUsed}`,
            body,
        );
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getStoreList(page: number, searchTxt: string) {
    try {
        const body = {
            searchTxt,
        };
        const { data } = await api.patch(
            `/users/search?page=${page}&limit=50`,
            body,
        );
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function resendArt(
    idArtUsed: number,
    idUser: number,
    type: string,
) {
    try {
        const body = {
            idArtUsed,
            idUser,
            type,
        };
        const { data } = await api.patch(`/arts/used/publish`, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getLogsArt(idArtUsed: number) {
    try {
        const { data } = await api.get(`/arts/logs?idArtUsed=${idArtUsed}`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function removeScheduled(idArtUsed: number) {
    try {
        const body = {
            idArtUsed,
        };
        const { data } = await api.patch(
            `/arts/used/removeScheduled?idArtUsed`,
            body,
        );
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function remove(idArtUsed: number) {
    try {
        const { data } = await api.delete(`/arts/used?idArtUsed=${idArtUsed}`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function scheduledArt(
    idArtUsed: number,
    idUser: number,
    type: string,
    dateSend?: Date,
) {
    try {
        const body = {
            idArtUsed,
            idUser,
            dateSend: dateSend || null,
            type,
        };
        const { data } = await api.patch(`/arts/used/scheduled`, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function allColorsList() {
    try {
        const { data } = await api.get(`/arts/colors`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getUsedArtCodition(idUser: number) {
    try {
        const { data } = await api.get(`/arts/used/limitUsed?idUser=${idUser}`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getUsedPublicationCodition(
    idUser: number,
    download?: string,
) {
    try {
        let baseURL = `/arts/used/limitPublication?idUser=${idUser}`;
        if (download) {
            baseURL = `${baseURL}&download=${download}`;
        }
        const { data } = await api.get(baseURL);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function computedArtsDownload(
    idUser: number,
    urls: Array<string>,
) {
    try {
        const { data } = await api.post(`/arts/downloads?idUser=${idUser}`, {
            urls,
        });
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}
