// @ts-nocheck
import React from 'react';
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu';

import ArrowBottom from '../../../../../assets/images/svg/arrowBottom'

import * as S from './styles';

interface PopupOptionProps {
    setOption: any,
    invoicing?: boolean,
}

export function PopupOption(props: PopupOptionProps) {
    const { setOption, invoicing } = props;

    function RenderOptionArea(nameOption: string, option: string) {
        const handleOptionPress = () => {
            setOption({
                nameOption,
                option,
            });
        }

        return (
            <MenuOption onSelect={handleOptionPress}>
                <S.OptionArea>
                    <S.OptionText>{nameOption}</S.OptionText>
                </S.OptionArea>
            </MenuOption>
        );
    }

    function RenderOptions() {
        if (invoicing) {
            return (
                <>
                    {RenderOptionArea('Ambos', 'all')}
                    {RenderOptionArea('Artes', 'arts')}
                    {RenderOptionArea('Planos', 'plans')}
                </>
            )
        }

        return (
            <>
                {RenderOptionArea('Hoje', 'today')}
                {RenderOptionArea('Essa semana', 'week')}
                {RenderOptionArea('Esse mês', 'month')}
            </>
        );
    }

    return (
        <Menu>
            <MenuTrigger text="" style={S.buttonStyle}>
                <ArrowBottom />
            </MenuTrigger>
            <MenuOptions optionsContainerStyle={S.optionsContainerStyle}>
                {RenderOptions()}
            </MenuOptions>
        </Menu>
    );
}
