// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={13.964}
        height={14.716}
        {...props}>
        <Path
            d="m13.256 7.031-6.031 6.031A3.94 3.94 0 1 1 1.654 7.49l6.031-6.031a2.627 2.627 0 1 1 3.713 3.715l-6.037 6.028a1.313 1.313 0 0 1-1.857-1.857l5.572-5.563"
            fill="none"
            stroke="#6746c8"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default SvgComponent;
