import styled, { css } from 'styled-components/native';
import { ResponsiveProps } from '../../../global/types';

interface TabBarButtonProps {
    isSelected: boolean;
    page: 'profile' | 'password';
}

interface FilterAreaProps extends ResponsiveProps {
    marginTop?: number,
    marginRight?: number,
    existsColorTertiary?: string,
    isVisibleModal?: boolean,
    zIndex?: number,
}

interface DropdownAreaProps extends FilterAreaProps {
    topNumber?: number,
    widthNumber?: number,
}

export const Container = styled.View<ResponsiveProps>`
    background-color: ${({ theme }) => theme.colors.white};
    flex: 1;
    padding: ${({ width }) => width < 350 ? '10px' : '40px 15px 40px 30px'};
`;

export const Header = styled.View`
    flex-direction: row;
    align-items: center;
`;

export const GobackButton = styled.TouchableOpacity``;

export const TextDefault = styled.Text`
    color: ${({ theme }) => theme.colors.gray[900]};
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: 25px;
`;

export const ContainerAlign = styled.ScrollView`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    width: 100%;
    /* padding-right: 20px; */
`;

export const Content = styled.View<ResponsiveProps>`
    display: grid;
    ${({ width }) =>
        width > 1800 &&
        css`
            grid-template-columns: repeat(5, 1fr);
        `}
    ${({ width }) =>
        width >= 1520 &&
        width <= 1800 &&
        css`
            grid-template-columns: repeat(4, 1fr);
        `}
    ${({ width }) =>
        width >= 1200 &&
        width <= 1520 &&
        css`
            grid-template-columns: repeat(3, 1fr);
        `}
    ${({ width }) =>
        width >= 880 &&
        width <= 1200 &&
        css`
            grid-template-columns: repeat(2, 1fr);
        `}
    gap: ${({ width }) => (width < 880 ? '20px' : '0px')};
`;

export const TabBar = styled.View`
    height: 60px;
    flex-direction: row;
`;

export const TabBarButton = styled.TouchableOpacity<TabBarButtonProps>`
    ${({ theme, isSelected, page }) =>
        isSelected
            ? css`
                  background-color: ${theme.colors.purple[500]}};
                  ${
                      page === 'profile'
                          ? 'border-top-left-radius: 6px;'
                          : 'border-top-right-radius: 6px;'
                  }
              `
            : css`
                  border-bottom-width: 1px;
                  border-bottom-color: ${theme.colors.gray[300]};
              `}
    opacity: 0.5;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const TabBarButtonText = styled.Text<TabBarButtonProps>`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 20px;
    color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.white : theme.colors.gray[900]};
`;

export const EditInfoArea = styled.View<ResponsiveProps>`
    width: '100%';
    padding: ${({ width }) => (width < 400 ? '10px' : '26px 63px 34px 57px')};
`;

export const TextTitle = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 22px;
    margin-bottom: 20px;
`;

export const DropdownArea = styled.View<DropdownAreaProps>`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    min-height: 120px;
    /* margin-left: ${({ width }) => width > 1100 ? 16 : '0px'}; */
    padding: 16px 0px 16px 20px;
    position: ${({ width, isVisibleModal }) => width > 1300 && !isVisibleModal ? 'absolute' : 'relative'};
    top: ${({ width, isVisibleModal, topNumber }) => width > 1300 && !isVisibleModal ? topNumber : 'auto'};
    width: ${({ widthNumber }) => widthNumber || 250};
`;

export const DropdownOption = styled.TouchableOpacity`
    padding-bottom: 16px;
`;

export const DropdownOptionText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
`;

export const RequestFilterButton = styled.TouchableOpacity<FilterAreaProps>`
    margin-top: ${({ marginTop }) => marginTop || '20px'};
    margin-right: ${({ marginRight }) => marginRight || '0px'};
    width: 235px;
`;
