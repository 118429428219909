import styled, { css } from 'styled-components/native';
import AntDesign from '@expo/vector-icons/AntDesign';

interface EditingProps {
    isEditing: boolean;
    currentPage: string;
    item?: any;
}

interface MoreOptionButtonProps {
    type: 'clients' | 'plans';
}

interface TextTitleProps {
    marginBottom?: number,
    marginTop: number,
}

export const IconNavigation = styled(AntDesign)`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
`;

export const Container = styled.TouchableOpacity<EditingProps>`
    ${({ isEditing, theme, currentPage }) =>
        isEditing ?
        css`
            /* background-color: ${theme.colors.gray[200]}; */
            border-radius: 6px;
            align-items: center;
            justify-content: center;
            padding: 16px;
            height: 300px;
            width: 300px;
        ` : css`
            width: ${['logs', 'published', 'download'].includes(currentPage) ? '285px' : '250px'};
            height: ${['logs', 'published', 'download'].includes(currentPage) ? 'auto' : '250px'};
        `}
    border: ${({ theme, currentPage, item }) => ['logs', 'published'].includes(currentPage) ? (item?.error_message || item?.error_at ? theme.colors.red[300] : theme.colors.gray[150]) : 'none'};
    padding: ${({ currentPage, isEditing }) => ['logs', 'published'].includes(currentPage) || isEditing ? '16px' : '0px'};
    border-radius: ${({ currentPage, isEditing }) => ['logs', 'published'].includes(currentPage) || isEditing ? '6px' : '0px'};
    background-color: ${({ currentPage, isEditing, theme, item }) => ['logs'].includes(currentPage) && (item?.error_message || item?.error_at) ? theme.colors.red[300] : (isEditing ? theme.colors.gray[200] : 'transparent')};
    margin-top: 60px;
`;

export const ScrollView = styled.ScrollView`
    min-width: 50vw;
    max-width: 50vw;
    max-height: 80vh;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const ContainerAlign = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 40px;
    padding: 14px;
    width: 95%;
`;

export const TitleTable = styled.View<TextTitleProps>`
    color: ${({ theme }) => theme.colors.lowBlack};
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    margin-top: ${({ marginTop }) => marginTop || 0};
`;

export const Title = styled.Text`
    color: ${({ theme }) => theme.colors.gray[800]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
`;

export const Row = styled.View`
    flex-direction: row;
`;

export const TitleLogs = styled.Text`
    color: ${({ theme }) => theme.colors.gray[800]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
    max-width: 100%;
    flex-wrap: wrap;
`;

export const Column = styled.View`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex-wrap: wrap;
`;

export const SubTitle = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
`;

export const ImageCard = styled.Image<EditingProps>`
    width: 250px;
    height: 250px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[200]};
    margin-bottom: 10px;
`;

export const PaymentIconArea = styled.View`
    flex-direction: row;
    justify-content: space-between;
`;

export const PaymentInfos = styled.View``;

export const PaymentPrice = styled.Text`
    color: ${({ theme }) => theme.colors.gray[150]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 12px;
    margin-top: 2px;
`;

export const ImageArea = styled.View``;

export const FavoriteButton = styled.TouchableOpacity`
    /* bottom: 20px;
    position: absolute;
    right: 10px; */
`;

export const ButtonEditing = styled.TouchableOpacity`
    position: absolute;
    top: 4px;
    right: 4px;
`;

export const AdjustTitle = styled.View`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const ButtonDowngrade = styled.TouchableOpacity``;

export const MoreOptionButton = styled.TouchableOpacity<MoreOptionButtonProps>`
    width: ${({ type }) => (type === 'plans' ? '9%' : '16%')};
    align-items: center;
    margin-left: -16px;
`;

export const optionsContainerStyle = {
    paddingBottom: 8,
    paddingTop: 16,
    width: 180,
};

export const buttonStyle = {
    alignItems: 'flex-end',
    marginRight: 12,
    width: 40,
};

export const OptionArea = styled.View`
    align-items: center;
    flex-direction: row;
    margin-bottom: 8px;
    padding-left: 16px;
`;

export const OptionText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
    margin-left: 16px;
`;

export const InfoArea = styled.View`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

export const TagArea = styled.View`
    align-items: center;
    flex-direction: row;
    background-color: ${({ theme }) => theme.colors.red[600]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
`;

export const CopyArea = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 5px;
    color: white;
`;

export const IconArea = styled.View`
    align-items: center;
    flex-direction: row;
`;

export const IconDefault = styled.Image`
    height: 20px;
    width: 20px;
`;

export const CloseButton = styled.TouchableOpacity`
    align-items: center;
    height: 30px;
    justify-content: center;
    width: 30px;
`;

export const CloseImage = styled.Image`
    height: 25px;
    width: 25px;
`;
