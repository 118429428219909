import styled from 'styled-components/native';

interface AttachTextProps {
    type: 'button' | 'text',
}

interface AttachDocumentAreaProps {
    isError?: boolean,
}

export const AttachText = styled.Text<AttachTextProps>`
    color: ${({ theme, type }) => type === 'button' ? theme.colors.white : theme.colors.gray[700]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
`;

export const AttachDocumentArea = styled.View<AttachDocumentAreaProps>`
    align-self: center;
    align-items: center;
    border: 1px solid ${(props) => props.isError ? props.theme.colors.red[500] : props.theme.colors.gray[700]};
    border-radius: 6px;
    height: 179px;
    padding: 27.3px 0px;
    width: 240px;
`;

export const AttachIconArea = styled.View`
    margin-bottom: 20px;
`;

export const AttachButton = styled.TouchableOpacity`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 6px;
    height: 28px;
    justify-content: center;
    width: 141px;
    margin-bottom: 10px;
`;

export const UploadText = styled.Text`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
`;

export const ButtonDropzoneOption = styled.View`
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
`;

export const DropzoneArea = styled.View`
    align-items: center;
    align-self: center;
    justify-content: center;
    /* background-color: green; */
    margin-bottom: 40px;
`;

export const AttachArea = styled.View`
    align-items: center;
    justify-content: center;
    width: 100%;
`;
