import styled from 'styled-components/native';

export const Container = styled.ScrollView`
    background-color: ${(props) => props.theme.colors.white};
    flex: 1;
    padding: 40px 15px 40px 30px;
`;

export const Header = styled.View`
    flex-direction: row;
    align-items: center;
`;

export const Title = styled.Text`
    color: ${(props) => props.theme.colors.gray[800]};
    font-family: ${(props) => props.theme.font.segoeUi.bold};
    font-size: 20px;
`;

export const BirthListContainer = styled.View`
    margin: 36px 0px;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const Divider = styled.View`
    height: 1px;
    background-color: ${(props) => props.theme.colors.gray[150]};
    margin-bottom: 36px;
`;

export const ButtonContainer = styled.View`
    padding: 44px 0px;
    align-items: center;
    justify-content: center;
`;

export const Button = styled.TouchableOpacity`
    width: 220px;
    height: 50px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.purple[500]};
    align-items: center;
    justify-content: center;
`;

export const ButtonText = styled.Text`
    color: ${(props) => props.theme.colors.white};
`;
