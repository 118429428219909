// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { loginAuth } from '../../../store/modules/user/actions';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { NavigationProps } from '../../../routes/index';
import { Title } from '../../../components/Title';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import carImage from '../../../assets/images/pexels-albin-berlin-919073@2x.png';
import LogoSvg from '../../../assets/images/svg/logoSvg';

import { clientsToken } from '../../../services/clients';
import { schemaLogin } from '../../../global/utils/formsSchems';
import { api } from '../../../services/api';
import * as S from './styles';

interface LoginScreenProps extends NavigationProps {}

export function LoginScreen(props: LoginScreenProps) {
    const resolver = {
        resolver: yupResolver(schemaLogin),
    };
    const {
        control,
        handleSubmit,
        getValues,
        setError,
        formState: { errors },
    } = useForm(resolver);
    const { navigation, route } = props;
    const token = route?.params?.token;
    const type = route?.params?.type;
    const idVehicle = route?.params?.idVehicle;
    const window = useWindowDimensions().width;
    const dispatch = useDispatch();
    const isFocus = useIsFocused();

    const [loading, setLoading] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [formVisibled, setFormVisibled] = useState(true);

    useEffect(() => {
        setLoadingScreen(isFocus);
    }, [isFocus]);

    useEffect(() => {
        if (!token) {
            setFormVisibled(false);
        } else {
            handleLogin({ revendaProToken: token });
        }
    }, []);

    function handleNavigationForgotPassword() {
        navigation.navigate('ForgotPasswordScreen');
    }

    async function handleLogin(data: any) {
        const emailConfig = {
            type: 'custom',
            message: 'Email não cadastrado',
        };
        const passwordConfig = {
            type: 'custom',
            message: 'Senha inválida.',
        };

        if (data?.password && data?.email || data.revendaProToken) {
            setLoading(true);
            const response = await clientsToken(data);

            if (response?.status === 200) {
                api.defaults.headers.common['x-access-token'] =
                    response.result?.token;
                dispatch(loginAuth(response.result));

                if (type === 'aniversariante') {
                    await AsyncStorage.setItem('@midiacarros:birthday_routes', true);
                }
                if (idVehicle) {
                    await AsyncStorage.setItem('@midiacarros:publication_routes', idVehicle);
                }

            } else {
                if (response?.message === 'Email inválido') {
                    setError('email', emailConfig);
                } else {
                    setError('password', passwordConfig);
                }
            }
            setLoading(false);
        } else {
            setError('password', passwordConfig);
        }
    }

    function keyPress(keyPress: any) {
        const data = getValues();
        if (keyPress.code === 'Enter' || keyPress.code === 'NumpadEnter') {
            handleLogin(data);
        }
    }

    function renderForm() {
        if (formVisibled) {
            return (
                <S.Loading />
            );
        }

        return (
            <>
                <Title label="Login" />
                <S.Form width={window}>
                    <Input
                        label="Email"
                        placeholder="Digite seu email"
                        control={control}
                        name="email"
                        errors={errors}
                        keyPress={keyPress}
                        autoFocus
                    />
                    <Input
                        label="Senha"
                        placeholder="Insira sua senha"
                        control={control}
                        name="password"
                        errors={errors}
                        isPassword
                        keyPress={keyPress}
                    />
                    <S.ForgotPassword onPress={handleNavigationForgotPassword}>
                        <S.TextForgotPassword>
                            Esqueceu a senha?
                        </S.TextForgotPassword>
                    </S.ForgotPassword>
                </S.Form>
                <Button
                    label="Entrar"
                    onPress={handleSubmit(handleLogin)}
                    loading={loading}
                />
            </>
        );
    }

    function RenderLoginArea() {
        if (!loadingScreen) {
            return null;
        }

        return (
            <S.ContainerShawdow>
                <S.Content width={window}>
                    <S.FirstBox width={window}>
                        <LogoSvg />
                        <S.Description width={window}>
                            Bem-vindo! Por favor, insira seu email e senha para
                            acessar sua conta.
                        </S.Description>
                    </S.FirstBox>
                    <S.SecondBox width={window}>{renderForm()}</S.SecondBox>
                </S.Content>
            </S.ContainerShawdow>
        );
    }

    return (
        <S.Container onContextMenu={(e) => e.preventDefault()} source={carImage}>
            {RenderLoginArea()}
        </S.Container>
    );
}
