// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import { useWindowDimensions, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '../../assets/images/svg/addIcon';
import { InputSearch } from '../InputSearch';
import LogoCLient from '../../assets/images/svg/logoClient';
import * as S from './styles';
import { filtersGlobal } from '../../store/modules/user/actions';
import { listColors } from '../../global/utils/lists';
import { Modal } from '../../components/Modal';
import { getEventList } from '../../services/events';

interface HeaderClientProps {
    handleOpenDrawer: () => void;
}

export function HeaderClient(props: HeaderClientProps) {
    const { handleOpenDrawer } = props;
    const width = useWindowDimensions().width;
    const navigation = useNavigation();
    const infoScreen = navigation.getState();
    const removeFilters = ['social', 'Aniversariantes', 'Financeiro', 'Minhas artes', 'Publicações'];
    const infoScreenRoutes = infoScreen.routes.find((v) => v.name === 'ClientDrawerNavigation') || null;

    const params = infoScreenRoutes?.state?.routes?.slice(-1)[0]?.params;
    const { user } = useSelector((state: any) => state.user.user);
    const actualClientDrawer = useSelector((state: any) => state.user.actualClientDrawer);
    const dispatch = useDispatch();
    const headerRef = useRef(null);

    const [searchOpen, setSearchOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [artType, setArtType] = useState('');
    const [idEvent, setIdEvent] = useState('');
    const [nameEvent, setNameEvent] = useState('');
    const [colorPrimary, setColorPrimary] = useState('');
    const [colorSecondary, setColorSecondary] = useState('');
    const [colorTertiary, setColorTertiary] = useState('');
    const [colorQuaternary, setColorQuaternary] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownVisibleColorPrimary, setDropdownVisibleColorPrimary] = useState(false);
    const [dropdownVisibleColorSecondary, setDropdownVisibleColorSecondary] = useState(false);
    const [dropdownVisibleColorTertiary, setDropdownVisibleColorTertiary] = useState(false);
    const [dropdownVisibleColorQuaternary, setDropdownVisibleColorQuaternary] = useState(false);
    const [dropdownVisibleEvent, setDropdownVisibleEvent] = useState(false);
    const [eventList, setEventList] = useState<any[]>([]);

    const uri = { uri: user.photo };

    useEffect(() => {
        const eventClick = (e: any) => {
            if (!headerRef.current.contains(e.target) && !openFilters) {
                setDropdownVisible(false);
                setDropdownVisibleColorPrimary(false);
                setDropdownVisibleColorSecondary(false);
                setDropdownVisibleColorTertiary(false);
                setDropdownVisibleColorQuaternary(false);
                setDropdownVisibleEvent(false);
            }
        }
        document.addEventListener('mousedown', eventClick);

        return () => document.removeEventListener('mousedown', eventClick);
    }, [openFilters]);

    function handleOpenSearch() {
        setSearchOpen(!searchOpen);
    }

    function onChangeInputGlobal(txt: string) {
        setSearchTxt(txt);
    }

    async function getAllEvents() {
        const { result } = await getEventList();
        setEventList(result.list);
    }

    useEffect(() => {
        getAllEvents();
    }, []);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            let formatIdsColors: Array<string> = [];
            if (colorPrimary) {
                formatIdsColors.push(colorPrimary);
            }
            if (colorSecondary) {
                formatIdsColors.push(colorSecondary);
            }
            if (colorTertiary) {
                formatIdsColors.push(colorTertiary);
            }
            if (colorQuaternary) {
                formatIdsColors.push(colorQuaternary);
            }
            const getFilters = {
                searchTxt,
                artType,
                idEvent,
                idsColors: formatIdsColors.join(),
            };
            dispatch(filtersGlobal(getFilters));
        }, 500);

        return () => clearTimeout(timeOutId);
    }, [searchTxt, artType, idEvent, colorPrimary, colorSecondary, colorTertiary, colorQuaternary]);

    function renderInputSearch(widthInput?: string | number) {
        if (!searchOpen && width < 860) {
            return null;
        }

        return (
            <S.SearchAreea>
                <S.InputContainer searchOpen={width}>
                    <S.SearchIconInput />
                    <S.InputSearch placeholder="Buscar por palavra-chave" value={searchTxt} onChangeText={onChangeInputGlobal} />
                </S.InputContainer>
            </S.SearchAreea>
        );
    }

    function renderIconSearchOrClose() {
        if (!searchOpen) {
            return (
                <S.SearchIconInput />
            );
        }

        return (
            <S.IconClose name="x" />
        );
    }

    function importArt() {
        navigation.navigate('CreateArtScreen', { type: 'client' });
    }

    function handleDropdownPress() {
        setDropdownVisible(!dropdownVisible);
    }

    function handleDropdownPressEvent() {
        setDropdownVisibleEvent(!dropdownVisibleEvent);
    }

    function handleDropdownPressColorPrimary() {
        setDropdownVisibleColorPrimary(!dropdownVisibleColorPrimary);
    }

    function handleDropdownPressColorSecondary() {
        setDropdownVisibleColorSecondary(!dropdownVisibleColorSecondary);
    }

    function handleDropdownPressColorTertiary() {
        setDropdownVisibleColorTertiary(!dropdownVisibleColorTertiary);
    }

    function handleDropdownPressColorQuaternary() {
        setDropdownVisibleColorQuaternary(!dropdownVisibleColorQuaternary);
    }

    function renderContainerSearchOrMenu() {
        if (width <= 860) {
            return (
                <S.ButtonDrawerContainer>
                    {width > 400 ? (
                        <S.ButtonSearch onPress={handleOpenSearch}>
                            {renderIconSearchOrClose()}
                        </S.ButtonSearch>
                    ) : null}
                    <S.Button onPress={handleOpenDrawer}>
                        <S.MenuIcon name="menu" />
                    </S.Button>
                </S.ButtonDrawerContainer>
            );
        }

        // FIXME botão de importar artes comentado
        return (
            <S.SearchContainer>
                {/* <S.NewClientButton width={width} onPress={importArt}>
                    <AddIcon />
                    <S.AddClientText>Importar arte</S.AddClientText>
                </S.NewClientButton> */}
                <S.UserImage onContextMenu={(e) => e.preventDefault()} source={uri} />
            </S.SearchContainer>
        );
    }

    function ResetAllDropDawn() {
        setDropdownVisibleColorPrimary(false);
        setDropdownVisibleColorSecondary(false);
        setDropdownVisibleColorTertiary(false);
        setDropdownVisibleColorQuaternary(false);
    }

    function RenderDropdownColors(dropdownVisibleColor, setColorSelect) {
        const widthColor = openFilters ? 235 : 150;

        if (!dropdownVisibleColor) {
            return null;
        }

        const filterAll = () => {
            setColorSelect('');
            ResetAllDropDawn();
        }

        const renderAllColors = () => (
            listColors.map((v) => {
                const filterColor = () => {
                    setColorSelect(v);
                    ResetAllDropDawn();
                }
                return (
                    <S.DropdownOption onPress={filterColor} key={v}>
                        <S.DropdownOptionText>
                            {v}
                        </S.DropdownOptionText>
                    </S.DropdownOption>
                );
            })
        );

        return (
            <S.DropdownArea width={width} isVisibleModal={openFilters} widthNumber={widthColor} topNumber={35}>
                <S.DropdownOption onPress={filterAll}>
                    <S.DropdownOptionText>
                        Nenhum
                    </S.DropdownOptionText>
                </S.DropdownOption>
                {renderAllColors()}
            </S.DropdownArea>
        );
    }

    function RenderDropdownArtType() {
        if (!dropdownVisible) {
            return null;
        }

        const filterAll = () => {
            setArtType('');
            handleDropdownPress();
        }

        const filterActive = () => {
            setArtType('feed');
            handleDropdownPress();
        }

        const filterPending = () => {
            setArtType('story');
            handleDropdownPress();
        }

        return (
            <S.DropdownArea width={width} isVisibleModal={openFilters} topNumber={35} widthNumber={235}>
                <S.DropdownOption onPress={filterAll}>
                    <S.DropdownOptionText>
                        Todos
                    </S.DropdownOptionText>
                </S.DropdownOption>
                <S.DropdownOption onPress={filterActive}>
                    <S.DropdownOptionText>
                        Feed
                    </S.DropdownOptionText>
                </S.DropdownOption>
                <S.DropdownOption onPress={filterPending}>
                    <S.DropdownOptionText>
                        Story
                    </S.DropdownOptionText>
                </S.DropdownOption>
            </S.DropdownArea>
        );
    }

    function RenderDropdownIdEvent() {
        if (!dropdownVisibleEvent) {
            return null;
        }

        const filterAll = () => {
            setIdEvent('');
            setNameEvent('Todos');
            handleDropdownPressEvent();
        }

        return (
            <S.DropdownArea width={width} isVisibleModal={openFilters} topNumber={35} widthNumber={235}>
                <S.DropdownOption onPress={filterAll}>
                    <S.DropdownOptionText>
                        Todos
                    </S.DropdownOptionText>
                </S.DropdownOption>
                {(eventList || []).map((v) => {
                    const filterActive = () => {
                        setIdEvent(v.id);
                        setNameEvent(v.name);
                        handleDropdownPressEvent();
                    }
                    return (
                        <S.DropdownOption onPress={filterActive} key={v.id}>
                            <S.DropdownOptionText>
                                {v.name}
                            </S.DropdownOptionText>
                        </S.DropdownOption>
                    );
                })}
            </S.DropdownArea>
        );
    }

    function renderButtonFilters() {
        // if (width < 400) {
        //     return null;
        // }
        if (width < 1740 && colorTertiary) {
            return RenderFilterButton();
        }
        if (width < 1575 && colorSecondary) {
            return RenderFilterButton();
        }
        if (width < 1400 && colorPrimary) {
            return RenderFilterButton();
        }
        if (openFilters) {
            return null;
        }

        return RenderFilterButton();
    }

    function RenderFilterButton() {
        if (!infoScreenRoutes?.state || (infoScreenRoutes?.state?.routes?.length > 0 && removeFilters.includes(params?.title || params?.initialTab || ''))) {
            return null;
        }
        return (
            <S.NewClientButton width={width} onPress={changeModalVisible} openFilters={openFilters}>
                <AddIcon />
                <S.AddClientText>{`${openFilters ? 'Fechar' : 'Abrir'} Filtros`}</S.AddClientText>
            </S.NewClientButton>
        );
    }

    function changeModalVisible() {
        setOpenFilters(!openFilters);
    }

    function RenderFilterArea() {
        if (width < 1740 && colorTertiary) {
            return null;
        }
        if (width < 1575 && colorSecondary) {
            return null;
        }
        if (width < 1400 && colorPrimary) {
            return null;
        }
        if (width < 1300 || openFilters) {
            return null;
        }

        return RenderBaseFilter();
    }

    function RenderBaseFilter() {
        const coditionMarginTop = width < 1300 || openFilters ? 20 : 0;
        const coditionMarginRight = width < 1300 || openFilters ? 0 : 16;

        return (
            <>
                {/* <S.RequestFilterButton width={width} onPress={handleDropdownPress} marginTop={coditionMarginTop} marginRight={coditionMarginRight}>
                    <InputSearch
                        value={artType}
                        setValue={setArtType}
                        placeholder="Buscar por tipo"
                        disabled
                        arrowIcon
                        marginLeft={0}
                        height={40}
                        widthArea={235}
                    />
                    {RenderDropdownArtType()}
                </S.RequestFilterButton> */}
                <S.RequestFilterButton width={width} onPress={handleDropdownPressEvent} marginTop={coditionMarginTop} marginRight={coditionMarginRight}>
                    <InputSearch
                        value={nameEvent}
                        setValue={setNameEvent}
                        placeholder="Buscar por evento"
                        disabled
                        arrowIcon
                        marginLeft={0}
                        height={40}
                        widthArea={235}
                    />
                    {RenderDropdownIdEvent()}
                </S.RequestFilterButton>
            </>
        )
    }

    function removeAllFilter() {
        setSearchTxt('');
        setArtType('');
        setIdEvent('');
        setNameEvent('');
        setColorPrimary('');
        setColorSecondary('');
        setColorTertiary('');
        setColorQuaternary('');
    }

    function RenderFilterColor() {
        if (width < 1740 && colorTertiary) {
            return null;
        }
        if (width < 1575 && colorSecondary) {
            return null;
        }
        if (width < 1400 && colorPrimary) {
            return null;
        }
        if (width < 1300 || openFilters) {
            return null;
        }

        return RenderColorsFilter();
    }


    function RenderColorsFilter() {
        const coditionMarginTop = width < 1300 || openFilters ? 20 : 0;
        const coditionMarginRight = width < 1300 || openFilters ? 0 : 16;
        const widthColor = openFilters ? 235 : 150;

        return (
            <>
                <S.RequestFilterButton width={width} onPress={handleDropdownPressColorPrimary} marginTop={coditionMarginTop} marginRight={coditionMarginRight}>
                    <InputSearch
                        value={colorPrimary}
                        setValue={setColorPrimary}
                        placeholder="Cor primária"
                        disabled
                        arrowIcon
                        marginLeft={0}
                        height={40}
                        widthArea={widthColor}
                    />
                    {RenderDropdownColors(dropdownVisibleColorPrimary, setColorPrimary)}
                </S.RequestFilterButton>
                {!colorPrimary ? null : (
                    <S.RequestFilterButton width={width} onPress={handleDropdownPressColorSecondary} marginTop={coditionMarginTop} marginRight={coditionMarginRight}>
                        <InputSearch
                            value={colorSecondary}
                            setValue={setColorSecondary}
                            placeholder="Cor secundária"
                            disabled
                            arrowIcon
                            marginLeft={0}
                            widthArea={widthColor}
                            height={40}
                        />
                        {RenderDropdownColors(dropdownVisibleColorSecondary, setColorSecondary)}
                    </S.RequestFilterButton>
                )}
                {!colorSecondary ? null : (
                    <S.RequestFilterButton width={width} onPress={handleDropdownPressColorTertiary} marginTop={coditionMarginTop} marginRight={coditionMarginRight}>
                        <InputSearch
                            value={colorTertiary}
                            setValue={setColorTertiary}
                            placeholder="Cor ternária"
                            disabled
                            arrowIcon
                            marginLeft={0}
                            height={40}
                            widthArea={widthColor}
                        />
                        {RenderDropdownColors(dropdownVisibleColorTertiary, setColorTertiary)}
                    </S.RequestFilterButton>
                )}
                {!colorTertiary ? null : (
                    <S.RequestFilterButton width={width} onPress={handleDropdownPressColorQuaternary} marginTop={coditionMarginTop} marginRight={coditionMarginRight}>
                        <InputSearch
                            value={colorQuaternary}
                            setValue={setColorQuaternary}
                            placeholder="Cor quaternária"
                            disabled
                            arrowIcon
                            marginLeft={0}
                            widthArea={widthColor}
                            height={40}
                        />
                        {RenderDropdownColors(dropdownVisibleColorQuaternary, setColorQuaternary)}
                    </S.RequestFilterButton>
                )}
            </>
        );
    }

    function RenderRemoveAllFilter() {
        if (!infoScreenRoutes?.state || (infoScreenRoutes?.state?.routes?.length > 0 && removeFilters.includes(params?.title || params?.initialTab || ''))) {
            return null;
        }
        return (
            <S.NewClientButton width={width} onPress={removeAllFilter} openFilters={openFilters}>
                <S.AddClientText>Remover filtros</S.AddClientText>
            </S.NewClientButton>
        );
    }

    function renderOptionsHeader(visible: boolean ) {
        if (!visible) {
            return null;
        }

        function buttonsFilter() {
            if(searchOpen){
                return null
            }

            return (
                <S.AllFilterArea width={width}>
                    <S.AdjustFilters
                        width={width}
                        existsColorTertiary={colorTertiary}
                        isVisibleModal={openFilters}>
                        {RenderFilterArea()}
                        {RenderFilterColor()}
                        {openFilters ? null : RenderRemoveAllFilter()}
                        {openFilters ? null : renderButtonFilters()}
                    </S.AdjustFilters>
                </S.AllFilterArea>
            )
        }

        return (
            <S.OptionArea width={width}>
                {renderInputSearch()}
                {buttonsFilter()}
            </S.OptionArea>
        );
    }

    function RenderContentArea() {
        // if (actualClientDrawer === 'Publicações' || actualClientDrawer === 'Financeiro'
        //     || actualClientDrawer === 'Continuar editando' || actualClientDrawer === 'edit' || actualClientDrawer === 'plans') {
        //     return (
        //         <S.SearchContainer>
        //             <S.UserImage source={uri} />
        //         </S.SearchContainer>
        //     );
        // }

        return (
            <>
               {renderOptionsHeader(width >= 901)}
                <S.ContainerSearch width={width} searchOpen={searchOpen}>
                    {renderContainerSearchOrMenu()}
                </S.ContainerSearch>
                <Modal isVisible={openFilters} closeModal={changeModalVisible}>
                    <S.ScrollFilters horizontal={false}>
                        <S.AdjustFilters width={width} existsColorTertiary={colorTertiary} isVisibleModal={openFilters}>
                            {RenderBaseFilter()}
                            {RenderColorsFilter()}
                            {RenderFilterButton()}
                            {RenderRemoveAllFilter()}
                        </S.AdjustFilters>
                    </S.ScrollFilters>
                </Modal>
            </>
        );
    }

    return (
        <S.Container width={width} isFocus={!infoScreenRoutes?.state || (infoScreenRoutes?.state?.routes?.length > 0 && removeFilters.includes(params?.title || params?.initialTab || ''))} ref={headerRef}>
            <S.HeaderContent width={width} isFocus={!infoScreenRoutes?.state || (infoScreenRoutes?.state?.routes?.length > 0 && removeFilters.includes(params?.title || params?.initialTab || ''))}>
                {/* <LogoCLient /> */}
                <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../assets/logotipo.png')} style={{ resizeMode: 'contain', width: 175.902, height: 28.904 }} />
                {RenderContentArea()}
            </S.HeaderContent>
            {renderOptionsHeader(width <= 900)}
        </S.Container>
    );
}
