import React from 'react';
import * as S from './styles';

interface TitleProps {
    label: string;
}

export function Title(props: TitleProps) {
    const { label } = props;
    return (
        <S.Title>{label}</S.Title>
    );
}
