// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { LineChart, YAxis, Grid, XAxis } from 'react-native-svg-charts'
import { useSelector } from 'react-redux';
import { useWindowDimensions } from 'react-native';

import * as S from './styles';
import theme from '../../../../../global/styles/theme';
import { getInvoicingDashboard } from '../../../../../services/dashboard';

import { PopupOption } from '../PopupOption';

interface InvoicingListProps {
    type?: string,
    price?: number,
    id_reference?: number,
}

interface InvoicingChartProps {
    invoicingList?: Array<InvoicingListProps>
}

interface PopupOptionProps {
    nameOption: string,
    option: 'plans' | 'arts' | 'all',
}

export function InvoicingChart(props: InvoicingChartProps) {
    const width = useWindowDimensions().width;
    const yAxisStyles = { fill: theme.colors.gray[15], fontSize: 10 };
    const xAxisStyles = { fontSize: width <= 800 ? 10 : 12, fontFamily: theme.font.segoeUi.regular };
    const xContentInset = { left: 20, right: 10 };
    const lineChartStyle = { flex: 1, marginLeft: 16 };
    const lineChartStroke = { stroke: 'rgb(134, 65, 244)' };
    const monthList = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
    const contentInset = { top: 20, bottom: 20 }
    const userInfo = useSelector((state) => state.user.user);

    const [popupOption, setPopupOption] = useState<PopupOptionProps>({} as PopupOptionProps);
    const [invoicingOptionList, setInvoicingOptionList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
        getInvoicingOption();
    }, [popupOption]);

    async function getInvoicingOption() {
        const { result } = await getInvoicingDashboard(userInfo.user.id, popupOption.option);
        const getValues = result ? Object.values(result) : [];
        setInvoicingOptionList(getValues);
        setLoading(true);
    }

    function RenderOptionName() {
        if (!popupOption) {
            return null;
        }

        return (
            <S.OptionNameArea>
                <S.TextDefault
                    fontSize={10}
                    fontFamily='regular'
                >
                    {popupOption.nameOption}
                </S.TextDefault>
            </S.OptionNameArea>
        );
    }

    function RenderCoditionInfo() {
        if (!loading) {
            return (
                <S.Loading />
            );
        }

        return RenderChart();
    }

    function xAxisFormatLabel(_value: string, index: number) {
        return monthList[index]
    }

    function yAxisFormatLabel(value: string) {
        return `R$ ${value},00`;
    }

    function RenderChart() {
        if (!invoicingOptionList.length) {
            return (
                <S.VoidTextArea>
                    <S.TextDefault
                        fontSize={14}
                        fontFamily='semi'
                    >
                        Não há dados!
                    </S.TextDefault>
                </S.VoidTextArea>
            );
        }

        return (
            <S.ChartArea>
                <S.AlignChart>
                    <YAxis
                        data={invoicingOptionList}
                        contentInset={contentInset}
                        svg={yAxisStyles}
                        numberOfTicks={5}
                        formatLabel={yAxisFormatLabel}
                    />
                    <S.InfoChartAlign>
                        <LineChart
                            style={lineChartStyle}
                            data={invoicingOptionList}
                            svg={lineChartStroke}
                            contentInset={contentInset}
                        >
                            <Grid />
                        </LineChart>
                        <XAxis
                            data={monthList}
                            formatLabel={xAxisFormatLabel}
                            contentInset={xContentInset}
                            svg={xAxisStyles}
                        />
                    </S.InfoChartAlign>
                </S.AlignChart>
            </S.ChartArea>
        );
    }

    return (
        <S.SignedChartArea>
            <S.TitleArea>
                <S.TextDefault
                    fontSize={14}
                    fontFamily='semi'
                >
                    Faturamento
                </S.TextDefault>
                <S.OptionArea>
                    {RenderOptionName()}
                    <PopupOption setOption={setPopupOption} invoicing />
                </S.OptionArea>
            </S.TitleArea>
            {RenderCoditionInfo()}
        </S.SignedChartArea>
    );
}
