// @ts-nocheck
import React, { useEffect } from 'react';
import { useWindowDimensions, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { ButtonLogout } from '../ButtonLogout';
import { clientDrawer } from '../../store/modules/user/actions';
import MedalGold from '../../assets/images/svg/medalGold';
import CakeIcon from '../../assets/images/svg/cakeIcon';
import * as S from './styles';

interface SideBarClientProps {
    currentPage: number;
    setCurrentPage: (screen: number) => void;
    setDrawerIsOpen: (isOpen: boolean) => void;
}

export function SideBarClient(props: SideBarClientProps) {
    const { currentPage, setCurrentPage, setDrawerIsOpen } = props;
    const { user } = useSelector((state: any) => state.user.user);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const width = useWindowDimensions().width;

    const uri = { uri: user?.photo };
    let screens = [
        {
            id: 1,
            label: 'Ajuda',
            icon: 'book',
            nameScreen: 'Instructions',
        },
        {
            id: 2,
            label: 'Minhas artes',
            icon: 'edit',
            nameScreen: 'SavedArtsList',
            inicialType: 'Minhas'
        },
        {
            id: 3,
            label: 'Publicações',
            icon: 'heart',
            nameScreen: 'HomeScreen',
            inicialType: 'estoque'
        },
        {
            id: 4,
            label: 'Modelos',
            icon: 'layout',
            nameScreen: 'HomeScreen',
            inicialType: 'Modelos'
        },
        {
            id: 5,
            label: 'Modelos Favoritos',
            icon: 'heart',
            nameScreen: 'SavedArtsList',
            inicialType: 'Favoritos'
        },
        {
            id: 6,
            label: 'Modelos comprados',
            icon: 'dollar-sign',
            nameScreen: 'SavedArtsList',
            inicialType: 'comprados'
        },
        // {
        //     id: 7,
        //     label: 'Continuar editando',
        //     icon: 'edit',
        //     nameScreen: 'SavedArtsList',
        // },
        {
            id: 8,
            label: 'Financeiro',
            icon: 'dollar-sign',
            nameScreen: 'PaymentControl',
        },
    ];

    screens =
        user?.platform === 'revenda_prod'
            ? [
                  screens[0],
                  {
                      id: 7,
                      label: 'Aniversariantes',
                      icon: 'cake',
                      nameScreen: 'BirthDays',
                  },
                  ...screens.slice(1, screens.length),
              ]
            : screens;

    function goToEditScreen() {
        navigation.navigate('EditPerfilScreen');
        setCurrentPage('EditPerfilScreen');
        dispatch(clientDrawer('edit'));
        if (width <= 860) {
            setDrawerIsOpen(false);
        }
    }

    function goToPlanInfo() {
        dispatch(clientDrawer('plans'));
        navigation.navigate('PlanScreen');
    }

    function renderButtonNavigation() {
        return screens.map((e) => {
            const { id, label, icon, nameScreen } = e;
            const isFocused = currentPage === id;
            const actualScreenName = currentPage === id ? label : null;

            if (actualScreenName) {
                dispatch(clientDrawer(actualScreenName));
            }

            function openUrl() {
                Linking.openURL('http://ajudamidiacarros.com.br/');
            }

            function handleNavigation() {
                if (nameScreen === 'Instructions') {
                    openUrl();
                } else {
                    navigation.navigate(nameScreen, { title: label });
                    setCurrentPage(id);
                    if (width <= 860) {
                        setDrawerIsOpen(false);
                    }
                }
            }

            function renderIcon() {
                if (icon === 'cake') {
                    return <CakeIcon />;
                }

                return (
                    <S.ButtonNavigationIcon isFocused={isFocused} name={icon} />
                );
            }

            return (
                <S.ButtonNavigation
                    width={width}
                    isFocused={isFocused}
                    key={id}
                    onPress={handleNavigation}>
                    <S.ButtonNavigationIconContainer>
                        {renderIcon()}
                    </S.ButtonNavigationIconContainer>
                    <S.ButtonNavigationText isFocused={isFocused}>
                        {label}
                    </S.ButtonNavigationText>
                </S.ButtonNavigation>
            );
        });
    }

    const restoreState = async () => {
        const inicialUrl = await Linking.getInitialURL()
        const inicialPage = screens.find((screen) =>
            inicialUrl?.includes(screen.inicialType),
        );
        setCurrentPage(inicialPage?.id)
    };

    useEffect(() => {
        restoreState();
    }, []);

    return (
        <S.Container width={width} vertical>
            <S.UserContainer onPress={goToEditScreen}>
                <S.UserImage onContextMenu={(e) => e.preventDefault()} source={uri} />
                <S.UserInfoContainer>
                    <S.UserName>{user.name}</S.UserName>
                    <S.UserType>Usuário</S.UserType>
                </S.UserInfoContainer>
            </S.UserContainer>
            <S.UserPlanContainer onPress={goToPlanInfo} width={width}>
                <MedalGold />
                <S.UserPlanTitle>{`Plano ${user.plan_name}`}</S.UserPlanTitle>
            </S.UserPlanContainer>
            <S.NavigationContainer>
                {renderButtonNavigation()}
            </S.NavigationContainer>
            <ButtonLogout />
        </S.Container>
    );
}
