import * as React from 'react';
import Svg, { SvgProps, G, Rect, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        data-name="Componente 4 \u2013 1"
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={19}
        {...props}>
        <G
            data-name="Ret\xE2ngulo 209"
            transform="translate(2 11)"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth={2}>
            <Rect width={26} height={8} rx={1} stroke="none" />
            <Path d="M1 1h24v6H1z" />
        </G>
        <G
            data-name="Ret\xE2ngulo 211"
            transform="translate(5 5)"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth={2}>
            <Rect width={20} height={8} rx={1} stroke="none" />
            <Path d="M1 1h18v6H1z" />
        </G>
        <G
            data-name="Ret\xE2ngulo 210"
            transform="translate(0 17)"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth={2}>
            <Path stroke="none" d="M0 0h30v2H0z" />
            <Rect x={1} y={1} width={28} />
        </G>
        <Path
            data-name="Linha 367"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth={2}
            d="M11.345 0v5.517"
        />
        <Path
            data-name="Linha 368"
            fill="none"
            stroke="#a5a5a5"
            strokeWidth={2}
            d="M18.241 0v5.517"
        />
    </Svg>
);

export default SvgComponent;
