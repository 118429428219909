// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Rect, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} {...props}>
        <G
            transform="translate(-1 -1)"
            fill="none"
            stroke={props.stroke || "#6746c8"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}>
            <Rect
                data-name="Ret\xE2ngulo 158"
                width={10}
                height={10}
                rx={2}
                transform="translate(9 9)"
            />
            <Path
                data-name="Caminho 94"
                d="M4.543 13.019H3.7a1.7 1.7 0 0 1-1.7-1.7V3.7A1.7 1.7 0 0 1 3.7 2h7.628a1.7 1.7 0 0 1 1.7 1.7v.848"
            />
        </G>
    </Svg>
);

export default SvgComponent;
