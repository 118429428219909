// @ts-nocheck
import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu';

import { deletePlans } from '../../../../services/plans';
import { deleteClient } from '../../../../services/clients';
import {
    converterCurrency,
    converterDate,
} from '../../../../global/utils/functions';
import { ClientProps, PlansProps } from '../../utils';
import { format, compareAsc } from 'date-fns'
import * as S from './styles';

import MoreOption from '../../../../assets/images/svg/moreOption';
import PenIcon from '../../../../assets/images/svg/penIcon';
import TrashIcon from '../../../../assets/images/svg/trashIcon';
import EyeIcon from '../../../../assets/images/svg/eyeIcon';
import RequestsIcon from '../../../../assets/images/svg/requestsIcon';

import { DeleteModal } from '../DeleteModal';
import { TableInfo } from '../TableInfo';
import { Modal } from '../../../Modal';
import { CoditionModal } from '../../../../components/CoditionModal';
import { RequestsModal } from '../../components/RequestsModal';
import { SelectedPlansModal } from '../SelectedPlansModal';
import { LoadingModal } from '../../../../components/LoadingModal';

interface InfosProps extends ClientProps, PlansProps {}

type TableListProps = ClientProps[] | PlansProps[];

interface ListTableProps {
    tableList: TableListProps;
    type: 'clients' | 'plans' | 'connections';
    getAllClient: () => void;
}

interface ModalInfoProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    buttonFunction: () => void,
}

interface RequestsModalProps {
    clientInfo: ClientProps,
}

interface SelectedPlansProps {
    plansList: Array<string>,
    modalVisible: boolean,
    changeModalVisible: () => void,
    refreshList: () => void,
    planDeletedId: number,
}

export function ListTable(props: ListTableProps) {
    const { getAllClient, tableList, type } = props;
    const navigation = useNavigation();
    const window = useWindowDimensions().width;

    const [modalVisible, setModalVisible] = useState(false);
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);
    const [requestsModalVisible, setRequestsModalVisible] = useState(false);
    const [requestsModalInfo, setRequestsModalInfo] = useState<RequestsModalProps>({} as RequestsModalProps);
    const [selectedPlansVisible, setSelectedPlansVisible] = useState<SelectedPlansProps>({} as SelectedPlansProps);
    const [idSelected, setIdSelected] = useState(null);
    const [loadingModalVisible, setLoadingModalVisible] = useState(false);

    function keyExtractor(item: ClientProps) {
        return item.id;
    }

    const changeModalVisible = () => {
        setModalVisible(!modalVisible);
    };

    function changeModalInfoVisible() {
        getAllClient();
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    function RenderOptionArea(
        optionName: string,
        OptionIcon: any,
        onPressFunction?: () => void,
    ) {
        return (
            <MenuOption onSelect={onPressFunction}>
                <S.OptionArea>
                    <OptionIcon />
                    <S.OptionText>{optionName}</S.OptionText>
                </S.OptionArea>
            </MenuOption>
        );
    }

    function renderInfos(item: InfosProps) {
        const status = item.status === 'active' ? 'Ativo' : 'Inativo';
        const statusUser = item.is_pending ? 'Pendente' : '-';
        const date = format(new Date(item.created_at), 'dd/MM/yyyy HH:mm');

        if (type === 'clients') {
            return (
                <>
                    <TableInfo
                        title={item.name}
                        width="30%"
                        typeInfo="info-user"
                        notBar
                        photo={item.photo}
                    />
                    <TableInfo title={item.email} width="25%" typeInfo="info" />
                    <TableInfo title={date} width="15%" typeInfo="info" />
                    {/* <S.StatusIndicator>
                        <S.StatusIndicatorTextClient is_pending={item.is_pending}>
                            {statusUser}
                        </S.StatusIndicatorTextClient>
                    </S.StatusIndicator> */}
                </>
            );
        }

        if (type === 'connections') {
            return (
                <>
                    <TableInfo
                        title={item.name}
                        width="30%"
                        typeInfo="info-user"
                        notBar
                        photo={item.photo}
                    />
                    <TableInfo title={item.plan_name} width="30%" typeInfo="info" />
                    {/* <TableInfo title={item.status} width="30%" typeInfo="info" /> */}
                    <S.StatusIndicator style={{ width: '30%', minWidth: '30%' }}>
                        <S.StatusIndicatorTextConnection status={item.status}>
                            {item.status === 'no_expiration' ? 'Nunca fez conexão' : (item.status === 'expiring_soon' ? '3 dias para expirar' : 'Conexão expirada')}
                        </S.StatusIndicatorTextConnection>
                    </S.StatusIndicator>
                    {/* <S.StatusIndicator>
                        <S.StatusIndicatorTextClient is_pending={item.is_pending}>
                            {statusUser}
                        </S.StatusIndicatorTextClient>
                    </S.StatusIndicator> */}
                </>
            );
        }

        if (type === 'vehicles') {
            const vehiclePhoto =  item?.photo?.split('|');
            const photo = vehiclePhoto?.length > 0 ? vehiclePhoto[0] : '';

            return (
                <>
                    <TableInfo
                        title={item.model}
                        width="30%"
                        typeInfo="info-user"
                        notBar
                        photo={photo}
                    />
                    <TableInfo title={item.year_model} width="10%" typeInfo="info" />
                    <TableInfo title={item.board} width="15%" typeInfo="info" />
                    <TableInfo title={item.price} width="15%" typeInfo="info" />
                    <TableInfo title={date} width="20%" typeInfo="info" />
                </>
            );
        }

        const pricerformatted = converterCurrency(item.price);

        return (
            <>
                <TableInfo title={item.name} isMain={item.is_main} width="23%" typeInfo="info" icon />
                {/* <TableInfo
                    title={item.amount_arts}
                    width="18%"
                    typeInfo="info"
                />
                <TableInfo
                    title={item.amount_posts}
                    width="18%"
                    typeInfo="info"
                /> */}
                <TableInfo
                    title={pricerformatted}
                    width="16%"
                    typeInfo="info"
                />
                <S.StatusIndicator>
                    <S.StatusIndicatorText status={item.status}>
                        {status}
                    </S.StatusIndicatorText>
                </S.StatusIndicator>
            </>
        );
    }

    function RenderRequestOption(openRequestsModal: () => void) {
        if (type === 'plans') {
            return null;
        }

        // return RenderOptionArea(
        //     'Solicitações',
        //     RequestsIcon,
        //     openRequestsModal,
        // );
    }

    function changeSelectedModalVisible() {
        setSelectedPlansVisible({ modalVisible: false } as SelectedPlansProps)
    }

    async function handleDelete() {
        changeModalVisible();
        setLoadingModalVisible(true);
        const response =
            type === 'plans'
                ? await deletePlans(idSelected)
                : await deleteClient(idSelected);
        if (response?.status === 200) {
            setLoadingModalVisible(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'success',
                titleModal: 'Sucesso',
                descriptionModal: response?.message,
                buttonText: 'Continuar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        } else {
            setLoadingModalVisible(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: response?.message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: navigationDelete,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        }
    }

    function navigationDelete() {
        const nameSreen =
            type === 'plans' ? 'PlansScreen' : 'ManageClientsRoutes';

        navigation.replace(nameSreen);
    }

    function RenderItem({ item }) {
        const handleDeletePress = () => {
            setIdSelected(item.id);
            type === 'plans' ? openSelectedPlans() : changeModalVisible();
        };

        const openSelectedPlans = () => {
            setSelectedPlansVisible({
                modalVisible: true,
                planDeletedId: item.id,
                plansList: tableList,
                refreshList: navigationDelete,
                changeModalVisible: changeSelectedModalVisible,
            })
        }

        function navigationEdit() {
            const typeScreen = type === 'plans' ? 'edit' : 'editClient';
            const props = { type: typeScreen, item };
            const nameSreen =
                type === 'plans' ? 'CreatePlansScreen' : 'NewClientScreen';

            navigation.navigate(nameSreen, props);
        }

        const handleEditPress = () => {
            navigationEdit(item);
        };

        function openRequestsModal() {
            setRequestsModalInfo({
                clientInfo: item,
            });
            setRequestsModalVisible(true);
        }

        function coditionTypeScreen() {
            if (type === 'plans') {
                return {
                    params: 'viewPlan',
                    screenName: 'CreatePlansScreen',
                };
            }

            const coditionScreen = type !== 'clients' ? 'VehicleScreen' : 'NewClientScreen';

            return {
                params: 'viewClient',
                screenName: coditionScreen,
            };
        }

        const goToViewProfile = () => {
            const typeScreen = coditionTypeScreen();
            const props = { type: typeScreen.params, item };
            navigation.navigate(typeScreen.screenName, props);
        }

        function RenderOtherOptions() {
            if (type === 'vehicles') {
                return null;
            }

            return (
                <>
                    {RenderOptionArea(
                        'Editar',
                        PenIcon,
                        handleEditPress,
                    )}
                    {RenderOptionArea(
                        'Excluir',
                        TrashIcon,
                        handleDeletePress,
                    )}
                </>
            );
        }

        return (
            <S.TableArea width={window}>
                {renderInfos(item)}
                {type === 'connections' ? null : (
                    <S.MoreOptionButton type={type}>
                        <Menu>
                            <MenuTrigger text="" style={S.buttonStyle}>
                                <MoreOption />
                            </MenuTrigger>
                            <MenuOptions
                                optionsContainerStyle={S.optionsContainerStyle}>
                                {RenderOptionArea('Ver', EyeIcon, goToViewProfile)}
                                {RenderOtherOptions()}
                                {RenderRequestOption(openRequestsModal)}
                            </MenuOptions>
                        </Menu>
                    </S.MoreOptionButton>
                )}
            </S.TableArea>
        );
    }

    return (
        <>
            <S.ListTable
                data={tableList}
                keyExtractor={keyExtractor}
                renderItem={RenderItem}
            />
            <Modal isVisible={modalVisible} closeModal={changeModalVisible}>
                <DeleteModal
                    handleCancelPress={changeModalVisible}
                    handleConfirmPress={handleDelete}
                    typeScreen={type}
                />
            </Modal>
            <RequestsModal
                modalVisible={requestsModalVisible}
                setModalVisible={setRequestsModalVisible}
                clientInfo={requestsModalInfo.clientInfo}
                refreshList={getAllClient}
            />
            <CoditionModal
                titleModal={modalInfo.titleModal}
                descriptionModal={modalInfo.descriptionModal}
                typeModal={modalInfo.typeModal}
                buttonText={modalInfo.buttonText}
                modalVisible={modalInfo.modalVisible}
                setModalVisible={modalInfo.setModalVisible}
                buttonFunction={modalInfo.buttonFunction}
            />
            <LoadingModal
                visible={loadingModalVisible}
                titleLoading='Exluindo...'
            />
            <SelectedPlansModal
                plansList={selectedPlansVisible.plansList}
                modalVisible={selectedPlansVisible.modalVisible}
                changeModalVisible={selectedPlansVisible.changeModalVisible}
                refreshList={selectedPlansVisible.refreshList}
                planDeletedId={selectedPlansVisible.planDeletedId}
            />
        </>
    );
}
