// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Path, Circle } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={13} {...props}>
    <G
      transform="translate(0 -3)"
      fill="none"
      stroke="#626477"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Path
        data-name="Caminho 7"
        d="M1 9.5S3.909 4 9 4s8 5.5 8 5.5S14.091 15 9 15 1 9.5 1 9.5Z"
      />
      <Circle
        data-name="Elipse 16"
        cx={2}
        cy={2}
        r={2}
        transform="translate(6.869 7.576)"
      />
    </G>
  </Svg>
)

export default SvgComponent
