// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        data-name="Componente 2 \u2013 1"
        xmlns="http://www.w3.org/2000/svg"
        width={213.674}
        height={35.151}
        {...props}>
        <Path
            data-name="Caminho 73"
            d="M19.938 2.121v2.121h-2.97c-4 0-4.454.273-7.485 4.485-2.394 3.364-3.242 4-3.636 2.788C5.12 9.273.877 9.091.12 11.273a1.516 1.516 0 0 0 .242 1.576c.455.727.7.788 2.455.788 2.242 0 2.455.3 1.242 1.727-1.545 1.848-1.636 2.333-1.455 7.424.121 4.151.212 4.818.7 5.273.455.394.576.939.576 2.3v1.788l4.3-.091 4.273-.091.3-.909c.576-1.818.939-1.97 4.212-1.97h2.97v6.061h2.121V0h-2.118Zm0 7.576v4.242h-5.091c-6.788 0-7.03-.242-3.939-4.333 2.667-3.545 3.545-4.091 7-4.121l2.03-.03ZM9.423 17.182c5.606 3.454 5.061 2.97 4.667 4.061-.485 1.273-1.3 1.606-3.364 1.364A5.868 5.868 0 0 1 6.362 20a2.85 2.85 0 0 1-.485-2.576c.182-1.515.333-1.97.606-1.97.061 0 1.395.788 2.94 1.728Zm10.515 5.545a15.373 15.373 0 0 1-.242 3.939 6.226 6.226 0 0 1-1.364-.939 7.593 7.593 0 0 1-2.485-4.151c-.182-.879-.091-1.151.636-1.909a2.414 2.414 0 0 1 2.151-.879h1.3Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 74"
            d="M64.056 10.121a27.58 27.58 0 0 0-2.3 2.727c0 .545 1.242 0 3.364-1.515 2.909-2.03 3.3-2.424 3.3-3.182 0-.455-.212-.576-1.03-.576-.876.001-1.301.301-3.334 2.546Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 75"
            d="M121.755 8.909a9.723 9.723 0 0 0-4.454 1.97c-4.485 3.273-5 12.848-.97 17 4.667 4.818 15.848 4.212 18.878-1a8.145 8.145 0 0 0 .939-2.121l.212-.818h-1.7c-1.333 0-1.7.091-1.7.455a6.738 6.738 0 0 1-2.03 3.485c-3 2.364-7.97 2.273-10.667-.152-1.727-1.576-2.545-3.667-2.7-7-.242-5.091.97-8.03 3.939-9.7a6.255 6.255 0 0 1 4.03-.848 7.006 7.006 0 0 1 4.03.818c1.636.848 3.394 3.03 3.394 4.212 0 .455.273.545 1.7.545h1.7l-.212-.848a8.493 8.493 0 0 0-5.758-5.667 21.222 21.222 0 0 0-8.631-.331Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 76"
            d="M30.847 19.848v11.06h2.121l.061-9 .091-9 4.454 9 4.454 9h2l3.939-8.848 3.939-8.848.091 8.848.061 8.848h3.636V8.757l-2 .091-2 .091-3.788 8.485c-2.091 4.667-3.879 8.576-4 8.727-.121.121-2.151-3.727-4.515-8.576l-4.3-8.788h-4.244Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 77"
            d="M80.846 13.818v4.454l-.636-.818c-1.515-1.939-4.97-2.758-7.939-1.879-3.394.97-5.151 3.515-5.182 7.454a7.44 7.44 0 0 0 2.545 6.182 9.207 9.207 0 0 0 5.3 2 8.173 8.173 0 0 0 4.879-1.848l1.03-1v2.545h3.333V9.394h-3.333Zm-3.03 3.212c1.97 1 3.03 3.182 3.03 6.151 0 3.03-1.061 5.151-3.121 6.182-2.576 1.273-5.182.3-6.636-2.515-.939-1.727-.939-5.485-.03-7.485a4.684 4.684 0 0 1 6.757-2.333Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 78"
            d="M88.119 9.999a2.093 2.093 0 0 0 1.152 3.485c2.394.515 3.939-1.97 2.3-3.636a3.125 3.125 0 0 0-3.452.151Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 79"
            d="M100.391 15.428a6.722 6.722 0 0 0-4.364 3.273c-.545 1.061-.394 1.3.818 1.3.97 0 1.182-.152 1.727-1.121a3.9 3.9 0 0 1 3.879-2.333c2.909-.03 4.3 1.03 4.636 3.576l.182 1.333-3.879.121c-3.424.091-4.121.212-5.424.848-2.03 1.03-2.727 2.061-2.727 4.091 0 3.061 1.97 4.545 6.061 4.515 2.606 0 4.606-.727 5.364-1.939.515-.818.848-.758.848.121 0 1.424.273 1.7 1.788 1.7h1.394l-.091-4.151c-.152-8.242-.455-9.3-3.212-10.666a13.045 13.045 0 0 0-7-.668Zm6.758 8.576a6.24 6.24 0 0 1-1.424 4.515 4.933 4.933 0 0 1-6.273.485 3.59 3.59 0 0 1-.606-4.091c.818-1.606 2.394-2.151 5.939-2.091l2.273.061Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 80"
            d="M142.724 15.697a5.186 5.186 0 0 0-3.273 2.545c-.727 1.394-.545 1.758.727 1.758 1.03 0 1.182-.091 1.545-1.091.667-1.848 3.3-2.909 5.7-2.273a3.437 3.437 0 0 1 2.758 3.3l.121 1.485-3.915.154c-4.485.152-6.364.788-7.394 2.485a5.526 5.526 0 0 0-.03 4.788c1.576 2.758 7.636 3.121 10.394.636l1.121-1.03.212 1.242.212 1.212h3.185l-.242-1.455a54.958 54.958 0 0 1-.242-5.667c-.03-3.788-.091-4.424-.7-5.606-.97-1.909-2.727-2.758-6.06-2.909a9.313 9.313 0 0 0-4.119.426Zm7.515 8.97a4.551 4.551 0 0 1-.667 2.909 4.331 4.331 0 0 1-4.212 2.273c-1.7 0-1.909-.091-2.727-1a3.506 3.506 0 0 1 1.788-5.757 21.412 21.412 0 0 1 3.485-.333l2.333-.03Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 81"
            d="M163.057 15.788a5.431 5.431 0 0 0-1.879 1.576l-.636.97v-2.879h-3.333v15.454h3.333v-4.788c0-4.394.061-4.879.727-6.333a3.768 3.768 0 0 1 3.818-2.515c1 0 1.212-.091 1.212-.576a3.882 3.882 0 0 1 .182-1.061c.273-.727-1.848-.636-3.424.152Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 82"
            d="M174.027 15.575a5.928 5.928 0 0 0-2.515 2.455c-.3.515-.333.333-.364-1v-1.576h-3.333v15.455h3.333v-4.485c0-5.242.394-6.818 2-8.242a3.077 3.077 0 0 1 2.364-.909c1.333 0 1.667-.364 1.7-1.758-.003-.485-2.003-.455-3.185.06Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 83"
            d="M183.875 15.545c-3.848 1.091-5.879 3.758-5.848 7.7a7.882 7.882 0 0 0 2.97 6.212c3.515 2.455 9.788 2.151 12.666-.606a8.606 8.606 0 0 0 2.03-8.091 7.975 7.975 0 0 0-4.3-4.7 13.54 13.54 0 0 0-7.518-.515Zm4.7 1.151a6.052 6.052 0 0 1 3.758 4c1.394 5.606-1.849 10.03-6.606 8.97-2.939-.667-4.333-2.758-4.333-6.485-.004-4.909 3.026-7.636 7.178-6.484Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 84"
            d="M203.117 15.333a6.545 6.545 0 0 0-3.485 1.7 2.615 2.615 0 0 0-.909 2.364 2.279 2.279 0 0 0 1.03 2.333c1 .879 1.909 1.182 7 2.364 2.515.545 3.606 1.424 3.545 2.879-.03 1.515-1 2.333-3.242 2.758-2.667.485-5.636-.818-6.091-2.667-.152-.606-.394-.7-1.545-.7-1.242 0-1.333.061-1.151.667a6.709 6.709 0 0 0 .939 1.636c2.454 3.3 11.424 3.3 13.879.03a4.431 4.431 0 0 0 .061-4.3c-.727-1.121-3.121-2.151-6.7-2.879-3.788-.788-4.454-1.182-4.333-2.636.151-1.667 1.242-2.333 3.879-2.333 2.364 0 3.3.455 4.394 2.182.515.818.788.939 1.879.97 1.273 0 1.273 0 .97-.848-.848-2.242-3.151-3.424-6.939-3.576a18.346 18.346 0 0 0-3.181.056Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 85"
            d="M60.241 23.181v7.727h3.333V15.454h-3.333Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 86"
            d="M88.119 23.181v7.727h3.333V15.454h-3.333Z"
            fill="#fff"
        />
    </Svg>
);

export default SvgComponent;
