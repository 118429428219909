import { api } from './api';

interface ImageProps {
    name: string;
    type: string;
    base64?: string;
    urlRevenda?: string;
}

interface ImageBlobProps {
    name: string;
    type: string;
    base64?: Blob;
    urlRevenda?: string;
}

export async function getImageLink(body: ImageProps) {
    try {
        const { data } = await api.post('/uploads', body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getImageLinkMultiples(files: Array<ImageBlobProps>) {
    try {
        // const formData = new FormData();
        // files.forEach((file) => {
        //     // const blob = base64ToBlob(file.base64);
        //     formData.append('files', JSON.stringify(file));
        // });
        // const { data } = await api.post(
        //     '/uploads/multiple',
        //     formData,
        //     {
        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //         },
        //     },
        // );
        // return data;
        const { data } = await api.post('/uploads/multiple', { images: files },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return data;
    } catch (response) {
        return response;
    }
}
