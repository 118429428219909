import React, { useRef, useState } from 'react';
import { Image, useWindowDimensions } from 'react-native';
import ImageModal, { ImageDetail } from 'react-native-image-modal';

import * as S from './styles';
import { isMobile } from 'react-device-detect';

interface CarImageProps {
    item: any;
    grid: boolean;
    index: number;
    data: Array<any>;
    setData: (v: Array<any>) => void;
    isClose?: boolean;
    onPressClose?: () => void;
    onPressDownload?: () => void;
}

export function CardImage(props: CarImageProps) {
    const { item, index, data, setData, grid, isClose, onPressClose, onPressDownload } = props;
    const width = useWindowDimensions().width;

    const imageModalRef = useRef<ImageDetail>(null);

    function handleOpenModal() {
        imageModalRef.current?.setState({
            isOpen: true,
            origin: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            },
        });
    }

    function nextPosition() {
        const format = data.map((e, i) => {
            if (i === index) {
                return data[index + 1];
            }
            if (i === index + 1) {
                return data[index];
            }
            return e;
        });
        setData(format);
    }

    function backPosition() {
        const format = data.map((e, i) => {
            if (i === index) {
                return data[index - 1];
            }
            if (i === index - 1) {
                return data[index];
            }
            return e;
        });
        setData(format);
    }

    // const [aspectRatio, setAspectRatio] = useState(1);

    const uri = item?.photo?.includes('|') ? item?.photo?.split('|')[0] : item?.photo;
    // Image.getSize(uri, (width, height) => {
    //     setAspectRatio(width / height);
    // });

    return (
        <>
            <S.ImageContainer
                width={width}
                grid={grid}
                onPress={handleOpenModal}
            >
                {!isClose ? null : (
                    <S.ContainerClose width={width} onPress={onPressClose}>
                        <S.IconClose width={width} name="x" style={{ color: 'white' }} />
                    </S.ContainerClose>
                )}
                <S.TemplateImage
                    resizeMode="contain"
                    style={{ aspectRatio: 4 / 3 }}
                    source={{ uri }}
                    onContextMenu={(e) => e.preventDefault()}
                />
                {!isMobile ? null : (
                    <>
                        <S.ContainerDownload width={width} onPress={onPressDownload}>
                            <S.IconClose width={width} name="arrow-down-circle" style={{ color: 'white' }} />
                        </S.ContainerDownload>
                        {!index ? null : (
                            <S.ContainerArrow width={width} onPress={backPosition} style={{ right: 0, left: 3 }}>
                                <S.IconArrow width={width} name="arrow-left" style={{ color: 'white' }} />
                            </S.ContainerArrow>
                        )}
                        {index + 1 === data.length ? null : (
                            <S.ContainerArrow width={width} onPress={nextPosition}>
                                <S.IconArrow width={width} name="arrow-right" style={{ color: 'white' }} />
                            </S.ContainerArrow>
                        )}
                    </>
                )}
            </S.ImageContainer>
            <S.ModalContent
                ref={imageModalRef}
                modalRef={imageModalRef}
                resizeMode="contain"
                imageBackgroundColor="transparent"
                source={{ uri }}
            ></S.ModalContent>
        </>
    );
}
