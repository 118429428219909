import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';

import { LOGIN, FILTERS, CLIENT_DRAWER } from './constants';

const initialState = {
    user: null,
    filtersGlobal: {},
    actualClientDrawer: null,
};

const handlers = {
    [LOGIN]: (state = initialState, action: AnyAction) => ({
        ...state,
        user: action.payload,
    }),
    [FILTERS]: (state = initialState, action: AnyAction) => ({
        ...state,
        filtersGlobal: action.payload,
    }),
    [CLIENT_DRAWER]: (state = initialState, action: AnyAction) => ({
        ...state,
        actualClientDrawer: action.payload,
    }),
};

export default handleActions(handlers, initialState);
