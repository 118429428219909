// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Ellipse, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} {...props}>
    <G
      data-name="clock (2)"
      transform="translate(1.429 1.143)"
      opacity={0.88}
      fill="none"
      stroke="#6746c8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Ellipse
        data-name="Elipse 15"
        cx={7}
        cy={7.5}
        rx={7}
        ry={7.5}
        transform="translate(-.429 -.143)"
      />
      <Path data-name="Caminho 8" d="M7.079 3.132v4.141l2.761 1.38" />
    </G>
  </Svg>
)

export default SvgComponent
