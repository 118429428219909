import styled from 'styled-components/native';

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    width: 360px;
    height: 360px;
    border-radius: 6px;
    align-items: center;
    padding: 50px 0px 60px 0px;
`;

export const Title = styled.Text`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 16px;
    margin-top: 12px;
`;

export const Description = styled.Text`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    margin-top: 16px;
    max-width: 263px;
    text-align: center;
`;

export const Button = styled.TouchableOpacity`
    background-color: ${({ theme }) => theme.colors.purple[500]};
    height: 40px;
    width: 242px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
`;

export const ButtonText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
`;
