import React from 'react';
import { useWindowDimensions } from 'react-native';

import * as S from './styles';
import SignIcon from '../../../../assets/images/svg/signIcon';

interface TableInfoProps {
    title: string | number;
    width: string;
    typeInfo: 'header' | 'info' | 'info-user';
    notBar?: boolean;
    photo?: string;
    isMain?: boolean;
    icon?: boolean;
}

export function TableInfo(props: TableInfoProps) {
    const { notBar, title, typeInfo, width, photo, icon, isMain } = props;
    const window = useWindowDimensions().width;

    function RenderBar() {
        if (typeInfo === 'info' || notBar) {
            return null;
        }

        return (
            <S.Bar />
        );
    }

    function renderLetterOrPhoto() {
        if (typeInfo !== 'info-user') {
            return null;
        }

        function renderLabelOrImage() {
            const arrayName = String(title).split(' ');
            const firstLetter = arrayName[0].substr(0, 1).toUpperCase();
            const secondLetter =
                arrayName[1]?.substr(0, 1)?.toUpperCase() || '';
            const label = firstLetter + secondLetter;
            const uri = { uri: photo };

            if (!photo) {
                return (
                    <S.FirstLetterText>{label}</S.FirstLetterText>
                );
            }

            return (
                <S.Photo onContextMenu={(e) => e.preventDefault()} source={uri} />
            );
        }

        return (
            <S.FirstLetterContainer>
                {renderLabelOrImage()}
            </S.FirstLetterContainer>
        );
    }

    return (
        <S.TableInfoArea width={width}>
            <S.TableInfoContent>
                {renderLetterOrPhoto()}
                <S.AdjustName>
                    <S.TextDefault typeInfo={typeInfo} width={window}>
                        {title}
                    </S.TextDefault>
                    {!icon || !isMain ? null : <SignIcon style={{ minWidth: 15 }} />}
                </S.AdjustName>
            </S.TableInfoContent>
            {RenderBar()}
        </S.TableInfoArea>
    );
}
