import styled, { css } from 'styled-components/native';
import Theme from '../../../../global/styles/theme';
import Feather from '@expo/vector-icons/Feather';
import { ResponsiveProps } from '../../../../global/types';
import ImageModal from 'react-native-image-modal';

interface ImageCardContainerProps {
    isSelected?: boolean;
}

export const Container = styled.View<ResponsiveProps>`
    height: auto;
    border: none;
    width: 100%;
    flex-direction: column-reverse;
    margin-bottom: 30px;
`;

export const FirstContainer = styled.View<ResponsiveProps>`
    width: 100%;
    /* height: ${({ width }) => width < 600 ? '300px' : '100%'}; */
    border: ${({ theme }) => `1px solid ${theme.colors.black}`};
`;

export const ViewTexts = styled.View`
    flex-direction: column;
    /* justify-content: space-between; */
    /* background-color: orange; */
`;

export const ContainerPurpleButton = styled.Pressable`
    max-height: 70px;
    max-width: 70px;
    min-height: 70px;
    min-width: 70px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 5px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    position: relative;
    z-index: 1000;
`;

export const TextFramePurple = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    /* max-width: 50%; */
    color: white;
    font-size: 24px;
    text-align: center;
`;

export const SecondContainer = styled.View<ResponsiveProps>`
    width: 100%;
    height: 370px;
    border: none;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
`;

export const ButtonToMarkContainer = styled.View`
    flex-direction: row;
    margin-left: 20px;
    margin-top: 20px;
`;

export const SearchInput = styled.TextInput.attrs({
    placeholderTextColor: Theme.colors.gray[500],
})`
    /* color: ${({ theme }) => theme.colors.gray[600]}; */
    border: ${({ theme }) => `1px solid ${theme.colors.gray[600]}`};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    height: 40px;
    outline-width: 0px;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
    width: 80%;
`;

export const ButtonToMark = styled.TouchableOpacity`
    margin-right: 20px;
`;

export const ButtonToMarkText = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
`;

export const FirstScroll = styled.ScrollView``;

export const FirstView = styled.View``;

export const SecondScroll = styled.ScrollView`
    margin-top: 20px;
`;

export const ImageCardContainer = styled.TouchableOpacity`
    align-items: center;
    margin-bottom: 10px;
`;

export const ImageCardContainerView = styled.View`
    align-items: center;
    margin-bottom: 10px;
`;

export const ImageCard = styled.Image<ResponsiveProps>`
    /* margin: 20px; */
    /* margin-bottom: 10px; */
    height: auto;
    width: 100%;
`;

export const FrameCard = styled.Image<ImageCardContainerProps>`
    height: 150px;
    width: 150px;
    margin: 10px 20px;
    margin-bottom: 10px;
    ${({ isSelected }) =>
        isSelected &&
        css`
            border: ${({ theme }) => `2px solid ${theme.colors.purple[500]}`};
        `}
`;

export const ButtonContainer = styled.View``;

export const TextFrame = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    max-width: 50%;
`;

export const ContainerIconZoom = styled.TouchableOpacity`
    position: absolute;
    cursor: pointer;
    padding: 6px;
    border-radius: 3px;
    z-index: 10;
    right: 20px;
    top: 0px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
`;

export const ContainerIconInfo = styled.TouchableOpacity`
    position: absolute;
    cursor: pointer;
    padding: 6px;
    border-radius: 3px;
    z-index: 10;
    right: 50px;
    top: 0px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
`;

export const IconZoom = styled(Feather)`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
`;

export const LoadingImage = styled.ActivityIndicator`
    height: 100%;
    width: 100%;
`;

export const ButtonUpdate = styled.TouchableOpacity`
    width: 72px;
    height: 30px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    align-items: center;
    justify-content: center;
`;

export const ButtonUpdateText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
`;

export const ModalContent = styled(ImageModal)``;

export const ContainerArrow = styled.TouchableOpacity<ResponsiveProps>`
    position: absolute;
    right: 0px;
    top: 100px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const IconArrow = styled(Feather)<ResponsiveProps>`
    color: ${({ theme }) => theme.colors.gray[150]};
    font-size: 14px;
`;
