// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={192.936}
        height={182.467}
        {...props}>
        <G
            data-name="Componente 7 \u2013 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={5}>
            <Path
                data-name="star (3)"
                d="M80.655 2.5 104.8 51.425l54.005 7.894-39.073 38.061 9.222 53.771-48.3-25.4-48.3 25.4 9.223-53.771L2.5 59.319l54.005-7.894Z"
                fill="#f2f3f5"
                stroke="#f2f3f5"
            />
            <Path
                d="M181.318 85.944a31.115 31.115 0 0 0-44.013 0l-6 6-6-6a31.122 31.122 0 0 0-44.007 44.013l6 6 44.01 44.01 44.013-44.013 6-6a31.115 31.115 0 0 0 0-44.013Z"
                fill="none"
                stroke="#6746c8"
            />
        </G>
    </Svg>
);

export default SvgComponent;
