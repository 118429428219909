import styled from 'styled-components/native';

import { ResponsiveProps } from '../../../../../global/types';

interface TextProps {
    fontFamily: 'semi' | 'regular',
    fontSize: number,
}

interface InputEventAreaProps {
    isError?: boolean,
}

export const Label = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const InputContainer = styled.View<ResponsiveProps>`
    width: ${({ width }) => width};
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 16px;
`;

export const InputEventArea = styled.View<InputEventAreaProps>`
    flex-direction: row;
    padding: 10px;
    margin-top: 6px;
    height: 40px;
    border: 1px solid ${(props) => props.isError ? props.theme.colors.red[500] : props.theme.colors.gray[300]};
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    align-items: center;
    width: 100%;
`;

export const InputEvent = styled.TextInput`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    outline-width: 0;
    width: 90%;
`;

export const EventListArea = styled.TouchableOpacity`
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 0px;
    padding-left: 10px;
    width: 100%;
`;

export const TextDefault = styled.Text<TextProps>`
    color: ${({ fontFamily, theme }) => fontFamily === 'regular' ? theme.colors.purple[500] : theme.colors.gray[650]};
    font-family: ${({ fontFamily, theme }) =>
        fontFamily === 'regular' ? theme.font.segoeUi.regular : theme.font.segoeUi.semiBold};
    font-size: ${({ fontSize }) => fontSize};
`;

export const EventListTitleArea = styled.View`
    width: 90%;
`;

export const OptionArea = styled.TouchableOpacity`
    position: absolute;
    right: 12px;
`;

export const AddEventArea = styled.View`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.purple[200]};
    border-radius: 20px;
    flex-direction: row;
    height: 28px;
    margin-top: 12px;
    margin-left: 4px;
    margin-right: 4px;
    padding: 6px 12px;
`;

export const EventListContainer = styled.ScrollView`
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    max-height: 180px;
    width: 100%;
`;

export const AddEventContainer = styled.View`
    flex-direction: row;
`;

export const CloseButton = styled.TouchableOpacity`
    align-items: center;
    height: 15px;
    justify-content: center;
    width: 15px;
`;

export const CloseImage = styled.Image`
    height: 10px;
    margin-left: 12px;
    tint-color: ${({ theme }) => theme.colors.purple[500]};
    width: 10px;
`;
