import styled from 'styled-components/native';
import Modal from 'react-native-modal';

interface TextDefaultProps {
    fontSize: number,
    marginTop?: number,
}

interface ButtonPropsCodition {
    optionSelected: boolean,
}

export const ModalArea = styled(Modal)`
    margin: 0px;
`;

export const ContainerModal = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    height: 300px;
    padding: 24px 12px;
    width: 300px;
`;

export const TextDefault = styled.Text<TextDefaultProps>`
    color: ${({ theme }) => theme.colors.lowBlack};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: ${({ fontSize }) => fontSize};
    margin-top: ${({ marginTop }) => marginTop || 0};
`;

export const DropdownArea = styled.TouchableOpacity`
    background-color: ${({ theme }) => theme.colors.gray[10]};
    border-radius: 4px;
    height: 40px;
    margin-top: 12px;
    padding-left: 12px;
    justify-content: center;
    width: 100%;
`;

export const DropdownListArea = styled.ScrollView`
    background-color: ${({ theme }) => theme.colors.gray[10]};
    max-height: 100px;
    padding: 0px 8px;
    width: 100%;
`;

export const DropdownList = styled.TouchableOpacity`
    padding-bottom: 12px;
    padding-top: 12px;
`;

export const ContinueButton = styled.TouchableOpacity<ButtonPropsCodition>`
    align-self: center;
    align-items: center;
    background-color: ${({ theme, optionSelected }) => optionSelected ? theme.colors.red[600] : theme.colors.gray[600]};
    border-radius: 6px;
    height: 45px;
    justify-content: center;
    width: 80px;
`;

export const ContinueButtonText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 13px;
`;

export const ContainerInfo = styled.View`
    flex: 1;
`;
