// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.827}
    height={16.827}
    {...props}
  >
    <G
      data-name="eye-off (1)"
      fill="none"
      stroke="#c5c5c5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Path
        data-name="Caminho 2"
        d="M12.977 12.14a6.922 6.922 0 0 1-3.953 1.3c-4.657.005-7.319-5.061-7.319-5.061a11.859 11.859 0 0 1 3.367-3.766m2.555-1.148a6.364 6.364 0 0 1 1.398-.152c4.658 0 7.32 5.066 7.32 5.066a11.7 11.7 0 0 1-1.437 2.02m-4.472-.678a2.063 2.063 0 0 1-1.954.546 1.95 1.95 0 0 1-1.44-1.371 1.841 1.841 0 0 1 .573-1.86"
      />
      <Path data-name="Linha 5" d="m1.414 1.413 15 14" />
    </G>
  </Svg>
)

export default SvgComponent
