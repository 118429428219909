import React, { useState, useEffect, useRef } from 'react';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import CloseIcon from '../../assets/images/close.png';
import Theme from '../../global/styles/theme';
import { converterDate, getDownloadRemoteFile } from '../../global/utils/functions';
import FavoriteUncheckedIcon from '../../assets/images/svg/favoriteUncheckedIcon';
import FavoriteCheckedIcon from '../../assets/images/svg/favoriteCheckedIcon';
import { Modal } from '../../components/Modal';
import { Loading } from '../../components/Loading';
import { userPaymentArt, userFavoriteArt } from '../../services/user';
import { computedArtsDownload, getArtsRecommended, getUsedArtCodition, getUsedPublicationCodition, resendArt } from '../../services/arts';
import * as S from './styles';

import { UseTemplateModal } from '../UseTemplateModal';
import { CoditionModal } from '../../components/CoditionModal';
import { InfoModal } from './components/InfoModal';
import { PaymentModal } from './components/PaymentModal';
import { ImageDetail } from 'react-native-image-modal';
import theme from '../../global/styles/theme';
import { TypeSocialModal } from '../../components/TypeSocialModal';
import { LoadingModal } from '../../components/LoadingModal';
import { ModalInfoProps } from '../../global/types';

interface ModalInfoUserProps {
    id: number;
    id_plan: number;
    title: string;
    store_name: string;
    colors: Array<string>;
    use: string;
    type: string;
    typeUsed: string;
    thumb: string;
    is_bought: boolean;
    is_favorite: boolean;
    author_name: string;
    author_photo: string;
    created_at: string;
    is_free: boolean;
    is_available: boolean;
    price: number;
    idArtUsed: number;
    description: string;
    preview_thumb: string;
    previewThumbPub?: string;
    titleArt?: string;
    logoSelectedPub?: string;
    published_at?: string;
    descriptionPub?: string;
    dateSendPub?: string;
    url?: string;
}

interface TempleteModalProps {
    modalVisible: boolean;
    setModalVisible: (modalVisible: boolean) => void;
    modalInfo: ModalInfoUserProps;
    updateList: () => {};
    typeScreen: 'Modelos' | 'Publicações' | 'details' | 'simple',
    setSocialMediaVisibleHandle?: (txt: string) => {};
    setSocialMediaVisible?: (txt: string) => {};
    currentPage?: string;
    currentTopBar?: number;
    isAdm?: boolean;
}

interface ContactModalProps  {
    description: string,
}

export function TempleteModal(props: TempleteModalProps) {
    const {
        modalInfo: {
            id,
            is_favorite,
            is_bought,
            is_free,
            thumb,
            title,
            author_photo,
            author_name,
            created_at,
            use,
            is_available,
            price,
            previewThumbPub,
            preview_thumb,
            titleArt,
            logoSelectedPub,
            published_at,
            descriptionPub,
            dateSendPub,
            type,
            typeUsed,
            idArtUsed,
            description
        },
        modalVisible,
        setModalVisible,
        updateList,
        typeScreen,
        setSocialMediaVisibleHandle,
        setSocialMediaVisible,
        currentPage,
        currentTopBar,
        isAdm,
    } = props;
    const { user } = useSelector((state: any) => state.user.user);
    const width = useWindowDimensions().width;
    const navigation = useNavigation();

    const [favorited, setFavorited] = useState(is_favorite);
    const [payment, setPayment] = useState(is_bought);
    const [loading, setLoading] = useState(false);
    const [artsRecommended, setArtsRecommended] = useState<ModalInfoUserProps[]>(
        [],
    );
    const [direct, setDirect] = useState(false);
    const [useModalVisible, setUseModalVisible] = useState(false);
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [contactModalInfo, setContactModalInfo] = useState<ContactModalProps>({} as ContactModalProps);
    const [paymentModalVisible, setPaymentModalVisible] = useState(false);
    const [socialMediaVisibleTemplate, setSocialMediaVisibleTemplate] = useState(false);
    const [typeSocial, setTypeSocial] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);

    const fontBlack = Theme.colors.lowBlack;
    const fontPurple = Theme.colors.purple[500];
    const fontWhite = Theme.colors.white;
    const fontGray = Theme.colors.gray[150];
    const fontGray800 = Theme.colors.gray[800];
    const buttonWidth = is_bought ? '100%' : '28%';
    const buttonBoughtTitle = is_bought ? 'Usar esse template' : 'Usar';
    const uri = { uri: previewThumbPub || thumb };
    const createdDate = published_at
        ? converterDate(new Date(published_at), 'date')
        : created_at && converterDate(new Date(created_at), 'date');
    const thereIsTemplate = artsRecommended.length === 0;

    function getDate() {
        if (published_at) {
            return `Criado em ${converterDate(new Date(published_at), 'date')}`;
        }
        if (created_at) {
            return `Criado em ${converterDate(new Date(created_at), 'date')}`;
        }

        return (
            dateSendPub &&
            `Criado em ${converterDate(new Date(dateSendPub), 'date')}`
        );
    }

    function handleBackdropPress() {
        // updateList();
        setModalVisible(false);
        if (setSocialMediaVisible) {
            setSocialMediaVisible(uuidv4());
        }
    }

    async function getArts() {
        setLoading(true);
        const response = await getArtsRecommended(id);

        if (response.status === 200) {
            setArtsRecommended(response.result?.list);
        }
        setLoading(false);
    }

    async function handleFavoritePress() {
        const response = await userFavoriteArt(user.id, id, !favorited);
        if (response.status === 200) {
            setFavorited(!favorited);
        }
    }
    async function confirmSocialPress(typeSelect?: string) {
        setSocialMediaVisibleTemplate(false);
        setLoadingModal(true);

        const response = await resendArt(idArtUsed, user.id, typeSelect === 'banner-site' ? 'banner-site' : typeSocial);
        const { status, message, error, result } = response;

        if (status === 200 || status === 201) {
            setLoadingModal(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'success',
                titleModal: 'Sucesso',
                descriptionModal: message,
                buttonText: 'Continuar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: updateList,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        } else if (error === 'LIMIT') {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setLoadingModal(false);

            setTimeout(() => {
                setContactModalVisible(true)
                setContactModalInfo(contactModalInfo);
            }, 500);
        } else {
            setLoadingModal(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        }
    }

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    async function handlePaymentArt() {
        changePaymentModalVisible();
        const response = await userPaymentArt(user.id, id);
        if (response.status === 200) {
            const modalProps: ModalInfoProps = {
                typeModal: 'success',
                titleModal: 'Sucesso',
                descriptionModal: response?.message || 'Arte comprada com sucesso.',
                buttonText: 'Entendi',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: handleBackdropPress,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
                setPayment(!favorited);
            }, 500);
        } else if (response.error === 'LIMIT') {
            const contactModalInfo: ContactModalProps = {
                description: response.message,
            }
            setContactModalVisible(true);
            setContactModalInfo(contactModalInfo);
        } else {
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: response?.message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        }
    }

    function RenderSimiliarTemplate() {
        if (loading && modalVisible) {
            return (
                <Loading />
            );
        }
        return artsRecommended.map((el) => {
            return (
                <S.SimiliarTemplatePhoto source={el.thumb} key={el.id} onContextMenu={(e) => e.preventDefault()} />
            );
        });
    }

    function renderTemplateArea() {
        if (thereIsTemplate) {
            return null;
        }

        return (
            <S.SimiliarTemplateArea width={width}>
                <S.TextDefault
                    fontSize={25}
                    fontFamily="bold"
                    color={fontBlack}>
                    Templates semelhantes
                </S.TextDefault>
                <S.SimiliarTemplatePhotoArea width={width}>
                    {RenderSimiliarTemplate()}
                </S.SimiliarTemplatePhotoArea>
            </S.SimiliarTemplateArea>
        );
    }

    function RenderButton(
        preferWidth: string,
        nameButton: string,
        colorText: string,
        type: 'payment' | 'use',
        functionButton: () => void,
    ) {
        // const coditionWidth = width >= 950 ? preferWidth : '90%';
        const coditionWidth = preferWidth;

        return (
            <S.ButtonArea
                onPress={functionButton}
                width={coditionWidth}
                type={type}>
                <S.TextDefault
                    fontSize={18}
                    fontFamily="regular"
                    color={colorText}>
                    {nameButton}
                </S.TextDefault>
            </S.ButtonArea>
        );
    }

    function changePaymentModalVisible() {
        setPaymentModalVisible(!paymentModalVisible);
    }

    function RenderPaymentButton() {
        if (is_bought || !is_available) {
            return null;
        }
        const coditionWidth = width >= 950 ? '80%' : '90%';

        return (
            <S.PaymentButton
                onPress={changePaymentModalVisible}
                width={coditionWidth}
            >
                <S.TextDefault
                    fontSize={18}
                    fontFamily="regular"
                    color={fontWhite}
                >
                    Comprar e usar com EXCLUSIVIDADE
                </S.TextDefault>
            </S.PaymentButton>
        );
    }

    function renderFavoriteButton() {
        if (typeScreen === "details" || typeScreen === 'simple') {
            return null;
        }

        function renderIconFavorite() {
            if (favorited) {
                return (
                    <FavoriteCheckedIcon />
                );
            }
            return (
                <FavoriteUncheckedIcon />
            );
        }

        return (
            <S.FavoriteButton onPress={handleFavoritePress}>
                {renderIconFavorite()}
            </S.FavoriteButton>
        );
    }

    function renderDescription() {
        if (!descriptionPub && !description) {
            return null;
        }
        const StylesScroll =  typeScreen === 'simple' ? {
            backgroundColor: theme.colors.white,
            borderRadius: 10,
            padding: 5,
            maxHeight: 400,
            minHeight: 40,
            marginBottom: 80,
        } : {};
        return (
            <S.ScrollView style={StylesScroll}>
                <S.Description
                    fontSize={14}
                    fontFamily="regular"
                    color={fontGray}
                    marginTop={6}>
                    {descriptionPub || description}
                </S.Description>
            </S.ScrollView>
        );
    }

    function renderIsBought() {
        if (!is_bought) {
            return null;
        }

        return (
            <S.IsBoughtContainer>
                <S.IsBoughtIcon name="dollar-sign" />
                <S.TextDefault
                    fontSize={12}
                    fontFamily="regular"
                    color={fontWhite}>
                    PAGO
                </S.TextDefault>
            </S.IsBoughtContainer>
        );
    }

    async function openUserTemplateModal() {
        const { status, message, result } = await getUsedArtCodition(user.id);

        if (status === 200) {
            setDirect(false);
            setUseModalVisible(true);
        } else {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setContactModalVisible(true);
            setContactModalInfo(contactModalInfo);
        }
    }

    async function openUserTemplateModalDirect() {
        const { status, message, result } = await getUsedArtCodition(user.id);

        if (status === 200) {
            setDirect(true);
            setUseModalVisible(true);
        } else {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setContactModalVisible(true);
            setContactModalInfo(contactModalInfo);
        }
    }

    function RenderUseButton() {
        // if (!is_bought) {
        //     return null;
        // }

        return RenderButton(
            buttonWidth,
            buttonBoughtTitle,
            fontPurple,
            'use',
            openUserTemplateModal,
        );
    }

    function RenderOptionsButton() {
        if (typeScreen === 'details' && !is_bought && !is_favorite) {
            return (
                <S.PaymentButton
                    onPress={() => {
                        if (setSocialMediaVisibleHandle) {
                            setSocialMediaVisibleHandle(uuidv4())
                        }
                    }}
                    width="auto"
                    style={{ position: 'absolute', right: 0, top: -35, minWidth: 100 }}
                >
                    <S.TextDefault
                        fontSize={18}
                        fontFamily="regular"
                        color={fontWhite}
                        style={{ padding: 5 }}
                    >
                        Publicar
                    </S.TextDefault>
                </S.PaymentButton>
            )
        }
        if (typeScreen !== 'Publicações' && !is_bought) {
            return (
                <>
                    {RenderPaymentButton()}
                    {RenderUseButton()}
                </>
            )
        }
        if (typeScreen === 'details' && (is_bought || is_favorite)) {
            return RenderUseButton();
        }

        return (
            <S.ButtonArea
                onPress={openUserTemplateModalDirect}
                width="100%"
                type="use"
            >
                <S.TextDefault
                    fontSize={18}
                    fontFamily="regular"
                    color={fontPurple}
                >
                    Criar arte própria
                </S.TextDefault>
            </S.ButtonArea>
        );
    }

    const imageModalRef = useRef<ImageDetail>(null);

    const [photoZoom, setPhotoZoom] = useState('');

    function handleOpenModal(uriProp) {
        setPhotoZoom(uriProp);
        setTimeout(() => {
            imageModalRef.current?.setState({
                isOpen: true,
                origin: {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                },
            });
        }, 300);
    }

    async function downloadPreviewThumbPubDownload() {
        if (props?.modalInfo?.url && JSON.parse(props?.modalInfo?.url)?.length > 0) {
            if (isAdm) {
                const urlDownload = JSON.parse(props?.modalInfo?.url);
                getDownloadRemoteFile(urlDownload);
            } else {
                const urlDownload = JSON.parse(props?.modalInfo?.url);
                const { status, message, result } = await getUsedPublicationCodition(user?.id, 'true');
                if (status === 200) {
                    const resultComputed = await computedArtsDownload(user?.id, urlDownload);
                    if (resultComputed.status === 201) {
                        getDownloadRemoteFile(urlDownload);
                    } else {
                        const contactModalInfo: ContactModalProps = {
                            description: resultComputed.message,
                        }
                        setContactModalVisible(true);
                        setContactModalInfo(contactModalInfo);
                    }
                } else {
                    const contactModalInfo: ContactModalProps = {
                        description: message,
                    }
                    setContactModalVisible(true);
                    setContactModalInfo(contactModalInfo);
                }
            }
        }
    }

    const allPhotos = (currentTopBar === 4 || isAdm) && props?.modalInfo?.url ? JSON.parse(props?.modalInfo?.url) : (previewThumbPub || preview_thumb || '')?.split('|') || [];

    function renderContent() {
        const img = new Image();
        img.src = uri.uri;
        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;
        const isBirthDay = type === "birthday";
        if (typeScreen === 'simple') {
            return (
                <>
                    <S.ContainerInfoArea style={{ backgroundColor: '#cce2e4', borderRadius: 5, paddingBottom: 40, paddingTop: 5, paddingRight: 5, paddingLeft: 5, marginTop: 60, maxWidth: '80%' }} width={width} withTemplate={thereIsTemplate}>
                        <S.Main width={width}>
                            <S.Content width={width}>
                                {allPhotos.map((v: string) => (
                                    <S.ImageContainer style={{ marginTop: 10, marginBottom: 10 }} onPress={() => handleOpenModal(v)}>
                                        <S.TemplatePhoto
                                            resizeMode='contain'
                                            withTemplate={thereIsTemplate}
                                            source={{ uri: v }}
                                            width={150}
                                            height={150}
                                            style={{ marginTop: 0, marginRight: 0, backgroundColor: theme.colors.gray[700] }}
                                            onContextMenu={(e) => e.preventDefault()}
                                        />
                                    </S.ImageContainer>
                                ))}
                            </S.Content>
                            {/* <S.InfoArea style={{ backgroundColor: theme.colors.white, marginLeft: 0, borderRadius: 10, padding: 5 }}>
                                {renderDescription()}
                            </S.InfoArea> */}
                            {renderDescription()}
                        </S.Main>
                        {isAdm || (currentPage !== 'logs' && currentTopBar === 4) ? (
                            <S.ContainerButton width={width} style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                <S.PaymentButton
                                    onPress={() => {
                                        downloadPreviewThumbPubDownload();
                                    }}
                                    width="auto"
                                >
                                    <S.TextDefault
                                        fontSize={18}
                                        fontFamily="regular"
                                        color={fontWhite}
                                        style={{ padding: 5 }}
                                    >
                                        Baixar
                                    </S.TextDefault>
                                </S.PaymentButton>
                            </S.ContainerButton>
                        ) : null}
                        {currentPage !== 'logs' && currentTopBar && ![2, 3, 4].includes(currentTopBar) ? (
                            <S.ContainerButton width={width} style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                <S.PaymentButton
                                    onPress={() => {
                                        if (setSocialMediaVisibleHandle) {
                                            setSocialMediaVisibleHandle(uuidv4())
                                        }
                                    }}
                                    width="auto"
                                >
                                    <S.TextDefault
                                        fontSize={18}
                                        fontFamily="regular"
                                        color={fontWhite}
                                        style={{ padding: 5 }}
                                    >
                                        Publicar
                                    </S.TextDefault>
                                </S.PaymentButton>
                            </S.ContainerButton>
                        ) : null}
                        {currentPage !== 'logs' && currentTopBar && ![1, 2, 4].includes(currentTopBar) ? (
                            <S.ContainerButton width={width} style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                <S.PaymentButton
                                    onPress={() => {
                                        if(typeUsed === 'banner-site') {
                                            confirmSocialPress('banner-site');
                                        } else {
                                            setSocialMediaVisibleTemplate(true);
                                        }
                                    }}
                                    width="auto"
                                >
                                    {loadingModal ? (
                                        <LoadingModal
                                            visible={loadingModal}
                                            titleLoading='Aguarde...'
                                        />
                                    ) : (
                                        <S.TextDefault
                                            fontSize={18}
                                            fontFamily="regular"
                                            color={fontWhite}
                                            style={{ padding: 5 }}
                                        >
                                            Republicar
                                        </S.TextDefault>
                                    )}
                                </S.PaymentButton>
                            </S.ContainerButton>
                        ) : null}
                    </S.ContainerInfoArea>
                    <S.CloseButton onPress={handleBackdropPress}>
                        <S.CloseIcon resizeMode="contain" source={CloseIcon} onContextMenu={(e) => e.preventDefault()} />
                    </S.CloseButton>
                </>
            );
        }
        return (
            <>
                <S.ContainerInfoArea width={width} withTemplate={thereIsTemplate}>
                    <S.InfoAreaAlign width={width}>
                        <S.ImageContainer onPress={() => handleOpenModal(uri.uri)}>
                            <S.TemplatePhoto
                                resizeMode='contain'
                                withTemplate={thereIsTemplate}
                                source={uri}
                                width={isBirthDay ? (width >= 800 ? 337.5 : 168.75) : (width > 1000 ? 360 : (width < 600 ? 250 : 300))}
                                height={isBirthDay ? (width >= 800 ? 600 : 300) : (width > 1000 ? 360 : (width < 600 ? 250 : 300))}
                                onContextMenu={(e) => e.preventDefault()}
                            />
                        </S.ImageContainer>
                        <S.InfoArea>
                            <S.TextDefault
                                fontSize={25}
                                fontFamily="bold"
                                color={fontBlack}>
                                {titleArt || title}
                            </S.TextDefault>
                            {renderIsBought()}
                            {width < 1000 ? null : (
                                <S.AuthorArea>
                                    <S.AuthorPhoto source={logoSelectedPub || author_photo} onContextMenu={(e) => e.preventDefault()} />
                                    <S.InfosAuthor>
                                        <S.TextContainer>
                                            <S.TextDefault
                                                fontSize={18}
                                                fontFamily="regular"
                                                color={fontGray}>
                                                {`${typeScreen === 'details' ? 'Criado' : 'Disponibilizado'} por `}
                                            </S.TextDefault>
                                            <S.TextDefault
                                                fontSize={18}
                                                fontFamily="regular"
                                                color={fontGray800}>
                                                {author_name || user.name}
                                            </S.TextDefault>
                                        </S.TextContainer>
                                        <S.TextDefault
                                            fontSize={14}
                                            fontFamily="regular"
                                            color={fontGray}
                                            marginTop={6}>
                                            {getDate()}
                                        </S.TextDefault>
                                    </S.InfosAuthor>
                                </S.AuthorArea>
                            )}
                            <S.ContainerButton width={width}>
                                {RenderOptionsButton()}
                                {renderFavoriteButton()}
                            </S.ContainerButton>
                            {renderDescription()}
                        </S.InfoArea>
                    </S.InfoAreaAlign>
                </S.ContainerInfoArea>
                {/* {renderTemplateArea()} */}
                <S.CloseButton onPress={handleBackdropPress}>
                    <S.CloseIcon resizeMode="contain" source={CloseIcon} onContextMenu={(e) => e.preventDefault()} />
                </S.CloseButton>
                <UseTemplateModal
                    modalVisible={useModalVisible}
                    setModalVisible={setUseModalVisible}
                    modalInfo={props.modalInfo}
                    updateList={updateList}
                    direct={direct}
                    setTemplateModalVisible={(v) => {
                        setModalVisible(v);
                        if (!v && setSocialMediaVisible) {
                            setSocialMediaVisible(uuidv4());
                        }
                    }}
                />
                <Modal isVisible={paymentModalVisible} closeModal={setPaymentModalVisible}>
                    <PaymentModal modalInfo={props.modalInfo} setModalVisible={setPaymentModalVisible} confirmPayment={handlePaymentArt} />
                </Modal>
            </>
        );
    }

    function renderModalAreaViewOrScrollView() {
        if (thereIsTemplate && typeScreen !== 'simple') {
            return (
                <S.ModalArea>{renderContent()}</S.ModalArea>
            );
        }
        return (
            <S.ModalAreaScrollView>{renderContent()}</S.ModalAreaScrollView>
        );
    }

    useEffect(() => {
        setFavorited(is_favorite);
        setPayment(is_bought);
        // if (modalVisible) {
        //     getArts();
        // }
    }, [is_favorite, modalVisible]);

    useEffect(() => {
      setSocialMediaVisibleTemplate(false);
    }, [modalVisible]);

    return (
        <S.ContainerModal isVisible={modalVisible} onBackdropPress={handleBackdropPress} onContextMenu={(e) => e.preventDefault()}>
            {renderModalAreaViewOrScrollView()}
            <S.ModalContent
                ref={imageModalRef}
                modalRef={imageModalRef}
                resizeMode="contain"
                imageBackgroundColor="transparent"
                source={{ uri: photoZoom }}></S.ModalContent>
            <Modal isVisible={socialMediaVisibleTemplate} closeModal={setSocialMediaVisibleTemplate}>
                <TypeSocialModal onPressConfirm={confirmSocialPress} setTypeSocial={setTypeSocial} typeSocial={typeSocial} />
            </Modal>
            <Modal isVisible={contactModalVisible} closeModal={setContactModalVisible}>
                <InfoModal userInfo={user} description={contactModalInfo.description} setModalVisible={setContactModalVisible} />
            </Modal>
            <CoditionModal
                titleModal={modalInfo.titleModal}
                descriptionModal={modalInfo.descriptionModal}
                typeModal={modalInfo.typeModal}
                buttonText={modalInfo.buttonText}
                modalVisible={modalInfo.modalVisible}
                setModalVisible={modalInfo.setModalVisible}
                buttonFunction={modalInfo.buttonFunction}
            />
        </S.ContainerModal>
    );
}
