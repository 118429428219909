import React, { createElement, useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useIsFocused } from '@react-navigation/native';

import * as S from './styles';

import { ReportTable } from '../../../components/ReportTable';
import { transfersList } from '../../../services/transfers';

import { Loading } from '../../../components/Loading';

interface DateProps {
    initialDay: Date,
    lastDay: Date,
}

export function ReportScreen() {
    const now = new Date();
    const width = useWindowDimensions().width;
    const isFocused = useIsFocused();

    const [date, setDate] = useState<DateProps>({
        initialDay: new Date(now.getFullYear(), now.getMonth(), 1),
        lastDay: new Date(now.getFullYear(), now.getMonth() + 1, 0),
    } as DateProps);
    const [paymentList, setPaymentList] = useState(null);
    const [openedList ,setOpenedList] = useState(null);
    const [loading, setLoading] = useState(isFocused);

    useEffect(() => {
        setLoading(isFocused);
        getAllTransfersList();
    }, [isFocused]);

    async function getAllTransfersList() {
        const formatedInitialDay = date.initialDay.toISOString().split("T")[0];
        const formatedLastDay = date.lastDay.toISOString().split("T")[0];

        const { result } = await transfersList(formatedInitialDay, formatedLastDay, 'pending');
        setOpenedList(result);
        getPaymentList();
    }

    async function getPaymentList() {
        const formatedInitialDay = date.initialDay.toISOString().split("T")[0];
        const formatedLastDay = date.lastDay.toISOString().split("T")[0];

        const { result } = await transfersList(formatedInitialDay, formatedLastDay, 'done');
        setPaymentList(result);
        setLoading(false);
    }

    function handleSearchPress() {
        getAllTransfersList();
    }

    function DateTimePicker(currentDay: Date, type: 'initial' | 'last') {
        const saveDate = (event: any) => {
            if (event.target.value && new Date(event.target.value)) {
                const preferUseDay = new Date(event.target.value);

                if (type === 'initial') {
                    const dateOption = {
                        ...date,
                        initialDay: preferUseDay,
                    };
                    setDate(dateOption);
                } else {
                    const dateOption = {
                        ...date,
                        lastDay: preferUseDay,
                    };
                    setDate(dateOption);
                }
            }
        }

        return createElement('input', {
            type: 'date',
            value: currentDay.toISOString().split("T")[0],
            // min: new Date(Date.now()).toISOString().split("T")[0],
            onChange: saveDate,
            required: true,
            style: S.InputStyle,
        });
    }

    function RenderContentInfo() {
        if (loading) {
            return (
                <Loading />
            );
        }

        return (
            <S.ScrollView>
                <S.ContainerAlign>
                    <S.TitleTable marginBottom={20}>
                        Selecione o período
                    </S.TitleTable>
                    <S.DateArea width={width}>
                        <S.InputDateArea width={width}>
                            {DateTimePicker(date.initialDay, 'initial')}
                        </S.InputDateArea>
                        <S.InputDateArea width={width}>
                            {DateTimePicker(date.lastDay, 'last')}
                        </S.InputDateArea>
                        <S.SearchButton
                            width={width}
                            backgroundCodition={date.lastDay < date.initialDay}
                            disabled={date.lastDay < date.initialDay}
                            onPress={handleSearchPress}
                        >
                            <S.SearchText>Buscar</S.SearchText>
                        </S.SearchButton>
                    </S.DateArea>
                    <S.TitleTable marginTop={50} marginBottom={20}>
                        Valores em aberto
                    </S.TitleTable>
                    <ReportTable
                        type='opened'
                        tableList={openedList}
                        refreshList={getAllTransfersList}
                    />
                    <S.TitleTable marginTop={50} marginBottom={20}>
                        Pagamentos efetuados
                    </S.TitleTable>
                    <ReportTable
                        type='payment'
                        tableList={paymentList}
                        refreshList={getAllTransfersList}
                    />
                </S.ContainerAlign>
        </S.ScrollView>
        );
    }

    return (
        <S.Container>
            {RenderContentInfo()}
        </S.Container>
    );
}
