import React from 'react';

import * as S from './styles';
import Theme from '../../../../global/styles/theme';
import InfoIcon from '../../../../assets/images/svg/infoIcon';

interface ModalInfoProps {
    setModalVisible: (visible: boolean) => void,
    confirmPayment: () => void,
}

export function ConfirmPaymentModal(props: ModalInfoProps) {
    const { confirmPayment, setModalVisible } = props;
    const backgroundInfoColor = Theme.colors.red[500];
    const contactColor = Theme.colors.green[400];

    function closeModal() {
        setModalVisible(false);
    }

    return (
        <S.ContainerModal>
            <InfoIcon />
            <S.TitleModal>
                Info
            </S.TitleModal>
            <S.DescriptionText>
                Deseja confirmar o pagamento?
            </S.DescriptionText>
            <S.ButtonArea>
                <S.Button backgroundColor={contactColor} onPress={confirmPayment}>
                    <S.TextButton>
                        Confirmar
                    </S.TextButton>
                </S.Button>
                <S.Button backgroundColor={backgroundInfoColor} onPress={closeModal}>
                    <S.TextButton>
                        Cancelar
                    </S.TextButton>
                </S.Button>
            </S.ButtonArea>
        </S.ContainerModal>
    );
}
