// @ts-nocheck
import React from 'react';
import { TouchableOpacityProps } from 'react-native';
import * as S from './styles';

interface ButtonProps extends TouchableOpacityProps {
    loading?: boolean;
    label: string;
}

export function Button(props: ButtonProps) {
    const { loading, label, ...rest } = props;

    function renderSpinnerOrLabel() {
        if (loading) {
            return (
                <S.Loading />
            );
        }

        return (
            <S.Label>{label}</S.Label>
        );
    }

    return (
        <S.Container {...rest}>{renderSpinnerOrLabel()}</S.Container>
    );
}
