import React from 'react';
import * as S from './styles';

interface PropsButtons {
    buttonConfirmText: 'Salvar' | 'Continuar';
    hiddenCancelButton?: boolean;
    cancelFuntion?: () => void;
    saveFuntion?: () => void;
    loading?: boolean;
    isValid?: boolean;
}

export function Buttons(props: PropsButtons) {
    const { buttonConfirmText, cancelFuntion, saveFuntion, loading, isValid, hiddenCancelButton } = props;

    function renderLoading() {
        if (loading) {
            return (
                <S.Loading />
            );
        }

        return (
            <S.TextButtonConfirm>{buttonConfirmText}</S.TextButtonConfirm>
        );
    }

    return (
        <S.Container>
            {hiddenCancelButton ? null : (
                <S.ButtonCancel onPress={cancelFuntion}>
                    <S.TextButtonCancel>Cancelar</S.TextButtonCancel>
                </S.ButtonCancel>
            )}
            <S.ButtonConfirm onPress={saveFuntion} isValid={isValid} disabled={!isValid}>
                {renderLoading()}
            </S.ButtonConfirm>
        </S.Container>
    );
}
