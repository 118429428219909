// @ts-nocheck
import React, { useState } from 'react';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryStack } from 'victory';

import theme from '../../../../../global/styles/theme';
import * as S from './styles';

interface FrequentBuyersListProps {
    name?: string,
    purchased_arts?: string,
    photo?: string,
}

interface FrequentBuyersChartProps {
    frequentBuyersList?: Array<FrequentBuyersListProps>,
}

export function FrequentBuyersChart(props: FrequentBuyersChartProps) {
    const { frequentBuyersList } = props;
    const graphInfo = (frequentBuyersList || []).map((el) => {
        const newArray = { x: `${el.name}`, y: Number(el.purchased_arts)};
        return newArray;
    });

    const VictoryChartPadding = { left: 20, bottom: 20, right: 20, top: 20 };
    const colorScale = ['#AEB9FF', '#FFDD8D', '#FC78FC', '#7BFFFF', '#6CF567'];
    const styleBar = { data: { fill: (d: any) => colorScale[d.index] }};
    const yAxisStyle = {
        axis: {stroke: "transparent"},
        grid: {stroke: theme.colors.gray[25]},
        tickLabels: { fontFamily: theme.font.segoeUi.regular, fontSize: 10 }
    };
    const xAxisStyle = {
        tickLabels: { fontFamily: theme.font.segoeUi.regular, fontSize: 8 }
    };
    const domaingPadding = { x: 50 };

    const CustomTick = ({ x, y, text, index }) => {
        const getUriImages = frequentBuyersList[index]?.photo;
        const formatText = text.split(' ');
        const urlImage = { uri: getUriImages };

        return (
            <>
                <foreignObject x={x - 12} y={y - 8} width={24} height={24}>
                    <S.ChartImage source={urlImage} onContextMenu={(e) => e.preventDefault()} />
                </foreignObject>
                <svg x={x - 13} y={y + 12} width={150} height={150}>
                    <text
                        fontFamily={theme.font.segoeUi.regular}
                        x="0"
                        y="15"
                        fill="#202125"
                        fontSize={10}
                        width={150}
                        height={150}
                    >
                        {formatText[0]}
                    </text>
                </svg>
            </>
        );
    };

    function RenderGraphCodition() {
        if (!graphInfo.length) {
            return (
                <S.VoidTextArea>
                    <S.TextDefault
                        fontSize={14}
                        fontFamily='semi'
                    >
                        Não há dados!
                    </S.TextDefault>
                </S.VoidTextArea>
            );
        }

        return (
            <S.ChartArea>
                <VictoryChart domainPadding={domaingPadding} height={220}>
                    <VictoryBar
                        data={graphInfo}
                        barWidth={15}
                        padding={VictoryChartPadding}
                        style={styleBar}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={yAxisStyle}
                    />
                    <VictoryAxis
                        tickLabelComponent={<CustomTick />}
                    />
                </VictoryChart>
            </S.ChartArea>
        );
    }

    return (
        <S.SignedChartArea>
            <S.TitleArea>
                <S.TextDefault
                    fontSize={14}
                    fontFamily='semi'
                >
                    Compradores frequêntes
                </S.TextDefault>
            </S.TitleArea>
            {RenderGraphCodition()}
        </S.SignedChartArea>
    );
}
