import React from 'react';
import toast from 'react-hot-toast';

import { deleteClient } from '../../../../services/clients';
import AlertIcon from '../../../../assets/images/svg/alertIcon';
import Theme from '../../../../global/styles/theme';
import * as S from './styles';

interface DeleteModalProps {
    handleCancelPress: () => void;
    handleConfirmPress: () => void;
    typeScreen: string,
}

export function DeleteModal(props: DeleteModalProps) {
    const { handleCancelPress, handleConfirmPress, typeScreen } = props;
    const alertColor = Theme.colors.orange[500];
    const buttonAlertColor = Theme.colors.gray[400];
    const textInfoColor = Theme.colors.lowBlack;
    const confirmColor = Theme.colors.white;
    const textDelete = typeScreen === 'plans' ? 'Plano' : 'Usuário';

    function RenderModalButton(
        type: 'Cancelar' | 'Excluir',
        onPressFunction: () => void,
    ) {
        const buttonTextColor = type === 'Cancelar' ? buttonAlertColor : confirmColor;

        return (
            <S.ModalButton type={type} onPress={onPressFunction}>
                <S.TextDefault
                    font="regular"
                    fontSize={12}
                    color={buttonTextColor}>
                    {type}
                </S.TextDefault>
            </S.ModalButton>
        );
    }

    return (
        <S.ModalArea>
            <AlertIcon />
            <S.TextDefault
                font="bold"
                color={alertColor}
                fontSize={16}
                marginTop={6}>
                Atenção
            </S.TextDefault>
            <S.TextDefault
                font="regular"
                color={textInfoColor}
                fontSize={14}
                marginBottom={22}
                marginTop={22}
            >
                {`Tem certeza que deseja\nexcluir esse ${textDelete}?`}
            </S.TextDefault>
            <S.ModalButtonArea>
                {RenderModalButton('Cancelar', handleCancelPress)}
                {RenderModalButton('Excluir', handleConfirmPress)}
            </S.ModalButtonArea>
        </S.ModalArea>
    );
}
