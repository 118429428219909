// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { TempleteModal } from '../../../components/TemplateModal';
import { Card } from './components/Card';
import ArrowLeft from '../../../assets/images/svg/arrowLeftButton';
import { Loading } from '../../../components/Loading';

import { UseTemplateModal } from '../../../components/UseTemplateModal';
import { TopTabs } from '../../../components/TopTabs';

import { getArtDownload, getArtList } from '../../../services/arts';
import * as S from './styles';
import { InputSearch } from '../../../components/InputSearch';
interface ItemProps {
    id: number;
    id_plan: number;
    title: string;
    store_name: string;
    colors: Array<string>;
    use: string;
    type: string;
    thumb: string;
    is_bought: boolean;
    is_favorite: boolean;
    author_name: string;
    author_photo: string;
    id_art?: number;
    preview_thumb?: string;
    created_at: string;
}

interface SavedArtsListProps {
    title: 'Modelos comprados' | 'Modelos Favoritos' | 'Continuar editando' | 'Minhas artes';
}

const listTopTabs = [
    "Finalizadas",
    "Agendadas",
    "Publicadas",
    "Meus downloads"
]

export function SavedArtsList() {
    const { user } = useSelector((state: any) => state.user.user);
    const filters = useSelector((state: any) => state.user.filtersGlobal);

    const width = useWindowDimensions().width;
    const navigation = useNavigation();
    const { params } = useRoute();
    const isFocused = useIsFocused();
    const { title, currentPageInitial, idPublish } = params as SavedArtsListProps;

    const [data, setData] = useState<ItemProps[]>([]);
    const [modalInfo, setModalInfo] = useState<ItemProps>({} as ItemProps);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalDetails, setModalDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState<'published' | 'scheduled' | 'finish' | 'download'>('finish');
    const [currentTopBar, setCurrentTopBar] = useState(1);
    const [socialMediaVisibleHandle, setSocialMediaVisibleHandle] = useState('');
    const [socialMediaVisibleBool, setSocialMediaVisible] = useState('');
    const [nameEvent, setNameEvent] = useState('');
    const [dropdownVisibleEvent, setDropdownVisibleEvent] = useState(false);
    const [idEvent, setIdEvent] = useState('');

    function handleDropdownPressEvent() {
        setDropdownVisibleEvent(!dropdownVisibleEvent);
    }

    const eventList = [
        {
            id: null,
            name: 'Todos',
        },
        {
            id: false,
            name: 'Publicadas',
        },
        {
            id: true,
            name: 'Publicações com erro',
        },
    ];

    function RenderDropdownIdEvent() {
        if (!dropdownVisibleEvent) {
            return null;
        }

        return (
            <S.DropdownArea width={width} isVisibleModal={true} topNumber={35} widthNumber={235}>
                {(eventList || []).map((v) => {
                    const filterActive = () => {
                        setIdEvent(v.id);
                        setNameEvent(v.name);
                        handleDropdownPressEvent();
                    }
                    return (
                        <S.DropdownOption onPress={filterActive} key={v.id}>
                            <S.DropdownOptionText>
                                {v.name}
                            </S.DropdownOptionText>
                        </S.DropdownOption>
                    );
                })}
            </S.DropdownArea>
        );
    }

    function getType() {
        if (title === 'Modelos comprados') {
            return 'bought';
        }
        if (title === 'Modelos Favoritos') {
            return 'favorite';
        }
        if (title === 'Minhas artes') {
            if (currentTopBar === 1) {
                return 'finish';
            }
            if (currentTopBar === 2) {
                return 'scheduled';
            }
            if (currentTopBar === 3) {
                return 'published';
            }

            return 'download';
        }
        return 'editing';
    }

    useEffect(() => {
        setLoading(true);
        setCurrentPage(currentPageInitial || 'finish');
        setCurrentTopBar(currentPageInitial === 'scheduled' ? 2 : (currentPageInitial === 'published' ? 3 : 1));
        if (idPublish && currentPageInitial === 'published') {
            getPublish();
        }
    }, [isFocused, title, currentPageInitial, idPublish]);

    async function getPublish() {
        const response = await getArtList(1, 'published', user.id, '', '', 0, '', null, null, null, null, idPublish);
        if (response?.result?.list?.length) {
            setModalInfo(response?.result?.list[0]);
            setTimeout(() => {
                setModalDetails(true);
            }, 1000);
        }
    }

    async function getList() {
        setModalDetails(false);
        setLoading(true);
        const pageType = getType();
        const response = pageType === 'download' ? await getArtDownload(1, user.id) : await getArtList(1, pageType, user.id, filters?.searchTxt || '', filters?.artType || '', Number(filters?.idEvent || 0), filters?.idsColors || '', null, null, null, null, null, title === 'Minhas artes' && ['published', 'scheduled'].includes(currentPage) ? idEvent : null);
        const newData = response?.result?.list || [];
        setData(newData);
        setLoading(false);
    }

    function renderCards() {
        return (data || []).map((e) => {
            return (
                <Card
                    key={e.id}
                    item={e}
                    setModalVisible={setModalVisible}
                    setModalInfo={setModalInfo}
                    type={title}
                    getList={getList}
                    currentPage={currentPage}
                    setModalDetails={setModalDetails}
                    socialMediaVisibleHandle={socialMediaVisibleHandle}
                    setSocialMediaVisibleHandle={setSocialMediaVisibleHandle}
                    socialMediaVisibleBool={socialMediaVisibleBool}
                />
            );
        });
    }

    useEffect(() => {
        setCurrentPage(currentTopBar === 1 ? 'finish' : (currentTopBar === 2 ? 'scheduled' : (currentTopBar === 3 ? 'published' : 'download')));
        if (isFocused) {
            const timeOutId = setTimeout(() => {
                getList();
            }, 500);

            return () => clearTimeout(timeOutId);
        }

        return;
    }, [isFocused, currentPage, currentTopBar, title, filters?.searchTxt, filters?.artType, filters?.idEvent, filters?.idsColors, idEvent]);

    useEffect(() => {
        setIdEvent('');
        setNameEvent('');
    }, [currentPage]);

    if (loading) {
        return (
            <Loading />
        );
    }

    function RenderModal() {
        if (title !== 'Continuar editando') {
            return (
                <TempleteModal
                    modalInfo={modalInfo}
                    setModalVisible={setModalVisible}
                    modalVisible={modalVisible}
                    updateList={getList}
                />
            )
        }

        return (
            <UseTemplateModal
                modalInfo={modalInfo}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                updateList={getList}
                typeMethod='edit'
            />
        )
    }

    function RenderEditInfo() {
        if (loading) {
            return (
                <Loading />
            );
        }

        return (
            <S.EditInfoArea width={width}>
                <S.Content width={width}>{renderCards()}</S.Content>
            </S.EditInfoArea>
        );
    }

    function renderPagePublish() {
        return (
            <>
                <TopTabs
                    listTabs={listTopTabs}
                    currentPosition={currentTopBar}
                    setCurrentPosition={setCurrentTopBar}
                    widthResponsive={400}
                />
                {title === 'Minhas artes' && ['published', 'scheduled'].includes(currentPage) ? (
                    <S.RequestFilterButton onPress={handleDropdownPressEvent}>
                        <InputSearch
                            value={nameEvent}
                            setValue={setNameEvent}
                            placeholder="Buscar por status"
                            disabled
                            arrowIcon
                            marginLeft={0}
                            height={40}
                            widthArea={235}
                        />
                        {RenderDropdownIdEvent()}
                    </S.RequestFilterButton>
                ) : null}
                {RenderEditInfo()}
            </>
        );
    }

    return (
        <S.Container width={width}>
            <S.ContainerAlign>
                {['published', 'scheduled', 'finish', 'download'].includes(getType()) ? renderPagePublish() : (
                    <>
                        <S.Header>
                            <S.TextDefault>{title}</S.TextDefault>
                        </S.Header>
                        <S.Content width={width}>{renderCards()}</S.Content>
                    </>
                )}
            </S.ContainerAlign>
            {RenderModal()}
            <TempleteModal
                modalInfo={modalInfo}
                setModalVisible={setModalDetails}
                modalVisible={modalDetails}
                updateList={getList}
                typeScreen={title === 'Minhas artes' && [1, 2, 3, 4].includes(currentTopBar)? 'simple' : 'details'}
                currentTopBar={currentTopBar}
                setSocialMediaVisibleHandle={setSocialMediaVisibleHandle}
                setSocialMediaVisible={setSocialMediaVisible}
            />
        </S.Container>
    );
}
