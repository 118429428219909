import styled from 'styled-components/native';
import Modal from 'react-native-modal';

export const Container = styled(Modal)`
    align-items: center;
    justify-content: center;
    margin: 0px;
`;

export const Content = styled.TouchableWithoutFeedback``;

export const Background = styled.View`
`;
