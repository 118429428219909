// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useWindowDimensions } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';

import { DashboardHeader } from '../../../components/DashboardHeader';
import { Card } from './components/Card';
import * as S from './styles';
import { getArtList } from '../../../services/arts';
import { Loading } from '../../../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { filtersGlobal } from '../../../store/modules/user/actions';

export function ManageArtsScreen() {
    const width = useWindowDimensions().width;
    const filters = useSelector((state: any) => state.user.filtersGlobal);
    const { user } = useSelector((state: any) => state.user.user);
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const dispatch = useDispatch();

    const [artList, setArtList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [qtdPage, setQtdPage] = useState<number>(0)
    const [loadingPagination, setLoadingPagination] = useState(true);

    useEffect(() => {
        if (isFocused) {
            const timeOutId = setTimeout(() => {
                if (!filters?.searchTxt && !filters?.artType && !filters?.idEvent && !filters?.idsColors && !filters?.use && !filters?.status) {
                    setLoading(false);
                }
                getArtsList("filter", 1);
                setPage(1);
            }, 500);

            return () => clearTimeout(timeOutId);
        }

    }, [isFocused, filters?.use, filters?.status, filters?.searchTxt, filters?.artType, filters?.idEvent, filters?.idsColors]);

    useEffect(() => {
        if (page !== 1) {
            getArtsList('pagination');
        }
    }, [page]);

    async function getArtsList(type: 'pagination' | 'filter', pageFixed?: number) {
        setLoadingPagination(true)
        // setArtList([]);
        const { result } = await getArtList(pageFixed || page, 'all', undefined, filters?.searchTxt || '', filters?.artType || '', Number(filters?.idEvent || 0), filters?.idsColors || '', user?.id, filters?.use || '', filters?.status || '',);
        if (type === 'pagination') {
            setArtList([...artList, ...result?.list]);
            setQtdPage(result.qtdPages);
        } else {
            setArtList([...result?.list]);
            setQtdPage(result.qtdPages);
        }
        setLoadingPagination(false);
        setLoading(true);
    }

    function renderCards() {
        return artList.map((e) => (
            <Card key={e.id} item={e} refreshArtList={getArtsList} />
        ));
    }

    function goToCreateArt() {
        navigation.navigate('CreateArtScreen', { type: 'newArt' });
    }

    function renderButtonPagination() {
        if (qtdPage === page) {
            return null;
        }

        if (loadingPagination) {
            return (
                <S.LoadingPaginationContainer>
                    <Loading />
                </S.LoadingPaginationContainer>
            );
        }

        return (
            <S.ButtonLoadMore onPress={changePage}>
                <S.ButtonLoadMoreIcon name="arrow-down-circle" />
            </S.ButtonLoadMore>
        );
    }

    function RenderArtInfo() {
        return (
            <>
                <DashboardHeader
                    typeHeader="manageClients"
                    title="Gerenciar artes"
                    buttonLabel="Criar arte"
                    goToScreen={goToCreateArt}
                    isInput
                />
                <S.ContainerAlign width={width}>
                    <S.Row>
                        <S.TextDefault>Artes</S.TextDefault>
                        {/* <S.ButtonUpdate
                            onPress={() => {
                                setPage(1);
                                getArtsList("filter", 1);
                            }}
                        >
                            <MaterialIcons name="refresh" size={16} color="white" />
                        </S.ButtonUpdate> */}
                    </S.Row>
                    <S.Content width={width}>
                        {renderCards()}
                    </S.Content>
                    {renderButtonPagination()}
                </S.ContainerAlign>
            </>
        );
    }

    function changePage() {
        setPage(page + 1);
    }

    return (
        <S.Container>
            {RenderArtInfo()}
        </S.Container>
    );
}
