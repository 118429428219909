// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        data-name="Componente 3 \u2013 1"
        xmlns="http://www.w3.org/2000/svg"
        width={132.988}
        height={21.853}
        {...props}>
        <Path
            data-name="Caminho 73"
            d="M12.448 1.319v1.319h-1.851c-2.5 0-2.781.17-4.673 2.788C4.429 7.517 3.9 7.913 3.654 7.159 3.2 5.765.554 5.652.078 7.008a.939.939 0 0 0 .151.98c.284.452.435.49 1.532.49 1.4 0 1.532.188.776 1.074-.965 1.149-1.022 1.451-.908 4.615.076 2.581.132 3 .435 3.278.284.245.359.584.359 1.432v1.111l2.687-.057 2.668-.057.189-.565c.359-1.13.587-1.224 2.63-1.224h1.854v3.768h1.324V0h-1.327Zm0 4.71v2.637H9.27c-4.238 0-4.389-.151-2.46-2.694 1.665-2.2 2.214-2.543 4.37-2.562l1.268-.019Zm-6.565 4.653c3.5 2.148 3.16 1.846 2.914 2.524-.3.791-.814 1-2.1.848a3.666 3.666 0 0 1-2.724-1.62 1.766 1.766 0 0 1-.3-1.6c.114-.942.208-1.224.378-1.224.035-.002.867.49 1.832 1.071Zm6.565 3.447a9.517 9.517 0 0 1-.151 2.449 3.889 3.889 0 0 1-.851-.584 4.717 4.717 0 0 1-1.551-2.581c-.114-.546-.057-.716.4-1.187a1.51 1.51 0 0 1 1.343-.546h.814Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 74"
            d="M39.87 6.295a17.218 17.218 0 0 0-1.438 1.7c0 .341.776 0 2.1-.946 1.816-1.268 2.062-1.514 2.062-1.987 0-.284-.132-.359-.643-.359-.549.003-.813.192-2.081 1.592Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 75"
            d="M75.768 5.464a6.07 6.07 0 0 0-2.781 1.23c-2.8 2.043-3.122 8.022-.605 10.614 2.914 3.008 9.895 2.63 11.787-.624a5.085 5.085 0 0 0 .587-1.324l.132-.511h-1.059c-.832 0-1.06.057-1.06.284a4.207 4.207 0 0 1-1.268 2.176 5.5 5.5 0 0 1-6.66-.095 5.758 5.758 0 0 1-1.684-4.37c-.151-3.178.605-5.014 2.46-6.054a3.905 3.905 0 0 1 2.516-.53 4.374 4.374 0 0 1 2.516.511 4.236 4.236 0 0 1 2.119 2.63c0 .284.17.341 1.06.341h1.059l-.132-.53a5.3 5.3 0 0 0-3.595-3.538 13.25 13.25 0 0 0-5.392-.21Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 76"
            d="M19.26 12.299v6.906h1.324l.038-5.619.057-5.619 2.781 5.619 2.781 5.619h1.249l2.46-5.525 2.46-5.525.057 5.525.038 5.525h2.27V5.374l-1.249.057-1.249.057-2.365 5.3c-1.305 2.914-2.422 5.354-2.5 5.449a48.629 48.629 0 0 1-2.819-5.354l-2.687-5.487H19.26Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 77"
            d="M50.333 8.533v2.781l-.4-.511a4.843 4.843 0 0 0-4.957-1.173c-2.119.605-3.216 2.195-3.235 4.654a4.645 4.645 0 0 0 1.589 3.86 5.748 5.748 0 0 0 3.311 1.249 5.1 5.1 0 0 0 3.046-1.154l.643-.624v1.589h2.081V5.771H50.33Zm-1.892 2.005c1.23.624 1.892 1.987 1.892 3.841 0 1.892-.662 3.216-1.949 3.86a2.974 2.974 0 0 1-4.143-1.57 6.516 6.516 0 0 1-.019-4.673 2.924 2.924 0 0 1 4.224-1.457Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 78"
            d="M54.841 6.215a1.307 1.307 0 0 0 .719 2.176 1.378 1.378 0 0 0 1.438-2.27 1.951 1.951 0 0 0-2.157.094Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 79"
            d="M62.477 9.536a4.2 4.2 0 0 0-2.724 2.043c-.341.662-.246.814.511.814.605 0 .738-.095 1.078-.7a2.437 2.437 0 0 1 2.419-1.457c1.816-.019 2.687.643 2.895 2.233l.114.832-2.422.076c-2.138.057-2.573.132-3.387.53a2.5 2.5 0 0 0-1.7 2.554c0 1.911 1.23 2.838 3.784 2.819 1.627 0 2.876-.454 3.349-1.211.322-.511.53-.473.53.076 0 .889.17 1.06 1.116 1.06h.87l-.057-2.592c-.095-5.146-.284-5.808-2.005-6.66a8.145 8.145 0 0 0-4.371-.417Zm4.219 5.354a3.9 3.9 0 0 1-.889 2.819 3.08 3.08 0 0 1-3.916.3 2.242 2.242 0 0 1-.378-2.554c.511-1 1.495-1.343 3.708-1.305l1.419.038Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 80"
            d="M88.817 9.706a3.238 3.238 0 0 0-2.043 1.589c-.454.87-.341 1.1.454 1.1.643 0 .738-.057.965-.681a2.987 2.987 0 0 1 3.557-1.419 2.146 2.146 0 0 1 1.722 2.062l.076.927-2.441.095c-2.8.095-3.973.492-4.616 1.551a3.45 3.45 0 0 0-.019 2.989c.984 1.722 4.768 1.949 6.489.4l.7-.643.132.776.132.757h1.987l-.151-.908a34.313 34.313 0 0 1-.151-3.538c-.019-2.365-.057-2.762-.435-3.5-.605-1.192-1.7-1.722-3.784-1.816a5.814 5.814 0 0 0-2.574.259Zm4.692 5.6a2.841 2.841 0 0 1-.419 1.816 2.7 2.7 0 0 1-2.63 1.419c-1.06 0-1.192-.057-1.7-.624a2.189 2.189 0 0 1 1.116-3.595 13.371 13.371 0 0 1 2.176-.208l1.457-.019Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 81"
            d="M101.481 9.763a3.392 3.392 0 0 0-1.173.984l-.4.605v-1.8H97.83v9.649h2.081v-2.989c0-2.743.038-3.046.454-3.954a2.353 2.353 0 0 1 2.384-1.57c.624 0 .757-.057.757-.359a2.423 2.423 0 0 1 .114-.662c.17-.452-1.155-.396-2.139.096Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 82"
            d="M108.309 9.63a3.7 3.7 0 0 0-1.57 1.532c-.189.322-.208.208-.227-.624v-.984h-2.082v9.649h2.081v-2.8c0-3.273.246-4.257 1.249-5.146a1.921 1.921 0 0 1 1.476-.568c.832 0 1.041-.227 1.059-1.1 0-.299-1.249-.28-1.986.041Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 83"
            d="M114.383 9.611a4.605 4.605 0 0 0-3.651 4.806 4.921 4.921 0 0 0 1.854 3.879c2.195 1.532 6.111 1.343 7.908-.378a5.373 5.373 0 0 0 1.268-5.052 4.979 4.979 0 0 0-2.687-2.933 8.454 8.454 0 0 0-4.692-.322Zm2.933.719a3.779 3.779 0 0 1 2.346 2.5c.87 3.5-1.154 6.262-4.125 5.6-1.835-.416-2.706-1.722-2.706-4.049.001-3.067 1.892-4.77 4.484-4.051Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 84"
            d="M126.397 9.479a4.086 4.086 0 0 0-2.176 1.06 1.633 1.633 0 0 0-.568 1.476 1.423 1.423 0 0 0 .643 1.457c.624.549 1.192.738 4.37 1.476 1.57.341 2.252.889 2.214 1.8-.019.946-.624 1.457-2.024 1.722-1.665.3-3.519-.511-3.8-1.665-.095-.378-.246-.435-.965-.435-.776 0-.832.038-.719.416a4.19 4.19 0 0 0 .587 1.022c1.532 2.062 7.133 2.062 8.665.019a2.766 2.766 0 0 0 .038-2.687c-.454-.7-1.949-1.343-4.181-1.8-2.365-.492-2.781-.738-2.706-1.646.095-1.041.776-1.457 2.422-1.457a2.612 2.612 0 0 1 2.743 1.362c.322.511.492.587 1.173.605.795 0 .795 0 .605-.53-.53-1.4-1.968-2.138-4.333-2.233a11.455 11.455 0 0 0-1.988.038Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 85"
            d="M37.491 14.379v4.825h2.081V9.555h-2.081Z"
            fill="#fff"
        />
        <Path
            data-name="Caminho 86"
            d="M54.841 14.379v4.825h2.081V9.555h-2.081Z"
            fill="#fff"
        />
    </Svg>
);

export default SvgComponent;
