// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useWindowDimensions, Linking } from 'react-native';
import { useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { formatInTimeZone } from 'date-fns-tz';
import { addHours, getTime, lightFormat, format, addDays, parseISO } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign } from '@expo/vector-icons';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import toast from 'react-hot-toast';

import Theme from '../../../global/styles/theme';
import { Modal } from '../../../components/Modal';
import { ModalChangePassword } from './components/ModalChangePassword';
import { InputInternal } from '../../../components/InputInternal';
import { DashboardHeader } from '../../../components/DashboardHeader';
import { Buttons } from '../../../components/DashboardButtons';
import { TopTabs } from '../../../components/TopTabs'

import AddPhoto from '../../../assets/images/svg/addPhoto';
import { loginAuth } from '../../../store/modules/user/actions';
import { clientsUpdate, clientChangePassword } from '../../../services/clients';
import { getImageLink } from '../../../services/uploads';
import { getPages, updatePage } from '../../../services/social';
import {
    schemaEditProfile,
    schemaChangePassword,
    schemaSocialMedia,
} from '../../../global/utils/formsSchems';
import { getDoc } from '../../../global/utils/functions';

import themeGlobal from '../../../global/styles/theme';
import * as S from './styles';
import { VehicleInfo } from '../NewClientScreen/components/VehicleInfo';
import { LoadingModal } from '../../../components/LoadingModal';
import { GeneralInfo } from '../NewClientScreen/components/GeneralInfo';
import axios from 'axios';
import theme from '../../../global/styles/theme';

interface NewPhotoProps {
    name: string;
    type: string;
    base64: string;
}

const listTopbar = [
    'Perfil',
    'Veículos',
    'Social'
]

const listTopbarAdm = [
    'Perfil',
]

const tabTopBarConfig = {
    profile: 1,
    vehicle: 2,
    social: 3,
};

export function EditPerfilScreen() {
    const {
        token,
        tokenRefresh,
        user,
        user: { id, name, email, photo, phone, type, revendaPro, signature, default_text, name_page, key_page, access_token, id_business, images, frames },
    } = useSelector((state: any) => state.user.user);

    const route = useRoute();
    const window = useWindowDimensions().width;
    const dispatch = useDispatch();
    const widthForTwoColumns = window <= 800 ? '100%' : '48%';
    const [currentPhoto, setCurrentPhoto] = useState(photo);
    const [newPhoto, setNewPhoto] = useState<NewPhotoProps>(
        {} as NewPhotoProps,
    );
    const [loading, setLoading] = useState(false);
    const [modalChangePasswordVisible, setModalChangePasswordVisible] =
        useState(false);

    const [showAllPagesSelect, setShowAllPagesSelect] = useState<any>([]);
    const [logoList, setLogoList] = useState<any[]>(images || []);
    const [framesList, setFramesList] = useState<any[]>(frames || []);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validityButton, setValidityButton] = useState<ButtonProps>({} as ButtonProps);
    const [image, setImage] = useState(photo || '');
    const [configsUser, setConfigsUser] = useState(null);
    const [modalSessionExpired, setModalSessionExpired] = useState(false);
    const [currentTabPosition, setCurrentTabPosition] = useState(tabTopBarConfig[route?.params?.initialTab] || tabTopBarConfig.profile);
    const currentListTopBar = type === 'adm' ? listTopbarAdm : listTopbar

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
        watch
    } = useForm(resolver);
    const resolver = {
        resolver: yupResolver(getSchema()), mode: 'onChange',
    };
    const navigation = useNavigation();
    const dateExpired = !configsUser?.expired_at ? null : addHours(new Date(configsUser?.expired_at), 3);
    const sessionExpired = !configsUser?.expired_at ? true : new Date() > new Date(configsUser?.expired_at);

    useEffect(() => {
        getConfigs();
    }, []);

    useEffect(() => {
        if (configsUser) {
            if (sessionExpired) {
                setModalSessionExpired(true);
            } else {
                setModalSessionExpired(false);
            }
        }
    }, [sessionExpired, configsUser]);

    async function getConfigs() {
        const configs = await getPages(id);
        setConfigsUser(configs?.result.configuration || null);
    }

    function getSchema() {
        if (currentTabPosition === tabTopBarConfig.profile) {
            return schemaEditProfile;
        }
        if (currentTabPosition === tabTopBarConfig.social) {
            return schemaSocialMedia;
        }
        return schemaChangePassword;
    }

    function chanageModalForgotPassword() {
        setModalChangePasswordVisible(!modalChangePasswordVisible);
    }

    function handleForgotPassword() {
        chanageModalForgotPassword();
    }

    function goBack() {
        navigation.navigate('DashboardScreen');
    }

    async function handleUpdatePhoto() {
        const docInfo = await getDoc();
        setNewPhoto(docInfo);
        setCurrentPhoto(docInfo.base64);
    }

    async function handleEditPerfil() {
        const data = getValues();
        if (data.password === data.confirmPassword) {
            setLoading(true);
            const responseImage = newPhoto?.type ? await getImageLink(newPhoto) : { status: 200, result: currentPhoto };
            const dataFormatted = {
                name: data.name,
                phone: data.phone,
                password: data.password,
                photo: responseImage.result || currentPhoto,
                signature: data?.signature,
                defaultText: data?.defaultText,
            };

            const body = email !== data.email ? data : dataFormatted;
            if (responseImage.status === 200) {
                const response = await clientsUpdate(body, id, type);
                if (response.status === 200) {
                    const infos = {
                        token,
                        tokenRefresh,
                        user: response.result?.user,
                    };
                    dispatch(loginAuth(infos));
                    toast.success(response.message, themeGlobal.toast);
                } else {
                    toast.error(response.message, themeGlobal.toast);
                }
            } else {
                toast.error(responseImage?.message, themeGlobal.toast);
            }
            setLoading(false);
        } else {
            toast.error('As senhas precisam ser iguais.', themeGlobal.toast);
        }
    }

    function renderButtonFacebook() {
        return (
            <FacebookLogin
                appId="796660831596361"
                fields="name,email,picture"
                scope="business_management,pages_show_list,instagram_basic,instagram_content_publish,pages_read_engagement,pages_manage_posts,public_profile"
                callback={responseFacebook}
                cssClass="fbtn"
                disableMobileRedirect
                // autoLoad
                render={({ onClick }) => (
                    <S.FacebookButtonArea onPress={onClick}>
                        <S.AlignIcon>
                            <AntDesign name="facebook-square" size={24} color="white" />
                        </S.AlignIcon>
                        <S.FacebookButtonText>
                            {configsUser?.name_page ? 'Reiniciar sessão' : 'Entre com o Facebook'}
                        </S.FacebookButtonText>
                    </S.FacebookButtonArea>
                )}
            />
        );
    }

    async function handleEditPassword(data: any) {
        setLoading(true);
        const body = { email, password: data.password };
        const response = await clientChangePassword(body);
        if (response.status === 200) {
            reset()
            toast.success(response.message, themeGlobal.toast);
        } else {
            toast.error(response.message, themeGlobal.toast);
        }
        setLoading(false);
    }

    function renderPageTitle() {
        if (currentTabPosition === tabTopBarConfig.profile) {
            return 'Perfil';
        }
        if (currentTabPosition === tabTopBarConfig.vehicle) {
            return 'Veículos';
        }
        return 'Social';
    }

    async function handleSave(data: any) {
        if (currentTabPosition === tabTopBarConfig.profile) {
            await handleEditPerfil(data);
        }
        // else {
        //     handleEditPassword(data);
        // }
    }

    function renderPhotoUser() {
        if (!currentPhoto) {
            return (
                <AddPhoto />
            );
        }
        return (
            <S.PhotoUser onContextMenu={(e) => e.preventDefault()} source={currentPhoto} />
        );
    }

    function RenderSignatureArea() {
        if (type === 'adm') {
            return null;
        }

        return (
            <S.InputContainer width={window}>
                <InputInternal
                    width={widthForTwoColumns}
                    label="Texto padrão"
                    placeholder="Insira o texto padrão"
                    control={control}
                    name="defaultText"
                    errors={errors}
                    inicialValue={default_text}
                    multiline
                />
                <InputInternal
                    width={widthForTwoColumns}
                    label="Assinatura"
                    placeholder="Digite sua assinatura"
                    control={control}
                    name="signature"
                    errors={errors}
                    inicialValue={signature}
                />
            </S.InputContainer>
        );
    }

    function openUrl() {
        Linking.openURL('http://ajudamidiacarros.com.br/');
    }

    function renderFormProfile() {
        if(currentTabPosition === tabTopBarConfig.profile && type === 'users') {
            return (
                <GeneralInfo
                    idUser={id}
                    info={user}
                    typeScreen="editClient"
                    control={control}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    image={image}
                    setImage={setImage}
                    logoList={logoList}
                    setLogoList={setLogoList}
                    password={password}
                    setPassword={setPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    isValid={validityButton.clientButtonValidity}
                    getValues={watch}
                    framesList={framesList}
                    setFramesList={setFramesList}
                />
            )
        }
        if (currentTabPosition === tabTopBarConfig.profile) {
            return (
                <>
                    <S.AddPhotoArea width={window}>
                        <S.PhotoArea>
                            <S.AddButtonPhoto onPress={handleUpdatePhoto}>
                                {renderPhotoUser()}
                            </S.AddButtonPhoto>
                            <S.AddPhotoText>Adicionar imagem</S.AddPhotoText>
                        </S.PhotoArea>
                    </S.AddPhotoArea>
                    <S.InputContainer width={window}>
                        <InputInternal
                            width={widthForTwoColumns}
                            label="Nome"
                            placeholder="Digite seu nome"
                            control={control}
                            name="name"
                            errors={errors}
                            inicialValue={name}
                            disabled={revendaPro}
                        />
                        <InputInternal
                            width={widthForTwoColumns}
                            label="Celular"
                            placeholder="(__) ____-____"
                            mask="cel-phone"
                            control={control}
                            name="phone"
                            errors={errors}
                            inicialValue={phone}
                            disabled={revendaPro}
                        />
                    </S.InputContainer>
                    <S.InputContainer width={window}>
                        <InputInternal
                            width="100%"
                            label="Email"
                            placeholder="exemplo@gmail.com"
                            control={control}
                            name="email"
                            errors={errors}
                            inicialValue={email}
                            disabled={revendaPro}
                        />
                        {/* <S.ButtonForgotPassword
                            onPress={chanageModalForgotPassword}>
                            <S.TextButtonForgotPassword>
                                Esqueceu a senha?
                            </S.TextButtonForgotPassword>
                        </S.ButtonForgotPassword> */}
                    </S.InputContainer>
                    <S.InputContainer width={window}>
                        <InputInternal
                            width={widthForTwoColumns}
                            label="Nova senha"
                            placeholder="Nova senha"
                            control={control}
                            name="password"
                            errors={errors}
                            password
                            disabled={revendaPro}
                        />
                        <InputInternal
                            width={widthForTwoColumns}
                            label="Confirmar senha"
                            placeholder="Confirmar senha"
                            control={control}
                            name="confirmPassword"
                            errors={errors}
                            password
                            disabled={revendaPro}
                        />
                    </S.InputContainer>
                    {RenderSignatureArea()}
                </>
            );
        }

        if (currentTabPosition === tabTopBarConfig.social) {
            return (
                <>
                    {renderButtonFacebook()}
                    {configsUser?.name_page ? (
                        <>
                            <S.FacebookIsLink>
                                Página vinculada com sucesso:
                                {' '}
                                <S.FacebookIsLink color={theme.colors.purple[500]}>{configsUser?.name_page}</S.FacebookIsLink>
                            </S.FacebookIsLink>
                            {!sessionExpired ? (
                                <S.FacebookIsLink>
                                    Sessão válida até
                                    {' '}
                                    <S.FacebookIsLink color={theme.colors.purple[500]}>{lightFormat(dateExpired, 'dd/MM/yyyy HH:mm')}</S.FacebookIsLink>
                                </S.FacebookIsLink>
                            ) : (
                                <S.FacebookIsLink>
                                    Sua sessão está expirada. Reinicie clicando no botão acima.
                                </S.FacebookIsLink>
                            )}
                        </>
                    ) : null}
                    {showAllPagesSelect.length ? (
                        <>
                            <S.InputContainer>
                                <InputInternal
                                    width={isMobile ? '100%' : '30%'}
                                    label="Páginas do Facebook"
                                    placeholder="Páginas do Facebook"
                                    control={control}
                                    name="id"
                                    errors={errors}
                                    inicialValue={String(key_page) || '0'}
                                    // disabled={revendaPro}
                                    list={showAllPagesSelect}
                                />
                            </S.InputContainer>
                            <S.ButtonArea>
                                <S.ButtonConfirm onPress={openUrl}>
                                    <S.FacebookButtonText>
                                        Ajuda
                                    </S.FacebookButtonText>
                                </S.ButtonConfirm>
                                <Buttons
                                    saveFuntion={handleSubmit(handlePage)}
                                    buttonConfirmText="Salvar"
                                    loading={loading}
                                    cancelFuntion={() => null}
                                    hiddenCancelButton
                                    isValid
                                />
                            </S.ButtonArea>
                        </>
                    ) : null}
                </>
            );
        }

        const userInfo = { id, revendaPro };

        return (
            <VehicleInfo clientInfo={userInfo} />
        );
    }

    function RenderButton() {
        if (currentTabPosition === tabTopBarConfig.profile || type === 'users') {
            return null;
        }

        return (
            <S.ButtonArea>
                <Buttons
                    saveFuntion={handleSave}
                    buttonConfirmText="Salvar"
                    loading={loading}
                    cancelFuntion={goBack}
                    isValid={true}
                />
            </S.ButtonArea>
        );
    }

    const responseFacebook = async (response: any) => {
        if (response?.accessToken && response?.id) {
            setModalSessionExpired(false);
            const { data } = await axios.get(`https://graph.facebook.com/v19.0/${response?.id}/accounts?access_token=${response?.accessToken}`);
            const formatToSelected = (data?.data || []).map((v) => {
                return {
                    value: v.id,
                    label: v.name,
                    access_token: v.access_token,
                }
            });
            const defaultSelected = { label: 'Selecione a página', value: '0' };
            setShowAllPagesSelect([defaultSelected, ...formatToSelected]);
            if (key_page) {
                const getMyPage = (data?.data || [])?.find((v) => v.id === key_page);
                let body: any = {
                    expiredAt: format(addDays(Date.now(), 60), "yyyy-MM-dd HH:mm:ss"),
                    namePage: getMyPage?.name || null,
                    keyPage: getMyPage?.id || null,
                    accessToken: getMyPage?.access_token,
                    idUser: id,
                };
                if (id_business) {
                    body = {
                        ...body,
                        idBusiness: id_business,
                    }
                }
                const registerNewPage = await updatePage(body);
                if (registerNewPage?.result?.currentClient?.id) {
                    const infos = {
                        token,
                        tokenRefresh,
                        user: registerNewPage?.result?.currentClient,
                    };
                    dispatch(loginAuth(infos));
                }
            }
        }
    };

    async function handlePage(data: any) {
        const getAllDataPage = showAllPagesSelect.find((v) => v.value === data.id);
        const response = await axios.get(`https://graph.facebook.com/v19.0/${getAllDataPage.value}?fields=instagram_business_account&access_token=${getAllDataPage.access_token}`);
        const idBusiness = response?.data?.instagram_business_account?.id;
        let body: any = {
            namePage: getAllDataPage.label,
            keyPage: getAllDataPage.value,
            accessToken: getAllDataPage.access_token,
            expiredAt: format(addDays(Date.now(), 60), "yyyy-MM-dd HH:mm:ss"),
            idUser: id,
        };
        if (idBusiness) {
            body = {
                ...body,
                idBusiness,
            }
        }
        const registerNewPage = await updatePage(body);
        if (registerNewPage?.result?.currentClient?.id) {
            const infos = {
                token,
                tokenRefresh,
                user: registerNewPage?.result?.currentClient,
            };
            dispatch(loginAuth(infos));
            setShowAllPagesSelect([]);
            getConfigs();
            toast.success(registerNewPage?.message || 'Configuração registrada com sucesso.');
            const idVehicle = await AsyncStorage.getItem(
                '@midiacarros:publication_routes',
            );
            if (idVehicle) {
                navigation.navigate('HomeScreen', { idVehicle, title: 'Publicações' });
            }
        }
    }

    return (
        <S.Container>
            <S.ScrollView>
                <DashboardHeader
                    title="Perfil"
                    typeHeader="home"
                    disableInputSearch
                />
                <S.ContainerAlign>
                    <TopTabs
                        listTabs={currentListTopBar}
                        currentPosition={currentTabPosition}
                        setCurrentPosition={setCurrentTabPosition}
                        widthResponsive={300}
                    />
                    <S.EditInfoArea>
                        <S.TextTitle>{renderPageTitle()}</S.TextTitle>
                        {renderFormProfile()}
                        {RenderButton()}
                    </S.EditInfoArea>
                </S.ContainerAlign>
            </S.ScrollView>
            <LoadingModal
                visible={loading}
                titleLoading="Atualizando perfil..."
            />
            <Modal
                isVisible={modalChangePasswordVisible}
                closeModal={setModalChangePasswordVisible}>
                <ModalChangePassword forgotPassword={handleForgotPassword} />
            </Modal>
            <Modal
                isVisible={modalSessionExpired}
                closeModal={() => {
                    setModalSessionExpired(!modalSessionExpired);
                }}
            >
                <S.ContainerModalConfirm width={window}>
                    <S.TextDefault
                        fontSize={25}
                        fontFamily="bold"
                        color={Theme.colors.lowBlack} style={{ textAlign: 'center' }}>
                        Sessão expirada
                    </S.TextDefault>
                    <S.ButtonModalConfirm>
                        <S.ButtonModalArea
                            background="red"
                            onPress={() => setModalSessionExpired(false)}
                            style={{ width: window < 400 ? (window < 350 ? (window < 300 ? 75 : 100) : 125) : 150 }}
                        >
                            <S.ButtonModalText style={{ paddingLeft: 5, paddingRight: 5 }}>
                                Reiniciar depois
                            </S.ButtonModalText>
                        </S.ButtonModalArea>
                        {renderButtonFacebook()}
                    </S.ButtonModalConfirm>
                </S.ContainerModalConfirm>
            </Modal>
        </S.Container>
    );
}
