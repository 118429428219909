import React from 'react';
import { useWindowDimensions } from 'react-native';

import * as S from './styles';
import SaleIcon from '../../../../../assets/images/svg/saleIcon';
import UpgradeIcon from '../../../../../assets/images/svg/upgradeIcon';
import DowngradeIcon from '../../../../../assets/images/svg/downgradeIcon';
import SignIcon from '../../../../../assets/images/svg/signIcon';

interface StatiticsInfoProps {
    title?: string,
    total?: number | string,
    type: 'upgrade' | 'sign' | 'downgrade' | 'sale',
}

export function StatiticsInfo(props: StatiticsInfoProps) {
    const { title, total, type } = props;
    const width = useWindowDimensions().width;
    const fontTitleSize = width <= 1415 ? 12 : 14;
    const numberTitleSize = width <= 1415 ? 40 : 42;
    const numberInfo = type === 'sale' ? 'artes' : 'usuários';

    function RenderIcon() {
        if (type === 'downgrade') {
            return (
                <DowngradeIcon />
            );
        }
        if (type === 'sale') {
            return (
                <SaleIcon />
            );
        }
        if (type === 'sign') {
            return (
                <SignIcon />
            );
        }
        return (
            <UpgradeIcon />
        );
    }

    return (
        <S.StatiticsContainer>
            <S.TitleArea>
                <S.TextDefault
                    fontSize={fontTitleSize}
                    fontFamily='semi'
                >
                    {title}
                </S.TextDefault>
                <S.TitleIconArea>
                    {RenderIcon()}
                </S.TitleIconArea>
            </S.TitleArea>
            <S.TextDefault
                fontSize={numberTitleSize}
                fontFamily='semi'
                marginBottom={0}
                textAlign='center'
            >
                {total}
            </S.TextDefault>
            <S.TextDefault
                fontSize={12}
                fontFamily='regular'
                textAlign='center'
            >
                {numberInfo}
            </S.TextDefault>
        </S.StatiticsContainer>
    );
}
