// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import * as DocumentPicker from 'expo-document-picker';
import toast from 'react-hot-toast';

import { NavigationProps } from '../../../routes/index';
import { importWithXml } from '../../../services/user';
import themeGlobal from '../../../global/styles/theme';
import * as S from './styles';
import { clientsList } from '../../../services/clients';

import { Table } from '../../../components/Table';
import { InputSearch } from '../../../components/InputSearch';
import { DashboardHeader } from '../../../components/DashboardHeader';
import { LoadingModal } from '../../../components/LoadingModal';
import { Loading } from '../../../components/Loading/index';
import { useSelector } from 'react-redux';

interface ManageClientsScreen extends NavigationProps {}

interface InfoTableProps {
    amount: number;
    list: Array<any>;
    qtdPages: number;
}

export function ManageClientsScreen(props: ManageClientsScreen) {
    const window = useWindowDimensions().width;
    const userInfo = useSelector((state) => state.user.user);
    const isFocus = useIsFocused();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [infoTable, setInfoTable] = useState<InfoTableProps>(
        {} as InfoTableProps,
    );
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [loadingModalVisible, setLoadingModalVisible] = useState(false)

    useEffect(() => {
        if (isFocus) {
            setLoading(false);
            getListClient();
        }
    }, [isFocus, pagination]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListClient(name, email);
        }, 500);

        return () => clearTimeout(timeOutId);
    }, [name, email]);

    async function getListClient(name?: string, email?: string, solicitation?: 'total' | 'pending') {
        const { result } = await clientsList(pagination, name, email, solicitation);
        setInfoTable(result);
        setLoading(true);
    }

    function goToNewClient() {
        const typeScreen = { type: 'newClient' };
        props.navigation.navigate('NewClientScreen', typeScreen);
    }

    async function handleGetDock() {
        const result = await DocumentPicker.getDocumentAsync({
            type: 'text/xml',
        });

        if (result.type === 'success') {
            setLoadingModalVisible(true);
            setLoading(true);
            const hashBase64 = result.uri.split(',')[1];
            const body = {
                base64: hashBase64,
                // idUser: userInfo.user.id,
            };
            const response = await importWithXml(body);
            if (response.status === 200) {
                setLoadingModalVisible(false);
                toast.success(response.message, themeGlobal.toast);
                getListClient()
            } else {
                setLoadingModalVisible(false);
                toast.error(response.message, themeGlobal.toast);
                setLoading(false);
            }
            setLoading(false);
        }
    }

    function handleDropdownPress() {
        setDropdownVisible(!dropdownVisible);
    }

    function RenderDropdown() {
        if (!dropdownVisible) {
            return null;
        }

        const filterAll = () => {
            getListClient(name, email, 'total');
            handleDropdownPress();
        }

        const filterPending = () => {
            getListClient(name, email, 'pending');
            handleDropdownPress();
        }

        return (
            <S.DropdownArea width={window}>
                <S.DropdownOption onPress={filterAll}>
                    <S.DropdownOptionText>
                        Todas
                    </S.DropdownOptionText>
                </S.DropdownOption>
                <S.DropdownOption onPress={filterPending}>
                    <S.DropdownOptionText>
                        Pendentes
                    </S.DropdownOptionText>
                </S.DropdownOption>
            </S.DropdownArea>
        );
    }

    function RenderContent() {
        if (!loading) {
            return (
                <Loading />
            );
        }

        return (
            <S.ScrollView>
                <DashboardHeader
                    title="Gerenciar clientes"
                    typeHeader="manageClients"
                    goToScreen={goToNewClient}
                    buttonLabel="Novo cliente"
                    typeButton="dropdown"
                    handleGetDock={handleGetDock}
                    disableInputSearch
                />
                <S.InputSearchArea width={window}>
                    <InputSearch
                        value={name}
                        setValue={setName}
                        placeholder="Buscar por nome"
                        marginLeft={0}
                        widthArea={235}
                    />
                    <InputSearch
                        value={email}
                        setValue={setEmail}
                        placeholder="Buscar por email"
                        marginLeft={20}
                        widthArea={235}
                    />
                    {/* <S.RequestFilterButton onPress={handleDropdownPress}>
                        <InputSearch
                            value={''}
                            setValue={setEmail}
                            placeholder="Buscar solicitações"
                            marginLeft={20}
                            disabled
                            arrowIcon
                        />
                        {RenderDropdown()}
                    </S.RequestFilterButton> */}
                </S.InputSearchArea>
                <S.ContainerAlign>
                    <Table
                        data={infoTable?.list || []}
                        type="clients"
                        getAllClient={getListClient}
                        totalItem={infoTable?.amount || 0}
                        qtdPage={infoTable?.qtdPages || 1}
                        currentPage={pagination}
                        setCurrentPage={setPagination}
                        widthTable={window < 800 ? 800 : undefined}
                    />
                </S.ContainerAlign>
            </S.ScrollView>
        );
    }

    return (
        <S.Container>
            {RenderContent()}
            <LoadingModal
                visible={loadingModalVisible}
                titleLoading='Importando clientes...'
            />
        </S.Container>
    )
}
