export default {
    colors: {
        white: '#FFFFFF',
        background: '#F2F2F2',
        black: '#000000',
        lowBlack: '#303030',
        yellow: '#FBBE28',
        orange: {
            250: '#FFE5CC',
            500: '#FDA62F',
        },
        gray: {
            10: '#EEEEEE',
            15: '#B3B3B3',
            25: '#E2E2E2',
            30: '#E3E3E3',
            50: '#F5F5F7',
            70: '#F7F7F7',
            150: '#A5A5A5',
            200: '#F2F3F5',
            300: '#F2F2F2',
            350: '#F0F4F7',
            400: '#757575',
            450: '#666666',
            500: '#C5C5C5',
            600: '#A5A5A5',
            650: '#626477',
            700: '#707070',
            800: '#202125',
            900: '#212121',
        },
        purple: {
            rgba: {
                30: 'rgba(103, 70, 200, 0.33)',
                50: 'rgba(103, 70, 200, 0.5)',
            },
            100: '#C3A5EB',
            200: '#A395CC',
            500: '#6746C8',
        },
        red: {
            150: '#FACFCF',
            200: '#FFE6E5',
            300: '#FF5454',
            350: '#E34850',
            500: '#F64A6D',
            400: '#FD553B',
            600: '#DE3839',
        },
        green: {
            50: '#61fec7',
            100: '#53EEB5',
            150: '#CFF9C5',
            200: '#6CF567',
            300: '#EEFBE5',
            400: '#009545',
            450: '#009D00',
            470: '#2AB12A',
            500: '#7DE339',
        },
        blue: {
            100: '#6ea3e2',
            200: '#0E66D0',
            500: '#3A4F90',
        },
    },
    font: {
        segoeUi: {
            regular: 'Segoe UI',
            semiBold: 'Segoe UI Semibold',
            bold: 'Segoe UI Bold',
        },
    },
    toast: {
        style: {
            borderRadius: '10px',
            background: '#202125',
            color: '#fff',
            fontFamily: 'Segoe UI',
            height: '40px',
        },
    },
};
