import React from 'react';
import { useNavigation } from '@react-navigation/native';

import * as S from './styles';
import ErrorIcon from '../../assets/images/svg/errorIcon';
import InfoIcon from '../../assets/images/svg/infoIcon';
import SuccessIcon from '../../assets/images/svg/successIcon';
import Theme from '../../global/styles/theme';

interface CoditionModalProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    buttonFunction: () => void,
}

export function CoditionModal(props: CoditionModalProps) {
    const { titleModal, typeModal, descriptionModal, buttonText, modalVisible, buttonFunction } = props;

    const navigation = useNavigation();
    const facebookErrorMessage = 'Você não se conectou ao facebook';

    function getColor() {
        if (descriptionModal === facebookErrorMessage) {
            return Theme.colors.blue[200];
        }
        if (typeModal === 'error') {
            return Theme.colors.red[600];
        }
        if (typeModal === 'info') {
            return Theme.colors.blue[200];
        }

        return Theme.colors.green[400];
    }

    function RenderIconModal() {
        if (descriptionModal === facebookErrorMessage) {
            return (
                <InfoIcon />
            );
        }
        if (typeModal === 'error') {
            return (
                <ErrorIcon />
            );
        }
        if (typeModal === 'info') {
            return (
                <InfoIcon />
            );
        }

        return (
            <SuccessIcon />
        );
    }

    function handleNavigateProfile() {
        navigation.navigate('EditPerfilScreen');
    }

    function renderButton() {
        if (descriptionModal !== facebookErrorMessage) {
            return (
                <S.Button onPress={buttonFunction} backgroundColor={getColor}>
                    <S.ButtonText>{buttonText}</S.ButtonText>
                </S.Button>
            );
        }

        return (
            <S.Button onPress={handleNavigateProfile} backgroundColor={getColor}>
                <S.ButtonText>Conectar ao Facebook</S.ButtonText>
            </S.Button>
        );
    }

    return (
        <S.ModalArea isVisible={modalVisible}>
            <S.Container>
                {RenderIconModal()}
                <S.Title backgroundColor={getColor}>{titleModal}</S.Title>
                <S.Description>{descriptionModal}</S.Description>
                {renderButton()}
            </S.Container>
        </S.ModalArea>
    );
}
