export function getImageDimensions(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height
            });
        };

        img.onerror = (error) => {
            reject(error);
        };

        img.src = url;
    });
}
export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
}

export function obterTipoDeImagemEmBase64(base64String) {
    const regex = /^data:image\/([a-zA-Z]+);base64,/;
    const match = base64String.match(regex);

    if (match) {
        const imageType = match[1].toLowerCase();
        return imageType;
    }
    return '';
}
