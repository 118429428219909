import styled, { css } from 'styled-components/native';
import { ResponsiveProps } from '../../global/types';
import Theme from '../../global/styles/theme';
import Feather from '@expo/vector-icons/Feather';

interface TextProps {
    fontSize: number;
    color: string;
    fontFamily: 'bold' | 'regular';
    marginTop: number;
    margin: number;
}

interface CheckboxProps {
    isMarked: boolean,
    marginLeft?: boolean,
}

interface TextProps {
    fontSize: number;
    color: string;
    fontFamily: 'bold' | 'regular';
    marginTop: number;
    margin: number;
}

interface ButtonModalProps {
    background: string,
}

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.gray[300]};
    /* flex: 1; */
`;

export const Title = styled.Text`
    color: ${({ theme }) => theme.colors.gray[800]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 22px;
`;

export const ButtonModalArea = styled.TouchableOpacity<ButtonModalProps>`
    align-items: center;
    background-color: ${({ background, theme }) => background === 'red'
        ? theme.colors.red[500] : theme.colors.purple[500]};
    border-radius: 6px;
    height: 40px;
    justify-content: center;
    width: 150px;
    margin: 5px;
`;

export const ButtonModalText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const ButtonModalConfirm = styled.View`
    /* align-self: flex-end; */
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 12px;
    flex-wrap: wrap-reverse;
    padding: 5px;
`;

export const ContainerModalConfirm = styled.View<ResponsiveProps>`
    height: 200px;
    border-radius: 5px;
    background-color: white;
    width: ${({ width }) => width < 600 ? '80%' : 450};
    justify-content: space-around;
    align-items: center;
    padding: 10px;
`;

export const CheckboxArea = styled.View<CheckboxProps>`
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
`;

export const CheckboxCircle = styled.TouchableOpacity<CheckboxProps>`
    align-items: center;
    border-color: ${({ isMarked, theme }) => isMarked ? theme.colors.purple[500] : theme.colors.gray[400]};
    border-radius: 10px;
    border-width: 2px;
    height: 20px;
    justify-content: center;
    margin-left: ${({ marginLeft }) => marginLeft ? 30 : 0};
    width: 20px
`;

export const CheckboxCircleMarked = styled.View<CheckboxProps>`
    align-items: center;
    background-color: ${({ isMarked, theme }) => isMarked ? theme.colors.purple[500] : theme.colors.gray[400]};
    border-radius: 10px;
    height: 12px;
    justify-content: center;
    opacity: ${({ isMarked }) => isMarked ? 1 : 0};
    width: 12px;
`;

export const CheckboxText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    margin-left: 12px;
`;

export const TextDefault = styled.Text<TextProps>`
    color: ${({ color }) => color};
    font-family: ${({ fontFamily, theme }) =>
        fontFamily === 'bold'
            ? theme.font.segoeUi.bold
            : theme.font.segoeUi.regular};
    font-size: ${({ fontSize }) => fontSize};
    ${({ marginTop }) => `margin-top: ${marginTop || 0}px`}
    ${({ margin }) => `margin: ${margin || 0}px`}
`;

export const ContainerAlign = styled.ScrollView<ResponsiveProps>`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 20px;
    margin-top: 40px;
    width: 95%;
    ${({ width }) => width >= 800 ? 
        css`padding: 28px 60px 40px` : css`padding: 20px 20px`};
`;

export const Content = styled.View<ResponsiveProps>`
    display: grid;
    ${({ width }) =>
        width > 1800 &&
        css`
            grid-template-columns: repeat(5, 1fr);
        `}
    ${({ width }) =>
        width >= 1520 &&
        width <= 1800 &&
        css`
            grid-template-columns: repeat(4, 1fr);
        `}
    ${({ width }) =>
        width >= 1200 &&
        width <= 1520 &&
        css`
            grid-template-columns: repeat(3, 1fr);
        `}
    ${({ width }) =>
        width >= 880 &&
        width <= 1200 &&
        css`
            grid-template-columns: repeat(2, 1fr);
        `}
    gap: ${({ width }) => (width < 880 ? '50px' : '0px')};
`;

export const ContentEdit = styled.View<ResponsiveProps>`
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 1fr);
    ${({ width }) =>
        width <= 900 &&
        css`
            grid-template-columns: 1fr;
        `}
`;

export const LoadingPaginationContainer = styled.View`
    margin-top: 20px;
    align-self: center;
`;

export const ButtonLoadMore = styled.TouchableOpacity`
    margin-top: 20px;
    align-self: center;
`;

export const ButtonLoadMoreIcon = styled(Feather)`
    color: ${({ theme }) => theme.colors.purple[500]};
    font-size: 30px;
`;

export const Box = styled.View`
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
    flex: 1;
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.gray[300]};
`;

export const Column = styled.View`
    height: 500px;
    flex: 1;
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 10px;
`;

export const ContainerModalGallery = styled.View`
    height: 85%;
    border-radius: 5px;
    background-color: white;
    width: 85%;
`;

export const ScrollFilters = styled.ScrollView`
    align-self: center;
    width: 90%;
`;

export const BoxArea = styled.TextInput.attrs({
    placeholderTextColor: Theme.colors.gray[600],
})`
    border-width: 1;
    border-color: gray;
    height: ${({ height }: any) => height || 150};
    margin-top: 10px;
    border-radius: 15;
    padding-left: 10;
    padding-right: 10;
    padding-top: 10;
    padding-bottom: 110;
    width: 100%;
`;

export const InputPickerContainer = styled.ScrollView`
    flex-direction: column;
    border-width: 1;
    border-color: gray;
    /* height: 150px; */
    margin-top: 10px;
    border-radius: 15;
    padding-left: 10;
    padding-right: 10;
    padding-top: 10;
    padding-bottom: 10;
    width: 100%;
    height: 300px;
`;

export const FlowTags = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
`;

export const TouchableOpacityEmpty = styled.TouchableOpacity`
    margin: 5px;
    border-width: ${({ focus }: any) => focus ? 1 : 0};
    border-radius: 10;
`;

export const Tag = styled.Text`
    padding: 5px;
    border-radius: 5px;
    min-width: 50px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    color: ${({ theme }) => theme.colors.white};
    margin: 5px;
    text-align: center;
`;

export const Row = styled.View`
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
`;
