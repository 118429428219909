// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';

import { TempleteModal } from '../../../components/TemplateModal';
import { Card } from '../../ClientScreen/SavedArtsList/components/Card';
import { Loading } from '../../../components/Loading';

import { UseTemplateModal } from '../../../components/UseTemplateModal';

import { getArtDownload, getArtLogs } from '../../../services/arts';
import * as S from './styles';
import { DashboardHeader } from '../../../components/DashboardHeader';
import { addDays, formatISO } from 'date-fns';
import { TopTabs } from '../../../components/TopTabs';
import { filtersGlobal } from '../../../store/modules/user/actions';
interface ItemProps {
    id: number;
    id_plan: number;
    title: string;
    store_name: string;
    colors: Array<string>;
    use: string;
    type: string;
    thumb: string;
    is_bought: boolean;
    is_favorite: boolean;
    author_name: string;
    author_photo: string;
    id_art?: number;
    preview_thumb?: string;
    created_at: string;
}

interface DataProps {
    amountPage: number;
    list: ItemProps[];
}

const listTopTabs = [
    "Publicações",
    "Downloads"
]

export function PublicationTracking() {
    const { user } = useSelector((state: any) => state.user.user);
    const filters = useSelector((state: any) => state.user.filtersGlobal);
    const dispatch = useDispatch();

    const width = useWindowDimensions().width;
    const isFocused = useIsFocused();
    const navigation = useNavigation();
    const title = 'Acompanhamento das publicações';

    const [data, setData] = useState<DataProps>({} as DataProps);
    const [cardList, setCardList] = useState<ItemProps[]>([] as ItemProps);
    const [dataDownload, setDataDownload] = useState<DataProps>({} as DataProps);
    const [cardListDownload, setCardListDownload] = useState<Array<any>>([]);
    const [modalInfo, setModalInfo] = useState<ItemProps>({} as ItemProps);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalDetails, setModalDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentTopBar, setCurrentTopBar] = useState(1);
    const [socialMediaVisibleHandle, setSocialMediaVisibleHandle] = useState('');
    const [loadingPagination, setLoadingPagination] = useState(true);
    const [socialMediaVisibleBool, setSocialMediaVisible] = useState('');
    const [page, setPage] = useState(1);
    const [intervalId, setIntervalId] = useState(null);
    const [pageDownload, setPageDownload] = useState(1);

    useEffect(() => {
        clearInterval(intervalId);
        const newIntervalId = setInterval(() => {
            getList('filter');
        }, 600000);
        setIntervalId(newIntervalId);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [filters]);

    useEffect(() => {
        if (currentTopBar === 2) {
            const timeOutId = setTimeout(() => {
                setPageDownload(1)
                getListDownload('filter');
            }, 500);

            return () => clearTimeout(timeOutId);
        }
    }, [isFocused, filters?.idUser, filters?.idPlan, filters?.searchTxt, filters?.periodStart, filters?.periodEnd]);

    useEffect(() => {
        if (isFocused && currentTopBar === 1) {
            const timeOutId = setTimeout(() => {
                setPage(1)
                getList('filter');
            }, 500);

            return () => clearTimeout(timeOutId);
        }

        return;
    }, [isFocused, filters?.periodType, filters?.idUser, filters?.idPlan, filters?.searchTxt, filters?.periodStart, filters?.periodEnd]);

    useEffect(() => {
        if (page !== 1) {
            getList('pagination');
        }
    }, [page]);

    useEffect(() => {
        if (pageDownload !== 1) {
            getListDownload('pagination');
        }
    }, [pageDownload]);

    useEffect(() => {
        dispatch(filtersGlobal({}));
    }, [currentTopBar]);

    function formatDate(dataString: string, type: 'start' | 'end') {
        var partesData = dataString.split('/');
        var date = new Date(partesData[2], partesData[1] - 1, partesData[0]);

        if (type === 'end') {
            date.setHours(23);
            date.setMinutes(59);
        }

        var resultDate = formatISO(date);
        return resultDate;
    }

    async function getListDownload(option: 'filter' | 'pagination') {
        setLoadingPagination(true);
        const formatPeriodStart = filters?.periodStart?.length === 10 ? formatDate(filters?.periodStart, 'start') : '';
        const formatPeriodEnd = filters?.periodEnd?.length === 10 ? formatDate(filters?.periodEnd, 'end') : '';
        const response = await getArtDownload(pageDownload, filters?.idUser, filters?.idPlan, filters?.searchTxt, formatPeriodStart, formatPeriodEnd);
        if (response.status === 200) {
            if (option === 'pagination') {
                setDataDownload({ ...dataDownload, ...response.result });
                setCardListDownload([...cardListDownload, ...response.result.list]);
            } else {
                setDataDownload({ ...response.result });
                setCardListDownload([...response.result.list]);
            }
        }
        setLoadingPagination(false);
    }

    async function getList(option: 'filter' | 'pagination') {
        setLoadingPagination(true);
        // setLoading(true);
        const formatPeriodStart = filters?.periodStart?.length === 10 ? formatDate(filters?.periodStart, 'start') : '';
        const formatPeriodEnd = filters?.periodEnd?.length === 10 ? formatDate(filters?.periodEnd, 'end') : '';
        const response = await getArtLogs(option === 'filter' ? 1 : page, filters?.periodType || 'published_at', filters?.idUser, filters?.idPlan, filters?.searchTxt, formatPeriodStart, formatPeriodEnd);
        if (response.status === 200) {
            if (option === 'pagination') {
                setData({ ...data, ...response.result });
                setCardList([...cardList, ...response.result.list]);
            } else {
                setData({ ...response.result });
                setCardList([...response.result.list]);
            }
        }
        setLoadingPagination(false);
        // setLoading(false);
    }

    function changePage() {
        setPage(page + 1);
    }

    function changePageDownload() {
        setPageDownload(pageDownload + 1);
    }

    function renderCards(list) {
        return (list || []).map((e) => {
            return (
                <Card
                    key={e.id}
                    item={e}
                    setModalVisible={setModalVisible}
                    setModalInfo={setModalInfo}
                    type="Minhas artes"
                    getList={getList}
                    isAdm
                    currentPage={currentTopBar === 1 ? 'logs' : 'download'}
                    setModalDetails={setModalDetails}
                    socialMediaVisibleHandle={socialMediaVisibleHandle}
                    setSocialMediaVisibleHandle={setSocialMediaVisibleHandle}
                    socialMediaVisibleBool={socialMediaVisibleBool}
                />
            );
        });
    }

    // if (loading) {
    //     return (
    //         <Loading />
    //     );
    // }

    function RenderModal() {
        if (title !== 'Continuar editando') {
            return (
                <TempleteModal
                    modalInfo={modalInfo}
                    setModalVisible={setModalVisible}
                    modalVisible={modalVisible}
                    updateList={getList}
                />
            )
        }

        return (
            <UseTemplateModal
                modalInfo={modalInfo}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                updateList={getList}
                typeMethod='edit'
            />
        )
    }

    function goToPublicationTracking() {
        navigation.navigate('PublicationTracking');
    }

    function renderButtonPagination(type: 'list' | 'listDownload') {
        if (type === 'list' ? data?.qtdPages === page || data?.amountPage === 0 : dataDownload?.qtdPages === pageDownload || dataDownload?.amountPage === 0) {
            return null;
        }

        if (loadingPagination) {
            return (
                <S.LoadingPaginationContainer>
                    <Loading />
                </S.LoadingPaginationContainer>
            );
        }

        return (
            <S.ButtonLoadMore onPress={type === 'list' ? changePage : changePageDownload}>
                <S.ButtonLoadMoreIcon name="arrow-down-circle" />
            </S.ButtonLoadMore>
        );
    }

    return (
        <S.Container>
            <S.ScrollView>
                <DashboardHeader title="Acompanhamento das publicações" typeHeader='logs' isInput notPeriod={currentTopBar === 2} goToScreen={goToPublicationTracking} />
                <S.ContainerAlign>
                    <TopTabs
                        listTabs={listTopTabs}
                        currentPosition={currentTopBar}
                        setCurrentPosition={setCurrentTopBar}
                        widthResponsive={400}
                    />
                    {currentTopBar === 1 ? (
                        <>
                            {!data?.amountPage ? null : (
                                <S.TextDefault style={{ margin: 20 }}>
                                    {data?.amountPage} templates disponíveis
                                </S.TextDefault>
                            )}
                            <S.Content width={width}>{renderCards(cardList)}</S.Content>
                            {renderButtonPagination('list')}
                        </>
                    ) : (
                        <>
                            {!dataDownload?.amountPage ? null : (
                                <S.TextDefault style={{ margin: 20 }}>
                                    {dataDownload?.amountPage} downloads
                                </S.TextDefault>
                            )}
                            <S.Content width={width}>{renderCards(cardListDownload)}</S.Content>
                            {!dataDownload?.amountPage ? null : renderButtonPagination('listDownload')}
                        </>
                    )}
                </S.ContainerAlign>
                {RenderModal()}
                <TempleteModal
                    modalInfo={modalInfo}
                    setModalVisible={setModalDetails}
                    modalVisible={modalDetails}
                    updateList={getList}
                    typeScreen="simple"
                    currentPage="logs"
                    isAdm
                    setSocialMediaVisibleHandle={setSocialMediaVisibleHandle}
                    setSocialMediaVisible={setSocialMediaVisible}
                />
            </S.ScrollView>
        </S.Container>
    );
}
