import styled from 'styled-components/native';

import { ResponsiveProps } from '../../global/types/index';

export const Container = styled.View``;

export const TableHeaderContainerArea = styled.View<ResponsiveProps>`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray[50]};
    flex-direction: row;
    height: 50px;
    justify-content: space-between;
    padding-left: ${({ width }) => (width > 600 ? '88px' : '8px')};
    width: 100%;
`;

export const PaginationArea = styled.View`
    align-items: center;
    align-self: flex-end;
    flex-direction: row;
    height: 75px;
    padding-right: 22px;
`;

export const TextDefault = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 16px;
`;

export const PaginationButton = styled.TouchableOpacity`
    align-items: center;
    border-radius: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;
