// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={14} height={16} {...props}>
    <G
      fill="none"
      stroke="#626477"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Path data-name="Caminho 8" d="M1 3.856h12" />
      <Path
        data-name="Caminho 9"
        d="M11.572 3.8v9.8a1.356 1.356 0 0 1-1.306 1.4H3.734a1.356 1.356 0 0 1-1.306-1.4V3.8m1.959 0V2.4A1.356 1.356 0 0 1 5.694 1h2.613a1.356 1.356 0 0 1 1.306 1.4v1.4"
      />
    </G>
  </Svg>
)

export default SvgComponent
