import styled from 'styled-components/native';
import Modal from 'react-native-modal';
import AntDesign from '@expo/vector-icons/AntDesign';
import FontAwesome from '@expo/vector-icons/FontAwesome';

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    width: 50%;
    min-width: 300px;
    border-radius: 6px;
    align-items: center;
    padding: 20px 20px 20px 20px;
`;

export const Title = styled.Text`
    color: ${({ theme }) => theme.colors.purple[500]};
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: 18px;
    margin-top: 12px;
    text-align: center;
`;

export const Description = styled.Text`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    margin-top: 16px;
    text-align: center;
`;

export const CoverText = styled.Text`
    color: ${({ theme }) => theme.colors.purple[500]};
    font-weight: bold;
`;

export const Button = styled.TouchableOpacity`
    background-color: ${({ theme }) => theme.colors.green[470]};
    height: 40px;
    padding: 0px 30px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
`;

export const ButtonText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
`;

export const ModalArea = styled(Modal)`
    align-items: center;
    margin: 0px;
`;

export const IconNavigation = styled(AntDesign)`
    font-size: 25px;
    color: ${({ theme }) => theme.colors.red[500]};
`;

export const IconFontAwesome = styled(FontAwesome)`
    font-size: 25px;
    color: ${({ theme }) => theme.colors.white};
    margin-right: 10px;
`;

export const TextButton = styled.Text`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.white};
`;

export const TouchableOpacity = styled.TouchableOpacity`
    align-self: flex-end;
    padding-right: 20px;
`;

export const ProgressBarContainer = styled.View`
  width: 100%;
  height: 15px;
  /* border: 1px solid #C3A5EB; */
  border-radius: 4px;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.gray[350]};
`;

export const ProgressBarFill = styled.View`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.purple[500]};
  transition: width 0.3s ease;
`;
