// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { ButtonLogout } from '../ButtonLogout';
import * as S from './styles';

interface DrawerCustomProps {
    children: any;
}

export function DrawerCustom(props: DrawerCustomProps) {
    const { user } = useSelector((state: any) => state.user.user);
    const { children } = props;
    const navigation = useNavigation();
    const width = useWindowDimensions().width;

    const drawerOpen = width <= 768;

    const [isOpen, setIsOpen] = useState(!drawerOpen);
    const [currentPage, setCurrentPage] = useState('ReportScreen');

    useEffect(() => {
        if (user?.type === 'adm') {
            if (window.location.pathname === '/DrawerNavigation/ReportScreen') {
                setCurrentPage('ReportScreen');
            }
            if (window.location.pathname === '/DrawerNavigation/DashboardScreen') {
                setCurrentPage('DashboardScreen');
            }
            if (window.location.pathname === '/DrawerNavigation/ManageClientsScreen') {
                setCurrentPage('ManageClientsScreen');
            }
            if (window.location.pathname === '/DrawerNavigation/PlansScreen') {
                setCurrentPage('PlansScreen');
            }
            if (window.location.pathname === '/DrawerNavigation/ManageArtsScreen') {
                setCurrentPage('ManageArtsScreen');
            }
            if (window.location.pathname === '/DrawerNavigation/ConfigDescriptionScreen') {
                setCurrentPage('ConfigDescriptionScreen');
            }
        }
    }, []);

    const userImage = {
        uri: user?.photo,
    };
    const userType = user?.type === 'adm' ? 'Administrador' : 'Cliente';

    const screens = [
        {
            id: 1,
            label: 'Financeiro',
            icon: 'dollar-sign',
            nameScreen: 'ReportScreen',
        },
        {
            id: 2,
            label: 'Dashboard',
            icon: 'home',
            nameScreen: 'DashboardScreen',
        },
        {
            id: 3,
            label: 'Gerenciar clientes',
            icon: 'briefcase',
            nameScreen: 'ManageClientsScreen',
        },
        {
            id: 4,
            label: 'Planos',
            icon: 'grid',
            nameScreen: 'PlansScreen',
        },
        {
            id: 5,
            label: 'Gerenciar artes',
            icon: 'film',
            nameScreen: 'ManageArtsScreen',
        },
        {
            id: 6,
            label: 'Configurações de descrição',
            icon: 'film',
            nameScreen: 'ConfigDescriptionScreen',
        },
        {
            id: 5,
            label: 'Acompanhamento de publicações',
            icon: 'film',
            nameScreen: 'PublicationTracking',
        },
        {
            id: 6,
            label: 'Conexões',
            icon: 'film',
            nameScreen: 'ConnectionsScreen',
        },
    ];

    function handleOpenDrawer() {
        setIsOpen(!isOpen);
    }

    function renderButtonNavigation() {
        return screens.map((screen) => {
            const { id, label, icon, nameScreen } = screen;
            const isFocused = currentPage === nameScreen;

            function handleNavigation() {
                if (drawerOpen) {
                    setIsOpen(false);
                }
                navigation.navigate(nameScreen);
                setCurrentPage(nameScreen);
            }

            function renderLabelNavigation() {
                if (!isOpen) {
                    return null;
                }

                return (
                    <S.TextNavigation isFocused={isFocused}>
                        {label}
                    </S.TextNavigation>
                );
            }

            return (
                <S.ButtonNavigation
                    key={id}
                    onPress={handleNavigation}
                    isFocused={isFocused}>
                    <S.IconContainer isOpen={isOpen}>
                        <S.IconNavigation name={icon} isFocused={isFocused} />
                    </S.IconContainer>
                    {renderLabelNavigation()}
                </S.ButtonNavigation>
            );
        });
    }

    function renderNavigationContainer() {
        if (width <= 769 && !isOpen) {
            return null;
        }

        function renderInfos() {
            if (!isOpen) {
                return null;
            }

            return (
                <S.Infos>
                    <S.Name>{user?.name}</S.Name>
                    <S.Permission>{userType}</S.Permission>
                </S.Infos>
            );
        }

        return (
            <S.Content>
                <S.UserContainer onPress={handleNavigateProfile}>
                    <S.UserImage onContextMenu={(e) => e.preventDefault()} source={userImage} />
                    {renderInfos()}
                </S.UserContainer>
                <S.NavigationContainer width={width}>
                    {renderButtonNavigation()}
                </S.NavigationContainer>
                <ButtonLogout />
            </S.Content>
        );
    }

    function handleNavigateProfile() {
        navigation.navigate('EditPerfilScreen');
        setCurrentPage('EditPerfilScreen');
    }

    function renderIconMenu() {
        if (!drawerOpen) {
            return (
                <S.GoBackIcon isOpen={isOpen} />
            );
        }

        return (
            <S.MenuIcon name="menu" />
        );
    }

    return (
        <S.Container width={width}>
            <S.DrawerContainer isOpen={isOpen} width={width}>
                <S.HeaderContainer isOpen={isOpen} width={width}>
                    <S.Logo isOpen={isOpen} width={width} />
                    <S.GoBackButton onPress={handleOpenDrawer}>
                        {renderIconMenu()}
                    </S.GoBackButton>
                </S.HeaderContainer>
                {renderNavigationContainer()}
            </S.DrawerContainer>
            {children}
        </S.Container>
    );
}
