// @ts-nocheck
import React, { createElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { addDays, addHours, lightFormat } from 'date-fns';

import CalendarIcon from '../../assets/images/svg/calendarIcon';
import ClockIcon from '../../assets/images/svg/clockIcon';
import Theme from '../../global/styles/theme';
import { createUsedArt, scheduledArt, updateEditArt } from '../../services/arts';
import * as S from './styles';

import { CoditionModal } from '../../components/CoditionModal';
import { LoadingModal } from '../../components/LoadingModal';
import { InfoModal } from '../../components/TemplateModal/components/InfoModal';
import { Modal } from '../../components/Modal';
import { TypeSocialModal } from '../../components/TypeSocialModal';

interface SchedulingModalProps {
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    idVehicle: number,
    idArt: number,
    setUseTemplateModalVisible: (modalVisible: boolean) => void,
    setTemplateModalVisible?: (modalVisible: boolean) => void,
    updateList: () => void,
    type?: string,
    initialSchedule?: any,
    description?: string,
    previewThumb?: string,
    photoVehicleSelected?: string,
    logoSelected?: string,
    direct: boolean,
    getUrlPhotos: () => void,
    selectedTemplateOption: any
    setModePublication?: () => void,
    titleVehicle: string,
    texts: Array<string>,
}

interface CreateUsedArtProps {
    dateSend: string,
    isFinished: boolean,
    idArt: number,
    idUser: number,
    idVehicle: number,
}

interface ModalCoditionProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    buttonFunction: () => void,
    updateList: () => void,
    handleBackdropPress: () => void;
}

interface ContactModalProps  {
    description: string,
}

export function SchedulingModal(props: SchedulingModalProps) {
    const {
        photoVehicleSelected,
        logoSelected,
        modalVisible,
        setModalVisible,
        idVehicle,
        description,
        previewThumb,
        idArt,
        setUseTemplateModalVisible,
        setTemplateModalVisible,
        type,
        initialSchedule,
        updateList,
        direct,
        getUrlPhotos,
        selectedTemplateOption,
        handleBackdropPress,
        setDateCurrent,
        setHourCurrent,
        setTypeSocialCurrent,
        setModePublication,
        titleVehicle,
        texts,
    } = props;
    const purpleFontColor = Theme.colors.purple[500];
    const whiteFontColor = Theme.colors.white;
    const maskType = { mask: '99:99' };
    const userInfo = useSelector((state) => state.user.user);
    const getHour = initialSchedule ? lightFormat(addHours(new Date(initialSchedule), 3), 'HH:mm') : '';

    const [hour, setHour] = useState('00:00');
    const [date, setDate] = useState(addDays(new Date(), 1));
    const [modalInfo, setModalInfo] = useState<ModalCoditionProps>({} as ModalCoditionProps);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [contactModalInfo, setContactModalInfo] = useState<ContactModalProps>({} as ContactModalProps);
    const [socialMediaVisible, setSocialMediaVisible] = useState(false);
    const [typeSocial, setTypeSocial] = useState('');

    useEffect(() => {
        if (typeSocial && setTypeSocialCurrent) {
            setTypeSocialCurrent(typeSocial);
        }
    }, [typeSocial]);

    function renderModais(status, message, error, result) {
        if (status === 200 || status === 201) {
            if (handleBackdropPress) {
                handleBackdropPress();
            }
            setLoadingVisible(false);
            const modalProps: ModalCoditionProps = {
                typeModal: 'success',
                titleModal: 'Sucesso',
                descriptionModal: message,
                buttonText: 'Continuar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: closeAllModal,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        } else if (error === 'LIMIT') {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setLoadingVisible(false);

            setTimeout(() => {
                setContactModalVisible(true);
                setContactModalInfo(contactModalInfo);
            }, 500);
        } else {
            setLoadingVisible(false);
            const modalProps: ModalCoditionProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        }
    }

    async function handleSchedulingPost(body: CreateUsedArtProps) {
        setLoadingVisible(true);
        if (type === 'reschedule') {
            const response = await scheduledArt(direct ? null : idArt, userInfo.user.id, typeSocial, body.dateSend);
            renderModais(response.status, response.message, response.error, response.result);
        } else {
            const { status, message, error, result } = type !== 'edit' ? await createUsedArt(body) : await updateEditArt(idArt, body);
            renderModais(status, message, error, result);
        }
    }

    function openSocialModal() {
        setSocialMediaVisible(true);
        if (setModePublication) {
            setModePublication('scheduled');
        }
    }

    function publish(data?: any){
        setSocialMediaVisible(false);
        const formatTexts = (texts || []).map((v) => v.txt).filter((e) => e);
        const bodyData = `${date.toISOString().split("T")[0]} ${hour}`;
        const body = type !== 'edit' ? {
            dateSend: bodyData,
            isFinished: true,
            isSend: true,
            idArt: direct ? null : idArt,
            idUser: userInfo.user.id,
            idVehicle,
            description: description || '',
            previewThumb: selectedTemplateOption?.thumb ? selectedTemplateOption?.thumb : (data || previewThumb)?.map((v) => v.photo).join(',').replaceAll(',', '|') || '',
            photoVehicleSelected: (data || photoVehicleSelected)?.map((v) => v.photo).join(',').replaceAll(',', '|') || '',
            logoSelected,
            wasPublishedFromStock: direct || false,
            type: typeSocial,
            titleVehicle,
            texts: formatTexts,
        } : {
            dateSend: bodyData,
            isFinished: true,
            isSend: true,
            idUser: userInfo.user.id,
            idVehicle,
            description: description || '',
            previewThumb: selectedTemplateOption?.thumb ? selectedTemplateOption?.thumb : (data || previewThumb)?.map((v) => v.photo).join(',').replaceAll(',', '|') || '',
            photoVehicleSelected: (data || photoVehicleSelected)?.map((v) => v.photo).join(',').replaceAll(',', '|') || '',
            logoSelected,
            wasPublishedFromStock: direct || false,
            type: typeSocial,
            titleVehicle,
            texts: formatTexts,
        };

        handleSchedulingPost(body);
    }

    async function confirmSocialPress() {
        if (previewThumb && previewThumb[0]?.photo.includes('base64')) {
            setSocialMediaVisible(false);
            await getUrlPhotos().then((result) => {
                publish(result);
            });
        } else {
            publish();
        }
    }

    function closeAllModal() {
        changeModalInfoVisible();
        setModalVisible(false);
        setUseTemplateModalVisible(false);
        if (type !== 'edit' && type !== 'reschedule') {
            setTemplateModalVisible(false);
        } else {
            updateList();
        }
    }

    function hideModal() {
        setModalVisible(false);
    }

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalCoditionProps);
    }

    function DateTimePicker() {
        const saveDate = (event: any) => {
            if (event.target.value && new Date(event.target.value)) {
                setDate(new Date(event.target.value));
                if (setDateCurrent) {
                    setDateCurrent(new Date(event.target.value))
                }
            }
        }

        return createElement('input', {
            type: 'date',
            value: date.toISOString().split("T")[0],
            min: new Date(Date.now()).toISOString().split("T")[0],
            onChange: saveDate,
            required: true,
            style: S.InputStyle,
        });
    }

    return (
        <S.ContainerModal onBackdropPress={hideModal} isVisible={modalVisible}>
            <S.ModalArea>
                <S.TextDefault
                    fontSize={13}
                    fontFamily="bold"
                    color={purpleFontColor}
                >
                    Agendamento da publicação
                </S.TextDefault>
                <S.SchedulingInputArea marginTop={45}>
                    <CalendarIcon />
                    <DateTimePicker />
                </S.SchedulingInputArea>
                <S.SchedulingInputArea activeOpacity={1} marginTop={19}>
                    <ClockIcon />
                    <S.InputHour
                        type='custom'
                        placeholder='--:--'
                        value={hour}
                        options={maskType}
                        onChangeText={(e: any) => {
                            setHour(e);
                            if (setHourCurrent) {
                                setHourCurrent(e);
                            }
                        }}
                    />
                </S.SchedulingInputArea>
                <S.SchedulingButton onPress={openSocialModal}>
                    <S.TextDefault
                        fontSize={13}
                        fontFamily="regular"
                        color={whiteFontColor}
                    >
                        Agendar
                    </S.TextDefault>
                </S.SchedulingButton>
                <CoditionModal
                    titleModal={modalInfo.titleModal}
                    descriptionModal={modalInfo.descriptionModal}
                    typeModal={modalInfo.typeModal}
                    buttonText={modalInfo.buttonText}
                    modalVisible={modalInfo.modalVisible}
                    buttonFunction={modalInfo.buttonFunction}
                />
                <LoadingModal
                    visible={loadingVisible}
                    titleLoading='Agendando template...'
                />
                <Modal isVisible={contactModalVisible} closeModal={setContactModalVisible}>
                    <InfoModal userInfo={userInfo?.user} description={contactModalInfo.description} setModalVisible={setContactModalVisible} />
                </Modal>
                <Modal isVisible={socialMediaVisible} closeModal={setSocialMediaVisible}>
                    <TypeSocialModal onPressConfirm={confirmSocialPress} setTypeSocial={setTypeSocial} typeSocial={typeSocial} />
                </Modal>
            </S.ModalArea>
        </S.ContainerModal>
    );
}
