import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';

import { getFavoritesArts } from '../../../services/arts';
import { ContentCards } from './components/ContentCards';
import { Loading } from '../../../components/Loading';
import * as S from './styles';

interface Cardprops {
    id: number;
    id_plan: number;
    title: string;
    store_name: string;
    colors: Array<string>;
    use: string;
    type: string;
    thumb: string;
    is_bought: boolean;
    is_favorite: boolean;
    author_name: string;
    author_photo: string;
}
interface AllDataProps {
    bought: Cardprops[];
    favorite: Cardprops[];
    editing: Cardprops[];
}

export function SavedArts() {
    const userInfo = useSelector((state: any) => state.user.user);
    const isFocused = useIsFocused();

    const [allData, setAllData] = useState<AllDataProps>({} as AllDataProps);
    const [loading, setLoading] = useState(true);

    async function getAllFavorites() {
        setLoading(true);
        const response = await getFavoritesArts(userInfo?.user.id);
        if (response?.status === 200) {
            const favorite = response.result.favorite.map((e) => {
                const formatted = {
                    ...e,
                    is_favorite: true,
                };
                return formatted;
            });
            const data = {
                favorite,
                bought: response.result?.bought,
                editing: response.result?.editing,
            };
            setAllData(data);
        }
        setLoading(false);
    }

    useEffect(() => {
        getAllFavorites();
    }, [isFocused]);

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <S.Container>
            <ContentCards
                updateList={getAllFavorites}
                data={allData.editing}
                title="Continuar editando"
            />
            <ContentCards
                updateList={getAllFavorites}
                data={allData.bought}
                title="Modelos comprados"
            />
            <ContentCards
                updateList={getAllFavorites}
                data={allData.favorite}
                title="Modelos Favoritos"
            />
            <ContentCards
                updateList={getAllFavorites}
                data={allData.bought}
                title="Minhas artes"
            />
        </S.Container>
    );
}
