import styled, { css } from 'styled-components/native';
import { ResponsiveProps } from '../../../global/types/index';

import { Picker } from '@react-native-picker/picker';
import ImageModal from 'react-native-image-modal';

// interface PickerProps {
//     currentvalue: number;
// }

interface ContainerProps {
    width: string;
}

export const Form = styled.ScrollView<ResponsiveProps>`
    padding-right: ${({ width }) => width >= 800 ? 36 : 0};
`;

export const TextDefault = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 22px;
`;

export const ModalContent = styled(ImageModal)``;

export const ImageContainer = styled.TouchableOpacity<ResponsiveProps>`
`;

export const InputContainer = styled.View<ResponsiveProps>`
    flex-direction: ${({ width }) => (width < 767 ? 'column' : 'row')};
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px 0 40px;
`;

export const AddPhotoArea = styled.View`
    align-items: center;
    width: 100%;
`;

export const AddButtonPhoto = styled.TouchableOpacity`
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const PhotoUser = styled.Image`
    width: 112px;
    height: 112px;
    border-radius: 6px;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 8px;
    border: 1px ${({ theme }) => theme.colors.gray[150]};
`;

export const AddPhotoText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 10px;
    margin-top: 6px;
`;

export const PhotoArea = styled.View`
    align-items: center;
    margin-bottom: 70px;
`;

export const PickerContainer = styled(Picker)`
    background-color: ${({ theme }) => theme.colors.gray[300]};
    border-width: 0px;
    width: 100%;
    outline-width: 0px;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    ${({ theme }) => theme.colors.gray[400]};
`;

export const InputArea = styled.View<ContainerProps>`
    width: ${({ width }) => width || '100%'};
    margin-bottom: 16px;
`;

export const Label = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const InputContainerArea = styled.View`
    flex-direction: row;
    padding: 10px;
    margin-top: 6px;
    height: 40px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    align-items: center;
`;

export const FuelList = styled.View`
    flex-direction: row;
`;

export const FuelArea = styled.View`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.purple[200]};
    border-radius: 20px;
    flex-direction: row;
    height: 28px;
    margin-top: 12px;
    margin-left: 4px;
    margin-right: 4px;
    padding: 6px 12px;
`;

export const CloseButton = styled.TouchableOpacity`
    align-items: center;
    height: 15px;
    justify-content: center;
    width: 15px;
`;

export const CloseImage = styled.Image`
    height: 10px;
    margin-left: 12px;
    tint-color: ${({ theme }) => theme.colors.purple[500]};
    width: 10px;
`;

export const TextFuel = styled.Text`
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const ContainerInfo = styled.View<ResponsiveProps>`
    ${({ width }) => width >= 800 ?
        css`margin: 40px 16px 34px 60px;` : css`margin: 20px 20px;`}
`;

export const ContainerAlign = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    margin: 40px 0 20px;
    width: 95%;
`;

export const ScrollView = styled.ScrollView``;

export const BackArea = styled.View`
    align-items: center;
    flex-direction: row;
`;

export const GoBackButton = styled.TouchableOpacity`
    margin-right: 12px;
`;
