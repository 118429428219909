import styled, { css } from 'styled-components/native';

interface ContentProps {
    columns: number;
}

export const Header = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0px 40px;
    padding-right: 28px;
`;

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
`;

export const ButtonView = styled.TouchableOpacity``;

export const ButtonViewText = styled.View`
    color: ${({ theme }) => theme.colors.gray[150]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 18px;
`;

export const Content = styled.View<ContentProps>`
    display: grid;
    gap: 18px;
    padding-right: 28px;
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
`;

export const TextDefault = styled.Text`
    color: ${({ theme }) => theme.colors.gray[900]};
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: 25px;
`;
