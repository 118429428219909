import styled from 'styled-components/native';

interface TextDefaultProps {
    fontSize: number,
    marginTop?: number,
    marginBottom?: number,
    textAlign?: string,
    fontFamily?: 'semi' | 'regular',
}

export const StatiticsContainer = styled.View`
    height: 174px;
`;

export const TextDefault = styled.Text<TextDefaultProps>`
    color: ${({ theme }) => theme.colors.lowBlack};
    font-family: ${({ fontFamily, theme }) => fontFamily === 'regular' ? theme.font.segoeUi.regular : theme.font.segoeUi.semiBold};
    font-size: ${({ fontSize }) => fontSize};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    margin-top: ${({ marginTop }) => marginTop || 0};
    text-align: ${({ textAlign }) => textAlign || 'auto'};
`;

export const TitleArea = styled.View`
    align-items: center;
    flex-direction: row;
`;

export const TitleIconArea = styled.View`
    margin-left: 8px;
`;
