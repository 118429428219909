// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import * as DocumentPicker from 'expo-document-picker';
import toast from 'react-hot-toast';

import { NavigationProps } from '../../../routes/index';
import { importWithXml } from '../../../services/user';
import themeGlobal from '../../../global/styles/theme';
import * as S from './styles';
import { clientsList } from '../../../services/clients';

import { Table } from '../../../components/Table';
import { InputSearch } from '../../../components/InputSearch';
import { DashboardHeader } from '../../../components/DashboardHeader';
import { LoadingModal } from '../../../components/LoadingModal';
import { Loading } from '../../../components/Loading/index';
import { useSelector } from 'react-redux';
import { connectionsList } from '../../../services/connections';
import { useForm } from 'react-hook-form';
import { getPlans } from '../../../services/plans';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaFiltersConnections } from '../../../global/utils/formsSchems';
import { InputInternal } from '../../../components/InputInternal';

interface ConnectionsScreen extends NavigationProps {}

interface InfoTableProps {
    amount: number;
    list: Array<any>;
    qtdPages: number;
}

export function ConnectionsScreen(props: ConnectionsScreen) {
    const window = useWindowDimensions().width;
    const userInfo = useSelector((state) => state.user.user);
    const isFocus = useIsFocused();
    const resolver = { resolver: yupResolver(schemaFiltersConnections), mode: 'onChange' };
    const { control, watch, formState: { errors } } = useForm(resolver);

    const [searchTxt, setSearchTxt] = useState('');
    const [infoTable, setInfoTable] = useState<InfoTableProps>(
        {} as InfoTableProps,
    );
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [loadingModalVisible, setLoadingModalVisible] = useState(false);
    const [plansList, setPlansList] = useState<any[]>([]);
    const statusList = [
        {
            label: 'Todos os status',
            value: '',
        },
        {
            label: 'Nunca fez conexão',
            value: 'no_expiration',
        },
        {
            label: '3 dias para expirar',
            value: 'expiring_soon',
        },
        {
            label: 'Conexão expirada',
            value: 'expired',
        },
    ];

    const filters = watch();

    useEffect(() => {
        if (isFocus) {
            setLoading(false);
            getListClient();
        }
    }, [isFocus, pagination]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListClient(searchTxt, filters);
        }, 500);

        return () => clearTimeout(timeOutId);
    }, [searchTxt, filters?.idPlan, filters?.status]);

    useEffect(() => {
        getPlansList();
    }, []);

    async function getListClient(searchTxt?: string) {
        const { result } = await connectionsList(pagination, searchTxt, filters?.status || '', filters?.idPlan || '',);
        setInfoTable(result);
        setLoading(true);
    }

    async function getPlansList() {
        const response = await getPlans(1, '', '');

        if (response.status === 200) {
            const labelDropdown = [
                { label: 'Todos os planos', value: '' },
            ];
            const listFormatted = response.result?.list.map((e) => {
                const formatted = {
                    label: e.name,
                    value: String(e.id),
                };
                return formatted;
            });
            setPlansList([...labelDropdown, ...listFormatted]);
        }
    }

    function RenderContent() {
        if (!loading) {
            return (
                <Loading />
            );
        }

        return (
            <S.ScrollView>
                <S.InputSearchArea width={window}>
                    <InputSearch
                        value={searchTxt}
                        setValue={setSearchTxt}
                        placeholder="Buscar por nome ou e-mail"
                        marginLeft={0}
                        widthArea={235}
                    />
                    <InputInternal
                        inicialValue={''}
                        widthArea={235}
                        width={235}
                        label=""
                        placeholder="Selecione um plano"
                        control={control}
                        errors={errors}
                        name="idPlan"
                        list={plansList}
                        backgroundColorInput="white"
                        height={50}
                        marginTopInput='0px'
                    />
                    <InputInternal
                        inicialValue={''}
                        widthArea={235}
                        width={235}
                        label=""
                        placeholder="Selecione um status"
                        control={control}
                        errors={errors}
                        name="status"
                        list={statusList}
                        backgroundColorInput="white"
                        height={50}
                        marginTopInput='0px'
                    />
                </S.InputSearchArea>
                <S.ContainerAlign>
                    <Table
                        data={infoTable?.list || []}
                        type="connections"
                        getAllClient={getListClient}
                        totalItem={infoTable?.amount || 0}
                        qtdPage={infoTable?.qtdPages || 1}
                        currentPage={pagination}
                        setCurrentPage={setPagination}
                        widthTable={window < 800 ? 800 : undefined}
                    />
                </S.ContainerAlign>
            </S.ScrollView>
        );
    }

    return (
        <S.Container>
            {RenderContent()}
        </S.Container>
    )
}
