import React from 'react';
import { useNavigation } from '@react-navigation/native';

import { Title } from '../Title';
import * as S from './styles';

interface HeaderWithGobackProps {
    label: string;
}

export function HeaderWithGoback(props: HeaderWithGobackProps) {
    const { label } = props;
    const navigation = useNavigation();

    function handleGoback() {
        navigation.navigate('LoginScreen', { autoFocus: true });
    }

    return (
        <S.Container>
            <S.ButtonGoBack onPress={handleGoback}>
                <S.IconGoback name="arrow-left" />
            </S.ButtonGoBack>
            <Title label={label} />
        </S.Container>
    );
}
