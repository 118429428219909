// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Circle } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={4} height={18} {...props}>
    <G
      transform="translate(-10 -3)"
      fill="none"
      stroke="#a5a5a5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Circle
        data-name="Elipse 3"
        cx={1}
        cy={1}
        r={1}
        transform="translate(11 11)"
      />
      <Circle
        data-name="Elipse 4"
        cx={1}
        cy={1}
        r={1}
        transform="translate(11 4)"
      />
      <Circle
        data-name="Elipse 5"
        cx={1}
        cy={1}
        r={1}
        transform="translate(11 18)"
      />
    </G>
  </Svg>
)

export default SvgComponent
