import styled from 'styled-components/native';
import ImageModal, { ImageDetail } from 'react-native-image-modal';

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    width: 250px;
    margin-top: 40px;
`;

export const Title = styled.Text`
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.gray[800]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 250px;
`;

export const TitleArt = styled.Text`
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.gray[450]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 15px;
`;

export const SubTitle = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    margin-right: 5px;
`;

export const ImageCard = styled.Image`
    width: 250px;
    height: 250px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[25]}; ;
`;

export const MoreOptionButton = styled.TouchableOpacity`
    width: 16%;
    align-items: flex-end;
    margin-left: -30px;
`;

export const OptionArea = styled.View`
    align-items: center;
    flex-direction: row;
    margin-bottom: 8px;
    padding-left: 16px;
`;

export const OptionText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
    margin-left: 16px;
`;

export const optionsContainerStyle = {
    paddingBottom: 8,
    paddingTop: 16,
    width: 135,
};

export const buttonStyle = {
    alignItems: 'flex-end',
    marginRight: 12,
    width: 40,
};

export const InfoArea = styled.View`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
`;

export const AdjustTitles = styled.View`
    flex-direction: column;
    display: flex;
    width: 100%;
`;

export const TitleArea = styled.View`
    flex-direction: row;
    align-items: center;
    max-width: 100%;
`;

export const AdjustSubTitle = styled.View`
    flex-direction: row;
`;

export const ModalContent = styled(ImageModal)``;
