// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <G
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Path data-name="Linha 3" d="M7 1v12" />
      <Path data-name="Linha 4" d="M1 7h12" />
    </G>
  </Svg>
)

export default SvgComponent
