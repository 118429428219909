// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Circle, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={55} height={55} {...props}>
    <G
      data-name="x-circle (2)"
      transform="translate(-.5 -.5)"
      fill="none"
      stroke="#de3839"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <Circle
        data-name="Elipse 44"
        cx={26}
        cy={26}
        r={26}
        transform="translate(2 2)"
      />
      <Path data-name="Linha 361" d="M36 20 20 36" />
      <Path data-name="Linha 362" d="m20 20 16 16" />
    </G>
  </Svg>
)

export default SvgComponent
