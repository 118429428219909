import styled, { css } from 'styled-components/native';
import { ResponsiveProps } from '../../../global/types';
import Feather from '@expo/vector-icons/Feather';

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.gray[300]};
    flex: 1;
`;

export const TextDefault = styled.Text`
    color: ${({ theme }) => theme.colors.gray[800]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 22px;
`;

export const ContainerAlign = styled.ScrollView<ResponsiveProps>`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 20px;
    margin-top: 40px;
    width: 95%;
    ${({ width }) => width >= 800 ? 
        css`padding: 28px 60px 40px` : css`padding: 20px 20px`};
`;

export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    /* position: absolute;
    top: 0px;
    left: 0px; */
`;

export const ButtonUpdate = styled.TouchableOpacity`
    width: 50px;
    height: 30px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    align-items: center;
    justify-content: center;
`;

export const ButtonUpdateText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
`;

export const Content = styled.View<ResponsiveProps>`
    display: grid;
    ${({ width }) =>
        width > 1800 &&
        css`
            grid-template-columns: repeat(5, 1fr);
        `}
    ${({ width }) =>
        width >= 1520 &&
        width <= 1800 &&
        css`
            grid-template-columns: repeat(4, 1fr);
        `}
    ${({ width }) =>
        width >= 1200 &&
        width <= 1520 &&
        css`
            grid-template-columns: repeat(3, 1fr);
        `}
    ${({ width }) =>
        width >= 880 &&
        width <= 1200 &&
        css`
            grid-template-columns: repeat(2, 1fr);
        `}
    gap: ${({ width }) => (width < 880 ? '50px' : '0px')};
`;

export const LoadingPaginationContainer = styled.View`
    margin-top: 20px;
    align-self: center;
`;

export const ButtonLoadMore = styled.TouchableOpacity`
    margin-top: 20px;
    align-self: center;
`;

export const ButtonLoadMoreIcon = styled(Feather)`
    color: ${({ theme }) => theme.colors.purple[500]};
    font-size: 30px;
`;
