import React from 'react';
import * as S from './styles';

import PaymentIcon from '../../../../../assets/images/svg/paymentIcon';
import FavoriteUncheckedIcon from '../../../../../assets/images/svg/heart';

interface ItemProps {
    id: number;
    id_plan: number;
    title: string;
    store_name: string;
    colors: Array<string>;
    use: string;
    type: string;
    thumb: string;
    is_bought: boolean;
    is_favorite: boolean;
    author_name: string;
    author_photo: string;
}

interface CardProps {
    item: ItemProps;
    setModalVisible: (modalVisible: boolean) => void;
    setModalInfo: (modalInfo: ItemProps) => void;
    type: 'Modelos comprados' | 'Modelos Favoritos' | 'Continuar editando' | 'Minhas artes';
}

export function Card(props: CardProps) {
    const {
        item: { title, thumb },
        setModalInfo,
        setModalVisible,
        type,
    } = props;
    const uri = { uri: thumb };

    const is_bought = type === 'Modelos comprados';
    const is_favorite = type === 'Modelos Favoritos';

    function handleTemplatePress() {
        setModalInfo(props.item);
        setModalVisible(true);
    }

    function RenderPayment() {
        if (!is_bought) {
            return null;
        }

        return (
            <S.PaymentIconArea>
                <PaymentIcon />
            </S.PaymentIconArea>
        );
    }

    function renderFavorite() {
        if (!is_favorite) {
            return null;
        }

        return (
            <S.FavoriteButton>
                <FavoriteUncheckedIcon />
            </S.FavoriteButton>
        );
    }

    return (
        <S.Container onPress={handleTemplatePress}>
            <S.ImageArea>
                <S.ImageCard onContextMenu={(e) => e.preventDefault()} source={uri} />
                {RenderPayment()}
                {renderFavorite()}
            </S.ImageArea>
            <S.Title>{title}</S.Title>
        </S.Container>
    );
}
