import React from 'react';
import * as S from './styles';

interface ModalProps {
    isVisible: boolean;
    closeModal: (value: boolean) => void;
    children: JSX.Element;
    disabledClick?: boolean;
}

export function Modal(props: ModalProps) {
    const { isVisible, closeModal, children, disabledClick } = props;

    function handleCloseModal() {
        if (disabledClick) {
            return null;
        }
        return closeModal(!isVisible);
    }

    return (
        <S.Container backdropOpacity={0.4} isVisible={isVisible} onBackdropPress={handleCloseModal}>
            {children}
        </S.Container>
    );
}
