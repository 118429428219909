// @ts-nocheck
import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { TempleteModal } from '../../../../../components/TemplateModal';
import { EmptyComponent } from '../EmptyComponent';
import { renderCards } from './utils';
import { Card } from '../Card';
import * as S from './styles';

interface DataProps {
    id: number;
    id_plan: number;
    title: string;
    store_name: string;
    colors: Array<string>;
    use: string;
    type: string;
    thumb: string;
    is_bought: boolean;
    is_favorite: boolean;
    author_name: string;
    author_photo: string;
}

interface ContentCardsProps {
    title: 'Modelos comprados' | 'Modelos Favoritos' | 'Continuar editando' | 'Minhas artes';
    data: DataProps[];
    updateList: () => void;
}

export function ContentCards(props: ContentCardsProps) {
    const { title, data, updateList} = props;
    const width = useWindowDimensions().width;
    const navigation = useNavigation();
    const dataLength = data?.length;
    const columns = renderCards(width);
    const [modalInfo, setModalInfo] = useState<DataProps>({} as DataProps);
    const [modalVisible, setModalVisible] = useState(false);

    function renderItem() {
        return (data || []).map((e, i) => {
            if (i + 1 > columns) {
                return null;
            }
            return (
                <Card
                    setModalVisible={setModalVisible}
                    setModalInfo={setModalInfo}
                    type={title}
                    item={e}
                    key={e.id}
                />
            );
        });
    }

    function handleNavigate() {
        navigation.navigate('SavedArtsList', { title });
    }

    function renderButton() {
        if (dataLength > columns) {
            return (
                <S.ButtonView onPress={handleNavigate}>
                    <S.ButtonViewText>Ver tudo</S.ButtonViewText>
                </S.ButtonView>
            );
        }
    }

    function updatingList() {
        updateList()
    }

    if (dataLength === 0) {
        return (
            <EmptyComponent />
        );
    }

    return (
        <S.Container>
            <S.Header>
                <S.TextDefault>{title}</S.TextDefault>
                {renderButton()}
            </S.Header>
            <S.Content columns={columns}>{renderItem()}</S.Content>
            <TempleteModal
                modalInfo={modalInfo}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                updateList={updatingList}
            />
        </S.Container>
    );
}
