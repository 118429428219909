// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { Card } from './components/Card';
import { Loading } from '../../../components/Loading';
import { TempleteModal } from '../../../components/TemplateModal';

import { userFavoriteArt } from '../../../services/user';
import themeGlobal from '../../../global/styles/theme';
import { getArtList, getUsedArtCodition, getArtListBirthDay } from '../../../services/arts';
import * as S from './styles';
import { UseTemplateModal } from '../../../components/UseTemplateModal';
import { Modal } from '../../../components/Modal';
import { InfoModal } from '../../../components/TemplateModal/components/InfoModal';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface ItemProps {
    id: number;
    id_plan: number;
    title: string;
    store_name: string;
    colors: Array<string>;
    use: string;
    type: string;
    thumb: string;
    is_bought: boolean;
    is_favorite: boolean;
    author_name: string;
    author_photo: string;
}

interface BirthDaysProps {
    age: number;
    birth_date: string;
    city: string;
    id: number;
    name: string;
    is_send: boolean;
}

interface DataProps {
    amount: number;
    list: ItemProps[];
}

interface HomeProps {
    title: 'Modelos' | 'Publicações',
    idVehicle?: string,
}

interface ContactModalProps  {
    description: string,
}

interface HomeScreenProps {
    birthday: boolean;
    birthInfo: BirthDaysProps;
    closeModalBirth: () => void;
    currentTabPositionBirth: number;
    disabledBirthayToBar?: (enabled: boolean) => void;
}

const typeBirthdays = {
    1: 'all',
    2: 'user',
    3: 'favorite',
}

export function HomeScreen(props: HomeScreenProps, handleOpenDrawer: any, drawerIsOpen: any) {
    const { birthday = false, birthInfo, closeModalBirth, currentTabPositionBirth, disabledBirthayToBar } = props;
    const { user } = useSelector((state: any) => state.user.user);
    const filters = useSelector((state: any) => state.user.filtersGlobal);
    const width = useWindowDimensions().width;
    const isFocused = useIsFocused();
    const { params } = useRoute();

    const [data, setData] = useState<DataProps>({} as DataProps);
    const [cardList, setCardList] = useState<ItemProps[]>([] as ItemProps)
    const [modalInfo, setModalInfo] = useState<ItemProps>({} as ItemProps);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingPagination, setLoadingPagination] = useState(true);
    const [nameScreen, setNameScreen] = useState('');
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [contactModalInfo, setContactModalInfo] = useState<ContactModalProps>({} as ContactModalProps);
    const [direct, setDirect] = useState(false);
    const [useModalVisible, setUseModalVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [idVehicle, setIdVehicle] = useState<any>(null);

    useEffect(() => {
        const condition = width <= 860 ? !drawerIsOpen : true;
        if (condition) {
            screenCodition();
        }
    }, [params]);

    async function screenCodition() {
        if (params) {
            const { title } = params as HomeProps;

            if (title === 'Publicações') {
                setNameScreen(title);
                setDirect(true);
                setUseModalVisible(true);
                setIdVehicle(params?.idVehicle);
                const isPublication = await AsyncStorage.getItem(
                    '@midiacarros:publication_routes',
                );
                if (isPublication) {
                    await AsyncStorage.removeItem('@midiacarros:publication_routes');
                }

            } else {
                setNameScreen(title);
            }
        }
    }

    function changePage() {
        setPage(page + 1);
    }

    function blockedTopBarBirthdayScreen(option: boolean) {
        if (disabledBirthayToBar) {
            disabledBirthayToBar(option);
        }
    }

    async function getList(option: 'filter' | 'pagination') {
        option === 'pagination' ? setLoadingPagination(true) : setLoading(true);
        blockedTopBarBirthdayScreen(true)
        // setCardList([]);
        const response = !birthday
            ? await getArtList(
                  page,
                  'all',
                  user.id,
                  filters?.searchTxt || '',
                  filters?.artType || '',
                  Number(filters?.idEvent || 0),
                  filters?.idsColors || '',
              )
            : await getArtListBirthDay(
                  page,
                  typeBirthdays[currentTabPositionBirth],
                  user.id,
                  filters?.searchTxt || '',
                  filters?.artType || '',
                  Number(filters?.idEvent || 0),
                  filters?.idsColors || '',
              );
        if (response.status === 200) {
            if (option === 'pagination') {
                setData({ ...data, ...response.result });
                setCardList([...cardList, ...response.result.list]);
                blockedTopBarBirthdayScreen(false)
            } else {
                setData({ ...response.result });
                setCardList([...response.result.list]);
                blockedTopBarBirthdayScreen(false)
            }
        }
        setLoading(false);
        setLoadingPagination(false);
    }

    async function handleFavorite(idArt: number, option: boolean) {
        const response = await userFavoriteArt(user.id, idArt, option);
        if (response.status === 200) {
            toast.success(response.message, themeGlobal.toast);
            getList();
        } else {
            toast.error(response.message, themeGlobal.toast);
        }
    }

    function calcSize() {
        if (birthday) {
            return width < 650 ? (width < 600 ? (width < 530 ? (width < 330 ? (width < 300 ? 150 : 80) : 100) : 150) : 180) : (width > 750 ? 250 : 200);
        }
        return width < 600 ? (width < 470 ? (width < 370 ? (width < 300 ? 200 : 100) : 150) : 200) : 250;
    }

    function renderCards() {
        return (cardList || []).map((e) => {
            return (
                <Card
                    key={e.id}
                    item={e}
                    setModalVisible={birthday ? setUseModalVisible : setModalVisible}
                    setModalInfo={setModalInfo}
                    handleFavorite={handleFavorite}
                    sizeCard={calcSize()}
                />
            );
        });
    }

    function renderButtonPagination() {
        if (data.qtdPages === page || data?.amount === 0) {
            return null;
        }

        if (loadingPagination) {
            return (
                <S.LoadingPaginationContainer>
                    <Loading />
                </S.LoadingPaginationContainer>
            );
        }

        return (
            <S.ButtonLoadMore onPress={changePage}>
                <S.ButtonLoadMoreIcon name="arrow-down-circle" />
            </S.ButtonLoadMore>
        );
    }

    useEffect(() => {
        if (isFocused) {
            const timeOutId = setTimeout(() => {
                setPage(1)
                getList('filter');
            }, 500);

            return () => clearTimeout(timeOutId);
        }

        return;
    }, [isFocused, filters?.searchTxt, filters?.artType, filters?.idEvent, filters?.idsColors, currentTabPositionBirth]);

    useEffect(() => {
        if (page !== 1) {
            getList('pagination');
        }
    }, [page]);

    if (loading && (width <= 860 ? !drawerIsOpen : true)) {
        return (
            <Loading />
        );
    }

    function RenderContentInfo() {
        if (nameScreen === 'Publicações' || useModalVisible) {
            return (
                <>
                    <UseTemplateModal
                        modalVisible={useModalVisible}
                        setModalVisible={setUseModalVisible}
                        modalInfo={birthday ? modalInfo : {}}
                        birthInfo={birthInfo}
                        // updateList={updateList}
                        direct={direct}
                        setTemplateModalVisible={setModalVisible}
                        birthday={birthday}
                        closeModalBirth={closeModalBirth}
                        handleOpenDrawer={handleOpenDrawer}
                        typeMethod={birthday ? null : "publishStock"}
                        idVehicle={idVehicle}
                    />
                    <Modal isVisible={contactModalVisible} closeModal={setContactModalVisible}>
                        <InfoModal userInfo={user} description={contactModalInfo.description} setModalVisible={setContactModalVisible} />
                    </Modal>
                </>
            );
        }

        return (
            <>
                <S.ContainerAlign>
                    <S.TextDefault>
                        {data?.amount} templates disponíveis
                    </S.TextDefault>
                    <S.Content width={width}>{renderCards()}</S.Content>
                    {renderButtonPagination()}
                </S.ContainerAlign>
                <TempleteModal
                    modalInfo={modalInfo}
                    setModalVisible={setModalVisible}
                    modalVisible={modalVisible}
                    updateList={getList}
                    typeScreen={nameScreen}
                />
            </>
        );
    }

    return (
        <S.Container width={width}>
            {RenderContentInfo()}
        </S.Container>
    );
}
