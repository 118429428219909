import styled from "styled-components/native";

interface TitleHeaderProps {
    width: string,
    height?: number,
}

interface TitleHeaderTextProps {
    type: 'header' | 'table',
    color?: string,
    fontFamily?: string,
    fontSize?: number,
}

export const TitleHeaderArea = styled.View<TitleHeaderProps>`
    height: ${({ height }) => height || 40} ;
    justify-content: center;
    padding-left: 8px;
    width: ${({ width }) => width};
`;

export const TitleHeaderText = styled.Text<TitleHeaderTextProps>`
    color: ${({ theme, type, color }) => (color || (type === 'header' ? theme.colors.white : theme.colors.lowBlack))};
    font-family: ${({ theme, fontFamily }) => fontFamily || theme.font.segoeUi.semiBold};
    font-size: ${({ fontSize }) => fontSize || 16};
`;
