import styled from 'styled-components/native';
import theme from '../../global/styles/theme';

export const Container = styled.TouchableOpacity`
    background-color: ${({ theme }) => theme.colors.purple[500]};
    height: 50px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
`;

export const Label = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 16px;
`;

export const Loading = styled.ActivityIndicator.attrs({
    color: theme.colors.white,
})``;
