import styled, { css } from 'styled-components/native';
import Feather from '@expo/vector-icons/Feather';

import { ResponsiveProps } from '../../global/types/index';
import theme from '../../global/styles/theme';
import SearchIcon from '../../assets/images/svg/searchIcon';

interface ContainerSearchProps extends ResponsiveProps {
    searchOpen: boolean;
}

interface FilterAreaProps extends ResponsiveProps {
    marginTop?: number,
    marginRight?: number,
    existsColorTertiary?: string,
    isVisibleModal?: boolean,
    zIndex?: number,
}

interface DropdownAreaProps extends FilterAreaProps {
    topNumber?: number,
    widthNumber?: number,
}

interface ContainerProps extends FilterAreaProps {
    isFocus?: boolean,
}

export const Container = styled.View<ContainerProps>`
    background-color: ${({ theme }) => theme.colors.white};
    padding: 24px 18px 18px 26px;
    flex-direction: ${({ width }) => width <= 900? 'column' : 'row'};
    align-items: ${({ width }) => width <= 900? '' : 'center'};
    justify-content: space-between;
    width: '100%';
    box-shadow: 0px 3px 6px #00000029;
    height: ${({ width, isFocus }) => width <= 900 ? ( width < 860 && isFocus ? 'auto' : (width < 400 ? '220px' : '120px')) : 'auto'};
`;

export const HeaderContent = styled.View<ContainerProps>`
    flex-direction: row;
    align-items: center;
    justify-content: ${({ width }) => width <= 260 ? 'center' : 'space-between'};
    flex-wrap: wrap;
    flex: ${({ width, isFocus }) => width < 860 && isFocus ? 'none' : 1};
`;

export const ButtonDrawerContainer = styled.View`
    flex-direction: row;
    align-items: center;
`;

export const SearchContainer = styled.View`
    flex-direction: row;
    align-items: center;
`;

export const Button = styled.TouchableOpacity``;

export const MenuIcon = styled(Feather)`
    color: ${({ theme }) => theme.colors.purple[500]};
    font-size: 28px;
    margin-right: 10px;
`;

export const ContainerSearch = styled.View<ContainerSearchProps>`
    flex-direction: row;
    align-items: center;
    ${({ searchOpen }) =>
        searchOpen &&
        css`
            justify-content: flex-end;
        `}
`;

export const InputContainer = styled.View<ContainerSearchProps>`
    align-self: flex-end;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    flex-direction: row;
    align-items: center;
    padding: 11px 4px 11px 20px;
    width: ${({ width }) => width || '100%'};
    margin-right: 12px;
    ${({ searchOpen }) =>
        searchOpen &&
        css`
            flex: 1;
            justify-content: flex-end;
        `}
`;

export const SearchAreea = styled.View`
    height: 40px;
    width: 235px;
`;

export const ContainerLogo = styled.View``

export const OptionArea = styled.View<ResponsiveProps>`
    justify-content: center;
    flex: 1;
    flex-direction: row;
    margin-top: ${({ width }) => width <= 900? '10px' : '0px'};
`;

export const ButtonSearch = styled.TouchableOpacity`
    margin-left: 20px;
`;

export const SearchIconInput = styled(SearchIcon).attrs({
    stroke: theme.colors.gray[150],
})`
    width: 50px;
`;

interface NewClientButtonInterfaces {
    width: number;
    openFilters?: boolean;
}

export const NewClientButton = styled.TouchableOpacity<NewClientButtonInterfaces>`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 6px;
    padding: 14px 20px;
    flex-direction: row;
    height: 40px;
    margin-top: ${({ openFilters, width }) => openFilters ? '20px' : (width < 400 ? '5px' : '0px')};
    margin-right: ${({ openFilters }) => openFilters ? '0px' : '5px'};
`;

export const AddClientText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: 16px;
    margin-left: 10px;
`;

export const UserImage = styled.Image`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 28px;
`;

export const IconClose = styled(Feather)`
    color: ${({ theme }) => theme.colors.gray[150]};
    font-size: 28px;
    margin-right: 10px;
`;

export const ScrollPicker = styled.ScrollView`
    max-height: 200;
    max-width: 270;
`;

export const ScrollFilters = styled.ScrollView`
    max-width: 70%;
    max-height: 70%;
    border-radius: 5px;
    background-color: white;
`;

export const AllFilterArea = styled.View<ResponsiveProps>`
    ${({ width }) => width <= 900 && css`flex: 1;`}
    align-items: center;
`;

export const AdjustFilters = styled.View<FilterAreaProps>`
    align-items: center;
    ${({ isVisibleModal }) => !isVisibleModal
        ? css`padding: 0px`
        : css`padding: 20px 10px 10px 10px`
    };
    ${({ width, isVisibleModal }) => isVisibleModal || width < 400 ?
        css`flex-direction: column`
    : css`flex-direction: row`};
    z-index: ${({ zIndex }) => zIndex}
`;

export const AdjustPickers = styled.View<ResponsiveProps>`
    align-items: flex-start;
    flex-direction: ${({ width }) => (width < 1200 && width > 900 ? 'row' : 'column')};
    margin-top: 10px;
    justify-content: space-between;
    min-width: ${({ width }) => (width < 1200 ? '100%' : '24%')};
`;

export const RequestFilterButton = styled.TouchableOpacity<FilterAreaProps>`
    margin-top: ${({ marginTop }) => marginTop || '0px'};
    margin-right: ${({ marginRight }) => marginRight || '0px'};
`;

export const InputSearch = styled.TextInput.attrs({
    placeholderTextColor: theme.colors.gray[150],
})`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray[800]};
    width: 100%;
    outline-width: 0px;
`;

export const DropdownArea = styled.View<DropdownAreaProps>`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    min-height: 120px;
    /* margin-left: ${({ width }) => width > 1100 ? 16 : '0px'}; */
    padding: 16px 0px 16px 20px;
    position: ${({ width, isVisibleModal }) => width > 1300 && !isVisibleModal ? 'absolute' : 'relative'};
    top: ${({ width, isVisibleModal, topNumber }) => width > 1300 && !isVisibleModal ? topNumber : 'auto'};
    width: ${({ widthNumber }) => widthNumber || 250};
`;

export const DropdownOption = styled.TouchableOpacity`
    padding-bottom: 16px;
`;

export const DropdownOptionText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
`;
