import styled from 'styled-components/native';

interface TextProps {
    color: string,
    font: 'bold' | 'regular',
    fontSize?: number,
    marginTop?: number,
    marginBottom?: number,
}

interface ModalButtonProps {
    type: 'Cancelar' | 'Exluir',
}

export const ModalArea = styled.View`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    padding: 26px 0px;
    width: 306px;
`;

export const TextDefault = styled.Text<TextProps>`
    color: ${({ color }) => color};
    font-family: ${({ font, theme }) => font === 'bold'
        ? theme.font.segoeUi.bold
        : theme.font.segoeUi.regular
    };
    font-size: ${({ fontSize }) => fontSize || '12px'};
    margin-bottom: ${({ marginBottom }) => marginBottom || '0px'};
    margin-top: ${({ marginTop }) => marginTop || '0px'};
    text-align: center;
`;

export const ModalButton = styled.TouchableOpacity<ModalButtonProps>`
    align-items: center;
    background-color: ${({ theme, type }) => type === 'Cancelar'
        ? theme.colors.white
        : theme.colors.orange[500]
    };
    border-color: ${({ theme, type }) => type !== 'Cancelar'
        ? theme.colors.orange[500]
        : theme.colors.gray[400]
    };
    border-radius: 6px;
    border-width: 1px;
    height: 40px;
    justify-content: center;
    width: 46%;
`;

export const ModalButtonArea = styled.View`
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
`;
