// @ts-nocheck
import React, { useState } from 'react';
import { TextInputProps } from 'react-native';
import { useController } from 'react-hook-form';

import SecureIcon from '../../assets/images/svg/secureIcon';
import * as S from './styles';

interface InputProps extends TextInputProps {
    label: string;
    isPassword?: boolean;
    control?: any;
    name: string;
    errors: any;
    keyPress?: () => void,
    ref?: any,
    autoFocus?: boolean,
}

export function Input(props: InputProps) {
    const { label, isPassword, keyPress, control, name, ref, errors, autoFocus, ...rest } = props;
    const controllers = { control, name, defaultValue: '' };
    const { field: { value, onChange } } = useController(controllers);
    const errorsMessage = errors[name]?.message;

    const [isSecure, setIsSecure] = useState(isPassword);

    function handleChangeSecureText() {
        setIsSecure(!isSecure);
    }

    function renderIconSecure() {
        if (!isPassword) {
            return null;
        }

        return (
            <S.SecureButton onPress={handleChangeSecureText}>
                <SecureIcon />
            </S.SecureButton>
        );
    }

    return (
        <S.InputContainer>
            <S.Label>{label}</S.Label>
            <S.Container>
                <S.Input secureTextEntry={isSecure} onChangeText={onChange} onKeyPress={keyPress} autoFocus={autoFocus} value={value} {...rest} />
                {renderIconSecure()}
            </S.Container>
            <S.Erros>{errorsMessage}</S.Erros>
        </S.InputContainer>
    );
}
