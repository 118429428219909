// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Rect, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={14} height={16} {...props}>
    <G
      transform="translate(1 1)"
      fill="none"
      stroke="#6746c8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Rect
        data-name="Ret\xE2ngulo 11"
        width={12}
        height={12}
        rx={2}
        transform="translate(0 2)"
      />
      <Path data-name="Linha 5" d="M9 0v3" />
      <Path data-name="Linha 6" d="M3 0v3" />
      <Path data-name="Linha 7" d="M0 6h12" />
    </G>
  </Svg>
)

export default SvgComponent
