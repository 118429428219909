import reducer from './reducers';
import { loginAuth, filtersGlobal, clientDrawer} from './actions';

const actions = {
    loginAuth,
    filtersGlobal,
    clientDrawer,
};

export default { actions, reducer };
