import styled from 'styled-components/native';
import { ResponsiveProps } from '../../../../../global/types/index';

export const Form = styled.ScrollView<ResponsiveProps>`
    padding-right: ${({ width }) => width >= 800 ? 36 : 0};
    min-height: 300px;
    width: 100%;
`;

export const ContainerAlign = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 20px;
    margin-top: 40px;
    width: 100%;
    z-index: -1;
    max-width: 100%;
    overflow-x: scroll;
`;

export const InputSearchArea = styled.View<ResponsiveProps>`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    /* margin-top: 40px; */
    /* flex-wrap: wrap; */
    width: 100%;
    max-width: 100%;
`;

export const Buttons = styled.View<ResponsiveProps>`
    flex-direction: row;
    align-self: flex-end;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin-top: 20px; */
    width: 49%;
    max-width: 49%;
`

export const ButtonsContainer = styled.View`
    flex-direction: row;
`;

export const ButtonOptions = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    height: 19px;
    margin-left: 12px;
    margin-top: 18px;
`;

export const SecondButtonOptions = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    height: 19px;
    margin-left: 12px;
    margin-top: 18px;
    margin-bottom: 18px;
`;

export const ButtonOptionsText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
    margin-left: 6px;
`;

export const AddClientText = styled.Text<ResponsiveProps>`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: ${({ width }) => (width < 600 ? (width < 400 ? '10px' : '14px') : '16px')};
    margin-left: 10px;
`;

export const OptionsContainer = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    position: fixed;
    width: 180px;
    top: 80px;
`;

export const ButtonContainer = styled.View<ResponsiveProps>`
    /* align-items: ${({ width }) => width < 400 ? 'flex-start' : 'center'}; */
    flex-direction: ${({ width }) => width < 975 ? 'column' : 'row'};
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 49%;
    max-width: 49%;
`;

export const NewClientButton = styled.TouchableOpacity<ResponsiveProps>`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 6px;
    padding: ${({ width }) => width < 400 ? '5px 10px' : '10px 20px'};
    flex-direction: row;
    margin-left: 8px;
    margin-top: 8px;
`;
