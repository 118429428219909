// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Image, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { MaterialIcons } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import {
    Menu,
    MenuOption,
    MenuOptions,
    MenuTrigger,
} from 'react-native-popup-menu';
import { formatInTimeZone } from 'date-fns-tz';

import * as S from './styles';
import MoreOption from '../../../../../assets/images/svg/moreOption';
import PaymentIconGold from '../../../../../assets/images/svg/paymentIconGold';
import InfoIcon from '../../../../../assets/images/svg/infoIconSmall';
import DowngradeIcon from '../../../../../assets/images/svg/downgradeIcon';
import PenIcon from '../../../../../assets/images/svg/penIcon';
import TrashIcon from '../../../../../assets/images/svg/trashIcon';
import FavoriteCheckedIcon from '../../../../../assets/images/svg/favoriteCheckedIcon';
import EditIcon from '../../../../../assets/images/svg/edit';
import { converterCurrency, getDownloadRemoteFile } from '../../../../../global/utils/functions';
import { userFavoriteArt } from '../../../../../services/user';
import Theme from '../../../../../global/styles/theme';
import { SchedulingModal } from '../../../../../components/SchedulingModal';
import { scheduledArt, resendArt, removeScheduled, remove, getLogsArt, getUsedPublicationCodition, computedArtsDownload } from '../../../../../services/arts';
import { ModalInfoProps } from '../../../../../global/types';
import CloseImage from '../../../../../assets/images/close.png';

import { ReportTable } from '../../../../../components/ReportTable';
import { LoadingModal } from '../../../../../components/LoadingModal';
import { CoditionModal } from '../../../../../components/CoditionModal';
import { Modal } from '../../../../../components/Modal';
import { InfoModal } from '../../../../../components/TemplateModal/components/InfoModal';
import { TypeSocialModal } from '../../../../../components/TypeSocialModal';
import { addDays, addHours, lightFormat, setHours, setMinutes, subHours } from 'date-fns';
import theme from '../../../../../global/styles/theme';
import { LogsModal } from '../../../../../components/TemplateModal/components/LogsModal';

interface ItemProps {
    id: number;
    id_plan: number;
    title: string;
    store_name: string;
    colors: Array<string>;
    use: string;
    type: string;
    thumb: string;
    is_bought: boolean;
    is_favorite: boolean;
    author_name: string;
    author_photo: string;
    price: string,
    preview_thumb?: string,
    previewThumbPub?: string,
    idArtUsed?: number;
    was_published_from_stock?: boolean,
    dateSendPub?: string;
    modelVehicle: string,
    id_art: number,
    yearModelVehicle: string,
    priceVehicle: number,
    published_at: string,
}

interface ContactModalProps  {
    description: string,
}

interface CardProps {
    item: ItemProps;
    setModalVisible: (modalVisible: boolean) => void;
    setModalInfo: (modalInfo: ItemProps) => void;
    setModalDetails: (modalVisible: boolean) => void;
    type: 'Modelos comprados' | 'Modelos Favoritos' | 'Continuar editando' | 'Minhas artes';
    getList: () => void,
    currentPage: 'published' | 'scheduled' | 'finish' | 'logs' | 'download',
    isAdm?: boolean,
}

export function Card(props: CardProps) {
    const {
        item: { title, titleVehicle, id_vehicle, photoVehicleSelectedPub, logoSelectedPub, thumb, price, modelVehicle, id, preview_thumb, published_at, previewThumbPub, idArtUsed, dateSendPub, was_published_from_stock, id_art, yearModelVehicle, priceVehicle },
        setModalInfo,
        setModalVisible,
        setModalDetails,
        type,
        getList,
        currentPage,
        socialMediaVisibleHandle,
        socialMediaVisibleBool,
        isAdm,
    } = props;
    const isBought = type === 'Modelos comprados';
    const isFavorite = type === 'Modelos Favoritos';
    const isEditing = type === 'Continuar editando';
    const isPublished = type === 'Minhas artes';
    const uri = currentPage === 'download' ? { uri: props?.item?.url ? JSON.parse(props?.item?.url)[0] : '' } :  { uri: preview_thumb?.split('|')[0] || previewThumbPub?.split('|')[0] || thumb };
    const userInfo = useSelector((state) => state.user.user);
    const width = useWindowDimensions().width;

    const [modePublication, setModePublication] = useState('published');
    const [dateCurrent, setDateCurrent] = useState(addDays(new Date(), 1));
    const [hourCurrent, setHourCurrent] = useState('00:00');
    const [schedulingModalVisible, setSchedulingModalVisible] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [modalStatusInfo, setModalStatusInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [contactModalInfo, setContactModalInfo] = useState<ContactModalProps>({} as ContactModalProps);
    const [socialMediaVisible, setSocialMediaVisible] = useState(false);
    const [typeSocial, setTypeSocial] = useState('');
    const [modalLogs, setModalLogs] = useState(false);
    const [logsCard, setLogsCard] = useState([]);
    const [logsPublic, setLogsPublic] = useState(false);

    // setSocialMediaVisibleHandle
    useEffect(() => {
        setModalDetails(false);
    }, [socialMediaVisibleHandle]);

    useEffect(() => {
        setSocialMediaVisible(false);
    }, [socialMediaVisibleBool]);

    useEffect(() => {
        if (!schedulingModalVisible) {
            setModePublication('published');
        }
    }, [schedulingModalVisible])

    const handleCancelScheduled = async () => {
        const { message } = await removeScheduled(idArtUsed);
        const modalProps: ModalInfoProps = {
            typeModal: 'success',
            titleModal: 'Sucesso',
            descriptionModal: message,
            buttonText: 'Continuar',
            modalVisible: true,
            setModalVisible: changeModalInfoVisible,
            buttonFunction: getList,
        };

        setTimeout(() => {
            setModalStatusInfo(modalProps);
        }, 500);
    };

    const handleRemovePublish = async () => {
        const { message } = await remove(idArtUsed);
        const modalProps: ModalInfoProps = {
            typeModal: 'success',
            titleModal: 'Sucesso',
            descriptionModal: message,
            buttonText: 'Continuar',
            modalVisible: true,
            setModalVisible: changeModalInfoVisible,
            buttonFunction: getList,
        };

        setTimeout(() => {
            setModalStatusInfo(modalProps);
        }, 500);
    };

    function handleTemplatePress() {
        if (currentPage === 'logs') {
            return null;
        }
        if (modePublication === 'published' && !isAdm) {
            setSocialMediaVisible(true);
        }
        setModalInfo(props.item);
        if(!socialMediaVisible && !schedulingModalVisible){
            setModalDetails(true);
        }
    }

    function changeModalInfoVisible() {
        setModalStatusInfo({ modalVisible: false } as ModalInfoProps);
        setModalVisible(false);
    }

    function RenderOptionArea(
        optionName: string,
        OptionIcon: any,
        onPressFunction?: () => void,
    ) {
        return (
            <MenuOption onSelect={onPressFunction}>
                <S.OptionArea>
                    <OptionIcon />
                    <S.OptionText>{optionName}</S.OptionText>
                </S.OptionArea>
            </MenuOption>
        );
    }

    async function downloadPreviewThumbPubDownload() {
        if (JSON.parse(props?.item?.url)?.length > 0) {
            if (isAdm) {
                const urlDownload = JSON.parse(props?.item?.url);
                getDownloadRemoteFile(urlDownload);
            } else {
                const urlDownload = JSON.parse(props?.item?.url);
                const { status, message, result } = await getUsedPublicationCodition(userInfo?.user?.id, 'true');
                if (status === 200) {
                    const resultComputed = await computedArtsDownload(userInfo?.user?.id, urlDownload);
                    if (resultComputed.status === 201) {
                        getDownloadRemoteFile(urlDownload);
                    } else {
                        const contactModalInfo: ContactModalProps = {
                            description: resultComputed.message,
                        }
                        setContactModalVisible(true);
                        setContactModalInfo(contactModalInfo);
                    }
                } else {
                    const contactModalInfo: ContactModalProps = {
                        description: message,
                    }
                    setContactModalVisible(true);
                    setContactModalInfo(contactModalInfo);
                }
            }
        }
    }

    async function downloadPreviewThumbPub() {
        if (previewThumbPub?.split('|')?.length > 0) {
            const urlDownload = previewThumbPub?.split('|');
            const { status, message, result } = await getUsedPublicationCodition(userInfo?.user?.id, 'true');
            if (status === 200) {
                const resultComputed = await computedArtsDownload(userInfo?.user?.id, urlDownload);
                if (resultComputed.status === 201) {
                    getDownloadRemoteFile(urlDownload);
                } else {
                    const contactModalInfo: ContactModalProps = {
                        description: resultComputed.message,
                    }
                    setContactModalVisible(true);
                    setContactModalInfo(contactModalInfo);
                }
            } else {
                const contactModalInfo: ContactModalProps = {
                    description: message,
                }
                setContactModalVisible(true);
                setContactModalInfo(contactModalInfo);
            }
        }
    }

    function openModalType() {
        setModalInfo(props.item);
        setModalDetails(true);
    }

    function openModalLogs() {
        setModalLogs(true);
        getAllLogsCard();
    }

    async function getAllLogsCard() {
        const { result } = await getLogsArt(props.item.idArtUsed);
        setLogsCard(result?.list || []);
    }

    async function getLogsPublic() {
        setLogsPublic(true);
    }

    async function confirmSocialPress() {
        setSocialMediaVisible(false);
        setLoadingModal(true);

        let response = null;
        if (modePublication === 'scheduled') {
            const [hour, minute] = hourCurrent.split(':');
            const currentDate = addHours(dateCurrent, 3);
            const newDateFromHours = setHours(currentDate, hour);
            const newDateFinal = subHours(setMinutes(newDateFromHours, minute), 3);
            response = await scheduledArt(idArtUsed, userInfo.user.id, typeSocial, newDateFinal);
        } else {
            response = await resendArt(props.item.idArtUsed, userInfo.user.id, typeSocial);
        }
        const { status, message, error, result } = response;

        if (status === 200 || status === 201) {
            setLoadingModal(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'success',
                titleModal: 'Sucesso',
                descriptionModal: message,
                buttonText: 'Continuar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: getList,
            };

            setTimeout(() => {
                setModalStatusInfo(modalProps);
            }, 500);
        } else if (error === 'LIMIT') {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setLoadingModal(false);

            setTimeout(() => {
                setContactModalVisible(true)
                setContactModalInfo(contactModalInfo);
            }, 500);
        } else {
            setLoadingModal(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalStatusInfo(modalProps);
            }, 500);
        }
    }

    const handleEditPress = () => {
        setSchedulingModalVisible(true);
    };

    const handleDeleteArtPress = async () => {
        setLoadingModal(true);
        const { status, message } = await remove(idArtUsed);

        if (status === 200 || status === 201) {
            setLoadingModal(false);
            getList();
        } else {
            setLoadingModal(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalStatusInfo(modalProps);
            }, 500);
        }
    };

    const handleDeletePress = async () => {
        setLoadingModal(true);
        const { status, message } = await scheduledArt(idArtUsed, userInfo.user.id, null);

        if (status === 200 || status === 201) {
            setLoadingModal(false);
            getList();
        } else {
            setLoadingModal(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalStatusInfo(modalProps);
            }, 500);
        }
    };

    function RenderMenuOption() {
        if (currentPage === 'download') {
            return (
                <S.MoreOptionButton type={type}>
                    <Menu>
                        <MenuTrigger text="" style={S.buttonStyle}>
                            <MoreOption />
                        </MenuTrigger>
                        <MenuOptions
                            optionsContainerStyle={S.optionsContainerStyle}
                        >
                            {RenderOptionArea(
                                'Baixar novamente',
                                DowngradeIcon,
                                downloadPreviewThumbPubDownload,
                            )}
                        </MenuOptions>
                    </Menu>
                </S.MoreOptionButton>
            );
        }

        if (currentPage === 'scheduled') {
            return (
                <S.MoreOptionButton type={type}>
                    <Menu>
                        <MenuTrigger text="" style={S.buttonStyle}>
                            <MoreOption />
                        </MenuTrigger>
                        <MenuOptions
                            optionsContainerStyle={S.optionsContainerStyle}
                        >
                            {RenderOptionArea(
                                'Baixar',
                                DowngradeIcon,
                                downloadPreviewThumbPub,
                            )}
                            {RenderOptionArea(
                                'Cancelar Agendamento',
                                TrashIcon,
                                handleDeletePress,
                            )}
                        </MenuOptions>
                    </Menu>
                </S.MoreOptionButton>
            );
        }

        if (currentPage === 'published') {
            return (
                <S.MoreOptionButton type={type}>
                    <Menu>
                        <MenuTrigger text="" style={S.buttonStyle}>
                            <MoreOption />
                        </MenuTrigger>
                        <MenuOptions
                            optionsContainerStyle={S.optionsContainerStyle}
                        >
                            {RenderOptionArea(
                                'Baixar',
                                DowngradeIcon,
                                downloadPreviewThumbPub,
                            )}
                            {RenderOptionArea(
                                'Republicar',
                                PenIcon,
                                openModalType,
                            )}
                            {RenderOptionArea(
                                'Agendar',
                                PenIcon,
                                handleEditPress,
                            )}
                            {RenderOptionArea(
                                'Excluir',
                                TrashIcon,
                                handleDeleteArtPress,
                            )}
                            {RenderOptionArea(
                                'Logs',
                                InfoIcon,
                                openModalLogs,
                            )}
                        </MenuOptions>
                    </Menu>
                </S.MoreOptionButton>
            );
        }

        if (currentPage === 'finish') {
            return (
                <S.MoreOptionButton type={type}>
                    <Menu>
                        <MenuTrigger text="" style={S.buttonStyle}>
                            <MoreOption />
                        </MenuTrigger>
                        <MenuOptions
                            optionsContainerStyle={S.optionsContainerStyle}
                        >
                            {RenderOptionArea(
                                'Baixar',
                                DowngradeIcon,
                                downloadPreviewThumbPub,
                            )}
                            {type === 'story' ? null : (
                               <>
                                    {RenderOptionArea(
                                        'Publicar',
                                        PenIcon,
                                        handleTemplatePress,
                                    )}
                                    {RenderOptionArea(
                                        'Agendar',
                                        PenIcon,
                                        handleEditPress,
                                    )}
                                    {!dateSendPub ? null : RenderOptionArea(
                                        'Cancelar agendamento',
                                        PenIcon,
                                        handleCancelScheduled,
                                    )}
                                    {published_at || dateSendPub ? null : RenderOptionArea(
                                        'Excluir',
                                        PenIcon,
                                        handleRemovePublish,
                                    )}
                               </>
                            )}
                        </MenuOptions>
                    </Menu>
                </S.MoreOptionButton>
            )
        }
    }

    const styleIconsPlatform = {
        resizeMode: 'contain',
        width: 20,
        height: 20,
    };

    function renderPlatform(typePlatform: string) {
        if (currentPage === 'finish') {
            return null;
        }
        if (typePlatform === 'instagram-feed' || typePlatform === 'instagram-story') {
            return (
                <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/instagram.png')} style={styleIconsPlatform} />
            );
        }
        if (typePlatform === 'facebook-feed') {
            return (
                <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/facebook.svg.png')} style={styleIconsPlatform} />
            );
        }
        if (typePlatform === 'facebook-feed-instagram-feed') {
            return (
                <>
                    <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/instagram.png')} style={styleIconsPlatform} />
                    <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/facebook.svg.png')} style={styleIconsPlatform} />
                </>
            );
        }

        return null;
    }

    const renderType = (typePublication: string) => {
        if (typePublication === 'instagram-story') {
            return ' no Stories';
        }
        if (typePublication === 'banner-site') {
            return ' no Site';
        }
        return ' no Feed';
    }

    function RenderTitleInfo() {
        if (currentPage === 'finish') {
            const convertedData = published_at || dateSendPub ? lightFormat(addHours(new Date(dateSendPub || published_at), 3), 'dd/MM/yyyy HH:mm') : '';
            const coditionPrice = was_published_from_stock ? `/ ${converterCurrency(priceVehicle)}` : '';
            const coditionIdArt = !was_published_from_stock ? String(id_art).padStart(6, "0") : yearModelVehicle;
            return (
                <>
                    <S.Title>{`${modelVehicle} / ${coditionIdArt} ${coditionPrice}\n${convertedData}`}</S.Title>
                    {renderPlatform(props.item.typeUsed)}
                </>
            );
        }
        if (currentPage === 'scheduled') {
            const convertedData = dateSendPub || published_at ? lightFormat(addHours(new Date(dateSendPub || published_at), 3), 'dd/MM/yyyy HH:mm') : '';
            if (!was_published_from_stock) {
                return (
                    <>
                        <S.Title>{`${modelVehicle} / ${String(id_art).padStart(6, "0")}\n${convertedData}`}</S.Title>
                        {renderPlatform(props.item.typeUsed)}
                    </>
                );
            }

            return (
                <>
                    <S.Title>{`${modelVehicle} / ${converterCurrency(priceVehicle)}\n${convertedData}`}</S.Title>
                    {renderPlatform(props.item.typeUsed)}
                </>
            );
        }

        const renderTags = () => (
            <>
                {props.item.artType ? (
                    <S.TagArea style={{ marginTop: 5, width: 100, justifyContent: 'center', alignItems: 'center' }}>
                        {(props.item.artType || '').toUpperCase().replace('-', ' ')}
                    </S.TagArea>
                ) : null}
                {!props.item.artType && props.item.was_published_from_stock ? (
                    <S.TagArea style={{ marginTop: 5, width: 100, justifyContent: 'center', alignItems: 'center' }}>
                        PUBL. DIRETA
                    </S.TagArea>
                ) : null}
            </>
        );

        if (currentPage === 'logs') {
            const convertedDataPublished = props.item.published_at ? lightFormat(addHours(new Date(props.item.published_at), 3), 'dd/MM/yyyy HH:mm') : '';
            const convertedDataSend = props.item.date_send ? lightFormat(addHours(new Date(props.item.date_send), 3), 'dd/MM/yyyy HH:mm') : '';
            const convertedLastDateSend = props.item.last_date_send ? lightFormat(addHours(new Date(props.item.last_date_send), 3), 'dd/MM/yyyy HH:mm') : '';
            const convertedDataCreated = props.item.created_at ? lightFormat(new Date(props.item.created_at), 'dd/MM/yyyy HH:mm') : '';
            const convertedErrorAt = props.item.error_at ? lightFormat(new Date(props.item.error_at), 'HH:mm') : '';

            if (props.item.last_date_cancel) {
                const convertedLastDateCancel = props.item.last_date_cancel ? lightFormat(new Date(props.item.last_date_cancel), 'dd/MM/yyyy HH:mm') : '';
                return (
                    <S.Column>
                        <S.TitleLogs selectable>{`Criado: ${props.item.created_at ? convertedDataCreated : '-'}`}</S.TitleLogs>
                        <S.Row style={{ marginTop: 5 }}>
                            <S.Title style={{ color: theme.colors.red[500], fontFamily: theme.font.segoeUi.bold, fontStyle: 'italic' }}>{`Agendamento cancelado: `}</S.Title>
                            <S.Title>{convertedLastDateCancel}</S.Title>
                        </S.Row>
                        <S.TitleLogs selectable>{`Loja: ${props.item.user_name || ''}`}</S.TitleLogs>
                        <S.Row style={{ justifyContent: 'space-between' }}>
                            {renderTags()}
                            <S.CopyArea onPress={getLogsPublic} disabled={!props?.item?.error_logs} style={{ opacity: props?.item?.error_logs ? 1 : 0.5 }}>
                                <S.IconArea>
                                    <S.IconNavigation name="copy1" />
                                </S.IconArea>
                            </S.CopyArea>
                        </S.Row>
                    </S.Column>
                );
            }
            // if (props.item.artType === 'story') {
            //     return (
            //         <S.Column>
            //             <S.Row style={{ marginTop: 5 }}>
            //                 <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/download.png')} style={styleIconsPlatform} />
            //                 <S.Title style={{ color: theme.colors.green[470] }}>{` Download - `}</S.Title>
            //                 <S.Title>{props.item.created_at ? convertedDataCreated : '-'}</S.Title>
            //             </S.Row>
            //             <S.TitleLogs selectable>{`Loja: ${props.item.user_name || ''}`}</S.TitleLogs>
            //         </S.Column>
            //     );
            // }
            return (
                <S.Column>
                    <S.TitleLogs selectable>{`Criado: ${props.item.created_at ? convertedDataCreated : '-'}`}</S.TitleLogs>
                    <S.TitleLogs selectable>
                        {`${props.item.type_publication === 'scheduled' && props.item.last_date_send ? 'Último agendamento' : 'Agendamento'}:`}
                        <S.TitleLogs style={{ color: theme.colors.gray[500], fontFamily: theme.font.segoeUi.bold, fontStyle: 'italic' }}>{` ${props.item.type_publication === 'scheduled' ? `${props.item.last_date_send ? convertedLastDateSend : '-'}` : '-'}`}</S.TitleLogs>
                    </S.TitleLogs>
                    {['banner-site'].includes(props.item.type) && !(props.item.error_message || props.item.error_at) ? (
                        <S.Row style={{ marginTop: 5 }}>
                            <MaterialIcons name="language" size={18} color={theme.colors.green[470]} />
                            {/* <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/facebook.svg.png')} style={styleIconsPlatform} /> */}
                            <S.Title style={{ color: theme.colors.green[470], fontSize: 12 }}>{` Publicado${renderType(props.item.type)} - `}</S.Title>
                            <S.Title style={{ fontSize: 12 }}>{convertedDataPublished}</S.Title>
                        </S.Row>
                    ) : null}
                    {['banner-site'].includes(props.item.type) && (props.item.error_message || props.item.error_at) ? (
                        <S.Row style={{ marginTop: 5 }}>
                            <MaterialIcons name="language" size={18} color={theme.colors.green[470]} />
                            <S.Title style={{ color: theme.colors.red[300] }}>{' Erro ao publicar'}</S.Title>
                            <S.Title>{convertedErrorAt ? ` - ${convertedErrorAt}` : ''}</S.Title>
                        </S.Row>
                    ) : null}
                    {/* <S.TitleLogs selectable style={{ color: props.item.published_at ? theme.colors.green[470] : theme.colors.red[300] }}>{props.item.published_at ? `Publicado: ${convertedDataPublished}` : 'Não publicado'}</S.TitleLogs> */}
                    {['facebook-feed', 'facebook-feed-instagram-feed'].includes(props.item.type) && !(props.item.error_message || props.item.error_at) ? (
                        <S.Row style={{ marginTop: 5 }}>
                            {props.item.type_publication === 'scheduled' && props.item.date_send ? (
                                <>
                                    <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/facebook.svg.png')} style={styleIconsPlatform} />
                                    <S.Title style={{ color: theme.colors.gray[500], fontFamily: theme.font.segoeUi.bold, fontStyle: 'italic' }}>{` Aguardando agendamento`}</S.Title>
                                </>
                            ) : (
                                <>
                                    {!props.item.type_publication ? (
                                        <S.Title style={{ color: theme.colors.green[470] }}>Arte salva</S.Title>
                                    ) : (
                                        <>
                                            <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/facebook.svg.png')} style={styleIconsPlatform} />
                                            <S.Title style={{ color: theme.colors.green[470], fontSize: 12 }}>{` Publicado${renderType(props.item.type)} - `}</S.Title>
                                            <S.Title style={{ fontSize: 12 }}>{convertedDataPublished}</S.Title>
                                        </>
                                    )}
                                </>
                            )}
                        </S.Row>
                    ) : null}
                    {['instagram-feed', 'instagram-story', 'facebook-feed-instagram-feed'].includes(props.item.type) && (props.item.error_message || props.item.error_at) ? (
                        <S.Row style={{ marginTop: 5 }}>
                            <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/instagram.png')} style={styleIconsPlatform} />
                            <S.Title style={{ color: theme.colors.red[300] }}>{' Erro ao publicar'}</S.Title>
                            <S.Title>{convertedErrorAt ? ` - ${convertedErrorAt}` : ''}</S.Title>
                        </S.Row>
                    ) : null}
                    {['instagram-feed', 'instagram-story', 'facebook-feed-instagram-feed'].includes(props.item.type) && !(props.item.error_message || props.item.error_at) ? (
                        <S.Row style={{ marginTop: 5 }}>
                            {props.item.type_publication === 'scheduled' && props.item.date_send ? (
                                <>
                                    <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/instagram.png')} style={styleIconsPlatform} />
                                    <S.Title style={{ color: theme.colors.gray[500], fontFamily: theme.font.segoeUi.bold, fontStyle: 'italic' }}>{` Aguardando agendamento`}</S.Title>
                                </>
                            ) : (
                                <>
                                    {!props.item.type_publication ? (
                                        <S.Title style={{ color: theme.colors.green[470] }}>Arte salva</S.Title>
                                    ) : (
                                        <>
                                            <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/instagram.png')} style={styleIconsPlatform} />
                                            <S.Title style={{ color: theme.colors.green[470], fontSize: 12 }}>{` Publicado${renderType(props.item.type)} - `}</S.Title>
                                            <S.Title style={{ fontSize: 12 }}>{convertedDataPublished}</S.Title>
                                        </>
                                    )}
                                </>
                            )}
                        </S.Row>
                    ) : null}
                    <S.TitleLogs selectable style={{ fontSize: 10, color: theme.colors.red[300] }}>{props.item.error_message || props.item.error_at ? `Erro: ${props.item.error_message || ''} - ${props.item.error_at || ''}` : ''}</S.TitleLogs>
                    <S.TitleLogs selectable>{`Loja: ${props.item.user_name || ''}`}</S.TitleLogs>
                    <S.Row style={{ justifyContent: 'space-between' }}>
                        {renderTags()}
                        <S.CopyArea onPress={getLogsPublic} disabled={!props?.item?.error_logs} style={{ opacity: props?.item?.error_logs ? 1 : 0.5 }}>
                            <S.IconArea>
                                <S.IconNavigation name="copy1" />
                            </S.IconArea>
                        </S.CopyArea>
                    </S.Row>
                </S.Column>
            );
        }

        if (currentPage === 'download') {
            const convertedDataCreated = props.item.created_at ? lightFormat(new Date(props.item.created_at), 'dd/MM/yyyy HH:mm') : '';
            return (
                <S.Column>
                    <S.Title>{`${props.item.user_name || ''} - ${convertedDataCreated}`}</S.Title>
                    <S.Title style={{ fontSize: 12 }}>{props?.item?.url ? `Quantidade de imagens: ${JSON.parse(props?.item?.url)?.length}` : ''}</S.Title>
                </S.Column>
            );
        }

        return PublicArea();
    }

    function PublicArea() {
        const convertedData = published_at ? lightFormat(addHours(new Date(published_at), 3), 'dd/MM/yyyy') : '';
        const convertedDataHour = published_at ? lightFormat(addHours(new Date(published_at), 3), 'HH:mm') : '';
        const convertedErrorAt = props.item.error_at ? lightFormat(new Date(props.item.error_at), 'HH:mm') : '';
        return (
            <S.Column>
                <S.Title>{`${modelVehicle} / ${!was_published_from_stock ? String(id_art).padStart(6, "0") : converterCurrency(priceVehicle)}\n${convertedData}`}</S.Title>
                {['banner-site'].includes(props.item.typeUsed) && !props.item.dateSendPub && published_at && !props.item.error_at ? (
                    <S.Row style={{ marginTop: 5 }}>
                        <MaterialIcons name="language" size={18} color={theme.colors.green[470]} />
                        {/* <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/facebook.svg.png')} style={styleIconsPlatform} /> */}
                        <S.Title style={{ color: theme.colors.green[470] }}>{` Publicado${renderType(props.item.typeUsed)} - `}</S.Title>
                        <S.Title>{convertedDataHour}</S.Title>
                    </S.Row>
                ) : null}
                {['banner-site'].includes(props.item.typeUsed) && (props.item.error_message || props.item.error_at) ? (
                    <S.Row style={{ marginTop: 5 }}>
                        <MaterialIcons name="language" size={18} color={theme.colors.green[470]} />
                        <S.Title style={{ color: theme.colors.red[300] }}>{' Erro ao publicar'}</S.Title>
                        <S.Title>{convertedErrorAt ? ` - ${convertedErrorAt}` : ''}</S.Title>
                    </S.Row>
                ) : null}
                {['facebook-feed', 'facebook-feed-instagram-feed'].includes(props.item.typeUsed) && !props.item.dateSendPub && published_at && !props.item.error_at ? (
                    <S.Row style={{ marginTop: 5 }}>
                        <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/facebook.svg.png')} style={styleIconsPlatform} />
                        <S.Title style={{ color: theme.colors.green[470] }}>{` Publicado${renderType(props.item.typeUsed)} - `}</S.Title>
                        <S.Title>{convertedDataHour}</S.Title>
                    </S.Row>
                ) : null}
                {['instagram-feed', 'instagram-story', 'facebook-feed-instagram-feed'].includes(props.item.typeUse) && (props.item.error_message || props.item.error_at) ? (
                    <S.Row style={{ marginTop: 5 }}>
                        <Image onContextMenu={(e) => e.preventDefault()} source={['instagram-feed', 'instagram-story', 'facebook-feed-instagram-feed'].includes(props.item.typeUsed) ? require('../../../../../assets/images/instagram.png') : require('../../../../../assets/images/facebook.svg.png')} style={styleIconsPlatform} />
                        <S.Title style={{ color: theme.colors.red[300] }}>{' Erro ao publicar'}</S.Title>
                        <S.Title>{convertedErrorAt ? ` - ${convertedErrorAt}` : ''}</S.Title>
                    </S.Row>
                ) : null}
                {['instagram-feed', 'instagram-story', 'facebook-feed-instagram-feed'].includes(props.item.typeUsed) && !(props.item.error_message || props.item.error_at) ? (
                    <S.Row style={{ marginTop: 5 }}>
                        <Image onContextMenu={(e) => e.preventDefault()} source={require('../../../../../assets/images/instagram.png')} style={styleIconsPlatform} />
                        <S.Title style={{ color: theme.colors.green[470] }}>{` Publicado${renderType(props.item.typeUsed)} - `}</S.Title>
                        <S.Title>{convertedDataHour}</S.Title>
                    </S.Row>
                ) : null}
            </S.Column>
        );
    }

    function renderPayment() {
        if (isEditing) {
            return null;
        }

        if (isPublished) {
            const isError = currentPage === 'logs' && (props.item?.error_message || props.item?.error_at);
            return (
                <S.AdjustTitle style={{ backgroundColor: isError ? theme.colors.white : 'transparent', padding: isError ? 5 : 0, borderRadius: isError ? 5 : 0 }}>
                    {RenderTitleInfo()}
                    {RenderMenuOption()}
                </S.AdjustTitle>
            );
        }

        if (!isBought) {
            return null;
        }

        return null;
    }

    function renderEditing() {
        if (!isEditing) {
            return null;
        }

        return (
            <S.ButtonEditing>
                <EditIcon />
            </S.ButtonEditing>
        );
    }

    async function handleFavorite() {
        const response = await userFavoriteArt(userInfo.user.id, id, false);
        if (response.status === 200) {
            toast.success(response.message, Theme.toast);
            getList();
        } else {
            toast.error(response.message, Theme.toast);
        }
    }

    function renderFavorite() {
        if (!isFavorite) {
            return null;
        }

        return (
            <S.FavoriteButton onPress={handleFavorite}>
                <FavoriteCheckedIcon />
            </S.FavoriteButton>
        );
    }

    return (
        <S.Container
            item={props.item}
            isEditing={isEditing}
            onPress={() => {
                if (contactModalVisible) {
                    return null;
                }
                if (currentPage === 'logs' || modalLogs) {
                    return openModalType();
                }
                return handleTemplatePress();
            }}
            currentPage={currentPage}
        >
            <S.ImageArea>
                <S.ImageCard onContextMenu={(e) => e.preventDefault()} currentPage={currentPage} resizeMode='contain' isEditing={isEditing} source={uri} />
            </S.ImageArea>
            {renderPayment()}
            {renderEditing()}
            <S.InfoArea>
                {type === 'Minhas artes' || type === 'Continuar editando' ? null : (
                    <S.Title>Núm. {String(id).padStart(6, "0")}</S.Title>
                )}
                {type === 'Minhas artes' && currentPage === 'scheduled' && props.item.typeUsed ? (
                    <S.TagArea style={{ marginTop: 5 }}>
                        {(props.item.typeUsed.includes('story') ? 'STORY' : 'FEED')}
                    </S.TagArea>
                ) : null}
                {type === 'Modelos comprados' && props.item.type ? (
                    <S.TagArea>
                        {(props.item.type || '').toUpperCase().replace('-', ' ')}
                    </S.TagArea>
                ) : null}
                <S.IconArea>
                    {type === 'Modelos comprados' ? (
                        <PaymentIconGold />
                    ) : renderFavorite()}
                </S.IconArea>
            </S.InfoArea>
            <SchedulingModal
                modalVisible={schedulingModalVisible}
                setModalVisible={setSchedulingModalVisible}
                idVehicle={null}
                idArt={idArtUsed || -1}
                setUseTemplateModalVisible={setModalVisible}
                type="reschedule"
                initialSchedule={dateSendPub}
                updateList={getList}
                setDateCurrent={setDateCurrent}
                setHourCurrent={setHourCurrent}
                setTypeSocialCurrent={setTypeSocial}
                setModePublication={setModePublication}
            />
            <LoadingModal
                visible={loadingModal}
                titleLoading='Aguarde...'
            />
            <CoditionModal
                titleModal={modalStatusInfo.titleModal}
                descriptionModal={modalStatusInfo.descriptionModal}
                typeModal={modalStatusInfo.typeModal}
                buttonText={modalStatusInfo.buttonText}
                modalVisible={modalStatusInfo.modalVisible}
                setModalVisible={modalStatusInfo.setModalVisible}
                buttonFunction={modalStatusInfo.buttonFunction}
            />
            <Modal isVisible={logsPublic} closeModal={setLogsPublic}>
                <LogsModal item={props.item} setModalVisible={setLogsPublic} />
            </Modal>
            <Modal isVisible={contactModalVisible} closeModal={setContactModalVisible}>
                <InfoModal userInfo={userInfo?.user} description={contactModalInfo.description} setModalVisible={setContactModalVisible} />
            </Modal>
            <Modal isVisible={socialMediaVisible} closeModal={setSocialMediaVisible}>
                <TypeSocialModal onPressConfirm={confirmSocialPress} setTypeSocial={setTypeSocial} typeSocial={typeSocial} />
            </Modal>
            <Modal isVisible={modalLogs} closeModal={setModalLogs}>
                <S.ScrollView style={{ maxWidth: width <= 800 ? '90vw' : '50vw', minWidth: width <= 800 ? '90vw' : '50vw' }}>
                    <S.ContainerAlign>
                        <S.Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <S.TitleTable marginBottom={20}>
                                Logs da publicação
                            </S.TitleTable>
                            <S.CloseButton onPress={() => setModalLogs(false)}>
                                <S.CloseImage onContextMenu={(e) => e.preventDefault()} resizeMode='contain' source={CloseImage} />
                            </S.CloseButton>
                        </S.Row>
                        <ReportTable
                            type='logsCard'
                            tableList={logsCard}
                            refreshList={getAllLogsCard}
                        />
                    </S.ContainerAlign>
                </S.ScrollView>
            </Modal>
        </S.Container>
    );
}
