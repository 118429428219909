import React, { useState } from 'react';

// components
import { TopTabs } from '../../../../../components/TopTabs';
import { HomeScreen } from '../../../HomeScreen';

// styles
import * as S from './styles';
import { useWindowDimensions } from 'react-native';

// interfaces

interface BirthDaysProps {
    age: number;
    birth_date: string;
    city: string;
    id: number;
    name: string;
    is_send: boolean;
}
interface ModalModeslProps {
    handleBackdropPress: () => void;
    isVisible: boolean;
    birthInfo: BirthDaysProps;
}

const listTabs = ['Modelos', 'Meus Modelos', 'Modelos Favoritos'];

export function ModalModels(props: ModalModeslProps) {
    const { handleBackdropPress, isVisible, birthInfo } = props;
    const width = useWindowDimensions().width;

    const [currentTabPosition, setCurrentTabPosition] = useState(1);
    const [loading, setLoading] = useState(false);

    function renderContent() {
        return (
            <HomeScreen
                currentTabPositionBirth={currentTabPosition}
                closeModalBirth={handleBackdropPress}
                birthday
                birthInfo={birthInfo}
                disabledBirthayToBar={setLoading}
            />
        );
    }

    return (
        <S.Container
            isVisible={isVisible}
            onBackdropPress={handleBackdropPress}>
            <S.ModalArea width={width}>
                <S.ButtonClose onPress={handleBackdropPress}>
                    <S.ButtonCloseIcon />
                </S.ButtonClose>
                <TopTabs
                    listTabs={listTabs}
                    currentPosition={currentTabPosition}
                    setCurrentPosition={setCurrentTabPosition}
                    disabledButton={loading}
                    birthInfo={birthInfo}
                />
                {renderContent()}
            </S.ModalArea>
        </S.Container>
    );
}
