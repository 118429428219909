import styled, { css } from 'styled-components/native';
import Feather from '@expo/vector-icons/Feather';
import Modal from 'react-native-modal';

import { ResponsiveProps } from '../../global/types';
import ImageModal from 'react-native-image-modal';

interface TextProps {
    fontSize: number;
    color: string;
    fontFamily: 'bold' | 'regular';
    marginTop: number;
}

interface ButtonProps {
    width: string;
    type: 'payment' | 'use';
}

interface PaymentButtonProps {
    paymentCodition: boolean;
    width: string;
}

interface TemplateProps extends ResponsiveProps {
    withTemplate: boolean;
}

interface TemplatePhotoProps {
    height?: number | string;
    width?: number | string;
    withTemplate: boolean;
}

export const ContainerModal = styled(Modal)`
    margin: 0px;
    flex: 1;
`;

export const ModalArea = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    width: 95%;
    height: 95%;
    margin: 30px 0px;
    padding-bottom: 30px;
`;

export const ModalAreaScrollView = styled.ScrollView`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    width: 95%;
    height: 95%;
    margin: 30px 0px;
    padding-bottom: 30px;
`;

export const ContainerInfoArea = styled.View<TemplateProps>`
    align-self: center;
    width: 100%;
    ${({ withTemplate }) =>
        withTemplate &&
        css`
            /* flex: 1; */
            /* margin-top: 20%; */
            align-items: center;
            justify-content: center;
        `}
`;

export const TemplatePhoto = styled.Image<TemplatePhotoProps>`
    justify-content: ${({ width }) => (typeof width === 'number' && width >= 900 ? 'flex-start' : 'center')};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    margin-top: 50px;
    margin-right: ${({ width }) => (typeof width === 'number' && width >= 800 ? 100 : 0)};
    height: ${({ height }) => `${typeof height === 'number' ? `${height}px` : height}`};
    width: ${({ width }) => `${typeof width === 'number' ? `${width}px` : width}`};
`;

export const TextDefault = styled.Text<TextProps>`
    color: ${({ color }) => color};
    font-family: ${({ fontFamily, theme }) =>
        fontFamily === 'bold'
            ? theme.font.segoeUi.bold
            : theme.font.segoeUi.regular};
    font-size: ${({ fontSize }) => fontSize};
    ${({ marginTop }) => `margin-top: ${marginTop || 0}px`}
`;

export const Description = styled.Text<TextProps>`
    color: ${({ color }) => color};
    font-family: ${({ fontFamily, theme }) =>
        fontFamily === 'bold'
            ? theme.font.segoeUi.bold
            : theme.font.segoeUi.regular};
    font-size: ${({ fontSize }) => fontSize};
    ${({ marginTop }) => `margin-top: ${marginTop || 0}px`}
    width: 300px;
`;

export const InfoArea = styled.View`
    margin-left: 20px;
    /* margin-top: 40%; */
    /* background-color: orange; */
    /* justify-content: center;
    align-items: center; */
`;

export const AuthorArea = styled.View`
    align-items: center;
    flex-direction: row;
    margin-top: 40px;
`;

export const AuthorPhoto = styled.Image`
    border-radius: 30px;
    height: 60px;
    margin-right: 13px;
    width: 60px;
`;

export const InfosAuthor = styled.View``;

export const TextContainer = styled.View`
    flex-direction: row;
    align-items: center;
`;

export const InfoAreaAlign = styled.View<ResponsiveProps>`
    flex-direction: ${({ width }) => (width >= 1000 ? 'row' : 'column')};
    padding-left: ${({ width }) => (width >= 900 ? 160 : 20)};
    padding-top: ${({ width }) => (width >= 900 ? 60 : 20)};
    padding-right: ${({ width }) => (width >= 900 ? 160 : 20)};
    padding-bottom: ${({ width }) => (width >= 900 ? 60 : 20)};
`;

export const SimiliarTemplateArea = styled.View<ResponsiveProps>`
    align-self: ${({ width }) => (width >= 1100 ? 'auto' : 'center')};
    /* margin-top: 48px; */
    ${({ width }) =>
        width >= 800
            ? css`
                  padding: 0px 40px;
              `
            : css`
                  padding: 0px 10px;
              `}
`;

export const SimiliarTemplatePhotoArea = styled.View<ResponsiveProps>`
    align-content: space-between;
    display: grid;
    ${({ width }) =>
        width > 1400 &&
        css`
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        `}
    ${({ width }) =>
        width > 1100 &&
        width <= 1400 &&
        css`
            grid-template-columns: 1fr 1fr 1fr 1fr;
        `}
    ${({ width }) =>
        width > 959 &&
        width <= 1100 &&
        css`
            grid-template-columns: 1fr 1fr 1fr 1fr;
        `}
    ${({ width }) =>
        width >= 768 &&
        width <= 959 &&
        css`
            grid-template-columns: 1fr 1fr;
        `}
    gap: 20px;
    margin-top: 40px;
`;

export const SimiliarTemplatePhoto = styled.Image`
    border-radius: 6px;
    height: 240px;
    width: 240px;
`;

export const ScrollView = styled.ScrollView`
    /* padding-bottom: 30px; */
    /* background-color: blue; */
    max-height: 20%;
    /* flex: 1;
    display: flex; */
`;

export const CloseIcon = styled.Image`
    height: 16px;
    width: 16px;
`;

export const CloseButton = styled.TouchableOpacity`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray[10]};
    border-radius: 20px;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;
`;

export const ButtonArea = styled.TouchableOpacity<ButtonProps>`
    align-items: center;
    align-self: ${({ width }) => (width === '90%' ? 'auto' : 'center')};
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid #6746c8;
    border-radius: 6px;
    height: 40px;
    margin-right: 16px;
    margin-left: 16px;
    margin-top: ${({ width }) => (width === '90%' ? 12 : 0)};
    justify-content: center;
    width: ${({ width }) => width};
`;

export const PaymentButton = styled.TouchableOpacity<PaymentButtonProps>`
    align-items: center;
    align-self: ${({ width }) => (width === '90%' ? 'auto' : 'center')};
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 6px;
    height: 40px;
    margin-right: 16px;
    margin-top: ${({ width }) => (width === '90%' ? 12 : 0)};
    justify-content: center;
    width: ${({ width }) => width};
`;

export const ContainerButton = styled.View<ResponsiveProps>`
    flex-direction: ${({ width }) => (width > 1000 ? 'row' : 'row-reverse')};
    margin-top: 40px;
`;

export const ImageContainer = styled.TouchableOpacity`
    align-self: center;
`;

export const ModalContent = styled(ImageModal)``;

export const TemplateImage = styled.Image`
    height: auto;
    width: 100%;
`;

export const FavoriteButton = styled.TouchableOpacity`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray[10]};
    height: 40px;
    justify-content: center;
    width: 40px;
    border-radius: 6px;
`;

export const IsBoughtContainer = styled.View`
    background-color: ${({ theme }) => theme.colors.gray[150]};
    max-width: 71px;
    border-radius: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
    margin-top: 20px;
`;

export const IsBoughtIcon = styled(Feather)`
    color: ${({ theme }) => theme.colors.white};
    margin-right: 3px;
    margin-bottom: -3px;
`;

export const Content = styled.View<ResponsiveProps>`
    display: grid;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
    padding: 5px;
    gap: 5px;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    ${({ width }) =>
        width <= 1050 && width > 1000 &&
        css`
            place-items: center;
            grid-template-columns: repeat(2, 1fr);
        `}
    ${({ width }) =>
        width <= 1000 && width > 850 &&
        css`
            place-items: flex-start;
            grid-template-columns: repeat(4, 1fr);
        `}
    ${({ width }) =>
        width <= 850 && width > 650 &&
        css`
            place-items: flex-start;
            grid-template-columns: repeat(3, 1fr);
        `}
    ${({ width }) =>
        width <= 650 && width > 450 &&
        css`
            place-items: center;
            grid-template-columns: repeat(2, 1fr);
        `}
    ${({ width }) =>
        width <= 450 &&
        css`
            place-items: center;
            grid-template-columns: repeat(1, 1fr);
        `}
`;

export const Main = styled.View<ResponsiveProps>`
    display: grid;
    border-radius: 5px;
    padding: 10px;
    gap: 5%;
    grid-template-columns: 60% 35%;
    width: 100%;
    ${({ width }) =>
        width <= 1000 &&
        css`
            grid-template-columns: 100%;
        `}
`;
