// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CoditionModal } from '../../../components/CoditionModal';
import { Buttons } from '../../../components/DashboardButtons';
import { DashboardHeader } from '../../../components/DashboardHeader';
import { InputInternal } from '../../../components/InputInternal';
import { LoadingModal } from '../../../components/LoadingModal';

import { createPlans, updatePlans } from '../../../services/plans';
import { schemaNewPlan } from '../../../global/utils/formsSchems';
import { unmaskedPrice } from '../../../global/utils/functions';
import { statusPlans } from '../../../global/utils/lists';

import { RootStackParamList } from '../../../routes/DrawerNavigation/index';
import * as S from './styles';

interface ModalInfoProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    buttonFunction: () => void,
}

export function CreatePlansScreen() {
    const resolver = {
        resolver: yupResolver(schemaNewPlan), mode: 'onChange',
    };
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm(resolver);
    const { params }: RouteProp<RootStackParamList> = useRoute();
    const { id, name, amount_arts, amount_posts, price, status, is_main } = params?.item;

    const window = useWindowDimensions().width;
    const navigation = useNavigation();
    const widthForTwoColumns = window <= 800 ? '100%' : '48%';
    const widthForThreeColumns = window <= 800 ? '100%' : '32.5%';
    const subTitleHeader =
        params?.type === 'edit' ? 'Edite o plano' : 'Criar plano';
    const titleLoading = params?.type === 'edit' ? 'Editando plano...' : 'Salvando plano...';

    const [checkboxId, setCheckboxId] = useState(is_main ? 1 : 2);
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);
    const [validityButton, setValidityButton] = useState(false);
    const [modalLoadingVisible, setModalLoadingVisible] = useState(false);

    useEffect(() => {
        const coditionValidity = isValid && checkboxId;
        setValidityButton(coditionValidity);
    }, [isValid, checkboxId]);

    function goToBack() {
        navigation.navigate('PlansScreen');
    }

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    function renderTitle() {
        if (params?.type === 'edit') {
            return 'Editar plano';
        }

        if (params?.type === 'viewPlan') {
            return 'Plano';
        }

        return 'Novo plano';
    }

    async function handleSave(data: any) {
        setModalLoadingVisible(true);
        const valueUnmasked = unmaskedPrice(data.price);
        const body = {
            ...data,
            price: valueUnmasked,
            isMain: checkboxId === 1,
            amountPosts: Number(data.amountPosts),
        };
        const response =
            params?.type === 'edit'
                ? await updatePlans(body, id)
                : await createPlans(body);
            if (response.status === 201 || response.status === 200) {
                setModalLoadingVisible(false);
                const modalProps: ModalInfoProps = {
                    typeModal: 'success',
                    titleModal: 'Sucesso',
                    descriptionModal: response.message,
                    buttonText: 'Continuar',
                    modalVisible: true,
                    setModalVisible: changeModalInfoVisible,
                    buttonFunction: goToBack,
                };

                setTimeout(() => {
                    setModalInfo(modalProps);
                }, 500);
            } else {
                setModalLoadingVisible(false);
                const modalProps: ModalInfoProps = {
                    typeModal: 'error',
                    titleModal: 'Erro',
                    descriptionModal: response.message,
                    buttonText: 'Fechar',
                    modalVisible: true,
                    setModalVisible: changeModalInfoVisible,
                    buttonFunction: changeModalInfoVisible,
                };

                setTimeout(() => {
                    setModalInfo(modalProps);
                }, 500);
            }
    }

    function Checkbox(name: string, idOption: number, marginLeft?: boolean) {
        const isMarked = idOption === checkboxId;

        const handleCheckboxPress = () => {
            setCheckboxId(idOption);
        }

        return (
            <>
                <S.CheckboxCircle
                    isMarked={isMarked}
                    marginLeft={marginLeft}
                    onPress={handleCheckboxPress}
                    disabled={params?.type === 'viewPlan'}
                >
                    <S.CheckboxCircleMarked isMarked={isMarked}/>
                </S.CheckboxCircle>
                <S.CheckboxText>{name}</S.CheckboxText>
            </>
        );
    }

    function RenderButton() {
        if (params?.type === 'viewPlan') {
            return null;
        }

        return (
            <Buttons
                saveFuntion={handleSubmit(handleSave)}
                buttonConfirmText="Salvar"
                cancelFuntion={goToBack}
                isValid={true}
            />
        );
    }

    function RenderSubTitle() {
        if (params?.type === 'viewPlan') {
            return null;
        }

        return (
            <S.TextTitle>{subTitleHeader}</S.TextTitle>
        );
    }

    return (
        <S.Container>
            <S.ScrollView>
                <DashboardHeader
                    title={renderTitle()}
                    typeHeader="home"
                    goToScreen={goToBack}
                    goBack
                    disableInputSearch
                />
                <S.ContainerAlign>
                    <S.Content width={window}>
                        <S.InfoArea width={window}>
                            {RenderSubTitle()}
                            <S.InputContainer width={window}>
                                <InputInternal
                                    width="100%"
                                    label="Nome*"
                                    placeholder="Digite o nome do plano"
                                    control={control}
                                    name="name"
                                    errors={errors}
                                    inicialValue={name}
                                    disabled={params?.type === 'viewPlan'}
                                />
                            </S.InputContainer>
                            <S.InputContainer width={window}>
                                <InputInternal
                                    width={widthForTwoColumns}
                                    label="Qnt. de artes*"
                                    placeholder="Digite a qnt. de artes"
                                    control={control}
                                    name="amountArts"
                                    errors={errors}
                                    inicialValue={amount_arts}
                                    disabled={params?.type === 'viewPlan'}
                                />
                                <InputInternal
                                    width={widthForTwoColumns}
                                    label="Qnt. de postagens*"
                                    placeholder="Digite a qnt. de postagens"
                                    control={control}
                                    name="amountPosts"
                                    errors={errors}
                                    inicialValue={amount_posts}
                                    disabled={params?.type === 'viewPlan'}
                                />
                            </S.InputContainer>
                            <S.InputContainer width={window}>
                                <InputInternal
                                    width={widthForTwoColumns}
                                    label="Valor do plano*"
                                    placeholder="Insira o valor"
                                    control={control}
                                    name="price"
                                    errors={errors}
                                    mask="money"
                                    inicialValue={price}
                                    disabled={params?.type === 'viewPlan'}
                                />
                                <InputInternal
                                    width={widthForTwoColumns}
                                    label="Status*"
                                    placeholder="Selecione"
                                    control={control}
                                    name="status"
                                    errors={errors}
                                    list={statusPlans}
                                    inicialValue={status}
                                    disabled={params?.type === 'viewPlan'}
                                />
                            </S.InputContainer>
                            <S.Label>Padrão*</S.Label>
                            <S.CheckboxArea>
                                {Checkbox('Sim', 1)}
                                {Checkbox('Não', 2, true)}
                            </S.CheckboxArea>
                        </S.InfoArea>
                        <CoditionModal
                            titleModal={modalInfo.titleModal}
                            descriptionModal={modalInfo.descriptionModal}
                            typeModal={modalInfo.typeModal}
                            buttonText={modalInfo.buttonText}
                            modalVisible={modalInfo.modalVisible}
                            setModalVisible={modalInfo.setModalVisible}
                            buttonFunction={modalInfo.buttonFunction}
                        />
                        <LoadingModal
                            visible={modalLoadingVisible}
                            titleLoading={titleLoading}
                        />
                        {RenderButton()}
                    </S.Content>
                </S.ContainerAlign>
            </S.ScrollView>
        </S.Container>
    );
}
