import styled, { css } from 'styled-components/native';

export const CloseIcon = styled.Image`
    height: 12px;
    width: 12px;
    color: white;
`;

export const CloseButton = styled.TouchableOpacity`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 13px;
    align-self: flex-end;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
`;
