import styled from 'styled-components/native';
import { ResponsiveProps } from '../../../../global/types/index';

interface TableInfoAreaProps {
    typeInfo: 'header' | 'info';
    width: string | number;
}

export const TableInfoArea = styled.View<TableInfoAreaProps>`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: ${({ width }) => width};
`;

export const TableInfoContent = styled.View`
    flex: 1;
    flex-direction: row;
    align-items: center;
`;

export const TextDefault = styled.Text<TableInfoAreaProps>`
    color: ${({ theme, typeInfo }) =>
        theme.colors.gray[typeInfo === 'header' ? 150 : 400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: ${({ typeInfo, width }) =>
        width >= 800 ? (typeInfo === 'header' ? 12 : 14) : 10};
    width: 80%;
    margin-left: 16px;
    margin-right: 5px;
`;

export const Bar = styled.View`
    background-color: ${({ theme }) => theme.colors.gray[150]};
    height: 25px;
    width: 1px;
`;

export const FirstLetterContainer = styled.View`
    width: 36px;
    height: 36px;
    background-color: ${({ theme }) => theme.colors.purple.rgba[50]};
    border-radius: 18px;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
`;

export const FirstLetterText = styled.Text`
    color: ${({ theme }) => theme.colors.purple[500]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 16px;
`;

export const Photo = styled.Image`
    width: 36px;
    height: 36px;
    border-radius: 18px;
`;

export const AdjustName = styled.View`
    flex-direction: row;
    align-items: flex-end;
`;
