import * as yup from 'yup';

const phoneRegExp = /^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;
const cpfOrCnpj = /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/

function selectedVadilate(val: string | undefined) {
    const validate = Number(val) !== 0;
    return validate;
}

export const schemaEditProfile = yup
    .object({
        name: yup.string().required('Digite seu nome'),
        phone: yup
            .string()
            .required('Digite seu celular')
            .matches(phoneRegExp, 'Número de celular inválido'),
        email: yup
            .string()
            .required('Digite seu email')
            .email('Email inválido'),
    })
    .required();

export const schemaChangePassword = yup
    .object({
        password: yup
            .string()
            .required('Digite sua senha')
            .min(6, 'Digite sua senha com mínimo 6 digitos'),
        confirmPassword: yup
            .string()
            .required('Digite sua senha')
            .min(6, 'Digite sua senha com mínimo 6 digitos')
            .oneOf([yup.ref('password')], 'As senhas não conferem'),
    })
    .required();

export const schemaSocialMedia = yup
.object({
    socialMedia: yup
        .string()
})
.required();

export const schemaFormatMedia = yup
.object({
    type: yup
        .string()
})
.required();

export const schemaCreateVehicles = yup
    .object({
        board: yup.string().required('Digite a placa do veículo'),
        km: yup.number().typeError('Digite os km rodados').required('Digite os km rodados'),
        condition: yup
            .string()
            .required('Selecione a condição')
            .test(
                'Selecione a condição',
                'Selecione a condição',
                selectedVadilate,
            ),
        type: yup.string().required('Digite o tipo'),
        bodywork: yup.string().required('Digite a carroceria'),
        brand: yup.string().required('Digite a marca'),
        model: yup.string().required('Digite o modelo'),
        version: yup.string().required('Digite a versão'),
        yearManufacturer: yup.string().required('Digite o ano/fabricação'),
        yearModel: yup.string().required('Digite o ano/modelo'),
        doors: yup.string().required('Digite a qnt. de portas'),
        gearshift: yup
            .string()
            .required('Selecione o câmbio')
            .test('Selecione o câmbio', 'Selecione o câmbio', selectedVadilate),
        color: yup.string().required('Digite a cor do veículo'),
        traction: yup.string().required('Digite o tipo de tração do veículo'),
        sellerName: yup.string().required('Digite o nome do vendedor'),
        sellerWhatsapp: yup
            .string()
            .required('Digite o whatsapp')
            .matches(phoneRegExp, 'Número de whatsapp inválido'),
        price: yup.string().required('Digite o preço de venda'),
        exchangePrice: yup.string().required('Digite o preço de troca'),
        promotionalPrice: yup.string().required('Digite o preço promocional'),
        inputValue: yup.string().required('Digite o valor de entrada'),
        installmentValue: yup.string().required('Digite o valor da parcela'),
        numberInstallments: yup.number().required('Selecione o Nº de parcelas'),
        typeFinancing: yup
            .string()
            .required('Selecione o tipo de financiamento')
            .test(
                'Selecione o tipo de financiamento',
                'Selecione o tipo de financiamento',
                selectedVadilate,
            ),
        title: yup.string().required('Informa o título do carro'),
        isTrade: yup.string().required('Aceita troca?'),
        isPayIPVA: yup.string().required('Informe se o IPVA foi pago'),
        isOwnerUnique: yup.string().required('Digite o número de donos do veículo'),
        isFreeTransfer: yup.string().required('Tranferência gratuíta'),
        isFullTank: yup.string().required('Informe se o tanque é cheio'),
    })
    .required();

export const schemaCreateClients = yup
    .object({
        name: yup.string().required('Digite o nome da loja'),
        email: yup.string().required('Digite o email').email('Email com formato inválido'),
        document: yup.string().required('Insira seu CNPJ/CPF').matches(cpfOrCnpj, 'CNPJ/CPF inválido'),
        stateRegistration: yup.string(),
        // address: yup.string().required('Digite seu endereço'),
        // stateAddress: yup.string().required('Digite seu estado (UF)'),
        // cep: yup.string().required('Digite seu CEP'),
        // numberAddress: yup
        //     .number().typeError('Digite o número da sua residência')
        //     .required('Digite o número da sua residência'),
        // complement: yup.string(),
        // district: yup.string().required('Digite seu bairro'),
        // city: yup.string().required('Digite o nome da sua cidade'),
        phone: yup
            .string(),
            // .required('Digite seu telefone')
            // .matches(phoneRegExp, 'Número de telefone inválido'),
        whatsapp: yup
            .string(),
        technicianName: yup.string(),
        // .required('Digite o nome do resp. técnico'),
        technicianEmail: yup
            .string(),
            // .required('Digite o email do resp. técnico').email('Email com formato inválido'),
        technicianWhatsapp: yup
            .string(),
            // .required('Digite o whatsapp')
            // .matches(phoneRegExp, 'Número de whatsapp inválido'),
        site: yup.string(),
        instagram: yup.string(),
        facebook: yup.string(),
        tiktok: yup.string(),
        idPlan: yup.number().required('Selecione o plano'),
        // signature: yup.string().required('Insira a assinatura'),
        buyLimit: yup.string().required('Digite o limite de compra'),
        images: yup.string(),
        defaultText: yup.string(),
    })
    .required();

export const schemaNewPlan = yup
    .object({
        name: yup.string().required('Digite o nome do plano'),
        price: yup.string().required('Insira o valor'),
        status: yup
            .string()
            .required('Selecione o status')
            .test('Selecione o status', 'Selecione o status', selectedVadilate),
        amountPosts: yup.string().required('Digite a quantidade de posts.'),
        amountArts: yup.number().required('Digite a qnt. de artes'),
    })
    .required();

export const schemaLogin = yup
    .object({
        email: yup
            .string()
            .required('Digite seu email para continuar')
            .email('Email inválido'),
        password: yup
            .string()
            .required('Digite sua senha')
            .min(6, 'Digite sua senha com mínimo 6 digitos'),
    })
    .required();

export const schemaForgotPassword = yup
    .object({
        email: yup
            .string()
            .required('Digite seu email para continuar')
            .email('Email inválido'),
    })
    .required();

export const schemaFiltersConnections = yup
    .object({
        idPlan: yup
            .string(),
        status: yup
            .string()
    })
    .required();

export const shemaCreateArt = yup
    .object({
        title: yup.string().required('Digite o título da arte'),
        colorPrimary: yup
            .string()
            .required('Selecione a cor primária da arte')
            .test('Selecione a cor primária da arte', 'Selecione a cor primária da arte', selectedVadilate),
        colorSecundary: yup
            .string()
            .optional(),
        colorTerciary: yup
            .string()
            .optional(),
        colorQuaternary: yup
            .string()
            .optional(),
        idPlan: yup
            .string()
            .required('Selecione seu plano')
            .test('Selecione seu plano', 'Selecione seu plano', selectedVadilate),
        useSelected: yup
            .string()
            .required('Selecione o uso')
            .test('Selecione o uso', 'Selecione o uso', selectedVadilate),
        type: yup
            .string()
            .required('Selecione o tipo')
            .test('Selecione o tipo', 'Selecione o tipo', selectedVadilate),
        keyWords: yup.string().required('Digite sua palavra-chave'),
    })
    .required();

export const shemaSocialMedia = yup.object({
    type: yup
    .string()
    .required('Selecione o tipo')
    .test('Selecione o tipo', 'Selecione o tipo', selectedVadilate),
}).required();
