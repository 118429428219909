import theme from '../../../../../global/styles/theme';
import styled from 'styled-components/native';

import { ResponsiveProps } from '../../../../../global/types';

interface TextProps {
    marginTop?: number;
}

interface InputProps {
    error: boolean,
}

export const ScrollView = styled.ScrollView``;

export const TextDefault = styled.Text<TextProps>`
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 22px;
    margin-bottom: 30px;
    margin-top: ${({ marginTop }) => marginTop || '0px'};
`;

export const AdjustBoxArea = styled.View<ResponsiveProps>`
    width: ${({ width }) => (width < 800 ? '100%' : '49%')};
`;

export const ResizeBoxArea = styled.TouchableOpacity`
    background-color: ${({ theme }) => theme.colors.purple[500]};
    padding: 10px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin-top: 20px;
    width: 40px;
`;

export const InputContainer = styled.View<ResponsiveProps>`
    width: 100%;
    /* align-items: center; */
    justify-content: space-between;
    flex-direction: ${({ width }) => (width < 800 ? 'column' : 'row')};
    flex-wrap: wrap;
`;

export const Form = styled.View<ResponsiveProps>`
    padding-right: ${({ width }) => (width >= 800 ? 30 : 0)};
`;

export const BasicInfoArea = styled.View<ResponsiveProps>`
    width: 100%;
    flex-direction: ${({ width }) => (width < 1300 ? 'column' : 'row')};
    margin-bottom: 20px;
`;

export const BasicInfoInputs = styled.View<ResponsiveProps>`
    width: ${({ width }) => (width < 1300 ? (width < 400 ? width / 1.2 : '100%') : '88%')};
    max-width: ${({ width }) => (width < 1300 ? (width < 400 ? width / 1.2 : '100%') : '88%')};
`;

export const AddPhotoArea = styled.View<ResponsiveProps>`
    align-items: ${({ width }) => (width < 1300 ? 'center' : 'flex-start')};
    width: ${({ width }) => (width < 1300 ? '100%' : '12%')};
`;

export const AddButtonPhoto = styled.TouchableOpacity``;

export const UserPhoto = styled.Image`
    height: 112px;
    width: 112px;
`;

export const AddPhotoText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 10px;
    margin-top: 6px;
`;

export const ButtonArea = styled.View`
    margin-top: 40px;
`;

export const PhotoArea = styled.View`
    align-items: center;
`;

export const LogoButton = styled.TouchableOpacity<InputProps>`
    flex-direction: row;
    padding: 10px;
    margin-top: 6px;
    height: 40px;
    border-color: ${({ theme }) => theme.colors.red[500]};
    border-radius: 6px;
    border-width: ${({ error }) => error ? 1 : 0};
    background-color: ${({ theme }) => theme.colors.gray[300]};
    align-items: center;
    width: 100%;
`;

export const LogoText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    width: 100%;
    margin-left: 14.5px;
`;

export const LogoArea = styled.TouchableOpacity`
    height: 60px;
    margin-top: 12px;
    margin-right: 8px;
    width: 60px;
`;

export const Logo = styled.ImageBackground`
    align-items: center;
    height: 60px;
    justify-content: center;
    margin-top: 12px;
    width: 60px;
`;

export const CloseImage = styled.Image`
    tint-color: ${({ theme }) => theme.colors.purple[500]};
    height: 30px;
    width: 30px;
`;

export const Label = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const AlignLogoArea = styled.View<ResponsiveProps>`
    align-self: flex-start;
    width: ${({ width, validationInput }) =>
        width <= 800 ? '100%' : '70%'};
`;

export const LogoAlign = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
`;

export const InputAlign = styled.View<ResponsiveProps>`
    align-self: flex-start;
    width: ${({ width }) => (width <= 800 ? '100%' : '28%')};
`;

export const InputPassword = styled.TextInput.attrs({
    placeholderTextColor: theme.colors.gray[400],
})`
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    outline-width: 0px;
    width: 100%;
`;

export const InputContainerPassword = styled.View<InputProps>`
    flex-direction: row;
    padding: 10px;
    margin-top: 6px;
    height: 40px;
    border-color: ${({ theme }) => theme.colors.red[500]};
    border-radius: 6px;
    border-width: ${({ error }) => error ? 1 : 0};
    background-color: ${({ theme }) => theme.colors.gray[300]};
    align-items: center;
`;

export const ContainerPassword = styled.View<ResponsiveProps>`
    width: ${({ width }) => width || '100%'};
    margin-bottom: 16px;
`;
