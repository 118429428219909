import React, { useState } from 'react';

import * as S from './styles';
import { deletePlans } from '../../../../services/plans';

import { Modal } from '../../../Modal';
import { CoditionModal } from '../../../../components/CoditionModal';
import { DeleteModal } from '../DeleteModal';

interface PlanListProps {
    name: string,
    id: number,
}

interface ModalInfoProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    buttonFunction: () => void,
}

interface SelectedPlansProps {
    plansList: Array<PlanListProps>,
    modalVisible: boolean,
    changeModalVisible: () => void,
    refreshList: () => void,
    planDeletedId: number,
}

export function SelectedPlansModal(props: SelectedPlansProps) {
    const { plansList, modalVisible, planDeletedId, refreshList, changeModalVisible } = props;

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [nameOption, setNameOption] = useState<PlanListProps>({} as PlanListProps);
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false);

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    async function handleDelete() {
        const response = await deletePlans(planDeletedId, nameOption.id);

        if (response?.status === 200) {
            const modalProps: ModalInfoProps = {
                typeModal: 'success',
                titleModal: 'Sucesso',
                descriptionModal: response?.message,
                buttonText: 'Continuar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: refreshList,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        } else {
            setTimeout(() => {
                const modalProps: ModalInfoProps = {
                    typeModal: 'error',
                    titleModal: 'Erro',
                    descriptionModal: response?.message,
                    buttonText: 'Fechar',
                    modalVisible: true,
                    setModalVisible: changeModalInfoVisible,
                    buttonFunction: changeModalInfoVisible,
                };

                setModalInfo(modalProps);
            }, 500);
        }
    }

    function changeModalDeleteVisible() {
        setModalDeleteVisible(!modalDeleteVisible);
    }

    function handleDropdownPress() {
        setDropdownVisible(!dropdownVisible);
    }

    function renderDropdown() {
        if (!dropdownVisible) {
            return null;
        }

        return (
            <S.DropdownListArea>
                {renderDropdownList()}
            </S.DropdownListArea>
        );
    }

    function showModal() {
        setDropdownVisible(false);
        setNameOption({ name: '' } as PlanListProps);
        changeModalVisible();
    }

    function renderDropdownList() {
        return (plansList || []).map((el) => {
            if (el.id === planDeletedId) {
                return null;
            }

            const handleOptionPress = () => {
                setNameOption({
                    name: el.name,
                    id: el.id,
                });
            }

            return (
                <S.DropdownList key={el.id} onPress={handleOptionPress}>
                    <S.TextDefault
                        fontSize={13}
                    >
                        {el.name}
                    </S.TextDefault>
                </S.DropdownList>
            );
        })
    }

    return (
        <S.ModalArea isVisible={modalVisible} backdropOpacity={0.4} onBackdropPress={showModal}>
            <S.ContainerModal>
                <S.ContainerInfo>
                    <S.TextDefault
                        fontSize={14}
                    >
                        Selecione o plano que será trocado no lugar do excluído:
                    </S.TextDefault>
                    <S.DropdownArea onPress={handleDropdownPress}>
                        <S.TextDefault fontSize={12}>
                            {nameOption.name || 'Selecione o plano'}
                        </S.TextDefault>
                    </S.DropdownArea>
                    {renderDropdown()}
                </S.ContainerInfo>
                <S.ContinueButton disabled={!nameOption.id} onPress={changeModalDeleteVisible} optionSelected={nameOption.id}>
                    <S.ContinueButtonText>
                        Continuar
                    </S.ContinueButtonText>
                </S.ContinueButton>
            </S.ContainerModal>
            <Modal isVisible={modalDeleteVisible} closeModal={changeModalDeleteVisible}>
                <DeleteModal
                    handleCancelPress={changeModalDeleteVisible}
                    handleConfirmPress={handleDelete}
                    typeScreen='plans'
                />
            </Modal>
            <CoditionModal
                titleModal={modalInfo.titleModal}
                descriptionModal={modalInfo.descriptionModal}
                typeModal={modalInfo.typeModal}
                buttonText={modalInfo.buttonText}
                modalVisible={modalInfo.modalVisible}
                setModalVisible={modalInfo.setModalVisible}
                buttonFunction={modalInfo.buttonFunction}
            />
        </S.ModalArea>
    );
}
