// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { useNavigation, useIsFocused } from '@react-navigation/native';

import { DashboardHeader } from '../../../components/DashboardHeader';
import { InputSearch } from '../../../components/InputSearch';
import { Table } from '../../../components/Table';
import { Loading } from '../../../components/Loading';

import { getPlans } from '../../../services/plans';
import * as S from './styles';

interface PlansProps {
    id: number;
    name: string;
    price: number;
    amount_arts: number;
    amount_posts: number;
    status: 'active' | 'inactive';
}

interface InfoTableProps {
    amount: number;
    list: PlansProps[];
}

export function PlansScreen() {
    const navigation = useNavigation();
    const window = useWindowDimensions().width;
    const focused = useIsFocused();

    const [inputSearchName, setInputSearchName] = useState('');
    const [inputSearchStatus, setInputSearchStatus] = useState('');
    const [infoTable, setInfoTable] = useState<InfoTableProps>(
        {} as InfoTableProps,
    );
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState(1);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    function goToNewPlan() {
        const props = {
            type: 'create',
            item: {},
        };
        navigation.navigate('CreatePlansScreen', props);
    }

    async function getAllPlans(paginatinoNumber: number, inputSearch: string, inputStatus: string) {
        const data = await getPlans(
            paginatinoNumber,
            inputSearch,
            inputStatus,
        );
        if (data?.status === 200) {
            setInfoTable(data.result);
        }
        setLoading(false);
    }

    function handleDropdownPress() {
        setDropdownVisible(!dropdownVisible);
    }

    function RenderDropdown() {
        if (!dropdownVisible) {
            return null;
        }

        const filterAll = () => {
            getAllPlans(pagination, inputSearchName, '');
            handleDropdownPress();
        }

        const filterActive = () => {
            getAllPlans(pagination, inputSearchName, 'active');
            handleDropdownPress();
        }

        const filterPending = () => {
            getAllPlans(pagination, inputSearchName, 'inactive');
            handleDropdownPress();
        }

        return (
            <S.DropdownArea width={window}>
                <S.DropdownOption onPress={filterAll}>
                    <S.DropdownOptionText>
                        Todos
                    </S.DropdownOptionText>
                </S.DropdownOption>
                <S.DropdownOption onPress={filterActive}>
                    <S.DropdownOptionText>
                        Ativos
                    </S.DropdownOptionText>
                </S.DropdownOption>
                <S.DropdownOption onPress={filterPending}>
                    <S.DropdownOptionText>
                        Inativos
                    </S.DropdownOptionText>
                </S.DropdownOption>
            </S.DropdownArea>
        );
    }

    function renderTable() {
        if (loading) {
            return (
                <Loading />
            );
        }

        return (
            <Table
                totalItem={infoTable?.amount || 0}
                currentPage={pagination}
                setCurrentPage={setPagination}
                qtdPage={infoTable?.qtdPages || 1}
                data={infoTable?.list || []}
                type="plans"
                widthTable={window < 800 ? 800 : undefined}
            />
        );
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getAllPlans(pagination, inputSearchName, '');
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [inputSearchName, focused, pagination]);

    useEffect(() => {
        getAllPlans(pagination, inputSearchName, '');
    }, [focused, pagination]);

    return (
        <S.Container>
            <DashboardHeader
                title="Planos"
                typeHeader="manageClients"
                goToScreen={goToNewPlan}
                buttonLabel="Novo plano"
                disableInputSearch
            />
            <S.InputSearchArea width={window}>
                <InputSearch
                    value={inputSearchName}
                    setValue={setInputSearchName}
                    placeholder="Buscar por nome"
                    marginLeft={0}
                    disabled={loading}
                    widthArea={235}
                />
                <S.RequestFilterButton onPress={handleDropdownPress}>
                    <InputSearch
                        value={''}
                        setValue={setInputSearchName}
                        placeholder="Buscar por status"
                        marginLeft={20}
                        disabled
                        arrowIcon
                        widthArea={235}
                    />
                    {RenderDropdown()}
                </S.RequestFilterButton>
            </S.InputSearchArea>
            <S.ContainerAlign>{renderTable()}</S.ContainerAlign>
        </S.Container>
    );
}
