import { TextInputMask } from 'react-native-masked-text';
import styled from 'styled-components/native';
import Modal from 'react-native-modal';

import Theme from '../../global/styles/theme';

interface TextProps {
    fontSize: number;
    color: string;
    fontFamily: 'bold' | 'regular';
    marginTop: number;
}

interface InputProps {
    marginTop?: number,
}

export const ContainerModal = styled(Modal)`
    margin: 0px;
    flex: 1;
`;

export const ModalArea = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    height: 276px;
    padding: 27px 47px 24px 35px;
    width: 276px;
`;

export const TextDefault = styled.Text<TextProps>`
    color: ${({ color }) => color};
    font-family: ${({ fontFamily, theme }) =>
        fontFamily === 'bold'
            ? theme.font.segoeUi.bold
            : theme.font.segoeUi.regular};
    font-size: ${({ fontSize }) => fontSize};
    ${({ marginTop }) => `margin-top: ${marginTop || 0}px`}
`;

export const SchedulingButton = styled.TouchableOpacity`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 6px;
    height: 39px;
    justify-content: center;
    margin-top: 25px;
    width: 100%;
`;

export const SchedulingInputArea = styled.TouchableOpacity<InputProps>`
    align-items: center;
    height: 40px;
    border: 2px solid #6746C8;
    border-radius: 6px;
    flex-direction: row;
    justify-content: center;
    ${({ marginTop }) => `margin-top: ${marginTop || 0}px`}
`;

export const InputHour = styled(TextInputMask).attrs({
    placeholderTextColor: Theme.colors.gray[700],
})`
    color: ${({ theme }) => theme.colors.gray[700]};
    margin-left: 14px;
    outline-width: 0px;
    width: 50px;
`;

export const InputStyle = {
    height: 25,
    marginLeft: 10,
    border: "0px",
    width: 110,
    outlineWidth: 0,
    WebkitAppearance: 'none',
    appearance: null,
    MozAppearance: 'none',
};
