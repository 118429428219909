// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Circle, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.5}
    height={15.518}
    {...props}
  >
    <G
      data-name="award (1)"
      transform="translate(-4.25 -.25)"
      fill="rgba(0,0,0,0)"
      stroke="#e3e932"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <Circle
        data-name="Elipse 38"
        cx={4.5}
        cy={4.5}
        r={4.5}
        transform="translate(5 1)"
      />
      <Path
        data-name="Caminho 83909"
        d="m7.045 9.213-.771 5.805 3.186-1.912 3.186 1.912-.772-5.811"
      />
    </G>
  </Svg>
)

export default SvgComponent
