import styled, { css } from 'styled-components/native';
import { ResponsiveProps } from '../../../global/types';
import Feather from '@expo/vector-icons/Feather';

export const Container = styled.View<ResponsiveProps>`
    background-color: ${({ theme }) => theme.colors.white};
    flex: 1;
    padding: ${({ width }) => width < 500 ? '40px 10px 10px 10px' : '40px 15px 40px 30px'};
`;

export const TextDefault = styled.Text`
    color: ${({ theme }) => theme.colors.gray[600]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 18px;
`;

export const ContainerAlign = styled.ScrollView`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    width: 100%;
    /* padding-right: 20px; */
`;

export const Content = styled.View<ResponsiveProps>`
    display: grid;
    justify-content: center;
    place-items: center;
    ${({ width }) =>
        width > 1800 &&
        css`
            grid-template-columns: repeat(5, 1fr);
        `}
    ${({ width }) =>
        width >= 1520 &&
        width <= 1800 &&
        css`
            grid-template-columns: repeat(4, 1fr);
        `}
    ${({ width }) =>
        width >= 1200 &&
        width <= 1520 &&
        css`
            grid-template-columns: repeat(3, 1fr);
        `}
    ${({ width }) =>
        width >= 300 &&
        width <= 1200 &&
        css`
            grid-template-columns: repeat(2, 1fr);
        `}
    gap: ${({ width }) => (width < 880 ? '20px' : '0px')};
    margin-top: 40px;
`;

export const LoadingPaginationContainer = styled.View`
    margin-top: 20px;
    align-self: center;
`;

export const ButtonLoadMore = styled.TouchableOpacity`
    margin-top: 20px;
    align-self: center;
`;

export const ButtonLoadMoreIcon = styled(Feather)`
    color: ${({ theme }) => theme.colors.purple[500]};
    font-size: 30px;
`;
