import styled from 'styled-components/native';
import theme from '../../global/styles/theme';

export const InputContainer = styled.View`
    margin-bottom: 26px;
`;

export const Container = styled.View`
    border-color: ${({ theme }) => theme.colors.gray[500]};
    border-width: 1px;
    border-radius: 6px;
    height: 50px;
    margin-top: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 17px;
`;

export const Label = styled.Text`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray[900]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
`;

export const Input = styled.TextInput.attrs({
    placeholderTextColor: theme.colors.gray[500],
})`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    outline-color: transparent;
    width: 90%;
`;

export const SecureButton = styled.TouchableOpacity``;

export const Erros = styled.Text`
    color: ${({ theme }) => theme.colors.red[300]};
    font-size: 10px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    margin-top: 2px;
`;
