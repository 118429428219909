import React, { useEffect, useState } from 'react';
import { format, subDays, addDays } from 'date-fns';
import { useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';

// components
import { Card } from './components/Card';
import { ModalModels } from './components/ModalModels';

// styles
import * as S from './styles';

// srvices
import { getBirthday } from '../../../services/user';

// interfaces
interface BirthDaysListProps {
    age: number;
    birth_date: string;
    city: string;
    id: number;
    name: string;
    is_send: boolean;
}

export function BirthDays() {
    const [listYesterday, setListYesterday] = useState<BirthDaysListProps[]>(
        [],
    );
    const [listToday, setListToday] = useState<BirthDaysListProps[]>([]);
    const [listTomorrow, setListTomorrow] = useState<BirthDaysListProps[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [birthdayInfo, setBirthdayInfo] = useState<BirthDaysListProps>(
        {} as BirthDaysListProps,
    );
    const [seventhDay, setSeventhDay] = useState<string[]>([]);
    const [sevenDaysList, setSevenDaysList] = useState({} as any);

    const { user } = useSelector((state: any) => state.user.user);
    const yesterday = format(subDays(new Date(), 1), 'dd/MM');
    const today = format(new Date(), 'dd/MM');
    const tomorrow = format(addDays(new Date(), 1), 'dd/MM');

    function renderCards(list: any) {
        return (list || []).map((card: BirthDaysListProps) => {
            function handleSentBirthday() {
                handleBackdropPress();
                setBirthdayInfo(card);
            }

            return (
                <Card data={card} handleSentBirthday={handleSentBirthday} />
            );
        });
    }

    async function getTodayBirthday() {
        const response = await getBirthday(today, 'last_days', user?.id);
        if (response.status === 200) {
            setListYesterday(response?.result?.yesterday);
            setListToday(response?.result?.today);
            setListTomorrow(response?.result?.tomorrow);
        }
    }

    async function getBirthdaysFoTheNextSevenDays() {
        if (seventhDay.length === 0) {
            const listDays: Array<string> = [];
            const size = 7;
            for (let i = 1; i <= size; i++) {
                const tomorrow = format(addDays(new Date(), i + 1), 'dd/MM');
                listDays.push(tomorrow);
            }
            setSeventhDay(listDays);

            const listDaysString = listDays.toString().replaceAll(',', '|');
            const response = await getBirthday(listDaysString, 'all', user?.id);
            if (response.status === 200) {
                setSevenDaysList(response?.result?.list);
            }
        } else {
            setSeventhDay([]);
        }
    }

    function handleBackdropPress() {
        setModalVisible(!modalVisible);
        getTodayBirthday();
    }

    function renderBirthDayToday() {
        return (
            <>
                <S.Header>
                    <S.Title>Aniversariantes ontem {yesterday}</S.Title>
                </S.Header>
                <S.BirthListContainer>
                    {renderCards(listYesterday)}
                </S.BirthListContainer>
                <S.Divider />
                <S.Header>
                    <S.Title>Aniversariantes hoje {today}</S.Title>
                </S.Header>
                <S.BirthListContainer>
                    {renderCards(listToday)}
                </S.BirthListContainer>
                <S.Divider />
                <S.Header>
                    <S.Title>Aniversariantes amanhã {tomorrow}</S.Title>
                </S.Header>
                <S.BirthListContainer>
                    {renderCards(listTomorrow)}
                </S.BirthListContainer>
                <S.Divider />
            </>
        );
    }

    function renderListForSeventhDay() {
        return (seventhDay || []).map((day: string) => (
            <>
                <S.Header>
                    <S.Title>Aniversariantes de {day}</S.Title>
                </S.Header>
                <S.BirthListContainer>
                    {renderCards(sevenDaysList[day])}
                </S.BirthListContainer>
                <S.Divider />
            </>
        ));
    }

    useEffect(() => {
        getTodayBirthday();
    }, []);

    return (
        <S.Container>
            {renderBirthDayToday()}
            {renderListForSeventhDay()}
            <S.ButtonContainer>
                <S.Button onPress={getBirthdaysFoTheNextSevenDays}>
                    <S.ButtonText>
                        {seventhDay.length > 0
                            ? 'Voltar'
                            : 'Ver próximos 7 dias'}
                    </S.ButtonText>
                </S.Button>
            </S.ButtonContainer>
            <ModalModels
                handleBackdropPress={handleBackdropPress}
                isVisible={modalVisible}
                birthInfo={birthdayInfo}
            />
        </S.Container>
    );
}
