import styled, { css } from 'styled-components/native';
import Feather from '@expo/vector-icons/Feather';

import { ResponsiveProps } from '../../global/types';
import LogoSvg from '../../assets/images/svg/logoSvgDrawer';
import GoBackDrawer from '../../assets/images/svg/goBackDrawer';

interface FocusedProps {
    isFocused: boolean;
}

interface OpenProps extends ResponsiveProps {
    isOpen: boolean;
}

export const Container = styled.View<ResponsiveProps>`
    ${({ width }) => width > 789 && `flex-direction: row`}
    background-color: ${({ theme }) => theme.colors.gray[800]};
    padding: 6px 6px 6px 0px;
    flex: 1;
`;

export const Content = styled.View`
    flex: 1;
`;

export const DrawerContainer = styled.View<OpenProps>`
    ${({ isOpen, width }) => isOpen && width > 789 && 'width: 233px'}
    ${({ isOpen, width }) => !isOpen && width > 789 && 'width: 100px'}
    ${({ width, isOpen }) =>
        isOpen && width <= 789 && 'width: 100%; height: 100%;'}
`;

export const HeaderContainer = styled.View<OpenProps>`
    margin: 30px 0px 20px 30px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Logo = styled(LogoSvg)<OpenProps>`
    ${({ width }) => width <= 769 && 'width: 132.99px;'}
    ${({ isOpen, width }) => !isOpen && width > 769 && 'width: 19.3px;'}
`;

export const GoBackButton = styled.TouchableOpacity``;

export const GoBackIcon = styled(GoBackDrawer)<OpenProps>`
    ${({ isOpen }) =>
        isOpen
            ? css`
                  margin-right: 20;
              `
            : css`
                  transform: rotate(180deg);
                  margin-right: 14.5px;
              `}
`;

export const MenuIcon = styled(Feather)`
    color: ${({ theme }) => theme.colors.white};
    font-size: 28px;
    margin-right: 10px;
`;

export const NavigationContainer = styled.View<OpenProps>`
    margin-top: 26px;
    flex: 1;
    overflow-y: auto;
`;

export const ButtonNavigation = styled.TouchableOpacity<FocusedProps>`
    ${({ isFocused }) =>
        isFocused &&
        css`
            background-color: ${({ theme }) => theme.colors.gray[300]};
        `}
    flex-direction: row;
    align-items: center;
    height: 70px;
`;

export const IconContainer = styled.View<OpenProps>`
    ${({ isOpen }) =>
        isOpen
            ? css`
                  width: 48px;
                  margin-left: 20px;
              `
            : css`
                  align-items: center;
                  justify-content: center;
                  width: 100%;
              `}
`;

export const IconNavigation = styled(Feather)<FocusedProps>`
    font-size: 25px;
    color: ${({ theme, isFocused }) =>
        isFocused ? theme.colors.purple[500] : theme.colors.gray[600]};
`;

export const TextNavigation = styled.Text<FocusedProps>`
    color: ${({ theme, isFocused }) =>
        isFocused ? theme.colors.purple[500] : theme.colors.gray[600]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 16px;
`;

export const UserContainer = styled.TouchableOpacity`
    flex-direction: row;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: ${({ theme }) => theme.colors.gray[700]};
    padding: 16px;
    margin: 0px 12px 0px 12px;
`;

export const Infos = styled.View`
    margin-left: 20px;
`;

export const UserImage = styled.Image`
    width: 40px;
    height: 40px;
    border-radius: 20px;
`;

export const Name = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 16px;
`;

export const Permission = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
    margin-top: 2px;
`;
