// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Picker } from '@react-native-picker/picker';

import * as S from './styles';
import { InputSearch } from '../InputSearch';
import AddIcon from '../../assets/images/svg/addIcon';
import GoBackIcon from '../../assets/images/svg/goBackIcon';
import CopyIcon from '../../assets/images/svg/copy';
import EditIcon from '../../assets/images/svg/edit';
import { useDispatch, useSelector } from 'react-redux';
import { filtersGlobal } from '../../store/modules/user/actions';
import { getEventList } from '../../services/events';
import { Modal } from '../../components/Modal';
import { listColors } from '../../global/utils/lists';
import { getStoreList } from '../../services/arts';
import { getPlans } from '../../services/plans';

interface HeaderProps {
    title: string;
    typeHeader: 'home' | 'manageClients' | 'logs';
    goToScreen?: () => void;
    goBack?: boolean;
    buttonLabel?: string;
    typeButton?: 'dropdown';
    handleGetDock?: () => void;
    isInput?: boolean;
    disableInputSearch?: boolean,
    notPeriod?: boolean;
}

export function DashboardHeader(props: HeaderProps) {
    const { goBack, goToScreen, title, typeHeader, buttonLabel, typeButton, handleGetDock, isInput, disableInputSearch, notPeriod } =
        props;
    const window = useWindowDimensions().width;
    const filters = useSelector((state: any) => state.user.filtersGlobal);
    const dispatch = useDispatch();
    const typeList = [
        { label: 'Publicados', value: 'published_at' },
        { label: 'Agendados', value: 'date_send' },
        { label: 'Criados', value: 'created_at' },
        { label: 'Erros', value: 'error_at' },
    ];

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    // const [artType, setArtType] = useState('');
    const [idEvent, setIdEvent] = useState('');
    const [nameEvent, setNameEvent] = useState('');
    const [colorPrimary, setColorPrimary] = useState('');
    const [colorSecondary, setColorSecondary] = useState('');
    const [colorTertiary, setColorTertiary] = useState('');
    const [colorQuaternary, setColorQuaternary] = useState('');
    const [periodType, setPeriodType] = useState(filters?.periodType || 'published_at');
    const [idUser, setIdUser] = useState(filters?.idUser || '');
    const [idPlan, setIdPlan] = useState(filters?.idPlan || '');
    const [periodStart, setPeriodStart] = useState(filters?.periodStart || '');
    const [periodEnd, setPeriodEnd] = useState(filters?.periodEnd || '');
    const [searchStore, setSearchStore] = useState(filters?.searchStore || '');
    const [searchPlan, setSearchPlan] = useState(filters?.searchPlan || '');
    const [allStoreList, setAllStoreList] = useState<any[]>([]);
    const [allPlanList, setAllPlanList] = useState<any[]>([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownVisibleColorPrimary, setDropdownVisibleColorPrimary] = useState(false);
    const [dropdownVisibleColorSecondary, setDropdownVisibleColorSecondary] = useState(false);
    const [dropdownVisibleColorTertiary, setDropdownVisibleColorTertiary] = useState(false);
    const [dropdownVisibleColorQuaternary, setDropdownVisibleColorQuaternary] = useState(false);
    const [dropdownVisibleEvent, setDropdownVisibleEvent] = useState(false);
    const [eventList, setEventList] = useState<any[]>([]);
    const [dropdownUseVisible, setDropdownUseVisible] = useState(false);
    const [dropdownStatusVisible, setDropdownStatusVisible] = useState(false);
    const [status, setStatus] = useState('');
    const [use, setUse] = useState('');

    function onChangeInputGlobal(txt: string) {
        setSearchTxt(txt);
    }

    async function getAllEvents() {
        const { result } = await getEventList();
        setEventList(result.list);
    }

    async function getPlanName(txt: string) {
        const { result } = await getPlans(1, txt);

        setAllPlanList(result?.list || []);
    }

    async function getStoreName(txt: string) {
        const { result } = await getStoreList(1, txt);

        setAllStoreList(result?.list || []);
    }

    useEffect(() => {
        if (searchStore.length >= 3) {
            const timeoutId = setTimeout(() => {
                getStoreName(searchStore);
            }, 500);

            return () => clearTimeout(timeoutId);
        }
    }, [searchStore]);

    useEffect(() => {
        if (!searchPlan.length) {
            setIdPlan('');
        }
        const timeoutId = setTimeout(() => {
            getPlanName(searchPlan);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [searchPlan]);

    useEffect(() => {
        getAllEvents();
    }, []);

    useEffect(() => {
        if (!Object.values(filters)?.length) {
            removeAllFilter();
        }
    }, [filters?.periodType, filters?.idUser, filters?.idPlan, filters?.searchTxt, filters?.periodStart, filters?.periodEnd]);

    if (typeHeader === 'logs') {
        useEffect(() => {
            const timeOutId = setTimeout(() => {
                dispatch(filtersGlobal({
                    searchTxt,
                    periodType,
                    idUser,
                    idPlan,
                    searchStore,
                    searchPlan,
                    periodStart,
                    periodEnd
                }));
            }, 500);

            return () => clearTimeout(timeOutId);
        }, [searchTxt, periodType, idUser, idPlan, periodStart, periodEnd]);
    }
    if (typeHeader !== 'logs') {
        useEffect(() => {
            const timeOutId = setTimeout(() => {
                let formatIdsColors: Array<string> = [];
                if (colorPrimary) {
                    formatIdsColors.push(colorPrimary);
                }
                if (colorSecondary) {
                    formatIdsColors.push(colorSecondary);
                }
                if (colorTertiary) {
                    formatIdsColors.push(colorTertiary);
                }
                if (colorQuaternary) {
                    formatIdsColors.push(colorQuaternary);
                }
                dispatch(filtersGlobal({
                    searchTxt,
                    // artType,
                    status,
                    use,
                    idEvent,
                    idsColors: formatIdsColors.join(),
                }));
            }, 500);

            return () => clearTimeout(timeOutId);
        }, [searchTxt, status, use, idEvent, colorPrimary, colorSecondary, colorTertiary, colorQuaternary]);
    }

    function OptionArea() {
        if (typeHeader === 'home' || typeHeader === 'logs') {
            return null;
        }

        function handleButtonClick() {
            if (typeButton !== 'dropdown') {
                goToScreen();
            } else {
                setDropdownOpen(!dropdownOpen);
            }
        }

        function handleNewClientManual() {
            setDropdownOpen(!dropdownOpen);
            goToScreen();
        }

        function renderDropdown() {
            if (!dropdownOpen) {
                return null;
            }

            return (
                <S.OptionsContainer>
                    <S.ButtonOptions onPress={handleNewClientManual}>
                        <CopyIcon />
                        <S.ButtonOptionsText>
                            Criar manualmente
                        </S.ButtonOptionsText>
                    </S.ButtonOptions>
                    <S.SecondButtonOptions onPress={handleGetDock}>
                        <EditIcon />
                        <S.ButtonOptionsText>
                            Importar arquivo
                        </S.ButtonOptionsText>
                    </S.SecondButtonOptions>
                </S.OptionsContainer>
            );
        }

        return (
            <S.ButtonContainer>
                <S.NewClientButton width={window} onPress={handleButtonClick}>
                    <AddIcon />
                    <S.AddClientText width={window}>
                        {buttonLabel}
                    </S.AddClientText>
                </S.NewClientButton>
                {renderDropdown()}
            </S.ButtonContainer>
        );
    }

    function renderInputSearch(widthInput?: string | number) {
        if (disableInputSearch) {
            return null;
        }

        return (
            <S.InputContainer width={widthInput} searchOpen={window}>
                <S.SearchIconInput />
                <S.InputSearch placeholder="Buscar palavra-chave" value={searchTxt} onChangeText={onChangeInputGlobal} />
            </S.InputContainer>
        );
    }

    function handleDropdownPress() {
        setDropdownVisible(!dropdownVisible);
    }

    function handleDropdownStatusPress() {
        setDropdownStatusVisible(!dropdownStatusVisible);
    }

    function handleDropdownUsePress() {
        setDropdownUseVisible(!dropdownUseVisible);
    }

    function handleDropdownPressEvent() {
        setDropdownVisibleEvent(!dropdownVisibleEvent);
    }

    function handleDropdownPressColorPrimary() {
        setDropdownVisibleColorPrimary(!dropdownVisibleColorPrimary);
    }

    function handleDropdownPressColorSecondary() {
        setDropdownVisibleColorSecondary(!dropdownVisibleColorSecondary);
    }

    function handleDropdownPressColorTertiary() {
        setDropdownVisibleColorTertiary(!dropdownVisibleColorTertiary);
    }

    function handleDropdownPressColorQuaternary() {
        setDropdownVisibleColorQuaternary(!dropdownVisibleColorQuaternary);
    }

    function RenderBackArrow() {
        if (!goBack) {
            return null;
        }

        return (
            <S.ArrowButton onPress={goToScreen}>
                <GoBackIcon />
            </S.ArrowButton>
        );
    }

    function ResetAllDropDawn() {
        setDropdownVisibleColorPrimary(false);
        setDropdownVisibleColorSecondary(false);
        setDropdownVisibleColorTertiary(false);
        setDropdownVisibleColorQuaternary(false);
    }

    function RenderDropdownColors(dropdownVisibleColor, setColorSelect) {
        if (!dropdownVisibleColor) {
            return null;
        }

        const filterAll = () => {
            setColorSelect('');
            ResetAllDropDawn();
        }

        const renderAllColors = () => (
            listColors.map((v) => {
                const filterColor = () => {
                    setColorSelect(v);
                    ResetAllDropDawn();
                }
                return (
                    <S.DropdownOption onPress={filterColor} key={v}>
                        <S.DropdownOptionText>
                            {v}
                        </S.DropdownOptionText>
                    </S.DropdownOption>
                );
            })
        );

        return (
            <S.DropdownArea width={window}>
                <S.DropdownOption onPress={filterAll}>
                    <S.DropdownOptionText>
                        Nenhum
                    </S.DropdownOptionText>
                </S.DropdownOption>
                {renderAllColors()}
            </S.DropdownArea>
        );
    }

    function RenderDropdownArtType() {
        if (!dropdownVisible) {
            return null;
        }

        const filterAll = () => {
            setArtType('');
            handleDropdownPress();
        }

        const filterActive = () => {
            setArtType('feed');
            handleDropdownPress();
        }

        const filterPending = () => {
            setArtType('story');
            handleDropdownPress();
        }

        return (
            <S.DropdownArea width={window}>
                <S.DropdownOption onPress={filterAll}>
                    <S.DropdownOptionText>
                        Todos
                    </S.DropdownOptionText>
                </S.DropdownOption>
                <S.DropdownOption onPress={filterActive}>
                    <S.DropdownOptionText>
                        Feed
                    </S.DropdownOptionText>
                </S.DropdownOption>
                <S.DropdownOption onPress={filterPending}>
                    <S.DropdownOptionText>
                        Story
                    </S.DropdownOptionText>
                </S.DropdownOption>
            </S.DropdownArea>
        );
    }

    function RenderDropdownStatus() {
        if (!dropdownStatusVisible) {
            return null;
        }

        const filterEnable = () => {
            setStatus('Ativado');
            handleDropdownStatusPress();
        }

        const filterDisable = () => {
            setStatus('Bloqueado');
            handleDropdownStatusPress();
        }

        return (
            <S.DropdownArea width={window}>
                <S.DropdownOption onPress={filterEnable}>
                    <S.DropdownOptionText>
                        Ativado
                    </S.DropdownOptionText>
                </S.DropdownOption>
                <S.DropdownOption onPress={filterDisable}>
                    <S.DropdownOptionText>
                        Bloqueado
                    </S.DropdownOptionText>
                </S.DropdownOption>
            </S.DropdownArea>
        );
    }

    function RenderUseDropdown() {
        if (!dropdownUseVisible) {
            return null;
        }

        const filterEnable = () => {
            setUse('Público');
            handleDropdownUsePress();
        }

        const filterDisable = () => {
            setUse('Privado');
            handleDropdownUsePress();
        }

        return (
            <S.DropdownArea width={window}>
                <S.DropdownOption onPress={filterEnable}>
                    <S.DropdownOptionText>
                        Público
                    </S.DropdownOptionText>
                </S.DropdownOption>
                <S.DropdownOption onPress={filterDisable}>
                    <S.DropdownOptionText>
                        Privado
                    </S.DropdownOptionText>
                </S.DropdownOption>
            </S.DropdownArea>
        );
    }

    function RenderDropdownIdEvent() {
        if (!dropdownVisibleEvent) {
            return null;
        }

        const filterAll = () => {
            setIdEvent('');
            setNameEvent('Todos');
            handleDropdownPressEvent();
        }

        return (
            <S.DropdownArea width={window}>
                <S.DropdownOption onPress={filterAll}>
                    <S.DropdownOptionText>
                        Todos
                    </S.DropdownOptionText>
                </S.DropdownOption>
                {(eventList || []).map((v) => {
                    const filterActive = () => {
                        setIdEvent(v.id);
                        setNameEvent(v.name);
                        handleDropdownPressEvent();
                    }
                    return (
                        <S.DropdownOption onPress={filterActive} key={v.id}>
                            <S.DropdownOptionText>
                                {v.name}
                            </S.DropdownOptionText>
                        </S.DropdownOption>
                    );
                })}
            </S.DropdownArea>
        );
    }

    function renderButtonFilters() {
        return (
            <>
                <S.NewClientButtonFilter width={window} onPress={changeModalVisible} openFilters={openFilters}>
                    <AddIcon />
                    {window < 700 ? null : (
                        <S.AddClientText>{`${openFilters ? 'Fechar' : 'Abrir'} Filtros`}</S.AddClientText>
                    )}
                </S.NewClientButtonFilter>
                {openFilters ? null : RenderRemoveAllFilter()}
            </>
        )
    }

    function changeModalVisible() {
        setOpenFilters(!openFilters);
    }

    function removeAllFilter() {
        setSearchTxt('');
        // setArtType('');
        setIdEvent('');
        setUse('');
        setStatus('');
        setNameEvent('');
        setColorPrimary('');
        setColorSecondary('');
        setColorTertiary('');
        setColorQuaternary('');
        setPeriodType('published_at');
        setIdUser('');
        setIdPlan('');
        setSearchStore('');
        setSearchPlan('');
        setPeriodStart('');
        setPeriodEnd('');
    }

    function RenderRemoveAllFilter() {
        return (
            <S.NewClientButton width={window} onPress={removeAllFilter} openFilters={openFilters}>
                <S.AddClientText>Remover filtros</S.AddClientText>
            </S.NewClientButton>
        );
    }

    function RenderStoreName() {
        if (searchStore.length <= 3) {
            return null;
        }

        return (
            <S.SearchListArea>
                {RenderSearchList()}
            </S.SearchListArea>
        );
    }

    function RenderPlanName() {
        return (
            <S.SearchListArea>
                {RenderPlanList()}
            </S.SearchListArea>
        );
    }

    function RenderSearchList() {
        return allStoreList?.map((el) => {
            const handleStorePress = () => {
                setIdUser(el.id);
                setSearchStore(el.name);
            }

            return (
                <S.SearchList key={el.id} onPress={handleStorePress}>
                    <S.SearchListText>
                        {el.name}
                    </S.SearchListText>
                </S.SearchList>
            );
        });
    }

    function RenderPlanList() {
        return allPlanList?.map((el) => {
            const handleStorePress = () => {
                setIdPlan(el.id);
                setSearchPlan(el.name);
            }

            return (
                <S.SearchList key={el.id} onPress={handleStorePress}>
                    <S.SearchListText>
                        {el.name}
                    </S.SearchListText>
                </S.SearchList>
            );
        });
    }

    function renderItem(list) {
        return (list || []).map((e) => {
            const { value, label } = e;
            return (
                <Picker.Item key={value} label={label} value={value} />
            );
        });
    }

    return (
        <S.HeaderArea width={window} isInput={isInput}>
            <S.AdjustHeader>
                <S.TitleHeaderArea>
                    {RenderBackArrow()}
                    <S.TitleHeader title={title} width={window}>{title}</S.TitleHeader>
                </S.TitleHeaderArea>
                <S.ContainerSearch>
                    {renderInputSearch('80%')}
                </S.ContainerSearch>
                {isInput && !openFilters ? renderButtonFilters() : null}
                {OptionArea()}
            </S.AdjustHeader>
            <Modal isVisible={openFilters} closeModal={changeModalVisible}>
                <S.ScrollFilters>
                    <S.AdjustFilters width={window}>
                        {typeHeader !== 'logs' ? (
                            <>
                                <S.AdjustPickers width={window}>
                                    <S.RequestFilterButton onPress={handleDropdownPressEvent}>
                                        <InputSearch
                                            value={nameEvent}
                                            setValue={setNameEvent}
                                            placeholder="Evento"
                                            disabled
                                            arrowIcon
                                            marginLeft={0}
                                            widthArea={235}
                                        />
                                        {RenderDropdownIdEvent()}
                                    </S.RequestFilterButton>
                                </S.AdjustPickers>
                                <S.AdjustPickers>
                                    <S.RequestFilterButton onPress={handleDropdownStatusPress}>
                                        <InputSearch
                                            value={status}
                                            setValue={setStatus}
                                            placeholder="Status"
                                            disabled
                                            arrowIcon
                                            marginLeft={0}
                                            widthArea={235}
                                        />
                                        {RenderDropdownStatus(dropdownStatusVisible, setDropdownStatusVisible)}
                                    </S.RequestFilterButton>
                                </S.AdjustPickers>
                                <S.AdjustPickers>
                                    <S.RequestFilterButton onPress={handleDropdownUsePress}>
                                        <InputSearch
                                            value={use}
                                            setValue={setUse}
                                            placeholder="Uso"
                                            disabled
                                            arrowIcon
                                            marginLeft={0}
                                            widthArea={235}
                                        />
                                        {RenderUseDropdown(dropdownUseVisible, setDropdownUseVisible)}
                                    </S.RequestFilterButton>
                                </S.AdjustPickers>
                                {/* <S.AdjustPickers>
                                    <S.RequestFilterButton onPress={handleDropdownPress}>
                                        <InputSearch
                                            value={artType}
                                            setValue={setArtType}
                                            placeholder="Tipo"
                                            disabled
                                            arrowIcon
                                            marginLeft={0}
                                            widthArea={235}
                                        />
                                        {RenderDropdownArtType()}
                                    </S.RequestFilterButton>
                                </S.AdjustPickers> */}
                                <S.AdjustPickers>
                                    <S.RequestFilterButton onPress={handleDropdownPressColorPrimary}>
                                        <InputSearch
                                            value={colorPrimary}
                                            setValue={setColorPrimary}
                                            placeholder="Buscar por cor primária"
                                            disabled
                                            arrowIcon
                                            marginLeft={0}
                                            widthArea={235}
                                        />
                                        {RenderDropdownColors(dropdownVisibleColorPrimary, setColorPrimary)}
                                    </S.RequestFilterButton>
                                </S.AdjustPickers>
                                <S.AdjustPickers width={window}>
                                    {!colorPrimary ? null : (
                                        <S.RequestFilterButton onPress={handleDropdownPressColorSecondary}>
                                            <InputSearch
                                                value={colorSecondary}
                                                setValue={setColorSecondary}
                                                placeholder="Buscar por cor secundária"
                                                disabled
                                                arrowIcon
                                                marginLeft={0}
                                                widthArea={235}
                                            />
                                            {RenderDropdownColors(dropdownVisibleColorSecondary, setColorSecondary)}
                                        </S.RequestFilterButton>
                                    )}
                                </S.AdjustPickers>
                                <S.AdjustPickers width={window}>
                                    {!colorSecondary ? null : (
                                        <S.RequestFilterButton onPress={handleDropdownPressColorTertiary}>
                                            <InputSearch
                                                value={colorTertiary}
                                                setValue={setColorTertiary}
                                                placeholder="Buscar por cor ternária"
                                                disabled
                                                arrowIcon
                                                marginLeft={0}
                                                widthArea={235}
                                            />
                                            {RenderDropdownColors(dropdownVisibleColorTertiary, setColorTertiary)}
                                        </S.RequestFilterButton>
                                    )}
                                    {!colorTertiary ? null : (
                                        <S.RequestFilterButton onPress={handleDropdownPressColorQuaternary}>
                                            <InputSearch
                                                value={colorQuaternary}
                                                setValue={setColorQuaternary}
                                                placeholder="Buscar por cor quaternária"
                                                disabled
                                                arrowIcon
                                                marginLeft={0}
                                                widthArea={235}
                                            />
                                            {RenderDropdownColors(dropdownVisibleColorQuaternary, setColorQuaternary)}
                                        </S.RequestFilterButton>
                                    )}
                                </S.AdjustPickers>
                            </>
                        ) : (
                            <>
                                {notPeriod ? null : (
                                    <S.AdjustPickersLogs width={window}>
                                        <S.Label>Período</S.Label>
                                        <S.InputPickerContainer>
                                            <S.PickerContainer
                                                currentvalue={periodType}
                                                selectedValue={periodType}
                                                placeholder="Período"
                                                dropdownIconColor="red"
                                                onValueChange={(txt) => {
                                                    setPeriodType(txt);
                                                }}
                                            >
                                                {renderItem(typeList)}
                                            </S.PickerContainer>
                                        </S.InputPickerContainer>
                                    </S.AdjustPickersLogs>
                                )}
                                <S.AdjustPickersLogs width={window}>
                                    <S.Label>Loja</S.Label>
                                    <S.InputPickerContainer>
                                        <S.InputCommon
                                            value={searchStore}
                                            onChangeText={setSearchStore}
                                            placeholder='Digite o nome da loja'
                                        />
                                    </S.InputPickerContainer>
                                    {RenderStoreName()}
                                </S.AdjustPickersLogs>
                                <S.AdjustPickersLogs width={window}>
                                    <S.Label>Plano</S.Label>
                                    <S.InputPickerContainer>
                                        <S.InputCommon
                                            value={searchPlan}
                                            onChangeText={setSearchPlan}
                                            placeholder='Digite o nome do plano'
                                        />
                                    </S.InputPickerContainer>
                                    {RenderPlanName()}
                                </S.AdjustPickersLogs>
                                <S.AdjustPickersLogs width={window}>
                                    <S.Label>Data inicial</S.Label>
                                    <S.InputPickerContainer>
                                        <S.InputMask
                                            type={'datetime'}
                                            options={{
                                                format: 'DD/MM/YYYY'
                                            }}
                                            placeholder="00/00/0000"
                                            value={periodStart}
                                            onChangeText={setPeriodStart}
                                        />
                                    </S.InputPickerContainer>
                                    <S.Label>Data final</S.Label>
                                    <S.InputPickerContainer>
                                        <S.InputMask
                                            type={'datetime'}
                                            options={{
                                                format: 'DD/MM/YYYY'
                                            }}
                                            placeholder="00/00/0000"
                                            value={periodEnd}
                                            onChangeText={setPeriodEnd}
                                        />
                                    </S.InputPickerContainer>
                                </S.AdjustPickersLogs>
                            </>
                        )}
                        {renderButtonFilters()}
                    </S.AdjustFilters>
                </S.ScrollFilters>
            </Modal>
        </S.HeaderArea>
    );
}
