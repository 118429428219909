import styled, { css } from 'styled-components/native';

import { ResponsiveProps } from '../../../global/types';

interface CheckboxProps {
    isMarked: boolean,
    marginLeft?: boolean,
}

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.gray[300]};
    flex: 1;
`;

export const ContainerAlign = styled.View`
    align-self: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    height: 80%;
    margin: 44px 0px 36px;
    width: 95%;
`;

export const TextTitle = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 22px;
    margin-bottom: 20px;
`;

export const Content = styled.View<ResponsiveProps>`
    background-color: ${({ theme }) => theme.colors.white};
    ${({ width }) =>
        width >= 800 ? css`padding: 40px 60px;` : css`padding: 20px 20px;`
    };
    justify-content: space-between;
    ${({ width }) => width >= 800 && css`height: 100%`};
    width: 100%;
`;

export const Form = styled.View`
    align-self: center;
    width: 80%;
`;

export const InputContainer = styled.View<ResponsiveProps>`
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: ${({ width }) => (width < 800 ? 'column' : 'row')};
    flex-wrap: wrap;
`;

export const InfoArea = styled.View<ResponsiveProps>`
`;

export const ScrollView = styled.ScrollView.attrs({
    contentContainerStyle: { flex: 1 },
})``;

export const CheckboxArea = styled.View<CheckboxProps>`
    align-items: center;
    flex-direction: row;
`;

export const CheckboxCircle = styled.TouchableOpacity<CheckboxProps>`
    align-items: center;
    border-color: ${({ isMarked, theme }) => isMarked ? theme.colors.purple[500] : theme.colors.gray[400]};
    border-radius: 10px;
    border-width: 2px;
    height: 20px;
    justify-content: center;
    margin-left: ${({ marginLeft }) => marginLeft ? 30 : 0};
    width: 20px
`;

export const CheckboxCircleMarked = styled.View<CheckboxProps>`
    align-items: center;
    background-color: ${({ isMarked, theme }) => isMarked ? theme.colors.purple[500] : theme.colors.gray[400]};
    border-radius: 10px;
    height: 12px;
    justify-content: center;
    opacity: ${({ isMarked }) => isMarked ? 1 : 0};
    width: 12px;
`;

export const CheckboxText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    margin-left: 12px;
`;

export const Label = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    margin-bottom: 15px;
`;
