// @ts-nocheck
import * as React from "react"
import Svg, {
  SvgProps,
  Defs,
  ClipPath,
  Rect,
  G,
  Circle,
  Path,
} from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    data-name="Componente 1 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={112}
    height={112}
    {...props}
  >
    <Defs>
      <ClipPath id="a">
        <Rect
          data-name="Ret\xE2ngulo 43"
          width={60}
          height={60}
          rx={6}
          transform="translate(2825 2313)"
          fill="#fff"
          stroke="#a5a5a5"
          strokeWidth={3}
        />
      </ClipPath>
    </Defs>
    <G data-name="Ret\xE2ngulo 42" fill="#f2f2f2" stroke="#a5a5a5">
      <Rect width={112} height={112} rx={6} stroke="none" />
      <Rect x={0.5} y={0.5} width={111} height={111} rx={5.5} fill="none" />
    </G>
    <G
      data-name="Grupo de m\xE1scara 1"
      transform="translate(-2799 -2287)"
      clipPath="url(#a)"
    >
      <G
        transform="translate(2827.939 2315.939)"
        fill="none"
        stroke="#a5a5a5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      >
        <Rect
          data-name="Ret\xE2ngulo 44"
          width={52.904}
          height={52.904}
          rx={4.899}
        />
        <Circle
          data-name="Elipse 17"
          cx={4.409}
          cy={4.409}
          r={4.409}
          transform="translate(11.757 11.757)"
        />
        <Path data-name="Caminho 15" d="m52.904 35.269-14.7-14.7-32.33 32.33" />
      </G>
    </G>
  </Svg>
)

export default SvgComponent
