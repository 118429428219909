// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={55.623}
    height={55.064}
    {...props}
  >
    <G
      data-name="check-circle (3)"
      fill="none"
      stroke="#009545"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    >
      <Path
        data-name="Caminho 83911"
        d="M53.505 25.152v2.392a26 26 0 1 1-15.42-23.762"
      />
      <Path data-name="Caminho 83912" d="m53.504 6.746-26 26.024-7.8-7.799" />
    </G>
  </Svg>
)

export default SvgComponent
