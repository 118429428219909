// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { toast } from 'react-hot-toast';

import { deleteEvent, getEventList, createEvent } from '../../../../../services/events';
import CloseImage from '../../../../../assets/images/close.png';
import Theme from '../../../../../global/styles/theme';

import * as S from './styles';

interface AddEventListProps {
    id: number,
    name: string,
}
interface InputEventProps {
    addEventList: Array<AddEventListProps>,
    setAddEventList: () => void;
    type: 'edit' | 'newArt',
    isError?: boolean,
}

export function InputEvent(props: InputEventProps) {
    const { setAddEventList, addEventList, isError } = props;
    const width = useWindowDimensions().width;
    const widthForTwoColumns = width <= 800 ? '100%' : '48%';

    const [eventValue, setEventValue] = useState('');
    const [eventList, setEventList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllEvents();
    }, []);

    async function getAllEvents() {
        const { result } = await getEventList();
        setEventList(result.list);
        setLoading(true);
    }

    async function handleAddPress() {
        const bodyCreate = { name: eventValue };
        setEventValue('');
        const { status, result, message } = await createEvent(bodyCreate);

        if (status === 201) {
            const newListEvent =  addEventList.length > 0 ? [...addEventList, result.event.id] : [result.event.id];

            setAddEventList(newListEvent);
            toast.success('Evento criado com sucesso!', Theme.toast);
            getAllEvents();
        } else {
            toast.error(message, Theme.toast);
        }
    }

    function RenderEventList() {
        if (!eventValue) {
            return null;
        }

        return (
            <S.EventListContainer>
                {EventList()}
            </S.EventListContainer>
        )
    }

    function EventList() {
        return eventList.map((el) => {
            const handleRemovePress = async () => {
                setEventValue('');
                const { status, message } = await deleteEvent(el.id);

                if (status === 200) {
                    toast.success(message, Theme.toast);
                    getAllEvents();
                } else {
                    toast.error(message, Theme.toast);
                }
            }

            const handleAddEventPress = () => {
                setEventValue('');
                const existEvent = addEventList.filter((v) => v === el.id);
                if (existEvent.length) {
                    return toast.error('Este evento já foi adicionado', Theme.toast);
                }
                const newListEvent = addEventList.length > 0 ? [...addEventList, el.id] : [el.id];

                return setAddEventList(newListEvent);
            }

            return (
                <S.EventListArea key={el.id} onPress={handleAddEventPress}>
                    <S.EventListTitleArea>
                        <S.TextDefault
                            fontFamily='semi'
                            fontSize={14}
                        >
                            {el.name}
                        </S.TextDefault>
                    </S.EventListTitleArea>
                    <S.OptionArea>
                        <S.TextDefault
                            fontFamily='regular'
                            fontSize={12}
                            onPress={handleRemovePress}
                        >
                            Remover
                        </S.TextDefault>
                    </S.OptionArea>
                </S.EventListArea>
            );
        });
    }

    function RenderAddText() {
        if (!eventValue) {
            return null;
        }

        return (
            <S.OptionArea onPress={handleAddPress}>
                <S.TextDefault
                    fontFamily='regular'
                    fontSize={12}
                >
                    Adicionar
                </S.TextDefault>
            </S.OptionArea>
        )
    }

    function RenderEventAdd() {
        if (!addEventList.length) {
            return null;
        }
        const listEventCodition = eventList.filter((el) =>  addEventList.includes(el.id));


        return listEventCodition.map((el) => {
            const removeEventUser = () => {
                const newListEvent =  addEventList.filter((item) => item !== el.id);
                setAddEventList(newListEvent);
            }

            return (
                <S.AddEventArea key={el.id}>
                    <S.TextDefault
                        fontFamily='regular'
                        fontSize={12}
                    >
                        {el.name}
                    </S.TextDefault>
                    <S.CloseButton onPress={removeEventUser}>
                        <S.CloseImage onContextMenu={(e) => e.preventDefault()} resizeMode='contain' source={CloseImage} />
                    </S.CloseButton>
                </S.AddEventArea>
            );
        })
    }

    function RenderInputEvent() {
        if (!loading) {
            return null;
        }

        return (
            <>
                <S.Label>Eventos*</S.Label>
                <S.InputEventArea isError={isError}>
                    <S.InputEvent
                        placeholder="Digite um evento"
                        value={eventValue}
                        onChangeText={setEventValue}
                    />
                    {RenderAddText()}
                </S.InputEventArea>
                {RenderEventList()}
                <S.AddEventContainer>
                    {RenderEventAdd()}
                </S.AddEventContainer>
            </>
        );
    }

    return (
        <S.InputContainer width={widthForTwoColumns}>
            {RenderInputEvent()}
        </S.InputContainer>
    );
}
