// @ts-nocheck
import React, { useRef } from 'react';
import { useWindowDimensions, TouchableOpacity } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputInternal } from '../../../components/InputInternal';

import { typeFuel } from '../../../global/utils/lists';
import * as S from './styles';
import CloseImage from '../../../assets/images/close.png';
import AddPhoto from '../../../assets/images/svg/addPhoto';
import { schemaCreateVehicles } from '../../../global/utils/formsSchems';
import GoBackIcon from '../../../assets/images/svg/goBackIcon';
import { converterCurrency } from '../../../global/utils/functions';
import { ImageDetail } from 'react-native-image-modal';

export function VehicleScreen() {
    const vehicleResolver = { resolver: yupResolver(schemaCreateVehicles), mode: 'onChange' };
    const { control, formState: { errors } } = useForm(vehicleResolver);
    const { user } = useSelector((state: any) => state.user.user);
    const route = useRoute();
    const { item } = route?.params;
    const width = useWindowDimensions().width;
    const navigation = useNavigation();
    const widthForTwoColumns = width > 767 ? '49%' : '100%';
    const widthForThreeColumns = width > 767 ? '32%' : '100%';
    const widthForFourColumns = width > 767 ? '23.5%' : '100%';
    // const convertFuel = item?.fuel?.split(',');
    const convertFuel = item?.fuel;

    function goBack() {
        if (user.type === 'adm') {
            return navigation.goBack();
        }
        return navigation.navigate('EditPerfilScreen');
    }

    const imageModalRef = useRef<ImageDetail>(null);

    function handleOpenModal() {
        imageModalRef.current?.setState({
            isOpen: true,
            origin: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            },
        });
    }

    function renderPhotoCar() {
        const vehiclePhoto =  item.photo.split('|');

        if (!item.photo) {
            return (
                <AddPhoto />
            );
        }

        return vehiclePhoto.map((el: string, index: number) => {
            return (
                <>
                    <S.ImageContainer onPress={handleOpenModal}>
                        <S.PhotoUser onContextMenu={(e) => e.preventDefault()} key={index} source={el} />
                    </S.ImageContainer>
                    <S.ModalContent
                        ref={imageModalRef}
                        modalRef={imageModalRef}
                        resizeMode="contain"
                        imageBackgroundColor="transparent"
                        source={el}></S.ModalContent>
                </>
            );
        });
    }

    function renderItem() {
        return (typeFuel || []).map((e) => {
            const { value, label } = e;
            return (
                <Picker.Item key={value} label={label} value={value} />
            );
        });
    }

    function RenderFuel() {
        return convertFuel.map((el, index) => {
            return (
                <S.FuelArea key={index}>
                    <S.TextFuel>
                        {el}
                    </S.TextFuel>
                </S.FuelArea>
            );
        });
    }

    function RenderFuelOption() {
        if (!convertFuel.length) {
            return null;
        }

        return (
            <S.FuelList>
                {RenderFuel()}
            </S.FuelList>
        );
    }

    return (
        <S.ScrollView>
            <S.ContainerAlign>
                <S.ContainerInfo>
                    <S.Form>
                        <S.BackArea>
                            <S.GoBackButton onPress={goBack}>
                                <GoBackIcon />
                            </S.GoBackButton>
                            <S.TextDefault>Informações Básicas</S.TextDefault>
                        </S.BackArea>
                        <S.AddPhotoArea width={window}>
                            <S.PhotoArea>
                                <S.AddButtonPhoto onContextMenu={(e) => e.preventDefault()}>
                                    {renderPhotoCar()}
                                </S.AddButtonPhoto>
                            </S.PhotoArea>
                        </S.AddPhotoArea>
                        <S.InputContainer>
                            <InputInternal
                                inicialValue={item?.title || ''}
                                label="Título da mídia"
                                placeholder="Digite o título da mídia"
                                width={widthForFourColumns}
                                control={control}
                                name="title"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.board || ''}
                                label="Placa"
                                placeholder="Digite a placa do veículo"
                                width={widthForFourColumns}
                                control={control}
                                name="board"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.km ? converterCurrency(String(Number(item?.km || 0).toFixed(0) || '')).replaceAll(',', '').replace('R$', '').slice(0, -2) : ''}
                                label="Km"
                                placeholder="Digite os km rodados"
                                width={widthForFourColumns}
                                control={control}
                                name="km"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.condition || ''}
                                label="Condição"
                                placeholder="Selecione a condição"
                                width={widthForFourColumns}
                                // list={conditionCar}
                                control={control}
                                name="condition"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.type || ''}
                                label="Tipo"
                                placeholder="Digite o tipo"
                                width={widthForFourColumns}
                                control={control}
                                name="type"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.bodywork || ''}
                                label="Carroceria"
                                placeholder="Digite a carroceria"
                                width={widthForFourColumns}
                                control={control}
                                name="bodywork"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.brand || ''}
                                label="Marca"
                                placeholder="Digite a marca"
                                width={widthForFourColumns}
                                control={control}
                                name="brand"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.model || ''}
                                label="Modelo"
                                placeholder="Digite o modelo"
                                width={widthForFourColumns}
                                control={control}
                                name="model"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.version || ''}
                                label="Versão"
                                placeholder="Digite a versão"
                                width={widthForThreeColumns}
                                control={control}
                                name="version"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.year_manufacturer || ''}
                                label="Ano/Fabricação"
                                placeholder="Digite o ano/fabricação"
                                width={widthForThreeColumns}
                                control={control}
                                name="yearManufacturer"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.year_model || ''}
                                label="Ano/Modelo"
                                placeholder="Digite o ano/modelo"
                                width={widthForThreeColumns}
                                control={control}
                                name="yearModel"
                                errors={errors}
                                disabled
                            />
                            <S.InputArea width={widthForThreeColumns}>
                                <S.Label>Combustível</S.Label>
                                <S.InputContainerArea>
                                    <S.PickerContainer
                                        currentvalue={convertFuel}
                                        selectedValue={convertFuel}
                                        placeholder='Selecione os tipos de combustiveis'
                                        dropdownIconColor="red"
                                        // onValueChange={onChange}
                                        disabled
                                    >
                                        <Picker.Item label={convertFuel} value={convertFuel} />
                                        {/* {renderItem()} */}
                                    </S.PickerContainer>
                                </S.InputContainerArea>
                                {/* {RenderFuelOption()} */}
                            </S.InputArea>
                            <InputInternal
                                inicialValue={item?.doors || ''}
                                label="Portas"
                                placeholder="Digite a qnt. de portas"
                                width={widthForThreeColumns}
                                control={control}
                                name="doors"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.gearshift || '0'}
                                label="Câmbio"
                                placeholder="Selecione o câmbio"
                                width={widthForThreeColumns}
                                // list={typeGear}
                                control={control}
                                name="gearshift"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.color || ''}
                                label="Cor"
                                placeholder="Digite a cor do veículo"
                                width={widthForTwoColumns}
                                control={control}
                                name="color"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.traction || ''}
                                label="Tração"
                                placeholder="Digite o tipo de tração do veículo"
                                width={widthForTwoColumns}
                                control={control}
                                name="traction"
                                errors={errors}
                                disabled
                            />
                        </S.InputContainer>
                        <S.TextDefault>Condições</S.TextDefault>
                        <S.InputContainer>
                            <InputInternal
                                inicialValue={item?.is_trade ? 'Sim' : 'Não'}
                                label="Aceita troca"
                                placeholder="Aceita troca"
                                width={widthForThreeColumns}
                                control={control}
                                name="isTrade"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.is_pay_ipva ? 'Sim' : 'Não'}
                                label="IPVA pago"
                                placeholder="IPVA pago"
                                width={widthForThreeColumns}
                                // list={typeGear}
                                control={control}
                                name="isPayIPVA"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.is_owner_unique ? 'Sim' : 'Não'}
                                label="Único dono"
                                placeholder="Único dono"
                                width={widthForThreeColumns}
                                // list={typeGear}
                                control={control}
                                name="isOwnerUnique"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.is_free_transfer ? 'Sim' : 'Não'}
                                label="Transferência grátis"
                                placeholder="Transferência grátis"
                                width={widthForTwoColumns}
                                control={control}
                                name="isFreeTransfer"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.is_full_tank ? 'Sim' : 'Não'}
                                label="Tanque cheio"
                                placeholder="Tanque cheio"
                                width={widthForTwoColumns}
                                control={control}
                                name="isFullTank"
                                errors={errors}
                                disabled
                            />
                        </S.InputContainer>
                        <S.TextDefault>Informações Básicas</S.TextDefault>
                        <S.InputContainer>
                            <InputInternal
                                inicialValue={item?.seller_name || ''}
                                label="Vendedor"
                                placeholder="Digite o nome do vendedor"
                                width={widthForTwoColumns}
                                control={control}
                                name="sellerName"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.seller_whatsapp || ''}
                                label="Whatsapp"
                                placeholder="(__) ____-____"
                                width={widthForTwoColumns}
                                mask="cel-phone"
                                control={control}
                                name="sellerWhatsapp"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.price || ''}
                                label="Preço de venda"
                                placeholder="R$"
                                width={widthForThreeColumns}
                                control={control}
                                name="price"
                                errors={errors}
                                mask="money"
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.exchange_price || ''}
                                label="Preço de troca"
                                placeholder="R$"
                                width={widthForThreeColumns}
                                control={control}
                                name="exchangePrice"
                                errors={errors}
                                mask="money"
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.promotional_price || ''}
                                label="Preço promocional"
                                placeholder="R$"
                                width={widthForThreeColumns}
                                control={control}
                                name="promotionalPrice"
                                errors={errors}
                                mask="money"
                                disabled
                            />
                        </S.InputContainer>
                        <S.TextDefault>Opções de financiamento</S.TextDefault>
                        <S.InputContainer>
                            <InputInternal
                                inicialValue={item?.input_value || ''}
                                label="Valor da entrada"
                                placeholder="R$"
                                width={widthForFourColumns}
                                control={control}
                                name="inputValue"
                                errors={errors}
                                mask="money"
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.installment_value || ''}
                                label="Valor da parcela"
                                placeholder="R$"
                                width={widthForFourColumns}
                                control={control}
                                name="installmentValue"
                                errors={errors}
                                mask="money"
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.number_installments || ''}
                                label="Nº de parcelas"
                                placeholder="Selecione o Nº de parcelas"
                                width={widthForFourColumns}
                                control={control}
                                name="numberInstallments"
                                errors={errors}
                                disabled
                            />
                            <InputInternal
                                inicialValue={item?.type_financing || ''}
                                label="Tipo de financiamento"
                                placeholder="Selecion o tipo de financiamento"
                                width={widthForFourColumns}
                                // list={list}
                                control={control}
                                name="typeFinancing"
                                errors={errors}
                                disabled
                            />
                        </S.InputContainer>
                    </S.Form>
                </S.ContainerInfo>
            </S.ContainerAlign>
        </S.ScrollView>
    );
}
