// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={14.414}
        height={12.828}
        {...props}>
        <G
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}>
            <Path data-name="Caminho 11" d="m6 11.414-5-5 5-5" />
            <Path data-name="Caminho 12" d="m13 11.414-5-5 5-5" />
        </G>
    </Svg>
);

export default SvgComponent;
