import React, { useRef, useState } from 'react';
import Checkbox from 'expo-checkbox';

import * as S from './styles';
import { ScrollView, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { ImageDetail } from 'react-native-image-modal';
import { TopTabs } from '../../../../components/TopTabs';
import { isMobile } from 'react-device-detect';
import theme from '../../../../global/styles/theme';

interface SelectFramesProps {
    list: Array<any>;
    frames: Array<any>;
    imagesWithFrameSelected: Array<any>;
    setImagesWithFrameSelected: (value: Array<string>) => void;
    framesSelecteds: Array<string>;
    setFramesSelecteds: (value: Array<string>) => void;
    frameActual: any;
    setFrameActual: (v: any) => void;
    loading: boolean;
    isFrame: boolean;
    textsForFrame: Array<{ id: number; txt: string }>;
    setTextsForFrame: (v: Array<{ id: number; txt: string }>) => void;
    handleClickOpenCrop: (v: any, f: boolean, e: any) => void;
    inputsInitialFrame: Array<{ id: number; txt: string }>;
    uriFrame: any;
    setUriFrame: (v: string) => void;
    currentTabPosition: number;
    setCurrentTabPosition: (v: number) => any;
    setIsRemoveBgCapaOrMoldura: (v: number) => any;
    framesOrCapeRemoveBg: boolean;
    setFramesOrCapeRemoveBg: (v: boolean) => any;
    applyFrame: (v: any, f: boolean, e: any) => void;
    renderInputVehicle: () => void;
}

export function SelectFrames(props: SelectFramesProps) {
    const {
        list,
        frames,
        setImagesWithFrameSelected,
        imagesWithFrameSelected,
        framesSelecteds,
        setFramesSelecteds,
        frameActual,
        setFrameActual,
        loading,
        isFrame,
        textsForFrame,
        setTextsForFrame,
        handleClickOpenCrop,
        inputsInitialFrame,
        uriFrame,
        setUriFrame,
        currentTabPosition,
        setCurrentTabPosition,
        setIsRemoveBgCapaOrMoldura,
        framesOrCapeRemoveBg,
        setFramesOrCapeRemoveBg,
        applyFrame,
        renderInputVehicle,
    } = props;
    const width = useWindowDimensions().width;
    const imageModalRef = useRef<ImageDetail>(null);
    const listTopbar = [
        'Minhas',
        'Logotipos',
        'Datas'
    ];

    const [endScroll, setEndScroll] = useState(false);
    const [info, setInfo] = useState<any>({ x: 0, y: 0 });

    function renderListImage() {
        if (loading) {
            return <S.LoadingImage />;
        }

        return (list || []).map((item) => {
            const isSelected = imagesWithFrameSelected.filter(
                (e) => item.id === e?.id,
            );

            function handleChangeImage() {
                if (isSelected.length > 0) {
                    const newList = imagesWithFrameSelected.filter(
                        (e) => e?.id !== item?.id,
                    );
                    setImagesWithFrameSelected(newList);
                } else {
                    setImagesWithFrameSelected([
                        ...imagesWithFrameSelected,
                        {...item, photo: item.photo_base},
                    ]);
                }
            }

            function handleOpenModal() {
                setUriFrame(item?.photo);
                setTimeout(() => {
                    imageModalRef.current?.setState({
                        isOpen: true,
                        origin: {
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                        },
                    });
                }, 300);
            }

            return (
                <S.ImageCardContainer>
                    <TouchableOpacity
                        onPress={handleOpenModal}
                        style={{
                            backgroundColor: theme.colors.purple[500],
                            height: 'auto',
                            margin: 20,
                            marginBottom: 10,
                            // height: width <= 696 ? (width <= 423 ? (width <= 317 ? '70px' : '100px') : '150px') : '200px',
                            width: width <= 696 ? (width <= 423 ? (width <= 317 ? '70px' : '100px') : '150px') : '200px'
                        }}
                    >
                        <S.ImageCard style={{ aspectRatio: 4 / 3 }} onContextMenu={(e) => e.preventDefault()} source={item?.photo} resizeMode='contain' width={width} />
                    </TouchableOpacity>
                    {/* <TouchableOpacity onPress={handleChangeImage}> */}
                    <Checkbox
                        value={isSelected.length > 0}
                        onValueChange={handleChangeImage}
                        color={isSelected ? '#4630EB' : undefined}
                    />
                    {/* </TouchableOpacity> */}
                    {/* {framesOrCapeRemoveBg ? (
                        <S.ContainerPurpleButton
                            onPress={() => {
                                const updateImagesWithFrameSelected = imagesWithFrameSelected.map((itemRemoveBg) => {
                                    if (itemRemoveBg.id === item?.id) {
                                        return {
                                            ...itemRemoveBg,
                                            removeBg: true,
                                        };
                                    }
                                    return itemRemoveBg;
                                });
                                setImagesWithFrameSelected(updateImagesWithFrameSelected);
                                applyFrame(updateImagesWithFrameSelected, false, frameActual?.id);
                            }}
                            style={{ marginTop: width <= 880 ? 0 : 10, marginLeft: width <= 880 ? (width <= 480 ? 0 : 10) : 0, alignSelf: width <= 880 ? 'flex-start' : 'center' , marginBottom: width <= 880 ? 10 : 0, minHeight: width <= 880 ? (width <= 600 ? 30 : 50) : 70, maxHeight: width <= 880 ? (width <= 600 ? 30 : 50) : 70, minWidth: width <= 880 ? 50 : 70 }}
                        >
                            <S.TextFramePurple style={{ fontSize: width <= 880 ? (width <= 600 ? 8 : 12) : 14 }}>
                                Remover fundo avançado
                            </S.TextFramePurple>
                        </S.ContainerPurpleButton>
                    ) : null} */}
                </S.ImageCardContainer>
            );
        });
    }

    function renderInputs(item: any) {
        let textsForFrameUpdate: Array<number> = [];
        if ((item?.options_text || [])?.includes('1')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 1];
        }
        if ((item?.options_text || [])?.includes('2')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 2];
        }
        if ((item?.options_text || [])?.includes('3')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 3];
        }
        if ((item?.options_text || [])?.includes('4')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 4];
        }
        if ((item?.options_text || [])?.includes('5')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 5];
        }
        if (!textsForFrameUpdate.length) {
            return null;
        }
        return textsForFrame.filter((v) => textsForFrameUpdate.includes(v.id)).map((v, index) => {
            const onChangeText = (txt) => {
                const updateText = textsForFrame.map((e) => {
                    if (e.id === v.id) {
                        return {
                            ...e,
                            txt,
                        };
                    }
                    return e;
                });
                setTextsForFrame(updateText);
            };
            return (
                <View style={{ flexDirection: 'column', width: isMobile ? '100%' : '50%' }}>
                    <S.TextFrame>{item?.placeholder_text?.length > 0 ? item?.placeholder_text[v.id - 1] || '' : ''}</S.TextFrame>
                    <S.SearchInput
                        // placeholder={`Texto ${v.id}`}
                        placeholder=""
                        value={v.txt}
                        onChangeText={onChangeText}
                        key={v.id}
                        style={{ width: '100%', padding: 3 }}
                        multiline
                        numberOfLines={2}
                    />
                </View>
            );
        })
    }

    function renderFrames() {
        return (frames || []).map((item, i) => {
            const isSelected = framesSelecteds === item?.id;

            function applyZoom(photoApplyZoom) {
                setUriFrame(photoApplyZoom);
                setTimeout(() => {
                    imageModalRef.current?.setState({
                        isOpen: true,
                        origin: {
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                        },
                    });
                }, 300);
            }

            function handleChangeImage() {
                if (imagesWithFrameSelected.length) {
                    setIsRemoveBgCapaOrMoldura(item?.is_removebg || item?.is_overlapbg || false);
                    const existOptionsText = item?.options_text?.filter((text) => text)?.length > 0;
                    // setFramesOrCapeRemoveBg(item?.is_removebg || false);
                    setFrameActual(item);
                    if (!existOptionsText && !item?.is_input_title) {
                        setFramesSelecteds(item?.id);
                        handleClickOpenCrop(imagesWithFrameSelected, true, item);
                    } else {
                        setTextsForFrame(inputsInitialFrame);
                        setFramesSelecteds(item?.id);
                    }
                }
            }

            return (
                <S.ImageCardContainerView>
                    {item?.img_help ? (
                        <S.ContainerIconInfo onPress={() => applyZoom(item?.img_help)}>
                            <S.IconZoom name="info" />
                        </S.ContainerIconInfo>
                    ) : null}
                    <S.ContainerIconZoom onPress={() => applyZoom(item?.thumb)}>
                        <S.IconZoom name="zoom-in" />
                    </S.ContainerIconZoom>
                    <S.TextFrame adjustsFontSizeToFit numberOfLines={2} style={{ marginRight: 60 }}>{item?.title}</S.TextFrame>
                    <TouchableOpacity onPress={handleChangeImage}>
                        <S.FrameCard onContextMenu={(e) => e.preventDefault()} source={item?.thumb} isSelected={isSelected} resizeMode='contain' />
                    </TouchableOpacity>
                    {/* {isSelected && width >= 600 ? (
                        <>
                            {item?.options_text?.length > 0 ? renderInputs(item) : null}
                            <S.ButtonUpdate onPress={applyFrameUpdate}>
                                <S.ButtonUpdateText>Atualizar</S.ButtonUpdateText>
                            </S.ButtonUpdate>
                        </>
                    ) : null} */}
                </S.ImageCardContainerView>
            );
        });
    }

    function handleSelectAll() {
        const resetList = list.map((item) => ({
            ...item,
            photo: item.photo_base,
        }));
        const newList = resetList.map((item) => item);
        setImagesWithFrameSelected(newList);
    }

    function handleDeselectAll() {
        setImagesWithFrameSelected([]);
    }

    const scrollRef: any = useRef(null);

    function scrollTo(type: 'next' | 'back') {
        scrollRef?.current?.scrollTo({ x: type === 'next' ? info?.x + 200 : info?.x - 200, y: 0, animated: true });
    }

    function handleScroll(event: any) {
        setInfo(event.nativeEvent.contentOffset);
        if (isCloseToBottom(event.nativeEvent)) {
            setEndScroll(true);
        } else {
            setEndScroll(false);
        }
    }

    const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
      return layoutMeasurement.width + contentOffset.x >=
        contentSize.width;
    };

    return (
        <S.Container width={width} onContextMenu={(e) => e.preventDefault()}>
            <S.FirstContainer width={width}>
                <S.ButtonToMarkContainer>
                    <S.ButtonToMark onPress={handleSelectAll}>
                        <S.ButtonToMarkText>Marcar todas</S.ButtonToMarkText>
                    </S.ButtonToMark>
                    <S.ButtonToMark onPress={handleDeselectAll}>
                        <S.ButtonToMarkText>Desmarcar todas</S.ButtonToMarkText>
                    </S.ButtonToMark>
                </S.ButtonToMarkContainer>
                <S.FirstView
                    style={{
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                    }}>
                    {renderListImage()}
                </S.FirstView>
            </S.FirstContainer>
            <S.SecondContainer width={width} style={{ height: 'auto', justifyContent: 'center'}}>
                <S.SecondScroll
                    horizontal
                    style={{ marginBottom: 10 }}
                    ref={scrollRef}
                    onScroll={handleScroll}
                >
                    {renderFrames()}
                </S.SecondScroll>
                {frames?.length <= 1 ? null : (
                    <>
                        {info?.x === 0 ? null : (
                            <S.ContainerArrow width={width} onPress={() => scrollTo('back')} style={{ right: 0, left: 0 }}>
                                <S.IconArrow width={width} name="arrow-left" style={{ color: 'white' }} />
                            </S.ContainerArrow>
                        )}
                        {endScroll ? null : (
                            <S.ContainerArrow width={width} onPress={() => scrollTo('next')}>
                                <S.IconArrow width={width} name="arrow-right" style={{ color: 'white' }} />
                            </S.ContainerArrow>
                        )}
                    </>
                )}
                {frameActual?.id? (
                    <>
                        {frameActual?.options_text?.filter((text) => text)?.length > 0 ? (
                            <S.ViewTexts style={{ maxWidth: isMobile ? '100%' : '50%' }}>
                                {renderInputs(frameActual)}
                            </S.ViewTexts>
                        ) : null}
                        {frameActual?.is_input_title ? renderInputVehicle() : false}
                        {frameActual?.is_input_title || frameActual?.options_text?.filter((text) => text)?.length > 0 ? (
                            <S.ButtonUpdate onPress={() => handleClickOpenCrop(imagesWithFrameSelected, true, frameActual)} style={{ marginBottom: 10, marginTop: 10 }}>
                                <S.ButtonUpdateText>Confirmar</S.ButtonUpdateText>
                            </S.ButtonUpdate>
                        ) : null}
                    </>
                ) : null}
            </S.SecondContainer>
            <TopTabs
                listTabs={listTopbar}
                currentPosition={currentTabPosition}
                setCurrentPosition={setCurrentTabPosition}
                widthResponsive={300}
            />
            <S.ModalContent
                ref={imageModalRef}
                modalRef={imageModalRef}
                resizeMode="contain"
                imageBackgroundColor="transparent"
                source={uriFrame}></S.ModalContent>
        </S.Container>
    );
}
