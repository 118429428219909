import styled from 'styled-components/native';

import { ResponsiveProps } from '../../../../../global/types';

export const Container = styled.TouchableOpacity<ResponsiveProps>`
    background-color: ${({ theme }) => theme.colors.white};
    margin-bottom: 32px;
    height: 250px;
    width: 250px;
`;

export const Title = styled.Text`
    color: ${({ theme }) => theme.colors.gray[800]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
`;

export const SubTitle = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
`;

export const ImageCard = styled.Image`
    height: 250px;
    width: 250px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[25]}; ;
`;

export const PaymentIconArea = styled.View`
    /* bottom: 10px;
    position: absolute;
    right: 40px; */
    margin-right: 8px;
`;

export const ImageArea = styled.View``;

export const Tag = styled.View`
    width: 40%;
    background-color: ${({ theme }) => theme.colors.red[600]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    align-items: center;
    justify-content: center;
    padding: 2px 4px 2px 4px;
    border-radius: 10px;
    color: white;
    font-size: 10px;
    text-align: center;
`;

export const FavoriteButton = styled.TouchableOpacity`
    /* bottom: 10px;
    position: absolute;
    right: 10px; */
`;

export const InfoArea = styled.View`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
`;

export const IconArea = styled.View`
    align-items: center;
    flex-direction: row;
`;
