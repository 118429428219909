// @ts-nocheck
import React from 'react';

import { TableInfo } from './components/TableInfo';
import { ListTable } from './components/ListTable';
import ArrowLeft from '../../assets/images/svg/arrowLeft';
import ArrowRight from '../../assets/images/svg/arrowRight';

import { headerConfigs } from './utils';
import themeGlobal from '../../global/styles/theme';
import * as S from './styles';

interface TableProps {
    data: any[];
    type: 'clients' | 'plans' | 'vehicles';
    getAllClient: () => void;
    totalItem?: number;
    currentPage: number;
    setCurrentPage: (valu: number) => void;
    qtdPage: number,
    widthTable?: number,
}

const buttonEnable = {
    disabled: false,
    color: themeGlobal.colors.gray[650],
};

const buttonDisabled = {
    disabled: true,
    color: themeGlobal.colors.gray[150],
};

export function Table(props: TableProps) {
    const { data, getAllClient, type, currentPage, setCurrentPage, qtdPage, widthTable } = props;

    const tableList = data.slice(currentPage === 1 ? 0 : currentPage * 10, currentPage * 10);
    const disabledPreviousButton = currentPage === 1 ? buttonDisabled : buttonEnable;
    const disabledNextButton = currentPage === qtdPage ? buttonDisabled : buttonEnable;
    const list = tableList.length ? tableList : data

    function renderHeaderTable() {
        return headerConfigs[type].map((e) => (
            <TableInfo
                key={e.title}
                title={e.title}
                width={e.width}
                typeInfo="header"
                notBar={e.notBar}
            />
        ));
    }

    function nextPage() {
        setCurrentPage(currentPage + 1);
    }

    function previousPage() {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }

    return (
        <S.Container style={{ width: widthTable || 'auto'}}>
            <S.TableHeaderContainerArea width={window}>
                {renderHeaderTable()}
            </S.TableHeaderContainerArea>
            <ListTable
                getAllClient={getAllClient}
                tableList={list}
                type={type}
            />
            <S.PaginationArea>
                <S.TextDefault>{`${currentPage} de ${qtdPage}`}</S.TextDefault>
                <S.PaginationButton
                    disabled={disabledPreviousButton.disabled}
                    onPress={previousPage}>
                    <ArrowLeft color={disabledPreviousButton.color} />
                </S.PaginationButton>
                <S.PaginationButton
                    disabled={disabledNextButton.disabled}
                    onPress={nextPage}>
                    <ArrowRight color={disabledNextButton.color} />
                </S.PaginationButton>
            </S.PaginationArea>
        </S.Container>
    );
}
