import styled from 'styled-components/native';

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    flex: 1;
`;

export const Content = styled.View`
    flex-direction: row;
    flex: 1;
    z-index: -1;
`;
