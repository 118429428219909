import styled from 'styled-components/native';
import { Picker } from '@react-native-picker/picker';
import { TextInputMask } from 'react-native-masked-text';

import Theme from '../../../global/styles/theme';
import { ResponsiveProps } from '../../../global/types';

interface AttachTextProps {
    type: 'button' | 'text',
}

interface ColorInfoProps {
    color: string,
}

interface InputPickerContainerProps {
    isCheckbox?: boolean,
    isError?: boolean,
}

interface PickerProps {
    currentvalue: number | string;
}

interface CheckboxProps {
    isMarked: boolean,
    marginLeft?: boolean,
}

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.background};
    flex: 1;
`;

export const ContainerAlign = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 20px;
    margin-top: 40px;
    width: 95%;
`;

export const ContainerAlignInfo = styled.View<ResponsiveProps>`
    align-self: center;
    padding: 40px 0px;
    width: ${({ width }) => width >= 800 ? '80%' : '90%'};
`;

export const Row = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
`;

export const AttachText = styled.Text<AttachTextProps>`
    color: ${({ theme, type }) => type === 'button' ? theme.colors.white : theme.colors.gray[700]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
`;

export const AttachDocumentArea = styled.TouchableOpacity`
    align-self: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.gray[700]};
    border-radius: 6px;
    height: 179px;
    padding: 27.3px 0px;
    width: 240px;
`;

export const AttachButton = styled.TouchableOpacity`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 6px;
    height: 28px;
    justify-content: center;
    width: 141px;
    margin-bottom: 10px;
    margin-top: 20px;
`;

export const InputContainer = styled.View<ResponsiveProps>`
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: ${({ width }) => (width < 800 ? 'column' : 'row')};
    flex-wrap: wrap;
`;

export const ColorPickerArea = styled.View`
    align-self: flex-end;
`;

export const ColorPicker = styled.View<ResponsiveProps>`
    margin-bottom: 10px;
    width: ${({ width }) => width <= 800 ? '100%' : '48%'};
`;

export const ButtonColor = styled.TouchableOpacity`
    flex-direction: row;
    padding: 10px;
    margin-top: 6px;
    height: 40px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    align-items: center;
    width: 100%;
`;

export const ScrollView = styled.ScrollView`

`;

export const Column = styled.View`
    flex-direction: column;
    align-items: center;
    max-width: 50%;
    min-width: 50%;
`;

export const Label = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const InputDocument = styled.TextInput``;

export const DropzoneArea = styled.View`
    align-self: center;
`;

export const ColorInfoArea = styled.TouchableOpacity<ColorInfoProps>`
    align-items: center;
    background-color: ${({ color }) => color};
    height: 28px;
    justify-content: center;
    margin-top: 9px;
    margin-right: 10px;
    width: 28px;
`;

export const ColorArea = styled.View`
    align-self: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`;

export const DeleteColorImage = styled.Image`
    height: 10px;
    tint-color: ${({ theme }) => theme.colors.white};
    width: 10px;
`;

export const PickerContainer = styled(Picker)<PickerProps>`
    background-color: ${({ theme }) => theme.colors.gray[300]};
    border-width: 0px;
    width: 100%;
    outline-width: 0px;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    ${({ theme, currentvalue }) =>
        currentvalue === '0'  && `color: ${theme.colors.gray[400]}`};
`;

export const InputPickerContainer = styled.View<InputPickerContainerProps>`
    flex-direction: row;
    padding: 10px;
    margin-top: 6px;
    height: 40px;
    border: 1px solid ${(props) => props.isError ? props.theme.colors.red[500] : props.theme.colors.gray[300]};
    border-radius: 6px;
    background-color: ${({ theme, isCheckbox }) => isCheckbox ? theme.colors.white : theme.colors.gray[300]};
    align-items: center;
`;

export const InputPickerArea = styled.View<ResponsiveProps>`
    margin-bottom: 16px;
    width: ${({ width }) => width <= 800 ? '100%' : '48%'};
`;

export const InputMask = styled(TextInputMask).attrs({
    placeholderTextColor: Theme.colors.gray[400],
})`
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    outline-width: 0px;
    width: 100%;
`;

export const InputCommon = styled.TextInput.attrs({
    placeholderTextColor: Theme.colors.gray[400],
})`
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    outline-width: 0px;
    width: 100%;
`;

export const InputArea = styled.View<ResponsiveProps>`
    margin-bottom: 16px;
    width: ${({ width }) => width};
`;

export const CheckboxArea = styled.View<CheckboxProps>`
    align-items: center;
    flex-direction: row;
`;

export const CheckboxCircle = styled.TouchableOpacity<CheckboxProps>`
    align-items: center;
    border-color: ${({ isMarked, theme }) => isMarked ? theme.colors.purple[500] : theme.colors.gray[400]};
    border-radius: 10px;
    border-width: 2px;
    height: 20px;
    justify-content: center;
    margin-left: ${({ marginLeft }) => marginLeft ? 30 : 0};
    width: 20px
`;

export const CheckboxCircleMarked = styled.View<CheckboxProps>`
    align-items: center;
    background-color: ${({ isMarked, theme }) => isMarked ? theme.colors.purple[500] : theme.colors.gray[400]};
    border-radius: 10px;
    height: 12px;
    justify-content: center;
    opacity: ${({ isMarked }) => isMarked ? 1 : 0};
    width: 12px;
`;

export const CheckboxText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    margin-left: 12px;
`;

export const SearchListArea = styled.ScrollView`
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.gray[150]};
    border-radius: 6px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    max-height: 120px;
    width: 100%;
`;

export const SearchList = styled.TouchableOpacity`
    padding: 12px;
`;

export const SearchListText = styled.Text`
    color: ${({ theme }) => theme.colors.lowBlack};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 13px;
`;
