import styled, { css } from 'styled-components/native';
import { Animated } from 'react-native';
import theme from '../../../global/styles/theme';

import { ResponsiveProps } from '../../../global/types';

export const Container = styled.ImageBackground`
    flex: 1;
`;

export const ContainerShawdow = styled.View`
    background-color: rgba(0, 0, 0, 0.33);
    align-items: center;
    justify-content: center;
    padding: 57px 0px;
    flex: 1;
`;

export const Content = styled(Animated.View)<ResponsiveProps>`
    flex-direction: ${({ width }) => (width > 768 ? 'row' : 'column')};
    width: ${({ width }) => (width > 966 ? '80%' : '95%')};
    align-items: center;
    justify-content: center;
`;

export const FirstBox = styled.View<ResponsiveProps>`
    ${({ width }) =>
        width > 768
            ? css`
                  flex: 1;
              `
            : css`
                  align-items: center;
                  justify-content: center;
                  margin: 20px 0px 30px;
              `}
`;

export const SecondBox = styled.View<ResponsiveProps>`
    width: ${({ width }) => (width > 523 ? '516px' : '100%')};
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${({ width }) => (width > 768 ? '60px 68px' : '30px 38px')};
    border-radius: 8px;
`;

export const Description = styled.Text<ResponsiveProps>`
    ${({ width }) =>
        width < 768 &&
        css`
            text-align: center;
        `}
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    margin-top: 16px;
    max-width: 342px;
    margin-right: 30px;
`;

export const Form = styled.View<ResponsiveProps>`
    margin: ${({ width }) => (width > 768 ? '80px 0px' : '40px 0px')};
`;

export const ForgotPassword = styled.TouchableOpacity`
    margin-top: -10px;
`;

export const TextForgotPassword = styled.Text`
    color: ${({ theme }) => theme.colors.gray[900]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    text-decoration: underline;
`;

export const Loading = styled.ActivityIndicator.attrs({
    color: theme.colors.purple[500],
})``;
