// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={64.153} height={56} {...props}>
    <G
      fill="none"
      stroke="#fda62f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
    >
      <Path
        data-name="Caminho 18"
        d="M27.164 4.766 2.844 45.383a5.745 5.745 0 0 0 4.91 8.62h48.64a5.745 5.745 0 0 0 4.91-8.617l-24.32-40.62a5.742 5.742 0 0 0-9.82 0Z"
      />
      <Path data-name="Linha 22" d="M32.074 19.512v11.532" />
      <Path data-name="Linha 23" d="M32.074 42.51h.029" />
    </G>
  </Svg>
)

export default SvgComponent
