import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={19.5} height={19.5} {...props}>
    <G data-name="Componente 7 \u2013 1">
      <Path
        data-name="phone (1)"
        d="M14.693 11.469v1.344a.895.895 0 0 1-.974.9 8.831 8.831 0 0 1-3.857-1.379 8.726 8.726 0 0 1-2.681-2.688 8.88 8.88 0 0 1-1.372-3.884.9.9 0 0 1 .884-.977h1.346a.9.9 0 0 1 .894.771 5.764 5.764 0 0 0 .313 1.259.9.9 0 0 1-.2.945l-.568.569a7.16 7.16 0 0 0 2.68 2.688l.568-.569a.892.892 0 0 1 .943-.2 5.727 5.727 0 0 0 1.256.314.9.9 0 0 1 .768.907Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        data-name="message-circle (3)"
        d="M18.75 9.25a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9l-5.7 1.9 1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </G>
  </Svg>
)

export default SvgComponent
