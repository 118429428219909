import { LOGIN, FILTERS, CLIENT_DRAWER } from './constants';

export const loginAuth = (user: any) => (dispatch: any) => {
    dispatch({
        type: LOGIN,
        payload: user,
    });
};

export const filtersGlobal = (txt: any) => (dispatch: any) => {
    dispatch({
        type: FILTERS,
        payload: txt,
    });
};

export const clientDrawer = (txt: any) => (dispatch: any) => {
    dispatch({
        type: CLIENT_DRAWER,
        payload: txt,
    });
};
