export interface ClientProps {
    id: number;
    name: string;
    email: string;
    data: string;
    created_at: string;
    photo: string;
    is_pending: boolean;
}

export interface PlansProps {
    id: number;
    name: string;
    amount_arts: number;
    amount_posts: number;
    price: number;
    is_main: boolean;
    status: 'active' | 'inactive';
}

export interface ShortPlansProps {
    id: number;
    name: string;
    status: 'active' | 'inactive';
}

export const headerConfigs = {
    clients: [
        {
            title: 'NOME',
            width: '30%',
            notBar: false,
        },
        {
            title: 'EMAIL',
            width: '25%',
            notBar: false,
        },
        {
            title: 'DATA DE CRIAÇÂO',
            width: '15%',
            notBar: false,
        },
        // {
        //     title: 'SOLICITAÇÕES',
        //     width: '15%',
        //     notBar: false,
        // },
        {
            title: 'AÇÔES',
            width: '15%',
            notBar: true,
        },
    ],
    connections: [
        {
            title: 'NOME FANTASIA',
            width: '30%',
            notBar: false,
        },
        {
            title: 'PLANO',
            width: '30%',
            notBar: false,
        },
        {
            title: 'STATUS',
            width: '30%',
            notBar: false,
        },
    ],
    plans: [
        {
            title: 'NOME',
            width: '23%',
            notBar: false,
        },
        // {
        //     title: 'QNT. DE ARTES',
        //     width: '18%',
        //     notBar: false,
        // },
        // {
        //     title: 'QNT. DE POSTS',
        //     width: '18%',
        //     notBar: false,
        // },
        {
            title: 'VALOR',
            width: '16%',
            notBar: false,
        },
        {
            title: 'STATUS',
            width: '16%',
            notBar: false,
        },
        {
            title: 'AÇÕES',
            width: '9%',
            notBar: true,
        },
    ],
    vehicles: [
        {
            title: 'MODELO',
            width: '30%',
            notBar: false,
        },
        {
            title: 'ANO DO MODELO',
            width: '10%',
            notBar: false,
        },
        {
            title: 'PLACA',
            width: '15%',
            notBar: false,
        },
        {
            title: 'PREÇO',
            width: '15%',
            notBar: false,
        },
        {
            title: 'DATA DE CRIAÇÂO',
            width: '20%',
            notBar: false,
        },
        // {
        //     title: 'SOLICITAÇÕES',
        //     width: '15%',
        //     notBar: false,
        // },
        {
            title: 'AÇÔES',
            width: '10%',
            notBar: true,
        },
    ],
};
