import styled from 'styled-components/native';

export const Container = styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const Description = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray[150]};
    max-width: 393px;
    margin-top: 24.03px;
`;
