import styled from 'styled-components/native';
import Modal from 'react-native-modal';

interface ButtonProps  {
    backgroundColor: string,
}

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    width: 360px;
    border-radius: 6px;
    align-items: center;
    padding: 50px 0px 60px 0px;
`;

export const Title = styled.Text<ButtonProps>`
    color: ${({ backgroundColor }) => backgroundColor};
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: 16px;
    margin-top: 12px;
`;

export const Description = styled.Text`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    margin-top: 16px;
    max-width: 263px;
    text-align: center;
`;

export const Button = styled.TouchableOpacity<ButtonProps>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    height: 40px;
    padding: 0px 30px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
`;

export const ButtonText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
`;

export const ModalArea = styled(Modal)`
    align-items: center;
    margin: 0px;
`;
