import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';

import * as S from './styles';

import { ReportTable } from '../../../components/ReportTable';
import { userTransfersList } from '../../../services/transfers';

import { Loading } from './../../../components/Loading';

export function PaymentControl() {
    const { user } = useSelector((state: any) => state.user.user);
    const isFocused = useIsFocused();

    const [paymentUserList, setPaymentUserList] = useState(null);
    const [loading, setLoading] = useState(isFocused);

    useEffect(() => {
        setLoading(isFocused);
        getAllTransfersList();
    }, [isFocused]);

    async function getAllTransfersList() {
        const { result } = await userTransfersList(user.id);
        setPaymentUserList(result);
        setLoading(false);
    }

    function RenderContentInfo() {
        if (loading) {
            return (
                <Loading />
            );
        }

        return (
            <S.ScrollView>
                <S.ContainerAlign>
                    <S.TitleTable marginBottom={20}>
                        Controle de pagamentos
                    </S.TitleTable>
                    <ReportTable
                        type='paymentControl'
                        tableList={paymentUserList}
                        refreshList={getAllTransfersList}
                    />
                </S.ContainerAlign>
            </S.ScrollView>
        );
    }

    return (
        <S.Container>
            {RenderContentInfo()}
        </S.Container>
    );
}
