import React from 'react';

import * as S from './styles';

interface TableInfoProps {
    width: string,
    title: string,
    key: number,
    type: 'header' | 'table',
    color?: string,
    fontFamily?: string,
    fontSize?: number,
    height?: number,
}

export function TableInfo(props: TableInfoProps) {
    const { width, title, key, type, color, fontFamily, fontSize, height } = props;

    return (
        <S.TitleHeaderArea width={width} key={key} height={height}>
            <S.TitleHeaderText color={color} type={type} fontFamily={fontFamily} fontSize={fontSize}>{title}</S.TitleHeaderText>
        </S.TitleHeaderArea>
    );
}
