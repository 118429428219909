import React from 'react';

import * as S from './styles';

interface LoadingModalProps {
    visible: boolean,
    titleLoading: string,
}

export function LoadingModal(props: LoadingModalProps) {
    const { visible, titleLoading } = props;

    return (
        <S.ModalArea isVisible={visible}>
            <S.ContainerModal>
                <S.IndicatorLoading size='large' />
                <S.TextLoading>{titleLoading}</S.TextLoading>
            </S.ContainerModal>
        </S.ModalArea>
    );
}
