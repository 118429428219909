import styled from 'styled-components/native';

interface ButtonProps {
    backgroundColor: string,
}

export const ContainerModal = styled.View`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    height: 300px;
    justify-content: center;
    width: 300px;
`;

export const TitleModal = styled.Text`
    color: ${({ theme }) => theme.colors.blue[200]};
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: 16px;
    margin-top: 7px;
`;

export const DescriptionText = styled.Text`
    align-self: center;
    color: ${({ theme }) => theme.colors.lowBlack};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    margin-top: 22px;
    text-align: center;
    width: 90%;
`;

export const ButtonArea = styled.View`
    align-self: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 22px;
    width: 75%;
`;

export const Button = styled.TouchableOpacity<ButtonProps>`
    align-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 6px;
    height: 40px;
    justify-content: center;
    padding: 0px 12px;
`;

export const TextButton = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
`;
