import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import Theme from '../../../../global/styles/theme';
import CloseIcon from '../../../../assets/images/close.png';
import * as S from './styles';
import { getArtsPending, updateStatusArts } from '../../../../services/arts';
import DollarIcon from '../../../../assets/images/svg/dollarIcon';
import { converterCurrency } from '../../../../global/utils/functions';

import { Loading } from '../../../Loading';

interface RequestsModalProps {
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    clientInfo: any,
    refreshList: () => void,
}

export function RequestsModal(props: RequestsModalProps) {
    const { modalVisible, setModalVisible, clientInfo, refreshList } = props;
    const fontBlack = Theme.colors.lowBlack;
    const fontGreen = Theme.colors.green[200];
    const fontRed = Theme.colors.red[350];
    const fontRegular = Theme.font.segoeUi.regular;
    const fontSemiBold = Theme.font.segoeUi.semiBold;
    const fontBold = Theme.font.segoeUi.bold;

    const [pendingArtList, setPendingArtList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    async function getListArts() {
        setLoading(false);
        const { result } = await getArtsPending(clientInfo.id);
        setPendingArtList(result.list)
        setLoading(true);
    }

    function onBackdropPress() {
        setModalVisible(false);
    }

    async function handleUpdatePress(statusOption: 'aproved' | 'canceled', idRequest: number) {
        const { status, message } = await updateStatusArts(idRequest, statusOption);

        if (status === 200) {
            getListArts();
            refreshList();
        } else {
            toast.error(message, Theme.toast);
        }
    }

    function RenderListPendingArts() {
        if (!pendingArtList.length) {
            return (
                <S.TextDefault
                    fontSize={20}
                    fontFamily="bold"
                    color={fontBlack}
                >
                    Sem solicitações!
                </S.TextDefault>
            );
        }

        return pendingArtList.map((el) => {
            const urlImage = { uri: el.thumb };

            function handleConfirmPress() {
                handleUpdatePress('aproved', el.id);
            }

            function handleCancelPress() {
                handleUpdatePress('canceled', el.id);
            }

            return (
                <S.ArtArea key={el.id}>
                    <S.ArtImage onContextMenu={(e) => e.preventDefault()} source={urlImage} />
                    <S.InfoArea>
                        <S.NameAndPrice>
                            <S.TextDefault
                                fontSize={14}
                                fontFamily={fontSemiBold}
                                color={fontBlack}
                            >
                                {el.title}
                            </S.TextDefault>
                            <S.TextDefault
                                fontSize={12}
                                fontFamily={fontSemiBold}
                                color={fontBlack}
                            >
                                {converterCurrency(el.price)}
                            </S.TextDefault>
                        </S.NameAndPrice>
                        <S.PriceArea>
                            <DollarIcon />
                        </S.PriceArea>
                    </S.InfoArea>
                    <S.ButtonAlterStatusArea>
                        <S.DefaultButton type='confirm' onPress={handleConfirmPress}>
                            <S.TextDefault
                                fontSize={14}
                                fontFamily={fontRegular}
                                color={fontGreen}
                            >
                                Aprovar
                            </S.TextDefault>
                        </S.DefaultButton>
                        <S.DefaultButton type='disapprove' onPress={handleCancelPress}>
                            <S.TextDefault
                                fontSize={14}
                                fontFamily={fontRegular}
                                color={fontRed}
                            >
                                Recusar
                            </S.TextDefault>
                        </S.DefaultButton>
                    </S.ButtonAlterStatusArea>
                </S.ArtArea>
            );
        })
    }

    function RenderArtInfo() {
        if (!loading) {
            return (
                <Loading />
            );
        }

        return (
            <S.ArtListArea>
                {RenderListPendingArts()}
            </S.ArtListArea>
        );
    }

    return (
        <S.ModalArea isVisible={modalVisible} onBackdropPress={onBackdropPress} backdropOpacity={0.1}>
            <S.ContainerModal>
                <S.HeaderArea>
                    <S.TextDefault
                        fontSize={25}
                        fontFamily={fontBold}
                        color={fontBlack}
                    >
                        Solicitações
                    </S.TextDefault>
                    <S.CloseButton onPress={onBackdropPress}>
                        <S.CloseImage onContextMenu={(e) => e.preventDefault()} resizeMode='contain' source={CloseIcon} />
                    </S.CloseButton>
                </S.HeaderArea>
                {RenderArtInfo()}
            </S.ContainerModal>
        </S.ModalArea>
    );
}
