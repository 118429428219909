import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';

import PaymentIcon from '../../../../../assets/images/svg/paymentIcon';
import FavoriteUncheckedIcon from '../../../../../assets/images/svg/favoriteUncheckedIcon';
import FavoriteCheckedIcon from '../../../../../assets/images/svg/favoriteCheckedIcon';

import * as S from './styles';

interface ItemProps {
    id: number;
    id_plan: number;
    title: string;
    store_name: string;
    colors: Array<string>;
    use: string;
    type: string;
    thumb: string;
    is_bought: boolean;
    is_favorite: boolean;
    author_name: string;
    author_photo: string;
    is_available: boolean;
    options_text: Array<any>;
}

interface CardProps {
    item: ItemProps;
    setModalVisible: (modalVisible: boolean) => void;
    setModalInfo: (modalInfo: ItemProps) => void;
    handleFavorite: (id: number, option: boolean) => Promise<any>;
    sizeCard?: number;
}

export function Card(props: CardProps) {
    const {
        item: { id, title, thumb, is_bought, is_favorite, is_available, options_text, ...teste },
        setModalInfo,
        setModalVisible,
        handleFavorite,
        sizeCard,
    } = props;
    const uri = { uri: thumb };
    const { width } = useWindowDimensions();
    const [favorited, setFavorited] = useState(is_favorite);

    function handleTemplatePress() {
        setModalInfo(props.item);
        setModalVisible(true);
    }

    function RenderPayment() {
        if (is_bought || !is_available) {
            return null;
        }

        return (
            <S.PaymentIconArea>
                <PaymentIcon />
            </S.PaymentIconArea>
        );
    }

    function renderFavoriteButton() {
        function renderIcon() {
            if (!favorited) {
                return (
                    <FavoriteUncheckedIcon />
                );
            }
            return (
                <FavoriteCheckedIcon />
            );
        }

        async function handleFavoriteTemplate() {
            const status = await handleFavorite(id, !favorited);
            if (status === 200) {
                setFavorited(!favorited);
            }
        }

        return (
            <S.FavoriteButton onPress={handleFavoriteTemplate}>
                {renderIcon()}
            </S.FavoriteButton>
        );
    }

    return (
        <S.Container onPress={handleTemplatePress} width={width} style={{ width: sizeCard || 250, height: sizeCard ? (sizeCard + 20) : 250}}>
            <S.ImageArea>
                <S.ImageCard onContextMenu={(e) => e.preventDefault()} style={{ width: sizeCard || 250, height: sizeCard || 250}} resizeMode='contain' source={uri} />
            </S.ImageArea>
            <S.InfoArea>
                <S.Title>Núm. {String(id).padStart(6, "0")}</S.Title>
                {options_text?.filter((text) => text)?.length > 0 ? (
                    <S.Tag>Mensagem editável</S.Tag>
                ) : null}
                <S.IconArea>
                    {RenderPayment()}
                    {renderFavoriteButton()}
                </S.IconArea>
            </S.InfoArea>
        </S.Container>
    );
}
