import React from 'react';
import * as S from './styles';

export function Loading() {
    return (
        <S.Container>
            <S.Spinner />
        </S.Container>
    );
}
