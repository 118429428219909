import styled from "styled-components/native";

import theme from "../../../global/styles/theme";
import { ResponsiveProps } from '../../../global/types';

interface SearchButtonProps extends ResponsiveProps {
    backgroundCodition: boolean,
}

interface TextTitleProps {
    marginBottom?: number,
    marginTop: number,
}

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.background};
    flex: 1;
`;

export const TitleTable = styled.View<TextTitleProps>`
    color: ${({ theme }) => theme.colors.lowBlack};
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    margin-top: ${({ marginTop }) => marginTop || 0};
`;

export const InputStyle = {
    height: 50,
    border: "0px",
    width: 220,
    outlineWidth: 0,
    WebkitAppearance: 'none',
    fontFamily: theme.font.segoeUi.regular,
    fontSize: 14,
    appearance: null,
    MozAppearance: 'none',
};

export const DateArea = styled.View<ResponsiveProps>`
    flex-direction: ${({ width }) => width > 1100 ? 'row' : 'column'};
`;

export const SearchButton = styled.TouchableOpacity<SearchButtonProps>`
    align-items: center;
    background-color: ${({ theme, backgroundCodition }) => !backgroundCodition ? theme.colors.purple[500] : theme.colors.gray[400]};
    border-radius: 8px;
    height: 50px;
    justify-content: center;
    margin-top: ${({ width }) => width > 1100 ? 0 : 8};
    width: 250px;
`;

export const SearchText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 16px;
`;

export const ContainerAlign = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 40px;
    padding: 14px;
    width: 95%;
`;

export const ScrollView = styled.ScrollView``;

export const InputDateArea = styled.View<ResponsiveProps>`
    border: 1px solid ${({ theme }) => theme.colors.gray[25]};
    border-radius: 8px;
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: ${({ width }) => width > 1100 ? 0 : 8};
    width: 250px;
`;
