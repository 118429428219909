import styled, { css } from 'styled-components/native';
import Modal from 'react-native-modal';

import { ResponsiveProps } from '../../../../global/types';

interface DefaultButtonProps {
    type: 'confirm' | 'disapprove',
}

interface TextProps {
    fontSize: number;
    color: string;
    fontFamily: string;
    marginTop: number;
}

export const ModalArea = styled(Modal)`
    margin: 0;
`;

export const ContainerModal = styled.View`
    align-self: flex-end;
    background-color: ${({ theme }) => theme.colors.white};
    height: 90%;
    margin-right: 28px;
    width: 85%;
`;

export const HeaderArea = styled.View`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 20px;
    padding-top: 36px;
`;

export const ArtListArea = styled.View<ResponsiveProps>`
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 40px;
    padding-right: 30px;
    padding-left: 50px;
`;

export const TextDefault = styled.Text<TextProps>`
    color: ${({ color }) => color};
    font-family: ${({ fontFamily }) =>
        fontFamily };
    font-size: ${({ fontSize }) => fontSize};
    ${({ marginTop }) => `margin-top: ${marginTop || 0}px`}
`;

export const CloseButton = styled.TouchableOpacity`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray[10]};
    border-radius: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

export const CloseImage = styled.Image`
    height: 16px;
    width: 16px;
`;

export const ArtArea = styled.View`
    width: 180px;
    margin-left: 30px;
    margin-right: 30px;
`;

export const ArtImage = styled.Image`
    border-radius: 6px;
    height: 180px;
    width: 180px;
`;

export const InfoArea = styled.View`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
`;

export const PriceArea = styled.View`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.yellow};
    border-radius: 12px;
    height: 23px;
    justify-content: center;
    width: 23px;
`;

export const NameAndPrice = styled.View`

`;

export const ButtonAlterStatusArea = styled.View`
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;
    width: 160px;
`;

export const DefaultButton = styled.TouchableOpacity<DefaultButtonProps>`
    align-items: center;
    background-color: ${({ type, theme }) => type === 'confirm' ? theme.colors.green[150] : theme.colors.red[150]};
    border-radius: 4px;
    height: 23px;
    justify-content: center;
    width: 70px;
`;
