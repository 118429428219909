import styled from 'styled-components/native';
import themeGlobal from '../../global/styles/theme';

export const Container = styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const Spinner = styled.ActivityIndicator.attrs({
    color: themeGlobal.colors.purple[500],
    size: 30,
})``;
