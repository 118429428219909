import React, { useState, useEffect } from 'react';
import { Linking, Platform } from 'react-native';
import { ThemeProvider } from 'styled-components/native';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { MenuProvider } from 'react-native-popup-menu';
import 'react-native-gesture-handler';
import { useFonts } from 'expo-font';
import { Toaster } from 'react-hot-toast';
import AsyncStorage from 'redux-persist/lib/storage/session';

import { persistor, store } from './src/store';
import { Routes } from './src/routes';
import theme from './src/global/styles/theme';
import 'intl';

export default function App() {
    const [isReady, setIsReady] = useState(false);
    const [initialState, setInitialState] = useState();
    const [inicialUrl, setInitialUrl] = useState<string | null>('');
    const fonts = {
        'Segoe UI': require('./src/assets/fonts/Segoe UI.ttf'),
        'Segoe UI Semibold': require('./src/assets/fonts/seguisb.ttf'),
        'Segoe UI Bold': require('./src/assets/fonts/Segoe UI Bold.ttf'),
    };
    const [fontsLoaded] = useFonts(fonts);

    const screens = {
        LoginScreen: '/login',
    };

    const linking = {
        config: {
            screens,
        },
    };

    async function getInicialUrl() {
        const url = await Linking.getInitialURL();
        setInitialUrl(url);
    }

    useEffect(() => {
        getInicialUrl();
        if (inicialUrl?.includes('login')) {
            setIsReady(true);
        }
        const restoreState = async () => {
            const savedStateString = await AsyncStorage.getItem(
                'navigation_state',
            );
            setInitialState(JSON.parse(savedStateString));
            setIsReady(true);
        };

        if (!isReady) {
            restoreState();
        }
    }, [isReady]);

    function renderRoutes() {
        if (!inicialUrl?.includes('login') && !isReady) {
            return null;
        }

        return (
            <NavigationContainer
                linking={linking}
                initialState={initialState}
                onStateChange={async (state) => {
                    await AsyncStorage.setItem(
                        'navigation_state',
                        JSON.stringify(state),
                    );
                }}>
                <MenuProvider>
                    <Routes />
                </MenuProvider>
            </NavigationContainer>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {renderRoutes()}
                </PersistGate>
            </Provider>
            <Toaster position="top-right" reverseOrder={true} />
        </ThemeProvider>
    );
}
