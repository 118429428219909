import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'

import { actions, reducers } from './modules';

const persistConfig = {
    key: 'root',
    storage: storageSession,
};

const middleware = [thunk];
const composer = compose(applyMiddleware(...middleware));
const pReducer = persistReducer(persistConfig, combineReducers(reducers));
const store = createStore(pReducer, composer);
const persistor = persistStore(store);
export { actions, persistor, store };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
