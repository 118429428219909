import styled from 'styled-components/native';
import themeGLobal from '../../global/styles/theme';

interface ConfirmButtonProps {
    isValid: boolean,
}

export const Container = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const ButtonCancel = styled.TouchableOpacity`
    height: 40px;
    width: 93px;
    border-width: 1px;
    border-radius: 6px;
    border-color: ${({ theme }) => theme.colors.red[500]};
    align-items: center;
    justify-content: center;
`;

export const TextButtonCancel = styled.Text`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red[500]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const ButtonConfirm = styled.TouchableOpacity<ConfirmButtonProps>`
    height: 40px;
    width: 93px;
    border-width: 1px;
    border-radius: 6px;
    border-color: ${({ theme }) => theme.colors.gray[150]};
    background-color: ${({ theme, isValid }) => isValid ? theme.colors.purple[500] : theme.colors.gray[150]};
    align-items: center;
    justify-content: center;
    margin-left: 16px;
`;

export const TextButtonConfirm = styled.Text`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const Loading = styled.ActivityIndicator.attrs({
    color: themeGLobal.colors.white,
})``;
