import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';

// styles
import * as S from './styles';

// interface
interface TopBarsProps {
    listTabs: Array<string>;
    setCurrentPosition: (position: number) => void;
    currentPosition: number;
    disabledButton?: boolean;
    widthResponsive?: number;
    birthInfo?: any;
}

export function TopTabs(props: TopBarsProps) {
    const {
        listTabs,
        setCurrentPosition,
        currentPosition,
        disabledButton = false,
        widthResponsive,
        birthInfo,
    } = props;

    const width = useWindowDimensions().width;

    function renderTabs() {
        return listTabs.map((tab, index) => {
            const isCurrentPosition = index + 1 === currentPosition;

            function handleSetCurrentPosition() {
                setCurrentPosition(index + 1);
            }

            return (
                <S.Button
                    currentPosition={isCurrentPosition}
                    onPress={handleSetCurrentPosition}
                    disabled={disabledButton}
                    >
                    <S.ButtonText currentPosition={isCurrentPosition} style={{ fontSize: birthInfo ? (width < 600 ? (width < 400 ? 12 : 14) : 18) : 18}}>
                        {tab}
                    </S.ButtonText>
                </S.Button>
            );
        });
    }

    return (
        <S.Container>
            {birthInfo ? (
                <S.ButtonsContainerBirthday widthResponsive={widthResponsive} width={width} fraction={listTabs.length}>
                    {renderTabs()}
                </S.ButtonsContainerBirthday>
            ) : (
                <S.ButtonsContainer widthResponsive={widthResponsive} width={width} fraction={listTabs.length}>
                    {renderTabs()}
                </S.ButtonsContainer>
            )}
            <S.Divider />
        </S.Container>
    );
}
