import React from 'react';
import * as Clipboard from 'expo-clipboard';

import * as S from './styles';
import Theme from '../../../../global/styles/theme';

interface ModalInfoProps {
    item: any,
    setModalVisible: (visible: boolean) => void,
}

export function LogsModal(props: ModalInfoProps) {
    const { item, setModalVisible } = props;
    const backgroundInfoColor = Theme.colors.red[600];
    const contactColor = Theme.colors.green[400];
    const logs = item.error_logs ? JSON.stringify(JSON.parse(item.error_logs), null, 2) : '';

    function closeModal() {
        setModalVisible(false);
    }

    async function openUrl() {
        await Clipboard.setStringAsync(logs || '');
    }

    const legendas = [
        'id: A identificação da publicação.',
        'can_reply_privately: Indica se o visualizador da Página pode enviar uma resposta privada à publicação. É preciso ter a permissão read_page_mailboxes.',
        'is_expired: Indica se a publicação tem um horário de expiração que já passou.',
        'created_time: Indica quando foi criada a publicação.',
        "from: O name e a id da Página, do grupo ou do evento que criou a publicação. Se você ler esse campo com um token de acesso do usuário, ele retornará somente o usuário atual.",
        "full_picture: URL para uma versão de tamanho completo da foto postada na publicação ou extraída de um link na publicação. Se a maior dimensão da foto exceder 720 pixels, ela será redimensionada com a maior dimensão definida como 720.",
        "icon: Link para um ícone que representa o tipo da publicação.",
        "is_eligible_for_promotion: Indica se uma publicação está qualificada para promoção.",
        "is_hidden: Indica se a publicação está marcada como oculta (aplica-se somente a Páginas). Ocultar uma publicação impedirá que ela seja exibida na linha do tempo da Página. No entanto, ainda será possível visualizá-la em outros locais do Facebook (por exemplo, um link).",
        "is_instagram_eligible: Indica se a publicação pode ser promovida no Instagram.",
        "is_popular: Indica se a publicação é popular. Essa classificação é aplicada quando as ações totais como um percentual de alcance excedem um certo limite.",
        "is_published: Indica se uma publicação programada foi feita (aplica-se somente à publicação da Página programada; para publicações de usuários e publicações feitas instantaneamente, o valor é sempre true). Esse valor é sempre false para publicações da Página criadas como parte do processo de criação de anúncio.",
        "is_spherical: Indica se é uma publicação de vídeo esférico.",
        "updated_time: O horário em que a publicação foi atualizada pela última vez, expresso com um registro de data e hora do UNIX. Isso ocorre quando a publicação é criada ou editada ou quando um usuário comenta em uma publicação.",
        "attachments: Todos os anexos que estão associados à história. Consulte a referência do nó Story Attachment para campos attachments.",
    ];

    return (
        <S.ContainerModal>
            <S.ModalAreaScrollView>
                <S.TitleModal>
                    LOGS:
                </S.TitleModal>
                <S.DescriptionText>
                    {logs}
                </S.DescriptionText>
                <S.TitleModal>
                    LEGENDAS:
                </S.TitleModal>
                {legendas.map((legenda) => (
                    <S.DescriptionText key={legenda}>
                        {legenda}
                    </S.DescriptionText>
                ))}
            </S.ModalAreaScrollView>
            <S.ButtonArea>
                <S.Button backgroundColor={contactColor} onPress={openUrl}>
                    <S.TextButton>
                        Copiar para área de transferência
                    </S.TextButton>
                </S.Button>
                <S.Button backgroundColor={backgroundInfoColor} onPress={closeModal}>
                    <S.TextButton>
                        Fechar
                    </S.TextButton>
                </S.Button>
            </S.ButtonArea>
        </S.ContainerModal>
    );
}
