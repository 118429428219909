// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <Path
      d="M11.591 1.584a2 2 0 0 1 2.824 2.824L4.883 13.94 1 15l1.059-3.883Z"
      fill="none"
      stroke="#626477"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
)

export default SvgComponent
