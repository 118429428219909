// @ts-nocheck
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginAuth } from '../../store/modules/user/actions';
import { clientLogout } from '../../services/clients';

import * as S from './styles';
import { ModalInfoProps } from '../../global/types';

import { LoadingModal } from '../../components/LoadingModal';
import { CoditionModal } from '../../components/CoditionModal';

export function ButtonLogout() {
    const { user } = useSelector((state: any) => state.user.user);
    const dispatch = useDispatch();

    const [loadingVisible, setLoadingVisible] = useState(false);
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    function updateUserCodition() {
        dispatch(loginAuth(logoutCodition));
        if (user.platform === 'revenda_prod') {
            window.close();
        }
    }

    const logoutCodition = { logout: true };

    async function handleLogout() {
        setLoadingVisible(true);
        const { status, message } = await clientLogout(user.id);

        if (status === 201 || status === 200) {
            setLoadingVisible(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'success',
                titleModal: 'Sucesso',
                descriptionModal: message,
                buttonText: 'Continuar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: updateUserCodition,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        } else {
            setLoadingVisible(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        }
    }

    return (
        <S.ButtonLogout onPress={handleLogout}>
            <S.IconLogout name="log-out" />
            <S.TextLogout>Sair</S.TextLogout>
            <LoadingModal
                visible={loadingVisible}
                titleLoading='Saindo...'
            />
            <CoditionModal
                titleModal={modalInfo.titleModal}
                descriptionModal={modalInfo.descriptionModal}
                typeModal={modalInfo.typeModal}
                buttonText={modalInfo.buttonText}
                modalVisible={modalInfo.modalVisible}
                setModalVisible={modalInfo.setModalVisible}
                buttonFunction={modalInfo.buttonFunction}
            />
        </S.ButtonLogout>
    );
}
