// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={55.372} height={54} {...props}>
    <G
      fill="none"
      stroke="#707070"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
    >
      <Path data-name="Caminho 100" d="m27.686 2-25 12.6 25 12.6 25-12.6Z" />
      <Path data-name="Caminho 101" d="m2.686 39.402 25 12.6 25-12.6" />
      <Path data-name="Caminho 102" d="m2.686 26.935 25 12.6 25-12.6" />
    </G>
  </Svg>
)

export default SvgComponent
