import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import * as S from './styles';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { HeaderClient } from '../HeaderClient';
import { SideBarClient } from '../SideBarClient';

interface DrawerClientsProps {
    children: any;
    drawerIsOpen: any;
    setDrawerIsOpen: any;
    handleOpenDrawer: () => void;
    currentPage: number;
    setCurrentPage: (screen: number) => void;
}

export function DrawerClients(props: DrawerClientsProps) {
    const { children, drawerIsOpen, setDrawerIsOpen, handleOpenDrawer, currentPage, setCurrentPage } = props;
    const width = useWindowDimensions().width;

    // const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    // const [currentPage, setCurrentPage] = useState(0);

    // function handleOpenDrawer() {
    //     setDrawerIsOpen(!drawerIsOpen);
    // }

    function handleSidebar() {
        if (!drawerIsOpen && width <= 860) {
            return null;
        }

        return (
            <SideBarClient
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setDrawerIsOpen={setDrawerIsOpen}
            />
        );
    }

    async function removeTypeScreenBirthday() {
        const isBirthDay = await AsyncStorage.getItem(
            '@midiacarros:birthday_routes',
        );
        if (isBirthDay) {
            setCurrentPage(7);
            await AsyncStorage.removeItem('@midiacarros:birthday_routes');
        }
    }

    useEffect(() => {
        removeTypeScreenBirthday()
    }, [])

    return (
        <S.Container>
            <HeaderClient handleOpenDrawer={handleOpenDrawer} />
            <S.Content>
                {handleSidebar()}
                {children}
            </S.Content>
        </S.Container>
    );
}
