// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useWindowDimensions } from 'react-native';
import { useForm, useController } from 'react-hook-form';
import { Switch } from 'react-native';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { SketchPicker } from 'react-color';
import { Picker } from '@react-native-picker/picker';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';

import * as S from './styles';
import { createNewArt, getStoreList, updateArt, userImportArt, allColorsList } from '../../../services/arts';
import { shemaCreateArt } from '../../../global/utils/formsSchems';
import { getPlans } from '../../../services/plans';
import Theme from '../../../global/styles/theme';
import CloseImage from '../../../assets/images/close.png';
import { unmaskedPrice, converterCurrency } from '../../../global/utils/functions';

import { InputInternal } from '../../../components/InputInternal';
import { DashboardHeader } from '../../../components/DashboardHeader';
import { Buttons } from '../../../components/DashboardButtons';
import { DropzoneComponent } from './components/Dropzone';
import { InputEvent } from './components/EventInput';
import { CoditionModal } from '../../../components/CoditionModal';
import { LoadingModal } from '../../../components/LoadingModal';
import { listColors } from '../../../global/utils/lists';
import { getImageLink } from '../../../services/uploads';
import { obterTipoDeImagemEmBase64 } from '../../../utils/functions';

interface UseValueProps {
    value: '1' | '2';
}

interface AddEventListProps {
    id: number;
    name: string;
}

interface ItemProps {
    id: number;
    type?: 'feed';
    use?: 'public' | 'private';
    colors?: Array<string>;
    price: 'string';
    id_events: Array<AddEventListProps>;
    attachment: string;
    id_plan: string,
    id_user: string,
    user_name: string,
    is_free: boolean,
    is_birthday_message_default: boolean,
}

interface ParamsProps {
    type: 'edit' | 'newArt' | 'client';
    item: ItemProps;
}

interface ModalInfoProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    buttonFunction: () => void,
}

interface StoreInfoProps {
    id: number,
    name: string,
}

const typesChange = {
    '1': 'feed',
    '2': 'story',
    '3': 'birthday',
    '4': 'molduras',
    '5': 'capa',
    '6': 'banner-site'
};

const paramsTypesChange = {
    feed: '1',
    story: '2',
    birthday: '3',
    molduras: '4',
    capa: '5',
    'banner-site': '6',
};

export function CreateArtScreen() {
    const resolver = { resolver: yupResolver(shemaCreateArt), mode: 'onChange' };
    const { control, handleSubmit, getValues, watch, setValue, formState: { errors, isValid, isValidating } } = useForm(resolver);
    const userInfo = useSelector((state) => state.user.user);
    const navigation = useNavigation();
    const route = useRoute();
    const params = route?.params as ParamsProps;
    const typeCoditionParams = params?.type === 'edit' ? paramsTypesChange[params?.item?.type] : '';
    const useDropCodition = params?.type === 'edit' ? params?.item?.use === 'public' ? '1' : '2' : '';
    const width = useWindowDimensions().width;
    const widthForTwoColumns = width <= 800 ? '100%' : '48%';
    const widthForFourColumns = width <= 800 ? '100%' : '19%';

    const useList = [
        { label: 'Selecionar', value: '0' },
        { label: 'Público', value: '1' },
        { label: 'Privado', value: '2' },
    ];
    const typeListFrame = [
        { label: 'Padrão', value: 'default' },
        { label: 'Logotipos', value: 'logotipos' },
        { label: 'Datas', value: 'datas' },
    ];
    const typeList = [
        { label: 'Selecionar', value: '0' },
        { label: 'Feed', value: '1' },
        { label: 'Story', value: '2' },
        { label: 'Birthday', value: '3' },
        { label: 'Molduras', value: '4' },
        { label: 'Capa', value: '5' },
        { label: 'Banner Site', value: '6' },
    ];

    const coditionCheckboxId = params?.item?.use  === 'private' || params?.item?.is_free ? 1 : 2;
    const [colorPicker, setColorPicker] = useState(false);
    const [actualColor, setActualColor] = useState('');
    const [colorInfo, setColorInfo] = useState<any[]>(params?.item?.colors || []);
    const [useValue, setUseValue] = useState<UseValueProps>({
        value: useDropCodition || '',
    } as UseValueProps);
    const [allColors, setAllColors] = useState({
        primary: params?.item?.colors[0] || '',
        secondary: params?.item?.colors[1] || '',
        tertiary: params?.item?.colors[2] || '',
        quaternary: params?.item?.colors[3] || '',
    });
    const [price, setPrice] = useState(converterCurrency(params?.item?.price) || '');
    const [addEventList, setAddEventList] = useState<AddEventListProps[]>(params?.item?.id_events || []);
    const [linkUrl, setLinkUrl] = useState(params?.item?.attachment || '');
    const [imgHelp, setImgHelp] = useState(params?.item?.img_help || '');
    const onPressButton = params?.type === 'client' ? importDocument : handleSubmit(handleSave);
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);
    const [plansList, setPlansList] = useState<any[]>([]);
    const [inputPlans, setInputPlans] = useState('');
    const [loadingModalVisible, setLoadingModalVisible] = useState(false);
    const [checkboxId, setCheckboxId] = useState(coditionCheckboxId || 1);
    const [searchStore, setSearchStore] = useState(params?.item?.user_name || '');
    const [allStoreList, setAllStoreList] = useState<any[]>([]);
    const [getStoreId, setGetStoreId] = useState(params?.item?.id_user || '');
    const [buttonValidity, setButtonValidity] = useState(false);
    const [isEnabled, setIsEnabled] = useState(!(params?.item?.blocked_at));
    const [textOne, setTextOne] = useState((params?.item?.options_text || [])?.includes('1'));
    const [textTwo, setTextTwo] = useState((params?.item?.options_text || [])?.includes('2'));
    const [textThree, setTextThree] = useState((params?.item?.options_text || [])?.includes('3'));
    const [textFour, setTextFour] = useState((params?.item?.options_text || [])?.includes('4'));
    const [textFive, setTextFive] = useState((params?.item?.options_text || [])?.includes('5'));
    const [isEnabledCar, setIsEnabledCar] = useState(true);
    const [isMessageDefault, setIsMessageDefault] = useState(params?.item?.is_birthday_message_default ? true : false);
    const [carWithBackground, setCarWithBackground] = useState(params?.item?.is_removebg || false);
    const [carWithOverlay, setCarWithOverlay] = useState(params?.item?.is_overlapbg || false);
    // FIXME Fluxo title
    const [carWithTitle, setCarWithTitle] = useState(params?.item?.is_input_title || false);
    const [currentType, setCurrentType] = useState(typeCoditionParams);
    const [isError, setIsError] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [splitColors, setSplitColors] = useState<any[]>([]);
    const [typeActualState, setTypeActualState] = useState<any>('');
    const [typeActualStateCapa, setTypeActualStateCapa] = useState<any>('');

    useEffect(() => {
        setSelectedOption(getValues('useSelected'));
        setSelectedType(getValues('type'));
    }, [isValidating]);

    useEffect(() => {
        const typeActual = getValues('typeMolduras');
        setTypeActualState(typeActual || '');
    }, [getValues('typeMolduras')]);

    useEffect(() => {
        const typeActual = getValues('typeCapa');
        setTypeActualStateCapa(typeActual || '');
    }, [getValues('typeCapa')]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => setCurrentType(value.type));
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (currentType === '6') {
            setSelectedOption('2');
            setValue('useSelected', '2');
        }
    }, [currentType]);

    useEffect(() => {
        let newIsError = isError;
        if (addEventList.length) {
            newIsError = newIsError.filter((e) => e !== 'events');
        }
        if (linkUrl) {
            newIsError = newIsError.filter((e) => e !== 'dropzone');
        }
        if(params?.item?.is_vehicle !== undefined){
            setIsEnabledCar(params?.item?.is_vehicle)
        }
        setIsError(newIsError);
    }, [addEventList, linkUrl, price]);

    useEffect(() => {
        if (isValid) {
            const coditionStoreName = useValue.value === '2' ? getStoreId : true;
            const validityButton = price && addEventList.length && linkUrl
                && checkboxId && coditionStoreName && useValue.value && allColors.primary;
            setButtonValidity(validityButton);
        }
    }, [price, addEventList, linkUrl, checkboxId, getStoreId, isValid, useValue, allColors]);

    useEffect(() => {
        getPlansList();
        getColors();
    }, []);

    useEffect(() => {
        if (searchStore.length >= 3) {
            const timeoutId = setTimeout(() => {
                getStoreName(searchStore);
            }, 500);

            return () => clearTimeout(timeoutId);
        }
    }, [searchStore]);

    function goToManageArts() {
        const goToScreen = params.type !== 'client' ? 'ManageArtsScreen' : 'HomeScreen';
        navigation.navigate(goToScreen);
    }

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    async function getPlansList() {
        const response = await getPlans(1, inputPlans, '');

        if (response.status === 200) {
            const labelDropdown = [
                { label: 'Todos os planos', value: '-1' },
            ];
            const listFormatted = response.result?.list.map((e) => {
                const formatted = {
                    label: e.name,
                    value: String(e.id),
                };
                return formatted;
            });
            setPlansList([...labelDropdown, ...listFormatted]);
        }
    }

    async function getColors() {
        const { result } = await allColorsList();
        const formatColors = result.colors.map((el, index) => {
            return {
                value: el,
                label: el,
            }
        })
        const addOption = [{ value: '0', label: 'Nenhum' }, ...formatColors];
        setSplitColors(addOption);
    }

    async function getStoreName(txt: string) {
        const { result } = await getStoreList(1, txt);

        setAllStoreList(result?.list || []);
    }

    // const validationText = () => {
    //     let numberDefinied = [];
    //     if (textOne) {
    //         numberDefinied = [...numberDefinied, 1];
    //     }
    //     if (textTwo) {
    //         numberDefinied = [...numberDefinied, 2];
    //     }
    //     if (textThree) {
    //         numberDefinied = [...numberDefinied, 3];
    //     }
    //     if (textFour) {
    //         numberDefinied = [...numberDefinied, 4];
    //     }
    //     if (textFive) {
    //         numberDefinied = [...numberDefinied, 5];
    //     }
    //     return numberDefinied;
    // };

    async function handleSave(data: any) {
        const useCodition = data.useSelected === '1' ? 'public' : 'private';
        const typeCodition = typesChange[data.type]
        const exchangePrice = unmaskedPrice(price);
        const artCodition = data.idPlan === '-1';
        const allStates = getValues();
        const getColors = [allStates.colorPrimary, allStates.colorSecundary, allStates.colorTerciary, allStates.colorQuaternary].map((v) => {
            if (Number(v) === 0) {
                return '';
            }
            return v;
        }).filter((v) => v);
        setLoadingModalVisible(true);

        const optionsText = [textOne ? '1' : '', textTwo ? '2' : '', textThree ? '3' : '', textFour ? '4' : '', textFive ? '5' : ''];
        const placeholderText = [
            textOne ? data.descText1 || '' : '',
            textTwo ? data.descText2 || '' : '',
            textThree ? data.descText3 || '' : '',
            textFour ? data.descText4 || '' : '',
            textFive ? data.descText5 || '' : '',
        ];
        const dimensiones = [data.box1, data.box2, data.box3, data.box4, data.box5, data.box6, data.box7, data.box8, data.box9, data.box10].filter((v) => v);
        const verificationPeriod = () => {
            if (typeCodition === 'capa' && data.typeCapa === 'datas') {
                return {
                    periodStart: data.typeCapaDatasPeriodStart || null,
                    periodEnd: data.typeCapaDatasPeriodEnd || null,
                };
            }
            if (typeCodition === 'molduras' && data.typeMolduras === 'datas') {
                return {
                    periodStart: data.typeMoldurasDatasPeriodStart || null,
                    periodEnd: data.typeMoldurasDatasPeriodEnd || null,
                };
            }
            return {
                periodStart: data.periodStart || null,
                periodEnd: data.periodEnd || null,
            };
        };
        const resultValidation = verificationPeriod();

        let createArtBody = {
            ...data,
            type: typeCodition,
            colors: getColors,
            use: useCodition,
            idEvents: addEventList,
            idPlan: !artCodition ? Number(data.idPlan) : null,
            price: exchangePrice,
            isAllPlans: artCodition,
            idAdm: userInfo.user.id,
            isFree: checkboxId === 1,
            blockedAt: !isEnabled,
            isVehicle: isEnabledCar,
            isRemovebg: carWithBackground,
            isOverlapbg: carWithOverlay,
            // FIXME Fluxo title
            isInputTitle: carWithTitle,
            isBirthdayMessageDefault: isMessageDefault,
            placeholderText: placeholderText.length > 0 ? placeholderText : null,
            optionsText: optionsText.length > 0 ? optionsText : null,
            dimensiones: dimensiones.length > 0 ? dimensiones : null,
            periodStart: resultValidation.periodStart,
            periodEnd: resultValidation.periodEnd
        };
        delete createArtBody.colorPrimary;
        delete createArtBody.colorSecondary;
        delete createArtBody.colorTertiary;
        delete createArtBody.colorQuaternary;
        delete createArtBody.box1;
        delete createArtBody.box2;
        delete createArtBody.box3;
        delete createArtBody.box4;
        delete createArtBody.box5;
        delete createArtBody.box6;
        delete createArtBody.box7;
        delete createArtBody.box8;
        delete createArtBody.box9;
        delete createArtBody.box10;
        delete createArtBody.typeMoldurasDatasPeriodStart;
        delete createArtBody.typeMoldurasDatasPeriodEnd;
        delete createArtBody.typeCapaDatasPeriodStart;
        delete createArtBody.typeCapaDatasPeriodEnd;
        // if (createArtBody.typeMolduras !== 'datas') {
        //     delete createArtBody.typeMoldurasDatasPeriodStart;
        //     delete createArtBody.typeMoldurasDatasPeriodEnd;
        // }
        // if (createArtBody.typeCapa !== 'datas') {
        //     delete createArtBody.typeCapaDatasPeriodStart;
        //     delete createArtBody.typeCapaDatasPeriodEnd;
        // }
        if (createArtBody.type === 'capa') {
            delete createArtBody?.typeMolduras;
        }
        if (createArtBody.type === 'molduras') {
            delete createArtBody?.typeCapa;
        }

        if (!linkUrl?.includes('https')) {
            createArtBody = {
                ...createArtBody,
                attachment: linkUrl,
            }
        }

        if (imgHelp && !imgHelp?.includes('https')) {
            const tipoDeImagem = obterTipoDeImagemEmBase64(imgHelp);
            const body = {
                name: uuidv4(),
                type: tipoDeImagem,
                base64: imgHelp,
            };


            const response = await getImageLink(body);
            createArtBody = {
                ...createArtBody,
                imgHelp: response?.result || '',
            }
        }

        if (useCodition === 'private') {
            createArtBody = {
                ...createArtBody,
                idUser: getStoreId,
            }
        }

        const { message, status } =
            params?.type === 'edit'
                ? await updateArt(params?.item?.id, createArtBody)
                : await createNewArt(createArtBody);
        if (status === 201 || status === 200) {
            setLoadingModalVisible(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'success',
                titleModal: 'Sucesso',
                descriptionModal: message,
                buttonText: 'Continuar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: goToManageArts,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        } else {
            setLoadingModalVisible(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        }
    }

    function handleColorPress() {
        setColorPicker(!colorPicker);
    }

    function planCodition() {
        if (params?.item?.id_plan === null) {
            return 'all';
        }
        if (params?.item?.id_plan) {
            return params?.item?.id_plan;
        }

        return '0';
    }

    function Checkbox(name: string, idOption: number, marginLeft?: boolean) {
        const isMarked = idOption === checkboxId;

        const handleCheckboxPress = () => {
            setCheckboxId(idOption);
        }

        return (
            <>
                <S.CheckboxCircle
                    isMarked={isMarked}
                    marginLeft={marginLeft}
                    onPress={handleCheckboxPress}
                >
                    <S.CheckboxCircleMarked isMarked={isMarked}/>
                </S.CheckboxCircle>
                <S.CheckboxText>{name}</S.CheckboxText>
            </>
        );
    }

    async function importDocument() {
        setLoadingModalVisible(true);
        const { status, message } = await userImportArt(linkUrl, userInfo.user.id);
        if (status === 200 || status === 201) {
            setLoadingModalVisible(false);
            toast.success(message, Theme.toast);
            navigation.navigate('HomeScreen');
        } else {
            setLoadingModalVisible(false);
            toast.error(message, Theme.toast);
        }
    }

    function getColor(color: { hex: string }) {
        if (colorInfo.length < 12) {
            const newArray = colorInfo.length
                ? [...colorInfo, color.hex]
                : [color.hex];
            setColorInfo(newArray);
        }
    }

    function changeColor(color: { hex: string }) {
        setActualColor(color.hex);
    }

    function RenderPicker() {
        if (!colorPicker) {
            return null;
        }

        return (
            <S.ColorPickerArea>
                <SketchPicker
                    color={actualColor}
                    onChange={changeColor}
                    onChangeComplete={getColor}
                />
            </S.ColorPickerArea>
        );
    }

    function RenderColorList() {
        return colorInfo.map((el) => {
            const deleteColor = () => {
                const newArrayColor = colorInfo.filter((color) => color !== el);
                setColorInfo(newArrayColor);
            };

            return (
                <S.ColorInfoArea color={el} onPress={deleteColor}>
                    <S.DeleteColorImage
                        resizeMode="contain"
                        source={CloseImage}
                        onContextMenu={(e) => e.preventDefault()}
                    />
                </S.ColorInfoArea>
            );
        });
    }

    function RenderColorArea() {
        if (!colorInfo.length) {
            return null;
        }

        return (
            <S.ColorArea>{RenderColorList()}</S.ColorArea>
        );
    }

    function renderItemColors() {
        return splitColors.map((e) => {
            const { value, label } = e;
            return (
                <Picker.Item key={value} label={label} value={value} />
            );
        });
    }

    function RenderStoreName() {
        if (searchStore.length <= 3) {
            return null;
        }

        return (
            <S.SearchListArea>
                {RenderSearchList()}
            </S.SearchListArea>
        );
    }

    function RenderSearchList() {

        return allStoreList?.map((el) => {
            const handleStorePress = () => {
                setGetStoreId(el.id);
                setSearchStore(el.name);
            }

            return (
                <S.SearchList key={el.id} onPress={handleStorePress}>
                    <S.SearchListText>
                        {el.name}
                    </S.SearchListText>
                </S.SearchList>
            );
        });
    }

    function RenderPriceArea() {
        // if (useValue.value !== '2' && checkboxId === 2) {
        //     return null;
        // }

        return (
            <S.InputContainer>
                <S.InputArea width='100%'>
                    <S.Label>Preço</S.Label>
                    <S.InputPickerContainer>
                        <S.InputMask
                            type='money'
                            value={price}
                            onChangeText={setPrice}
                            placeholder="Digite o preço da arte"
                        />
                    </S.InputPickerContainer>
                </S.InputArea>
            </S.InputContainer>
        );
    }

    function renderSwitchMessage() {
        if (currentType !== '3') {
            return (
                <>
                    <S.Label>Carros sem plano de fundo:*</S.Label>
                    <Switch
                        trackColor={{
                            false: '#767577',
                            true: Theme.colors.purple[500],
                        }}
                        thumbColor="#f4f3f4"
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={toggleSwitchCarWithBackground}
                        value={carWithBackground}
                        style={{ marginTop: 5, marginLeft: 5 }}
                    />
                </>
            );
        }

        return (
            <>
                <S.Label>Mensagem de aniversário pré definida:*</S.Label>
                <Switch
                    trackColor={{
                        false: '#767577',
                        true: Theme.colors.purple[500],
                    }}
                    thumbColor="#f4f3f4"
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={toggleSwitchMessageDefault}
                    value={isMessageDefault}
                    style={{ marginTop: 5, marginLeft: 5 }}
                />
            </>
        );
    }

    function renderInputMessageBirth() {
        if (!isMessageDefault || currentType !== '3') {
            return null;
        }

        return (
            <InputInternal
                inicialValue={params?.item?.message || ''}
                width="100%"
                label="Mensagem pré definida:*"
                placeholder="Mensagem pré definida"
                control={control}
                name="message"
                errors={errors}
                multiline
                maxLength={150}
                heightBoxArea="120px"
            />
        );
    }

    function onChangeColorPrimary(value) {
        setAllColors({
            ...allColors,
            primary: value,
        });
    }

    function onChangeColorSecondary(value) {
        setAllColors({
            ...allColors,
            secondary: value,
        });
    }

    function onChangeColorTertiary(value) {
        setAllColors({
            ...allColors,
            tertiary: value,
        });
    }

    function saveFunction() {
        let newIsError = isError;
        if (!linkUrl) {
            newIsError = [...newIsError, 'dropzone'];
        }
        if (!addEventList.length) {
            newIsError = [...newIsError, 'events'];
        }
        if (!price || price === 'R$ 0,00') {
            newIsError = [...newIsError, 'price'];
        }
        setIsError(newIsError);
        onPressButton();
    }

    function onChangeColorQuaternary(value) {
        setAllColors({
            ...allColors,
            quaternary: value,
        });
    }

    const toggleSwitch = (value) => {
        setIsEnabled(value);
    };

    const toggleSwitchMessageDefault = (value) => {
        setValue('message', null);
        setIsMessageDefault(value);
    };

    const toggleSwitchCarWithBackground = (value) => {
        setCarWithBackground(value);
    };

    const toggleSwitchCarWithOverlay = (value) => {
        setCarWithOverlay(value);
    };

    const toggleSwitchCarWithTitle = (value) => {
        setCarWithTitle(value);
    };

    const toggleSwitchCar = (value) => {
        setIsEnabledCar(value);
    };

    function RenderContent() {
        if (params?.type === 'client') {
            return null;
        }

        return (
            <>
                <S.InputContainer width={width}>
                    <InputInternal
                        inicialValue={params?.item?.title || ''}
                        width="100%"
                        label="Título*"
                        placeholder="Digite o título da arte"
                        control={control}
                        name="title"
                        errors={errors}
                    />
                </S.InputContainer>
                <S.InputContainer width={width}>
                    <InputInternal
                        inicialValue={useDropCodition || '0'}
                        width={widthForTwoColumns}
                        label="Uso*"
                        placeholder="Selecione"
                        list={useList}
                        control={control}
                        name="useSelected"
                        errors={errors}
                        disabled={['6'].includes(selectedType)}
                    />
                    <InputInternal
                        inicialValue={typeCoditionParams || '0'}
                        width={widthForTwoColumns}
                        label="Tipo*"
                        placeholder="Selecione"
                        list={typeList}
                        control={control}
                        name="type"
                        errors={errors}
                        onChange={()=> alert('Teste')}
                    />
                </S.InputContainer>
                {['1', '2', '3'].includes(selectedType) ? (
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={params?.item?.period_start || ''}
                            width={widthForTwoColumns}
                            label="Data inicial"
                            placeholder="00/00/0000"
                            control={control}
                            errors={errors}
                            name="periodStart"
                            mask="date"
                        />
                        <InputInternal
                            inicialValue={params?.item?.period_end || ''}
                            width={widthForTwoColumns}
                            label="Data final"
                            placeholder="00/00/0000"
                            control={control}
                            errors={errors}
                            name="periodEnd"
                            mask="date"
                        />
                    </S.InputContainer>
                ) : null}
                {['1', '2', '4', '5', '6'].includes(selectedType) ? (
                    <>
                        {selectedType === '4' ? (
                            <>
                                <S.InputContainer width={width}>
                                    <InputInternal
                                        inicialValue={params?.item?.type_molduras || 'default'}
                                        width={widthForTwoColumns}
                                        label="Tipo da moldura"
                                        placeholder="Selecione"
                                        list={typeListFrame}
                                        control={control}
                                        name="typeMolduras"
                                        errors={errors}
                                    />
                                </S.InputContainer>
                                {typeActualState === 'datas' ? (
                                    <S.InputContainer width={window}>
                                        <InputInternal
                                            inicialValue={params?.item?.period_start || ''}
                                            width={widthForTwoColumns}
                                            label="Data inicial"
                                            placeholder="00/00/0000"
                                            control={control}
                                            errors={errors}
                                            name="typeMoldurasDatasPeriodStart"
                                            mask="date"
                                        />
                                        <InputInternal
                                            inicialValue={params?.item?.period_end || ''}
                                            width={widthForTwoColumns}
                                            label="Data final"
                                            placeholder="00/00/0000"
                                            control={control}
                                            errors={errors}
                                            name="typeMoldurasDatasPeriodEnd"
                                            mask="date"
                                        />
                                    </S.InputContainer>
                                ) : null}
                            </>
                        ) : null}
                        {selectedType === '5' ? (
                            <>
                                <S.InputContainer width={width}>
                                    <InputInternal
                                        inicialValue={params?.item?.type_capa || 'default'}
                                        width={widthForTwoColumns}
                                        label="Tipo da capa"
                                        placeholder="Selecione"
                                        list={typeListFrame}
                                        control={control}
                                        name="typeCapa"
                                        errors={errors}
                                    />
                                </S.InputContainer>
                                {typeActualStateCapa === 'datas' ? (
                                    <S.InputContainer width={window}>
                                        <InputInternal
                                            inicialValue={params?.item?.period_start || ''}
                                            width={widthForTwoColumns}
                                            label="Data inicial"
                                            placeholder="00/00/0000"
                                            control={control}
                                            errors={errors}
                                            name="typeCapaDatasPeriodStart"
                                            mask="date"
                                        />
                                        <InputInternal
                                            inicialValue={params?.item?.period_end || ''}
                                            width={widthForTwoColumns}
                                            label="Data final"
                                            placeholder="00/00/0000"
                                            control={control}
                                            errors={errors}
                                            name="typeCapaDatasPeriodEnd"
                                            mask="date"
                                        />
                                    </S.InputContainer>
                                ) : null}
                            </>
                        ) : null}
                        {['4', '5', '6'].includes(selectedType) ? (
                            <S.InputContainer width={window}>
                                <InputInternal
                                    inicialValue={params?.item?.width || ''}
                                    width={widthForTwoColumns}
                                    label="Largura"
                                    placeholder="Largura"
                                    control={control}
                                    errors={errors}
                                    name="width"
                                />
                                <InputInternal
                                    inicialValue={params?.item?.height || ''}
                                    width={widthForTwoColumns}
                                    label="Altura"
                                    placeholder="Altura"
                                    control={control}
                                    errors={errors}
                                    name="height"
                                />
                            </S.InputContainer>
                        ) : null}
                        <S.InputContainer width={window}>
                            <S.InputPickerArea style={{ width: '19%' }}>
                                <S.Label>Texto 1</S.Label>
                                <Switch
                                    trackColor={{ false: '#767577', true: Theme.colors.purple[500] }}
                                    thumbColor="#f4f3f4"
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={() => setTextOne(!textOne)}
                                    value={textOne}
                                    style={{ marginTop: 5, marginLeft: 5 }}
                                />
                            </S.InputPickerArea>
                            <S.InputPickerArea style={{ width: '19%' }}>
                                <S.Label>Texto 2</S.Label>
                                <Switch
                                    trackColor={{ false: '#767577', true: Theme.colors.purple[500] }}
                                    thumbColor="#f4f3f4"
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={() => setTextTwo(!textTwo)}
                                    value={textTwo}
                                    style={{ marginTop: 5, marginLeft: 5 }}
                                />
                            </S.InputPickerArea>
                            <S.InputPickerArea style={{ width: '19%' }}>
                                <S.Label>Texto 3</S.Label>
                                <Switch
                                    trackColor={{ false: '#767577', true: Theme.colors.purple[500] }}
                                    thumbColor="#f4f3f4"
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={() => setTextThree(!textThree)}
                                    value={textThree}
                                    style={{ marginTop: 5, marginLeft: 5 }}
                                />
                            </S.InputPickerArea>
                            <S.InputPickerArea style={{ width: '19%' }}>
                                <S.Label>Texto 4</S.Label>
                                <Switch
                                    trackColor={{ false: '#767577', true: Theme.colors.purple[500] }}
                                    thumbColor="#f4f3f4"
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={() => setTextFour(!textFour)}
                                    value={textFour}
                                    style={{ marginTop: 5, marginLeft: 5 }}
                                />
                            </S.InputPickerArea>
                            <S.InputPickerArea style={{ width: '19%' }}>
                                <S.Label>Texto 5</S.Label>
                                <Switch
                                    trackColor={{ false: '#767577', true: Theme.colors.purple[500] }}
                                    thumbColor="#f4f3f4"
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={() => setTextFive(!textFive)}
                                    value={textFive}
                                    style={{ marginTop: 5, marginLeft: 5 }}
                                />
                            </S.InputPickerArea>
                        </S.InputContainer>
                    </>
                ) : null}
                <S.InputContainer width={width}>
                    {textOne ? (
                        <InputInternal
                            inicialValue={params?.item?.placeholder_text?.length > 0 ? params?.item?.placeholder_text[0] || '' : ''}
                            width={widthForFourColumns}
                            label="Descrição do texto 1"
                            placeholder="Descrição 1"
                            control={control}
                            name="descText1"
                            errors={errors}
                        />
                    ) : null}
                    {textTwo ? (
                        <InputInternal
                            inicialValue={params?.item?.placeholder_text?.length > 0 ? params?.item?.placeholder_text[1] || '' : ''}
                            width={widthForFourColumns}
                            label="Descrição do texto 2"
                            placeholder="Descrição 2"
                            control={control}
                            name="descText2"
                            errors={errors}
                        />
                    ) : null}
                    {textThree ? (
                        <InputInternal
                            inicialValue={params?.item?.placeholder_text?.length > 0 ? params?.item?.placeholder_text[2] || '' : ''}
                            width={widthForFourColumns}
                            label="Descrição do texto 3"
                            placeholder="Descrição 3"
                            control={control}
                            name="descText3"
                            errors={errors}
                        />
                    ) : null}
                    {textFour ? (
                        <InputInternal
                            inicialValue={params?.item?.placeholder_text?.length > 0 ? params?.item?.placeholder_text[3] || '' : ''}
                            width={widthForFourColumns}
                            label="Descrição do texto 4"
                            placeholder="Descrição 4"
                            control={control}
                            name="descText4"
                            errors={errors}
                        />
                    ) : null}
                    {textFive ? (
                        <InputInternal
                            inicialValue={params?.item?.placeholder_text?.length > 0 ? params?.item?.placeholder_text[4] || '' : ''}
                            width={widthForFourColumns}
                            label="Descrição do texto 5"
                            placeholder="Descrição 5"
                            control={control}
                            name="descText5"
                            errors={errors}
                        />
                    ) : null}
                </S.InputContainer>
                {selectedOption !== '2' ? null : (
                    <S.InputArea width={widthForTwoColumns}>
                        <S.Label>Loja*</S.Label>
                        <S.InputPickerContainer>
                            <S.InputCommon
                                value={searchStore}
                                onChangeText={setSearchStore}
                                placeholder='Digite o nome da loja'
                            />
                        </S.InputPickerContainer>
                        {RenderStoreName()}
                    </S.InputArea>
                )}
                {selectedOption !== '1' ? null : (
                    <S.InputPickerArea width={width}>
                        <S.Label>Template negociável*</S.Label>
                        <S.InputPickerContainer isCheckbox>
                            <S.CheckboxArea>
                                {Checkbox('Sim', 1)}
                                {Checkbox('Não', 2, true)}
                            </S.CheckboxArea>
                        </S.InputPickerContainer>
                    </S.InputPickerArea>
                )}
                <S.InputPickerArea width={width}>
                    <S.InputPickerArea width={700}>
                        {renderSwitchMessage()}
                        {/* {renderInputMessageBirth()} */}
                    </S.InputPickerArea>
                    <S.Label>Carros com sobreposição de fundo:*</S.Label>
                    <Switch
                        trackColor={{
                            false: '#767577',
                            true: Theme.colors.purple[500],
                        }}
                        thumbColor="#f4f3f4"
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={toggleSwitchCarWithOverlay}
                        value={carWithOverlay}
                        style={{ marginTop: 5, marginLeft: 5 }}
                    />
                    <S.Label>Título mídia:*</S.Label>
                    <Switch
                        trackColor={{
                            false: '#767577',
                            true: Theme.colors.purple[500],
                        }}
                        thumbColor="#f4f3f4"
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={toggleSwitchCarWithTitle}
                        value={carWithTitle}
                        style={{ marginTop: 5, marginLeft: 5 }}
                    />
                    <S.Label>Disponibilidade do Template*</S.Label>
                    <Switch
                        trackColor={{ false: '#767577', true: Theme.colors.purple[500] }}
                        thumbColor="#f4f3f4"
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={toggleSwitch}
                        value={isEnabled}
                        style={{ marginTop: 5, marginLeft: 5 }}
                    />
                    <S.Label>É obrigatório a seleção do carro*</S.Label>
                    <Switch
                        trackColor={{ false: '#767577', true: Theme.colors.purple[500] }}
                        thumbColor="#f4f3f4"
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={toggleSwitchCar}
                        value={isEnabledCar}
                        style={{ marginTop: 5, marginLeft: 5 }}
                    />
                </S.InputPickerArea>
                <S.InputContainer width={width}>
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[0] || '' : ''}
                        width={widthForFourColumns}
                        label="Box foto 1"
                        placeholder="1:1"
                        control={control}
                        name="box1"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[1] || '' : ''}
                        width={widthForFourColumns}
                        label="Box foto 2"
                        placeholder="1:1"
                        control={control}
                        name="box2"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[2] || '' : ''}
                        width={widthForFourColumns}
                        label="Box foto 3"
                        placeholder="1:1"
                        control={control}
                        name="box3"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[3] || '' : ''}
                        width={widthForFourColumns}
                        label="Box foto 4"
                        placeholder="1:1"
                        control={control}
                        name="box4"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[4] || '' : ''}
                        width={widthForFourColumns}
                        label="Box foto 5"
                        placeholder="1:1"
                        control={control}
                        name="box5"
                        errors={errors}
                    />
                </S.InputContainer>
                <S.InputContainer width={width}>
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[5] : ''}
                        width={widthForFourColumns}
                        label="Box foto 6"
                        placeholder="1:1"
                        control={control}
                        name="box6"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[6] : ''}
                        width={widthForFourColumns}
                        label="Box foto 7"
                        placeholder="1:1"
                        control={control}
                        name="box7"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[7] : ''}
                        width={widthForFourColumns}
                        label="Box foto 8"
                        placeholder="1:1"
                        control={control}
                        name="box8"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[8] : ''}
                        width={widthForFourColumns}
                        label="Box foto 9"
                        placeholder="1:1"
                        control={control}
                        name="box9"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={params?.item?.dimensiones?.length > 0 ? params?.item?.dimensiones[9] : ''}
                        width={widthForFourColumns}
                        label="Box foto 10"
                        placeholder="1:1"
                        control={control}
                        name="box10"
                        errors={errors}
                    />
                </S.InputContainer>
                {RenderPriceArea()}
                <S.InputContainer width={width}>
                    <InputInternal
                        inicialValue={allColors.primary || '0'}
                        width={widthForTwoColumns}
                        label="Cor primária*"
                        placeholder="Selecione a cor primária"
                        list={splitColors}
                        control={control}
                        name="colorPrimary"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={allColors.secondary || '0'}
                        width={widthForTwoColumns}
                        label="Cor secundária (Opcional)"
                        placeholder="Selecione a cor secundária"
                        list={splitColors}
                        control={control}
                        name="colorSecundary"
                        errors={errors}
                    />
                </S.InputContainer>
                <S.InputContainer width={width}>
                    <InputInternal
                        inicialValue={allColors.tertiary || '0'}
                        width={widthForTwoColumns}
                        label="Cor terciária (Opcional)"
                        placeholder="Selecione a cor terciária"
                        list={splitColors}
                        control={control}
                        name="colorTerciary"
                        errors={errors}
                    />
                    <InputInternal
                        inicialValue={allColors.quaternary || '0'}
                        width={widthForTwoColumns}
                        label="Cor quaternária (Opcional)"
                        placeholder="Selecione a cor quaternária"
                        list={splitColors}
                        control={control}
                        name="colorQuaternary"
                        errors={errors}
                    />
                </S.InputContainer>
                <S.InputContainer width={width}>
                    <InputEvent
                        setAddEventList={setAddEventList}
                        addEventList={addEventList}
                        type={params?.type}
                        isError={isError.includes('events')}
                    />
                    <InputInternal
                        inicialValue={params?.item?.id_plan || '-1'}
                        width={widthForTwoColumns}
                        label="Assinatura*"
                        placeholder="Selecione seu plano"
                        control={control}
                        errors={errors}
                        name="idPlan"
                        list={plansList}
                    />
                </S.InputContainer>
                <S.InputContainer width={width}>
                    <InputInternal
                        inicialValue={params?.item?.key_words || ''}
                        width="100%"
                        label="Palavras-chaves*"
                        placeholder="Digite as palavras-chaves"
                        control={control}
                        name="keyWords"
                        errors={errors}
                    />
                </S.InputContainer>
            </>
        );
    }

    return (
        <S.Container>
            <S.ScrollView>
                <DashboardHeader title="Criar arte" typeHeader='home' goBack goToScreen={goToManageArts} disableInputSearch />
                <S.ContainerAlign>
                    <S.ContainerAlignInfo width={width}>
                        <S.Row>
                            <S.Column>
                                <S.Label style={{ marginBottom: 10 }}>Arquivos da arte*</S.Label>
                                <DropzoneComponent
                                    setDocumentUrl={setLinkUrl}
                                    type={params?.type}
                                    attachment={params?.item?.attachment}
                                    isError={isError.includes('dropzone')}
                                />
                            </S.Column>
                            <S.Column>
                                <S.Label style={{ marginBottom: 10 }}>Imagem como usar</S.Label>
                                <DropzoneComponent
                                    setDocumentUrl={setImgHelp}
                                    type={params?.type}
                                    attachment={params?.item?.img_help}
                                    typeArchive="image/*"
                                    labelButton="Anexar imagem"
                                    labelDropzone="ou arraste uma imagem"
                                />
                            </S.Column>
                        </S.Row>
                        {RenderContent()}
                        <CoditionModal
                            titleModal={modalInfo.titleModal}
                            descriptionModal={modalInfo.descriptionModal}
                            typeModal={modalInfo.typeModal}
                            buttonText={modalInfo.buttonText}
                            modalVisible={modalInfo.modalVisible}
                            setModalVisible={modalInfo.setModalVisible}
                            buttonFunction={modalInfo.buttonFunction}
                        />
                        <Buttons
                            saveFuntion={saveFunction}
                            buttonConfirmText="Salvar"
                            cancelFuntion={goToManageArts}
                            isValid={true}
                        />
                    </S.ContainerAlignInfo>
                    <LoadingModal
                        titleLoading='Criando arte...'
                        visible={loadingModalVisible}
                    />
                </S.ContainerAlign>
            </S.ScrollView>
        </S.Container>
    );
}
