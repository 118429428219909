import React from 'react';
import { Linking } from 'react-native';

import * as S from './styles';
import Theme from '../../../../global/styles/theme';
import InfoIcon from '../../../../assets/images/svg/infoIcon';
import { converterCurrency } from '../../../../global/utils/functions';

interface ModalInfoProps {
    setModalVisible: (visible: boolean) => void,
    confirmPayment: () => void,
    modalInfo: any,
}

export function PaymentModal(props: ModalInfoProps) {
    const { confirmPayment, setModalVisible, modalInfo } = props;
    const backgroundInfoColor = Theme.colors.red[500];
    const contactColor = Theme.colors.green[400];

    function closeModal() {
        setModalVisible(false);
    }

    return (
        <S.ContainerModal>
            <InfoIcon />
            <S.TitleModal>
                Info
            </S.TitleModal>
            <S.DescriptionText>
                Ao comprar um modelo, você garante que vai usar com exclusividade, pois este modelo não aparecerá para outras lojas. Confirma compra pelo valor informado?
            </S.DescriptionText>
            <S.PriceText>
                Valor da compra: {converterCurrency(modalInfo.price)}
            </S.PriceText>
            <S.ButtonArea>
                <S.Button backgroundColor={contactColor} onPress={confirmPayment}>
                    <S.TextButton>
                        Confirmar
                    </S.TextButton>
                </S.Button>
                <S.Button backgroundColor={backgroundInfoColor} onPress={closeModal}>
                    <S.TextButton>
                        Cancelar
                    </S.TextButton>
                </S.Button>
            </S.ButtonArea>
        </S.ContainerModal>
    );
}
