// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={22.903}
        height={20.232}
        {...props}>
        <Path
            d="M20.291 2.612a5.5 5.5 0 0 0-7.78 0l-1.06 1.06-1.06-1.06a5.501 5.501 0 0 0-7.78 7.78l1.06 1.06 7.78 7.78 7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78Z"
            fill="#6746c8"
            stroke="#6746c8"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        />
    </Svg>
);

export default SvgComponent;
