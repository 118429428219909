// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, Circle, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    data-name="Componente 37 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    {...props}
  >
    <Circle data-name="Elipse 43" cx={7.5} cy={7.5} r={7.5} fill="#e3e932" />
    <G
      data-name="dollar-sign (1)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Path data-name="Linha 360" d="M7.461 3.348v8.303" />
      <Path
        data-name="Caminho 83910"
        d="M9.419 4.743H6.54a1.343 1.343 0 1 0 0 2.687h1.919a1.343 1.343 0 1 1 0 2.687H5.197"
      />
    </G>
  </Svg>
)

export default SvgComponent
