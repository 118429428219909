import React from 'react';
import { useWindowDimensions } from 'react-native';

import SearchIcon from '../../assets/images/svg/searchIcon';
import ArrowDown from '../../assets/images/svg/arrowBottom';
import * as S from './styles';

interface InputSearchProps {
    value: string;
    setValue: (value: string) => void;
    placeholder: string;
    marginLeft: number;
    disabled?: boolean;
    arrowIcon?: boolean;
    height?: number;
    widthArea?: number | string;
}

export function InputSearch(props: InputSearchProps) {
    const { value, setValue, placeholder, marginLeft, disabled, arrowIcon, height, widthArea } = props;
    const window = useWindowDimensions().width;

    function RenderIconType() {
        if (!arrowIcon) {
            return (
                <SearchIcon />
            );
        }

        return (
            <ArrowDown />
        );
    }

    return (
        <S.InputSearch marginLeft={marginLeft} width={window} height={height} widthArea={widthArea}>
            <S.Input
                onChangeText={setValue}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
            />
            {RenderIconType()}
        </S.InputSearch>
    );
}
