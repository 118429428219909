import React from 'react';
import {
    createNativeStackNavigator,
    NativeStackNavigationProp,
} from '@react-navigation/native-stack';

import { DashboardScreen } from '../../screens/DashboardScreen/DashboardScreen';
import { PlansScreen } from '../../screens/DashboardScreen/PlansScreen';

import { DrawerCustom } from '../../components/DrawerCustom';
import { ManageClientsRoutes } from './ManageClientsRoutes';
import { EditPerfilScreen } from '../../screens/DashboardScreen/EditPerfilScreen';
import { CreatePlansScreen } from '../../screens/DashboardScreen/CreatePlansScreen';
import { ManageArtsScreen } from '../../screens/DashboardScreen/ManageArtsScreen';
import { ConfigDescriptionScreen } from '../../screens/DashboardScreen/ConfigDescriptionScreen';
import { PublicationTracking } from '../../screens/DashboardScreen/PublicationTracking';
import { ConnectionsScreen } from '../../screens/DashboardScreen/ConnectionsScreen';
import { CreateArtScreen } from '../../screens/DashboardScreen/CreateArtScreen';
import { VehicleScreen } from '../../screens/DashboardScreen/VehicleScreen';
import { ManageClientsScreen } from '../../screens/DashboardScreen/ManageClientsScreen';
import { NewClientScreen } from '../../screens/DashboardScreen/NewClientScreen';
import { ReportScreen } from '../../screens/DashboardScreen/ReportScreen';


export type RootStackParamList = {
    ManageClientsScreen: undefined;
    DashboardScreen: undefined;
    PlansScreen: undefined;
    EditPerfilScreen: undefined;
    CreatePlansScreen: { type?: 'edit'; item?: any };
    ManageArtsScreen: undefined;
    ConfigDescriptionScreen: undefined;
    PublicationTracking: undefined;
    ConnectionsScreen: undefined;
    CreateArtScreen: undefined;
    VehicleScreen: undefined;
    NewClientScreen: undefined;
    ReportScreen: undefined;
};

export interface NavigationProps {
    navigation: NativeStackNavigationProp<RootStackParamList, any>;
}

const { Navigator, Screen } = createNativeStackNavigator<RootStackParamList>();

export function DrawerNavigation() {
    const screenOptions = { headerShown: false };

    function DrawerNavigation() {
        return (
            <DrawerCustom>
                <Navigator screenOptions={screenOptions}>
                    <Screen
                        name="ReportScreen"
                        component={ReportScreen}
                    />
                    <Screen
                        name="DashboardScreen"
                        component={DashboardScreen}
                    />
                    <Screen name="PlansScreen" component={PlansScreen} />
                    <Screen
                        name="EditPerfilScreen"
                        component={EditPerfilScreen}
                    />
                    <Screen
                        name="CreatePlansScreen"
                        component={CreatePlansScreen}
                    />
                    <Screen
                        name="ManageArtsScreen"
                        component={ManageArtsScreen}
                    />
                    <Screen
                        name="ConfigDescriptionScreen"
                        component={ConfigDescriptionScreen}
                    />
                    <Screen
                        name="PublicationTracking"
                        component={PublicationTracking}
                    />
                    <Screen
                        name="ConnectionsScreen"
                        component={ConnectionsScreen}
                    />
                    <Screen
                        name="CreateArtScreen"
                        component={CreateArtScreen}
                    />
                    <Screen
                        name="VehicleScreen"
                        component={VehicleScreen}
                    />
                    <Screen
                        name="ManageClientsScreen"
                        component={ManageClientsScreen}
                    />
                    <Screen
                        name="NewClientScreen"
                        component={NewClientScreen}
                    />
                </Navigator>
            </DrawerCustom>
        );
    }

    return DrawerNavigation();
}
