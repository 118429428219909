import styled from 'styled-components/native';

// interfaces

interface CurrentPositionProps {
    currentPosition: boolean;
}

interface ButtonsContainerProps {
    fraction: number;
    width: number;
    widthResponsive?: number;
}

export const Container = styled.View`
    width: 100%;
`;

export const ButtonsContainer = styled.TouchableOpacity<ButtonsContainerProps>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.width >= (props?.widthResponsive || 768) ? `repeat(${props.fraction}, 1fr)` : '1fr'};
    gap: 10px;
`;

export const ButtonsContainerBirthday = styled.TouchableOpacity<ButtonsContainerProps>`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
`;

export const Button = styled.TouchableOpacity<CurrentPositionProps>`
    flex: 1;
    background-color: ${(props) =>
        props.currentPosition
            ? props.theme.colors.purple[100]
            : props.theme.colors.gray[30]};
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 8px;
`;

export const ButtonText = styled.Text<CurrentPositionProps>`
    color: ${(props) =>
        props.currentPosition
            ? props.theme.colors.white
            : props.theme.colors.gray[800]};
    font-family: ${(props) =>
        props.currentPosition
            ? props.theme.font.segoeUi.regular
            : props.theme.font.segoeUi.bold};
    font-size: 18px;
    padding: 8px;
`;

export const Divider = styled.View`
    background-color: ${(props) => props.theme.colors.purple[100]};
    height: 4px;
`;
