import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        data-name="Componente 6 \u2013 3"
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={31.389}
        {...props}>
        <G data-name="map-pin (3)">
            <Path
                data-name="Caminho 83920"
                d="M18 9c0 7-9 13-9 13S0 16 0 9a9 9 0 1 1 18 0Z"
                fill="#6746c8"
            />
        </G>
        <Path
            data-name="Caminho 83921"
            d="M8 20s2.8 2.877 2.551 4.727-3.528 1.174-3.528 2.673 3.528 3.323 3.528 3.323"
            fill="none"
            stroke="#6746c8"
            strokeWidth={1.5}
        />
    </Svg>
);

export default SvgComponent;
