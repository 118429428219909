import * as DocumentPicker from 'expo-document-picker';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { v4 as uuidv4 } from 'uuid';
import { triggerBase64Download } from 'react-base64-downloader';
import { getImageLinkMultiples } from '../../services/uploads';

export function converterCurrency(value: number): string {
    const currency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value ? value : 0);

    return currency;
}

export function unmaskedPrice(value: string) {
    const string = value.replace('R$', '');
    const unmasked = Number(string.replace(/[.]/g, '').replace(',', '.'));
    return unmasked;
}

export function converterDate(value: Date, options?: 'date') {
    const configDateAndHours = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: 'UTC',
    } as Intl.DateTimeFormatOptions;

    const configDate = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC',
    } as Intl.DateTimeFormatOptions;

    const config = options === 'date' ? configDate : configDateAndHours;

    const date = new Intl.DateTimeFormat('pt-BR', config).format(value);

    return date;
}

export async function convertedBLob(images: string) {
    const includes = images.indexOf('data:image/jpeg;base64');
    if (includes !== -1) {
        var byteString = atob(images.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }
    return undefined;
}

export async function getDoc() {
    const result = await DocumentPicker.getDocumentAsync({
        type: 'image/*',
    });

    if (result.type === 'success') {
        const bodyImage = {
            name: result?.name,
            type: result?.mimeType,
            base64: result?.uri,
        };
        return bodyImage;
    }
}

export async function getDownloadRemoteFile(file: Array<string>, setLoading?: (v: boolean) => any): Promise<any> {
    if (file.includes('base64,')) {
        triggerBase64Download(file[0], 'Foto 1');
    } else {
        const bodyMultipleUploads = file.map((v) => ({
            urlRevenda: v,
            name: uuidv4(),
            type: `image/${v?.split('.')?.slice(-1)[0]}`
        }));
        if (setLoading) {
            setLoading(true);
        }
        const { result } = await getImageLinkMultiples(bodyMultipleUploads);
        const applyDownload = result.map(async (v: string, i: number) => {
            await axios.get(v, {
                responseType: 'arraybuffer',
            }).then((res) => {
                fileDownload(res.data, `Foto ${i + 1}.png`);
            });
        })
        await Promise.all(applyDownload);
        if (setLoading) {
            setLoading(false);
        }
    }
}

export function MaskPhone(v: string): string {
    let valueInitial = v?.replace(/\D/g, '').replace(/^0/, '') || '';
    if (valueInitial && valueInitial.length > 11) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (valueInitial.length > 7) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (valueInitial.length > 2) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (v?.trim() !== '') {
        valueInitial = valueInitial.replace(/^(\d*)/, '($1');
    }
    return valueInitial;

}
