// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, Circle, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    data-name="Componente 39 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={23}
    {...props}
  >
    <Circle data-name="Elipse 46" cx={11.5} cy={11.5} r={11.5} fill="#fbbe28" />
    <G
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <Path data-name="Linha 41" d="M11 4v13" />
      <Path
        data-name="Caminho 103"
        d="M14.234 7.279H9.887a2.029 2.029 0 1 0 0 4.057h2.9a2.029 2.029 0 1 1 0 4.057H7.858"
      />
    </G>
  </Svg>
)

export default SvgComponent
