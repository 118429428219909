import React from 'react';

import IconEmpty from '../../../../../assets/images/svg/emptyArts';
import * as S from './styles';

export function EmptyComponent() {
    return (
        <S.Container>
            <IconEmpty />
            <S.Description>
                Os templates marcados como favoritos aparecerão aqui
            </S.Description>
        </S.Container>
    );
}
