import styled from 'styled-components/native';
import Modal from 'react-native-modal';
import { ResponsiveProps } from '../../../../../global/types';

// components
import IconCLose from '../../../../../assets/images/svg/close';

export const Container = styled(Modal)``;

export const ModalArea = styled.View<ResponsiveProps>`
    align-self: center;
    background-color: ${(props) => props.theme.colors.white};
    width: calc(100vw - 60px);
    height: calc(100vh - 110px);
    padding: ${({ width }) => width < 400 ? '10px' : ' 53px 48px 0px'};
    position: relative;
`;

export const ButtonClose = styled.TouchableOpacity`
    background-color: ${(props) => props.theme.colors.gray[10]};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
`;

export const ButtonCloseIcon = styled(IconCLose)``;
