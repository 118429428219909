// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={24} {...props}>
        <G
            transform="translate(-4)"
            fill="none"
            stroke="#fbbe28"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}>
            <Circle
                data-name="Elipse 24"
                cx={7}
                cy={7}
                r={7}
                transform="translate(5 1)"
            />
            <Path
                data-name="Caminho 104"
                d="M8.21 13.89 7 23l5-3 5 3-1.21-9.12"
            />
        </G>
    </Svg>
);

export default SvgComponent;
