import styled from 'styled-components/native';
import Theme from '../../global/styles/theme';

interface InputProps {
    marginLeft: number;
    height?: number;
    width: number;
    widthArea?: number;
}

export const InputSearch = styled.View<InputProps>`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    flex-direction: row;
    height: ${({ height }) => height || 50};
    margin-left: ${({ marginLeft, width }) =>
        width > 1100 ? marginLeft : '0px'};
    margin-top: ${({ width }) => (width > 1100 ? '0px' : '8px')};
    width: ${({ widthArea }) => widthArea || '150px'};
`;

export const Input = styled.TextInput.attrs({
    placeholderTextColor: Theme.colors.gray[150],
})`
    color: ${({ theme }) => theme.colors.gray[150]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    height: 50px;
    margin-left: 20px;
    width: 75%;
    outline-width: 0px;
`;
