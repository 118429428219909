import styled from 'styled-components/native';

interface TextDefaultProps {
    fontSize: number,
    marginTop?: number,
    marginBottom?: number,
    textAlign?: string,
    fontFamily?: 'semi' | 'regular',
}

export const SignedChartArea = styled.View``;

export const TextDefault = styled.Text<TextDefaultProps>`
    color: ${({ theme }) => theme.colors.lowBlack};
    font-family: ${({ fontFamily, theme }) => fontFamily === 'regular' ? theme.font.segoeUi.regular : theme.font.segoeUi.semiBold};
    font-size: ${({ fontSize }) => fontSize};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    margin-top: ${({ marginTop }) => marginTop || 0};
    text-align: ${({ textAlign }) => textAlign || 'auto'};
`;

export const ChartArea = styled.View`
    align-self: center;
    height: 220px;
`;

export const TitleArea = styled.View`
    flex-direction: row;
    justify-content: space-between;
`;

export const OptionNameArea = styled.View`
    margin-right: 12px;
`;

export const OptionArea = styled.View`
    align-items: center;
    flex-direction: row;
`;

export const Loading = styled.ActivityIndicator`
    align-self: center;
    height: 274px;
`;

export const VoidTextArea = styled.View`
    align-items: center;
    height: 140px;
    justify-content: center;
    width: 100%;
`;
