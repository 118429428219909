import React from 'react';
import { Linking } from 'react-native';

import * as S from './styles';
import Theme from '../../../../global/styles/theme';
import InfoIcon from '../../../../assets/images/svg/infoIcon';

interface ModalInfoProps {
    userInfo: any,
    description: string,
    setModalVisible: (visible: boolean) => void,
}

export function InfoModal(props: ModalInfoProps) {
    const { userInfo, description, setModalVisible } = props;
    const backgroundInfoColor = Theme.colors.red[600];
    const contactColor = Theme.colors.green[400];

    function closeModal() {
        setModalVisible(false);
    }

    function openUrl() {
        Linking.openURL(`https://wa.me/555491720482?text=Olá, sou da loja ${userInfo.name} e gostaria de mais informações sobre os planos do MídiaCarros.`);
    }

    return (
        <S.ContainerModal>
            <InfoIcon />
            <S.TitleModal>
                Info
            </S.TitleModal>
            <S.DescriptionText>
                {description}
            </S.DescriptionText>
            <S.ButtonArea>
                <S.Button backgroundColor={contactColor} onPress={openUrl}>
                    <S.TextButton>
                        Entrar em contato
                    </S.TextButton>
                </S.Button>
                <S.Button backgroundColor={backgroundInfoColor} onPress={closeModal}>
                    <S.TextButton>
                        Fechar
                    </S.TextButton>
                </S.Button>
            </S.ButtonArea>
        </S.ContainerModal>
    );
}
