import React from 'react';
import { useWindowDimensions } from 'react-native';

import * as S from './styles';
import { headerConfigs } from './utils';

import { TableInfo } from './components/TableInfo';
import { ListTable } from './components/ListTable';

interface ReportTableProps {
    type: 'opened' | 'payment' | 'paymentControl' | 'logsCard',
    tableList: any,
    refreshList: () => void,
}

export function ReportTable(props: ReportTableProps) {
    const { type, tableList, refreshList } = props;
    const width = useWindowDimensions().width;

    function renderHeaderTable() {
        if (type === 'opened') {
            return headerConfigs[type].map((e: any) => (
                <TableInfo
                    key={e.title}
                    title={e.title}
                    width={width > 1100 ? e.width : '20%'}
                    type='header'
                />
            ));
        }

        if (type === 'payment') {
            return headerConfigs[type].map((e: any) => (
                <TableInfo
                    key={e.title}
                    title={e.title}
                    width={width > 1100 ? e.width : '25%'}
                    type='header'
                />
            ));
        }

        if (type === 'logsCard') {
            return headerConfigs[type].map((e: any) => (
                <TableInfo
                    key={e.title}
                    title={e.title}
                    width="25%"
                    type='header'
                />
            ));
        }

        return headerConfigs[type].map((e: any) => (
            <TableInfo
                key={e.title}
                title={e.title}
                width={width > 1100 ? e.width : '33%'}
                type='header'
            />
        ));
    }

    return (
        <>
            {type === 'logsCard' && !tableList?.length ? (
                <S.Error>Não há logs para esta publicação</S.Error>
            ) : (
                <S.ContainerTable>
                    <S.TableHeaderContainerArea type='header' width={window}>
                        {renderHeaderTable()}
                    </S.TableHeaderContainerArea>
                    <ListTable
                        type='table'
                        typeInfo={type}
                        list={tableList?.finance || tableList?.invoices || tableList || []}
                        refreshList={refreshList}
                        totalNumber={tableList?.invoiceTotal}
                    />
                </S.ContainerTable>
            )}
        </>
    );
}
