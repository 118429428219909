export function renderCards(width: number) {
    if (width > 588 && width <= 1024) {
        return 4;
    }
    if (width > 425 && width <= 588) {
        return 3;
    }
    if (width <= 425) {
        return 2;
    }

    return 5;
}
