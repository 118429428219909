import React, { useState } from 'react';
import { Picker } from '@react-native-picker/picker';
import { useController } from 'react-hook-form';

import SecureIcon from '../../assets/images/svg/secureIcon';
import * as S from './styles';

type MaskProps = 'money' | 'cel-phone' | 'zip-code' | 'cpf' | 'cnpj' | 'custom' | 'date';

interface ArrayProps {
    label: string;
    value: string;
}

interface InputInternalProps {
    name: string;
    control?: any;
    errors?: any;
    width?: string;
    label: string;
    placeholder: string;
    mask?: MaskProps;
    list?: Array<ArrayProps>;
    inicialValue?: string | number;
    password?: boolean;
    iconName?: string;
    disabled?: boolean,
    customMask?: { mask: string },
    cpfOrCnpjNumber?: string;
    errorCodition?: boolean,
    multiline?: boolean,
    heightBoxArea?: number,
    height?: number;
    maxLength?: number;
    marginTop?: string;
    marginTopInput?: string;
    backgroundColorInput?: string;
}

export function InputInternal(props: InputInternalProps) {
    const {
        name,
        control,
        label,
        placeholder,
        list,
        width,
        mask,
        errors,
        inicialValue,
        password,
        iconName,
        cpfOrCnpjNumber,
        customMask,
        disabled,
        errorCodition,
        multiline,
        heightBoxArea,
        height,
        maxLength,
        marginTop,
        marginTopInput,
        backgroundColorInput,
    } = props;
    const defaultValue = inicialValue || '';
    const controllers = { control, defaultValue, name };
    const {
        field: { value, onChange },
    } = useController(controllers);
    const errorsMessage = errors[name]?.ref;

    const [isSecure, setIsSecure] = useState(password);

    function handleChangeSecureText() {
        setIsSecure(!isSecure);
    }

    function renderInput() {
        if (!list && !mask) {
            return (
                <S.InputCommon
                    multiline={multiline}
                    heightBoxArea={heightBoxArea}
                    value={value}
                    onChangeText={onChange}
                    placeholder={placeholder}
                    secureTextEntry={isSecure}
                    editable={!disabled}
                    maxLength={maxLength}
                />
            );
        }

        if (mask) {
            if (mask === 'custom') {
                const telephoneMask = { mask: '(99) 9999-9999' }

                return (
                    <S.InputMask
                        type='custom'
                        placeholder={placeholder}
                        value={value}
                        onChangeText={onChange}
                        editable={!disabled}
                        options={telephoneMask}
                    />
                );
            }

            if (mask === 'date') {
                const dateMask = { mask: '99/99/9999' }

                return (
                    <S.InputMask
                        type={'datetime'}
                        options={{
                            format: 'DD/MM/YYYY'
                        }}
                        placeholder={placeholder}
                        value={value}
                        onChangeText={onChange}
                        editable={!disabled}
                    />
                );
            }

            if (mask === 'cpf') {
                const inputMask = {
                    cpf: { mask: '999.999.999-999' },
                    cnpj: { mask: '99.999.999/9999-99' },
                };

                return (
                    <S.InputMask
                        type='custom'
                        options={cpfOrCnpjNumber?.length <= 14 ? inputMask.cpf : inputMask.cnpj}
                        keyboardType='number-pad'
                        placeholder={placeholder}
                        value={value}
                        onChangeText={onChange}
                        editable={!disabled}
                    />
                )
            }

            return (
                <S.InputMask
                    type={mask}
                    placeholder={placeholder}
                    value={value}
                    onChangeText={onChange}
                    editable={!disabled}
                />
            );
        }

        return (
            <S.PickerContainer
                currentvalue={value}
                selectedValue={value}
                placeholder={placeholder}
                dropdownIconColor="red"
                onValueChange={onChange}
                enabled={!disabled}
                backgroundColorInput={backgroundColorInput}
            >
                {renderItem()}
            </S.PickerContainer>
        );
    }

    function renderItem() {
        return (list || []).map((e) => {
            const { value, label } = e;
            return (
                <Picker.Item key={value} label={label} value={value} />
            );
        });
    }

    function renderIconSecure() {
        if (!password) {
            return null;
        }

        return (
            <S.SecureButton onPress={handleChangeSecureText}>
                <SecureIcon />
            </S.SecureButton>
        );
    }

    function renderIconInput() {
        if (!iconName) {
            return null;
        }

        return (
            <S.IconInput name={iconName} />
        );
    }

    return (
        <S.Container height={height} marginTop={marginTop} width={width} multiline={multiline} heightBoxArea={heightBoxArea}>
            <S.Label>{label}</S.Label>
            <S.InputContainer marginTop={marginTopInput} height={height} backgroundColorInput={backgroundColorInput} error={errorCodition || errorsMessage} multiline={multiline} heightBoxArea={heightBoxArea}>
                {renderIconInput()}
                {renderInput()}
                {renderIconSecure()}
            </S.InputContainer>
            {/* <S.Erros>{errorsMessage}</S.Erros> */}
        </S.Container>
    );
}
