// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Rect, Path, Circle } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        data-name="Componente 8 \u2013 1"
        xmlns="http://www.w3.org/2000/svg"
        width={83}
        height={83}
        {...props}>
        <G
            transform="translate(22.409 16.344)"
            fill="none"
            stroke="#202125"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}>
            <Rect
                data-name="Ret\xE2ngulo 166"
                width={38.667}
                height={24.269}
                rx={2}
                transform="translate(0 20.08)"
            />
            <Path
                data-name="Caminho 97"
                d="M8.964 20.169V11.2C8.964 5.017 13.582 0 19.278 0s10.315 5.017 10.315 11.2v8.964"
            />
        </G>
        <G data-name="Elipse 20" fill="none" stroke="#202125" strokeWidth={3}>
            <Circle cx={41.5} cy={41.5} r={41.5} stroke="none" />
            <Circle cx={41.5} cy={41.5} r={40} />
        </G>
    </Svg>
);

export default SvgComponent;
