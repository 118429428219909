import React from 'react';
import toast from 'react-hot-toast';

import { deleteArt } from '../../../../../services/arts';
import AlertIcon from '../../../../../assets/images/svg/alertIcon';
import Theme from '../../../../../global/styles/theme';
import * as S from './styles';

interface DeleteModalProps {
    handleCancelPress: () => void;
    handleConfirmPress: () => void;
}

export function DeleteModal(props: DeleteModalProps) {
    const { handleCancelPress, handleConfirmPress } = props;
    const alertColor = Theme.colors.orange[500];
    const textInfoColor = Theme.colors.orange[500];
    const confirmColor = Theme.colors.white;

    function RenderModalButton(
        type: 'Cancelar' | 'Excluir',
        onPressFunction: () => void,
    ) {
        const buttonTextColor = type === 'Cancelar' ? alertColor : confirmColor;

        return (
            <S.ModalButton type={type} onPress={onPressFunction}>
                <S.TextDefault
                    font="regular"
                    fontSize={12}
                    color={buttonTextColor}>
                    {type}
                </S.TextDefault>
            </S.ModalButton>
        );
    }

    return (
        <S.ModalArea>
            <AlertIcon />
            <S.TextDefault
                font="bold"
                color={alertColor}
                fontSize={16}
                marginTop={6}>
                Atenção
            </S.TextDefault>
            <S.TextDefault
                font="regular"
                color={textInfoColor}
                fontSize={14}
                marginBottom={22}
                marginTop={22}>
                {'Tem certeza que deseja\nexcluir essa arte?'}
            </S.TextDefault>
            <S.ModalButtonArea>
                {RenderModalButton('Cancelar', handleCancelPress)}
                {RenderModalButton('Excluir', handleConfirmPress)}
            </S.ModalButtonArea>
        </S.ModalArea>
    );
}
