import styled from 'styled-components/native';
import Feather from '@expo/vector-icons/Feather';

export const Container = styled.View`
    flex-direction: row;
    align-items: center;
`;

export const ButtonGoBack = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    margin-right: 16px;
`;

export const IconGoback = styled(Feather)`
    color: ${({ theme }) => theme.colors.black};
    font-size: 25px;
`;
