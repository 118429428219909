// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, Circle, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    data-name="Componente 36 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    {...props}
  >
    <Circle data-name="Elipse 42" cx={7.5} cy={7.5} r={7.5} fill="#e34850" />
    <G
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <Path data-name="Linha 50" d="M7.542 4.545v5.78" />
      <Path data-name="Caminho 106" d="m9.924 7.434-2.423 3.022-2.424-3.022" />
    </G>
  </Svg>
)

export default SvgComponent
