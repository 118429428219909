import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as S from './styles';

import { InputInternal } from '../../components/InputInternal';
import { shemaSocialMedia } from '../../global/utils/formsSchems';

interface TypeSocialProps {
    setTypeSocial: (type: string) => void,
    onPressConfirm: () => void,
    typeSocial: string,
}

export function TypeSocialModal(props: TypeSocialProps) {
    const { setTypeSocial, onPressConfirm, typeSocial } = props;
    const resolver = { resolver: yupResolver(shemaSocialMedia), mode: 'onChange'};
    const { control, formState: { errors, isValidating }, getValues } = useForm(resolver);

    const typeList = [
        { label: 'Selecionar', value: '0' },
        { label: 'Facebook Feed', value: '1' },
        { label: 'Instagram Feed', value: '2' },
        { label: 'Facebook Feed e Instagram Feed', value: '3' },
        { label: 'Instagram Story', value: '4' }
    ];

    useEffect(() => {
        const type = getValues('type');
        let typeCondition = 'facebook-feed';
        if (type === '2') {
            typeCondition = 'instagram-feed';
        }
        if (type === '3') {
            typeCondition = 'facebook-feed-instagram-feed';
        }
        if (type === '4') {
            typeCondition = 'instagram-story';
        }
        setTypeSocial(typeCondition);
    }, [isValidating]);

    return (
        <S.ModalArea>
            <S.Text>
                Tipo de postagem:
            </S.Text>
            <InputInternal
                inicialValue='0'
                width='100%'
                label="Tipo*"
                placeholder="Selecione"
                list={typeList}
                control={control}
                name="type"
                errors={errors}
            />
            <S.ButtonType buttonCodition={typeSocial} onPress={onPressConfirm} disabled={!typeSocial}>
                <S.Text>Confirmar e publicar</S.Text>
            </S.ButtonType>
        </S.ModalArea>
    );
}
