import React, { useState } from 'react';
import { lightFormat } from 'date-fns';
import { useWindowDimensions } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';

import * as S from './styles';
import theme from '../../../../global/styles/theme';

import { TableInfo } from '../TableInfo';
import { converterCurrency } from '../../../../global/utils/functions';
import { changedTransferStatus } from '../../../../services/transfers';
import { ModalInfoProps } from '../../../../global/types';

import { LoadingModal } from '../../../../components/LoadingModal';
import { CoditionModal } from '../../../../components/CoditionModal';
import { ConfirmPaymentModal } from '../ConfirmPaymentModal';
import { Modal } from '../../../../components/Modal';

interface TableInfoProps {
    list: Array<any>,
    type: 'header' | 'table',
    typeInfo: 'opened' | 'payment' | 'paymentControl' | 'logsCard',
    refreshList: () => void,
    totalNumber?: number,
}

export function ListTable(props: TableInfoProps) {
    const { type, list, typeInfo, refreshList, totalNumber } = props;
    const fontTableFamily = theme.font.segoeUi.regular;
    const width = useWindowDimensions().width;
    const fontDropdownSize = width > 1100 ? 14 : 11;
    const fontTableSize = width > 1100 ? 16 : 12;

    const [dropdownVisible, setDropdownVisible] = useState<any[]>([]);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);
    const [modalInfoPayment, setModalInfoPayment] = useState(false);
    const [idPayment, setIdPayment] = useState<number>(0);

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    function RenderArrow(coditionArrow: number, handleDropdownPress: () => void, coditionDropdown: boolean) {
        if (!coditionArrow) {
            return null;
        }

        return (
            <S.ArrowPress onPress={handleDropdownPress}>
                {RenderIcon(coditionDropdown)}
            </S.ArrowPress>
        );
    }

    async function confirmPayment() {
        setModalInfoPayment(false);
        setLoadingVisible(true);
        const { status, message } = await changedTransferStatus(idPayment, 'done');

        if (status === 200 || status === 201) {
            setLoadingVisible(false);
            setIdPayment(0);
            refreshList();
        } else {
            setLoadingVisible(false);
            const modalProps: ModalInfoProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        }
    }

    function RenderItem({ item }) {
        const coditionDropdown = dropdownVisible.includes(item.id);
        const widthOptions = width > 1100 ? '10%' : (typeInfo === 'opened' ? '20%' : '33%');
        const coditionRenderArrow = item?.reports?.length;

        const handleDropdownPress = () => {
            const newArrayDropdown = !coditionDropdown ? [...dropdownVisible, item.id] : dropdownVisible.filter((el) => el !== item.id);
            setDropdownVisible(newArrayDropdown);
        }

        function changeModalInfoPayment() {
            setIdPayment(item.id);
            setModalInfoPayment(true);
        }

        if (typeInfo === 'payment') {
            return (
                <>
                    <S.TableArea>
                        <TableInfo
                            width={width > 1100 ? '40%' : '25%'}
                            title={item.nameUser}
                            type={type}
                            key={item.id}
                            height={50}
                            fontFamily={fontTableFamily}
                            fontSize={fontTableSize}
                        />
                        <TableInfo
                            width={width > 1100 ? '40%' : '25%'}
                            title={item.updatedAt}
                            type={type}
                            key={item.id}
                            height={50}
                            fontFamily={fontTableFamily}
                            fontSize={fontTableSize}
                        />
                        <TableInfo
                            width={width > 1100 ? '10%' : '25%'}
                            title={converterCurrency(item.totalPrice)}
                            type={type}
                            key={item.id}
                            height={50}
                            fontFamily={fontTableFamily}
                            fontSize={fontTableSize}
                        />
                        <S.ActionsArea width={widthOptions}>
                            {/* <S.OptionArea width={width}>
                                <Ionicons name="document-outline" size={16} color="gray" />
                            </S.OptionArea> */}
                            {/* <S.OptionArea width={width}>
                                <MaterialIcons name="refresh" size={16} color="gray" />
                            </S.OptionArea> */}
                            {RenderArrow(coditionRenderArrow, handleDropdownPress, coditionDropdown)}
                        </S.ActionsArea>
                    </S.TableArea>
                    {RenderDropdown(item.reports, coditionDropdown)}
                </>
            );
        }

        if (typeInfo === 'paymentControl') {
            return (
                <>
                    <S.TableArea>
                        <TableInfo
                            width={width > 1100 ? '80%' : '33%'}
                            title={item.nameUser || item.title}
                            type={type}
                            key={item.id}
                            fontFamily={fontTableFamily}
                            height={50}
                            fontSize={fontTableSize}
                        />
                        <TableInfo
                            width={width > 1100 ? '10%' : '33%'}
                            title={converterCurrency(item.pricePlan || item.total)}
                            type={type}
                            key={item.id}
                            fontFamily={fontTableFamily}
                            height={50}
                            fontSize={fontTableSize}
                        />
                        <S.ActionsArea width={widthOptions}>
                            {/* <S.OptionArea width={width}>
                                <Ionicons name="document-outline" size={16} color="gray" />
                            </S.OptionArea> */}
                            {RenderArrow(coditionRenderArrow, handleDropdownPress, coditionDropdown)}
                        </S.ActionsArea>
                    </S.TableArea>
                    {RenderDropdown(item.reports, coditionDropdown)}
                </>
            );
        }

        if (typeInfo === 'logsCard') {
            return (
                <S.TableArea>
                    <TableInfo
                        width="25%"
                        title={item.created_at ? lightFormat(new Date(item.created_at), 'dd/MM/yyyy') : ''}
                        type={type}
                        key={item.id}
                        fontFamily={fontTableFamily}
                        height={50}
                        fontSize={fontTableSize}
                    />
                    <TableInfo
                        width="25%"
                        title={item.created_at ? lightFormat(new Date(item.created_at), 'HH:mm') : ''}
                        type={type}
                        key={item.id}
                        fontFamily={fontTableFamily}
                        height={50}
                        fontSize={fontTableSize}
                    />
                    <TableInfo
                        width="25%"
                        title={item.type === 'facebook-feed' ? 'Facebook' : 'Instagram'}
                        type={type}
                        key={item.id}
                        fontFamily={fontTableFamily}
                        height={50}
                        fontSize={fontTableSize}
                    />
                    <TableInfo
                        width="25%"
                        title={item.status === 'published' ? 'Publicado' : 'Erro'}
                        type={type}
                        key={item.id}
                        fontFamily={fontTableFamily}
                        height={50}
                        fontSize={fontTableSize}
                        color={item.status === 'error' ? theme.colors.red[300] : theme.colors.green[470]}
                    />
                </S.TableArea>
            );
        }

        return (
            <>
                <S.TableArea>
                    <TableInfo
                        width={width > 1100 ? '30%' : '20%'}
                        title={item.nameUser}
                        type={type}
                        key={item.id}
                        fontFamily={fontTableFamily}
                        height={50}
                        fontSize={fontTableSize}
                    />
                    <TableInfo
                        width={width > 1100 ? '25%' : '20%'}
                        title={item.namePlan}
                        type={type}
                        key={item.id}
                        fontFamily={fontTableFamily}
                        height={50}
                        fontSize={fontTableSize}
                    />
                    <TableInfo
                        width={width > 1100 ? '25%' : '20%'}
                        title={converterCurrency(item.pricePlan)}
                        type={type}
                        key={item.id}
                        fontFamily={fontTableFamily}
                        height={50}
                        fontSize={fontTableSize}
                    />
                    <TableInfo
                        width={width > 1100 ? '10%' : '20%'}
                        title={converterCurrency(item.totalPrice)}
                        type={type}
                        key={item.id}
                        fontFamily={fontTableFamily}
                        height={50}
                        fontSize={fontTableSize}
                    />
                    <S.ActionsArea width={widthOptions}>
                        {/* <S.OptionArea>
                            <Ionicons name="document-outline" size={16} color="gray" />
                        </S.OptionArea> */}
                        <S.OptionArea width={width} onPress={changeModalInfoPayment}>
                            <Ionicons name="md-checkmark-done" size={16} color="gray" />
                        </S.OptionArea>
                        {RenderArrow(coditionRenderArrow, handleDropdownPress, coditionDropdown)}
                    </S.ActionsArea>
                </S.TableArea>
                {RenderDropdown(item.reports, coditionDropdown)}
            </>
        );
    }

    function RenderIcon(coditionDropdown: boolean) {
        if (!coditionDropdown) {
            return (
                <MaterialIcons name="arrow-back-ios" size={18} color="black" />
            );
        }

        return (
            <MaterialIcons name="keyboard-arrow-down" size={28} color="black" />
        )
    }

    function keyExtractor(item: any) {
        return String(item.id);
    }

    function RenderFooterText() {
        if (typeInfo === 'opened') {
            return `Total em aberto do período ${converterCurrency(totalNumber || 0)}`;
        }
        if (typeInfo === 'payment') {
            return `Total faturado no período ${converterCurrency(totalNumber || 0)}`;
        }
        return '';
    }

    function ListFooterComponent() {
        if (typeInfo === 'paymentControl') {
            return null;
        }

        return (
            <S.FooterArea>
                <S.FooterText>{RenderFooterText()}</S.FooterText>
            </S.FooterArea>
        );
    }

    function RenderDropdown(paymentInfo: Array<any>, coditionDropdown: boolean) {
        if (!coditionDropdown) {
            return null;
        }

        return (
            <S.DropdownArea>
                <S.TableHeaderContainerArea>
                    <TableInfo
                        width={width > 1100 ? '10%' : '20%'}
                        title='Item'
                        type='header'
                        key={1}
                        fontFamily={fontTableFamily}
                    />
                    <TableInfo
                        width={width > 1100 ? '10%' : '20%'}
                        title='Data'
                        type='header'
                        key={1}
                        fontFamily={fontTableFamily}
                    />
                    <TableInfo
                        width={width > 1100 ? '60%' : '20%'}
                        title='Descrição'
                        type='header'
                        key={1}
                        fontFamily={fontTableFamily}
                    />
                    <TableInfo
                        width={width > 1100 ? '10%' : '20%'}
                        title='Status'
                        type='header'
                        key={1}
                        fontFamily={fontTableFamily}
                    />
                    <TableInfo
                        width={width > 1100 ? '10%' : '20%'}
                        title='Valor'
                        type='header'
                        key={1}
                        fontFamily={fontTableFamily}
                    />
                </S.TableHeaderContainerArea>
                {RenderDropdownList(paymentInfo)}
            </S.DropdownArea>
        );
    }

    function RenderDropdownList(paymentInfo: Array<any>) {
        return (paymentInfo || []).map((el) => {
            const statusCodition = el.status === 'done';
            return (
                <S.TableArea key={el.id_item}>
                    <TableInfo
                        width={width > 1100 ? '10%' : '20%'}
                        title={el.id_item}
                        type={type}
                        key={el.id}
                        fontFamily={fontTableFamily}
                        fontSize={fontDropdownSize}
                    />
                    <TableInfo
                        width={width > 1100 ? '10%' : '20%'}
                        title={el.date}
                        type={type}
                        key={el.id}
                        fontFamily={fontTableFamily}
                        fontSize={fontDropdownSize}
                    />
                    <TableInfo
                        width={width > 1100 ? '60%' : '20%'}
                        title={el.name}
                        type={type}
                        fontFamily={fontTableFamily}
                        fontSize={fontDropdownSize}
                        key={el.id}
                    />
                    <S.StatusArea width={width}>
                        <S.StatusCoditionArea statusCodition={statusCodition}>
                            <S.StatusText>
                                {statusCodition ? 'Pago' : 'Não pago'}
                            </S.StatusText>
                        </S.StatusCoditionArea>
                    </S.StatusArea>
                    <TableInfo
                        width={width > 1100 ? '10%' : '20%'}
                        title={converterCurrency(el.price)}
                        type={type}
                        key={el.id}
                        fontFamily={fontTableFamily}
                        fontSize={fontDropdownSize}
                    />
                </S.TableArea>
            )
        });
    }

    return (
        <>
            <S.Flatlist
                data={list}
                keyExtractor={keyExtractor}
                renderItem={RenderItem}
                ListFooterComponent={() => {
                    if (typeInfo === 'logsCard') {
                        return null;
                    }
                    return ListFooterComponent();
                }}
            />
            <LoadingModal
                visible={loadingVisible}
                titleLoading='Aguarde...'
            />
            <CoditionModal
                titleModal={modalInfo.titleModal}
                descriptionModal={modalInfo.descriptionModal}
                typeModal={modalInfo.typeModal}
                buttonText={modalInfo.buttonText}
                modalVisible={modalInfo.modalVisible}
                setModalVisible={modalInfo.setModalVisible}
                buttonFunction={modalInfo.buttonFunction}
            />
            <Modal isVisible={modalInfoPayment} closeModal={setModalInfoPayment}>
                <ConfirmPaymentModal
                    setModalVisible={setModalInfoPayment}
                    confirmPayment={confirmPayment}
                />
            </Modal>
        </>
    );
}
