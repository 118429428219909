import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as S from './styles';
import theme from '../../../global/styles/theme';
import { getPlansDetails } from '../../../services/plans';
import { converterCurrency } from '../../../global/utils/functions';

import { Loading } from '../../../components/Loading';

interface PlanDetailsProps {
    countPublished: string,
    countUsed: string,
    plan: any,
}

export function PlanScreen() {
    const { user } = useSelector((state: any) => state.user.user);
    const titleColor = theme.colors.lowBlack;
    const regularFont = theme.font.segoeUi.regular;
    const boldFont = theme.font.segoeUi.bold;
    const statusPlanColor = theme.colors.white;

    const [planDetails, setPlanDetails] = useState<PlanDetailsProps>({} as PlanDetailsProps);
    const [loading, setLoading] = useState(false);

    const planText = planDetails?.plan?.status === 'active' ? 'Ativo' : 'Inativo';

    useEffect(() => {
        getUserPlansDetails();
    }, []);

    async function getUserPlansDetails() {
        const { result } = await getPlansDetails(user.id);
        setPlanDetails(result);
        setLoading(true);
    }

    function RenderContentInfo() {
        if (!loading) {
            return (
                <Loading />
            );
        }

        return (
            <>
                <S.TextDefault
                    color={titleColor}
                    fontSize={25}
                    fontFamily={boldFont}
                >
                    {`Plano ${planDetails?.plan?.name}`}
                </S.TextDefault>
                <S.ContainerPlan>
                    <S.PlanInfoArea>
                        <S.TextDefault
                            color={titleColor}
                            fontSize={20}
                            fontFamily={boldFont}
                        >
                            Dados do plano
                        </S.TextDefault>
                        <S.TypePlanArea>
                            <S.TextDefault
                                color={titleColor}
                                fontSize={20}
                                fontFamily={boldFont}
                            >
                                {`Plano ${planDetails?.plan?.name}`}
                            </S.TextDefault>
                            <S.PlanStatus backgroundCodition={planText}>
                                <S.TextDefault
                                    color={statusPlanColor}
                                    fontSize={14}
                                    fontFamily={boldFont}
                                >
                                    {planText}
                                </S.TextDefault>
                            </S.PlanStatus>
                        </S.TypePlanArea>
                    </S.PlanInfoArea>
                    <S.TextDefault
                        color={titleColor}
                        fontSize={18}
                        fontFamily={boldFont}
                        marginTop={12}
                    >
                        {'Valor do plano: '}
                        <S.TextDefault
                            color={titleColor}
                            fontSize={18}
                            fontFamily={regularFont}
                        >
                            {converterCurrency(planDetails?.plan?.price)}
                        </S.TextDefault>
                    </S.TextDefault>
                    <S.TextDefault
                        color={titleColor}
                        fontSize={18}
                        fontFamily={boldFont}
                        marginTop={12}
                    >
                        {'Limite de uso do plano: '}
                        <S.TextDefault
                            color={titleColor}
                            fontSize={18}
                            fontFamily={regularFont}
                        >
                            {`${planDetails?.countUsed} de ${planDetails?.plan?.amount_arts} usados`}
                        </S.TextDefault>
                    </S.TextDefault>
                    <S.TextDefault
                        color={titleColor}
                        fontSize={18}
                        fontFamily={boldFont}
                        marginTop={12}
                    >
                        {'Limite de publicação do plano: '}
                        <S.TextDefault
                            color={titleColor}
                            fontSize={18}
                            fontFamily={regularFont}
                        >
                            {`${planDetails?.countPublished} de ${planDetails?.plan?.amount_posts} publicados`}
                        </S.TextDefault>
                    </S.TextDefault>
                </S.ContainerPlan>
            </>
        );
    }

    return (
        <S.Container>
            {RenderContentInfo()}
        </S.Container>
    );
}
