import styled from 'styled-components/native';
import { ResponsiveProps } from '../../../../global/types/index';

interface MoreOptionButtonProps {
    type: 'clients' | 'plans';
}

interface StatusIndicatorTextProps {
    status: 'active' | 'inactive';
}

interface StatusIndicatorTextConnectionProps {
    status: 'no_expiration' | 'expiring_soon' | 'expired'
}

interface StatusIndicatorTextClientProps {
    is_pending: boolean;
}


export const ListTable = styled.FlatList.attrs({
    contentContainerStyle: { flex: 1 },
})``;

export const TableArea = styled.View<ResponsiveProps>`
    align-items: center;
    flex-direction: row;
    border: 1px solid #e9e9ec;
    height: 75px;
    justify-content: space-between;
    padding-left: 8px;
    width: 100%;
`;

export const MoreOptionButton = styled.TouchableOpacity<MoreOptionButtonProps>`
    width: ${({ type }) => (type === 'plans' ? '9%' : '16%')};
    align-items: center;
    margin-left:-16px;
`;

export const OptionArea = styled.View`
    align-items: center;
    flex-direction: row;
    margin-bottom: 8px;
    padding-left: 16px;
`;

export const OptionText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
    margin-left: 16px;
`;

export const StatusIndicator = styled.View`
    width: 16%;
    margin-left: 16px;
`;

export const StatusIndicatorTextConnection = styled.Text<StatusIndicatorTextConnectionProps>`
    /* color: ${({ status, theme }) =>
        status === 'no_expiration' ? theme.colors.green[500] : theme.colors.red[400]};
    background-color: ${({ status, theme }) =>
        status === 'no_expiration' ? theme.colors.green[300] : theme.colors.red[200]}; */
    color: ${({ status, theme }) => theme.colors.red[400]};
    background-color: ${({ status, theme }) => theme.colors.red[200]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
    padding: 6px 12px;
    border-radius: 17px;
    text-align: center;
    width: ${({ status, theme }) =>  '200px'};
`;

export const StatusIndicatorText = styled.Text<StatusIndicatorTextProps>`
    color: ${({ status, theme }) =>
        status === 'active' ? theme.colors.green[500] : theme.colors.red[400]};
    background-color: ${({ status, theme }) =>
        status === 'active' ? theme.colors.green[300] : theme.colors.red[200]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
    padding: 6px 12px;
    border-radius: 17px;
    text-align: center;
    width: ${({ status, theme }) => (status === 'active' ? '52px' : '60px')};
`;

export const StatusIndicatorTextClient = styled.Text<StatusIndicatorTextClientProps>`
    color: ${({ is_pending, theme }) =>
        is_pending ? theme.colors.orange[500] : theme.colors.gray[400]};
    background-color: ${({ is_pending, theme }) =>
        is_pending ? theme.colors.orange[250] : theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 12px;
    text-align: center;
    width: ${({ is_pending, theme }) => (is_pending ? '80px' : '60px')};
`;

export const optionsContainerStyle = {
    paddingBottom: 8,
    paddingTop: 16,
    width: 180,
};

export const buttonStyle = {
    alignItems: 'flex-end',
    marginRight: 12,
    width: 40,
};
