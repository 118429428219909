import styled from 'styled-components/native';

interface TextDefaultProps {
    color: string,
    fontSize: number,
    fontFamily: string,
    marginBottom: number,
    marginTop: number,
}

interface PlanStatusProps {
    backgroundCodition: string,
}

export const Container = styled.ScrollView`
    flex: 1;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 40px 0px 46px 30px;
`;

export const TextDefault = styled.Text<TextDefaultProps>`
    color: ${({ color }) => color};
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: ${({ fontSize }) => fontSize};
    margin-bottom: ${({ marginBottom }) => marginBottom || '0px'};
    margin-top: ${({ marginTop }) => marginTop || '0px'};
`;

export const ContainerPlan = styled.View`
    align-self: center;
    border: 1px solid ${({ theme }) => theme.colors.purple[500]};
    border-radius: 16px;
    margin-top: 60px;
    padding: 30px;
    width: 85%;
`;

export const PlanInfoArea = styled.View`
    flex-direction: row;
    justify-content: space-between;
`;

export const TypePlanArea = styled.View`
    align-items: flex-end;
`;

export const PlanStatus = styled.View<PlanStatusProps>`
    align-items: center;
    background-color: ${({ backgroundCodition, theme }) => backgroundCodition === 'Ativo'
        ? theme.colors.green[400]
        : theme.colors.red[500]
    };
    border-radius: 6px;
    height: 25px;
    justify-content: center;
    width: 57px;
`;
