import styled from 'styled-components/native';

export const OptionArea = styled.View`
    align-items: center;
    flex-direction: row;
    margin-bottom: 8px;
    padding-left: 4px;
`;

export const OptionText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
    margin-left: 16px;
`;

export const optionsContainerStyle = {
    paddingBottom: 8,
    paddingTop: 16,
    width: 135,
};

export const buttonStyle = {
    alignItems: 'flex-end',
    marginRight: 12,
    width: 40,
};
