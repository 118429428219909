import styled from 'styled-components/native';
import { ResponsiveProps } from '../../../global/types';

export const Container = styled.ScrollView`
    background-color: ${({ theme }) => theme.colors.gray[300]};
    flex: 1;
`;

export const TextDefault = styled.Text`
    margin-bottom: 10px;
`;

export const InputSearchArea = styled.View<ResponsiveProps>`
    align-items: flex-start;
    align-self: center;
    flex-direction: ${({ width }) => (width < 600 ? 'column' : 'row')};
    margin-top: 40px;
    width: 95%;
`;

export const ContainerAlign = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 20px;
    margin-top: 40px;
    width: 95%;
    max-width: 95%;
    overflow-x: scroll;
`;

export const RequestFilterButton = styled.TouchableOpacity`

`;

export const DropdownArea = styled.View<ResponsiveProps>`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    height: 120px;
    margin-left: ${({ width }) => width > 1100 ? 16 : '0px'};
    padding: 16px 0px 16px 20px;
    width: 250px;
`;

export const DropdownOption = styled.TouchableOpacity`
    padding-bottom: 16px;
`;

export const DropdownOptionText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[650]};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
`;
