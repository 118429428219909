import styled, { css } from 'styled-components/native';
import Modal from 'react-native-modal';
import Feather from '@expo/vector-icons/Feather';
import { Picker } from '@react-native-picker/picker';

import Theme from '../../global/styles/theme';
import { ResponsiveProps } from '../../global/types';
import WhatsappIcon from '../../assets/images/svg/whatsappIcon';
import ImageModal from 'react-native-image-modal';
import { isMobile } from 'react-device-detect';

interface PickerProps {
    currentvalue: number | string;
}

interface ButtonModalProps {
    background: string,
}

interface ButtonProps {
    buttonColor?: string,
    borderColor: string,
    marginRight?: number,
    width: number,
    fillWidth?: number,
}

interface InputPickerContainerProps {
    isCheckbox?: boolean,
}

interface TextProps {
    fontSize: number;
    color: string;
    fontFamily: 'bold' | 'regular';
    marginTop: number;
    margin: number;
}

interface ArrowButtonProps {
    type: 'left' | 'right';
    width: number,
}

interface CarAreProps {
    isSelected: boolean,
}

interface CheckboxProps {
    isMarked: boolean,
    marginLeft?: boolean,
}

interface ButtonFramesContainerProps {
    renderFrame: number;
}

export const ContainerModalError = styled.View`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    height: 100px;
    justify-content: center;
    padding: 10px;
    width: 300px;
`;

export const ContainerModal = styled(Modal)`
    margin: 0px;
    flex: 1;
    padding-bottom: ${isMobile ? '40px' : '0px'};
`;

export const ModalArea = styled.View<ResponsiveProps>`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    width: 95%;
    margin: 30px 0px;
    ${({ width }) => width <= 900
        ? css`padding: 10px 10px 10px 10px`
        : css`padding: 36px 20px 30px 36px`
    };
`;

export const ButtonType = styled.TouchableOpacity`
    align-items: center;
    align-self: center;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 6px;
    height: 40px;
    justify-content: center;
    width: 180px;
`;

export const ModalAreaScrollView = styled.ScrollView`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    width: 95%;
    height: 95%;
    margin: 30px 0px;
    padding-bottom: 30px;
`;

export const GoBackButton = styled.TouchableOpacity`
    margin-right: 20px;
`;

export const TextDefault = styled.Text<TextProps>`
    color: ${({ color }) => color};
    font-family: ${({ fontFamily, theme }) =>
        fontFamily === 'bold'
            ? theme.font.segoeUi.bold
            : theme.font.segoeUi.regular};
    font-size: ${({ fontSize }) => fontSize};
    ${({ marginTop }) => `margin-top: ${marginTop || 0}px`}
    ${({ margin }) => `margin: ${margin || 0}px`}
`;

export const HeaderArea = styled.View<ResponsiveProps>`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${({ width }) => width < 600 ? '10px' : '38px'};
`;

export const GoBackArea = styled.View`
    align-items: center;
    flex-direction: row;
`;

export const CloseButton = styled.TouchableOpacity`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray[10]};
    border-radius: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

export const QuitIcon = styled.Image`
    height: 16px;
    width: 16px;
`;

export const Column = styled.View`
    flex-direction: column;
    display: flex;
`;

export const ColumnTitle = styled.View<ResponsiveProps>`
    flex-direction: column;
    min-width: ${({ width }) => width <= 622 ? '100%' : '65%'};
    max-width: ${({ width }) => width <= 622 ? '100%' : '65%'};
`;

export const RowTitle = styled.View`
    flex-direction: row;
    justify-content: space-between;
`;

export const Divider = styled.View`
    height: 2px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: ${({ theme }) => theme.colors.gray[500]};
`;

export const Title = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 18px;
    margin-bottom: 10px;
`;

export const ViewTexts = styled.View`
    flex-direction: column;
    /* justify-content: space-between; */
    /* background-color: orange; */
`;

export const Description = styled.Text`
    color: ${({ theme }) => theme.colors.gray[150]};
    font-size: 12px;
    margin-bottom: 20px;
`;

export const InfoArea = styled.View<ResponsiveProps>`
    flex-direction: ${({ width }) => width <= 1071 ? 'column' : 'row'};
    margin-top: 10px;
`;

export const ImageGrid = styled.FlatList`
    height: 500px;
`;

export const ImageContainer = styled.TouchableOpacity<ResponsiveProps>`
    background-color: aliceblue;
    flex-direction: ${({ width }) => (width < 385 ? 'column' : 'row')};
    flex: 1;
    justify-content: space-between;
`;

export const ModalContent = styled(ImageModal)``;

export const TemplateImage = styled.Image<ResponsiveProps>`
    border-radius: 6px;
    height: auto;
    width: auto;
    flex: 1;
    ${({ width, grid }) =>
        width <= 800 && !grid &&
        css`
            height: 240px;
            width: 240px;
        `};
    ${({ width, grid }) =>
        width <= 1387 && !grid &&
        css`
            height: 450px;
            width: 450px;
        `};
    ${({ width, grid }) =>
        width <= 1277 && !grid &&
        css`
            height: 400px;
            width: 400px;
        `};
    ${({ width, grid }) =>
        width <= 1167 && !grid &&
        css`
            height: 350px;
            width: 350px;
        `};
    ${({ width, grid }) =>
        width <= 1071 && !grid &&
        css`
            height: 250px;
            width: 250px;
        `};
    ${({ grid }) =>
        grid &&
        css`
            height: 290px;
            width: 290px;
            margin-top: 20px;
        `}
    margin: 10px;
`;

export const AdjustEmojis = styled.View`
    flex-direction: row;
    margin: 0px 0px 20px 0px;
`;

export const TouchableOpacityEmpty = styled.TouchableOpacity`
    margin: 3px;
    border-width: ${({ focus }: any) => focus ? 1 : 0};
    border-radius: 10;
`;

export const TemplateImageArea = styled.View<ResponsiveProps>`
    /* align-items: center; */
    background-color: aliceblue;
    border: 2px solid ${({theme}) => theme.colors.black};
    /* flex-direction: row; */
    flex-direction: ${({ width }) => (width < 600 ? 'column-reverse' : 'row')};
    width: ${({ width }) => (width < 1071 ? '100%' : '50%')};
    min-height: ${({ width }) => (width < 600 ? '400px' : '550px')};
    height: ${({ width }) => (width < 600 ? '400px' : '550px')};
    margin-bottom: 12px;
    justify-content: space-between;
`;

export const TemplateImageAreaPublishStock = styled.View<ResponsiveProps>`
    /* align-items: center; */
    background-color: aliceblue;
    border: 2px solid ${({theme}) => theme.colors.black};
    flex-direction: ${({ width }) => (width < 385 ? 'column' : 'row')};
    width: 100%;
    /* height: 500px; */
    margin-bottom: 12px;
    justify-content: space-between;
`;

export const ContainerLogosScroll = styled.ScrollView<ResponsiveProps>`
    width: 100%;
    max-width: 100%;
    max-height: 110px;
`;

export const ContainerLogosScrollVertical = styled.ScrollView<ResponsiveProps>`
    /* width: 100%;
    max-width: 100%;
    max-height: 120px;
    min-height: 120px; */
`;

export const ContainerLogos = styled.View<ResponsiveProps>`
    /* display: grid; */
    width: 40%;
    max-width: 40%;
    flex-direction: row;
    flex-wrap: wrap;
    /* ${({ width }) => width >= 2000 &&
        css`grid-template-columns: repeat(4, 1fr)`
    };
    ${({ width }) => width < 2000 && width >= 1850 &&
        css`grid-template-columns: repeat(3, 1fr)`
    };
    ${({ width }) => width < 1850 && width >= 1630 &&
        css`grid-template-columns: repeat(2, 1fr)`
    };
    ${({ width }) => width < 1630 && width >= 1500 &&
        css`grid-template-columns: 1fr`
    };
    ${({ width }) => width < 1500 && width >= 1070 &&
        css`grid-template-columns: 1fr`
    };
    ${({ width }) => width < 1070 && width >= 960 &&
        css`grid-template-columns: repeat(3, 1fr)`
    };
    ${({ width }) => width < 960 && width >= 800 &&
        css`grid-template-columns: repeat(2, 1fr)`
    };
    ${({ width }) => width < 800 && width >= 725 &&
        css`grid-template-columns: repeat(4, 1fr)`
    };
    ${({ width }) => width < 725 && width >= 600 &&
        css`grid-template-columns: repeat(3, 1fr)`
    };
    ${({ width }) => width < 600 && width >= 500 &&
        css`grid-template-columns: repeat(2, 1fr)`
    };
    ${({ width }) => width < 385 &&
        css`grid-template-columns: repeat(2, 1fr)`
    };
    align-content: start;
    gap: 2px; */
`;

export const AdjustFilters = styled.View<ResponsiveProps>`
    align-items: center;
    /* flex: 1; */
    /* height: 100%; */
    /* padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px; */
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const ScrollFilters = styled.ScrollView`
    align-self: center;
    width: 90%;
`;

export const ContainerModalGallery = styled.View`
    height: 85%;
    border-radius: 5px;
    background-color: white;
    width: 85%;
`;

export const ContainerModalConfirm = styled.View<ResponsiveProps>`
    height: 200px;
    border-radius: 5px;
    background-color: white;
    width: ${({ width }) => width < 400 ? (width < 350 ? (width < 300 ? 200 : 250) : 300) : 350 };
    justify-content: center;
    align-items: center;
`;

export const BoxArea = styled.TextInput.attrs({
    placeholderTextColor: Theme.colors.gray[600],
})`
    border-width: 1;
    border-color: gray;
    height: ${({ height }: any) => height || 150};
    margin-top: 10px;
    background-color: white;
    border-radius: 15;
    padding-left: 10;
    padding-right: 10;
    padding-top: 10;
    padding-bottom: 110;
    width: 100%;
`;

export const ResizeBoxArea = styled.TouchableOpacity`
    background-color: ${({ theme }) => theme.colors.purple[500]};
    padding: 10px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin-top: 10px;
    width: 40px;
`;

export const ButtonLogo = styled.TouchableOpacity<CarAreProps>`
    border-width: 2;
    border-color: ${({ isSelected, theme }) => isSelected ? theme.colors.purple[500] : theme.colors.white};
    margin-right: 5px;
    width: 104px;
    height: 104px;
`;

export const Logo = styled.Image`
    height: 100px;
    width: 100px;
`;

export const TemplateInfoArea = styled.View<ResponsiveProps>`
    padding-left: ${({ width }) => width < 800 ? 0 : 36};
    margin-bottom: 20px;
    width: ${({ width }) => width < 1071 ? '100%' : '50%'};
    height: auto;
`;

export const SearchInputArea = styled.View<ResponsiveProps>`
    align-items: center;
    border: 1px solid #A5A5A5;
    border-radius: 6px;
    flex-direction: row;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 20px;
    width: 95%;
`;

export const ContentEdit = styled.View<ResponsiveProps>`
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(2, 1fr);
    ${({ width }) =>
        width <= 900 &&
        css`
            grid-template-columns: 1fr;
        `}
`;

export const SearchInputAreaPublishStock = styled.View<ResponsiveProps>`
    align-items: center;
    border: 1px solid #A5A5A5;
    border-radius: 6px;
    flex-direction: row;
    height: 40px;
    padding-left: 20px;
    width: 40%;
`;

export const SearchInput = styled.TextInput.attrs({
    placeholderTextColor: Theme.colors.gray[600],
})`
    /* color: ${({ theme }) => theme.colors.gray[600]}; */
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 16px;
    outline-width: 0px;
    margin-left: 20px;
    width: 100%;
`;

export const SearchInputTexts = styled.TextInput.attrs({
    placeholderTextColor: Theme.colors.gray[500],
})`
    /* color: ${({ theme }) => theme.colors.gray[600]}; */
    border: ${({ theme }) => `1px solid ${theme.colors.gray[600]}`};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    height: 40px;
    outline-width: 0px;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
    width: 80%;
`;

export const SearchInputPublishStock = styled.TextInput.attrs({
    placeholderTextColor: Theme.colors.gray[600],
})`
    color: ${({ theme }) => theme.colors.gray[600]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 16px;
    outline-width: 0px;
    margin-left: 20px;
    width: 80%;
`;

export const ButtonArea = styled.View<ResponsiveProps>`
    align-items: center;
    flex-direction: ${({ width }) => width <= 900 ? 'column' : 'row'};
    justify-content: flex-end;
    margin-left: ${({ width }) => width <= 900 ? 0 : 30};
    margin-top: 18px;
`;

export const PublicationButtonArea = styled.View<ResponsiveProps>`
    align-items: center;
    flex-direction: ${({ width }) => width <= 900 ? 'column' : 'row'};
    width: ${({ width }) => width <= 900 ? '100%' : 'auto'};
`;

export const Button = styled.TouchableOpacity<ButtonProps>`
    align-items: center;
    background-color: ${({ buttonColor, theme }) => buttonColor || theme.colors.white};
    border-color: ${({ borderColor, theme }) => borderColor || theme.colors.purple[500]};
    border-radius: 6px;
    border-width: 1px;
    height: 40px;
    justify-content: center;
    margin-bottom: ${({ width }) => width <= 900 ? 8 : 0};
    margin-right: ${({ marginRight }) => marginRight || 0};
    width: ${({ width, fillWidth }) => width <= 900 ? '100%' : (fillWidth || 198)};
`;

export const CarListArea = styled.View`
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
`;


export const ButtonFramesContainer = styled.View<ButtonFramesContainerProps>`
    margin-bottom: 30px;
    flex-direction: row;
    /* flex-wrap: wrap; */
    width: 100%;
    ${({ renderFrame }) =>
        renderFrame === 3 ?
        css`
            align-items: flex-end;
            justify-content: flex-end;
        ` : ``}
`;

export const ScrollView = styled.ScrollView`
    /* background-color: pink; */
`;

export const CarArea = styled.TouchableOpacity<CarAreProps>`
    /* align-items: center; */
    border-color: ${({ isSelected, theme }) => isSelected ? theme.colors.purple[500] : theme.colors.gray[350]};
    border-width: 2px;
    border-radius: 5px;
    /* height: 255px; */
    margin-bottom: 26px;
    width: 250px;
    padding: 2px;
    margin-left: 20px;
    background-color: ${({ theme }) => theme.colors.gray[350]};
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ImageCardArea = styled.View<CarAreProps>`
    align-items: center;
    /* background-color: ${({ isSelected, theme }) => isSelected ? theme.colors.purple[500] : theme.colors.white}; */
    /* height: 150px; */
    width: 244px;
`;

export const ImageCar = styled.Image`
    height: 150px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
`;

export const FooterImageCar = styled.View`
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px;
    height: 25px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    color: ${({ theme }) => theme.colors.white};
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 5px;
`;

export const ArrowButtonArea = styled.TouchableOpacity<ArrowButtonProps>`
    align-self: center;
    align-items: center;
    justify-content: center;
    top: 150px;
    height: 40px;
    position: absolute;
    right: ${({ type }) => type === 'right' ? 0 : 'auto'};
    left: ${({ type }) => type === 'left' ? 0 : 'auto'};
    margin-right: 20px;
    width: 40px;
`;

export const AlignCarAreaNotScroll = styled.View<ResponsiveProps>`
    padding-bottom: 10px;
    padding-right: 20px;
    /* align-self: center;
    flex-direction: row;
    flex-wrap: wrap;
    ${({ width }) => width <= 900 ? css`padding: 0px 0px` : css`padding: 0px 16px`};
    width: ${({ width }) => width <= 900 ? '90%' : '80%'};
    height: 380px */
`;

export const AlignCarArea = styled.ScrollView<ResponsiveProps>`
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    /* align-self: center;
    flex-direction: row;
    flex-wrap: wrap;
    ${({ width }) => width <= 900 ? css`padding: 0px 0px` : css`padding: 0px 16px`};
    width: ${({ width }) => width <= 900 ? '90%' : '80%'};
    height: 380px */
`;

export const LoadingImage = styled.ActivityIndicator<ResponsiveProps>`
    height: ${({ width }) => width <= 800 ? 250 : 500};
    /* margin-left: ${({ width }) => width <= 800 ? 0 : 30}; */
    width: ${({ width }) => width < 400 ? '100%' : '80%'};
    /* width: 80%; */
`;

export const InputPickerArea = styled.View<ResponsiveProps>`
    margin-bottom: 16px;
    margin-top: 10px;
    width: ${({ width }) => width <= 800 ? '100%' : '48%'};
`;

export const Label = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const AdjustBox = styled.View<ResponsiveProps>`
    flex-direction: ${({ width }) => width <= 600 ? 'column-reverse' : 'row'};
    justify-content: space-between;
    /* flex-wrap: ${({ width }) => width <= 400 ? 'wrap' : 'nowrap'}; */
    width: 100%;
`;

export const InputPickerContainer = styled.ScrollView`
    flex-direction: column;
    border-width: 1;
    border-color: gray;
    /* height: 150px; */
    margin-top: 10px;
    border-radius: 15;
    padding-left: 10;
    padding-right: 10;
    padding-top: 10;
    padding-bottom: 10;
    width: 100%;
    height: 300px;
`;

export const AdjustBoxTag = styled.View<ResponsiveProps>`
    flex-direction: column;
    /* width: 45%; */
    width: ${({ width }) => width <= 600 ? '100%' : '45%'};
    /* flex: 1; */
`;

export const FlowTags = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
`;

export const AdjustHeader = styled.View`
    flex-direction: row;
    justify-content: space-between;
`;

export const Tag = styled.Text`
    padding: 3px;
    border-radius: 5px;
    min-width: 50px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
`;

export const CheckboxArea = styled.View<CheckboxProps>`
    align-items: center;
    flex-direction: column;
`;

export const AdjustCheckBox = styled.View`
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
`;

export const CheckboxCircle = styled.TouchableOpacity<CheckboxProps>`
    align-items: center;
    border-color: ${({ isMarked, theme }) => isMarked ? theme.colors.purple[500] : theme.colors.gray[400]};
    border-radius: 10px;
    border-width: 2px;
    height: 20px;
    justify-content: center;
    margin-left: ${({ marginLeft }) => marginLeft ? 30 : 0};
    width: 20px
`;

export const CheckboxCircleMarked = styled.View<CheckboxProps>`
    align-items: center;
    background-color: ${({ isMarked, theme }) => isMarked ? theme.colors.purple[500] : theme.colors.gray[400]};
    border-radius: 10px;
    height: 12px;
    justify-content: center;
    opacity: ${({ isMarked }) => isMarked ? 1 : 0};
    width: 12px;
`;

export const CheckboxText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 14px;
    margin-left: 12px;
`;

export const PickerContainer = styled(Picker)<PickerProps>`
    background-color: ${({ theme }) => theme.colors.gray[300]};
    border-width: 0px;
    width: 100%;
    outline-width: 0px;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    ${({ theme, currentvalue }) =>
        currentvalue === '0'  && `color: ${theme.colors.gray[400]}`};
`;

export const NumberCar = styled.View`
    align-self: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 25px;
    height: 40px;
    justify-content: center;
    width: 40px;
    position: absolute;
    top: 50px;
`;

export const NumberText = styled.View`
    color: ${({ theme }) => theme.colors.white};
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
`;

export const ImageArea = styled.View``;

export const ButtonModalArea = styled.TouchableOpacity<ButtonModalProps>`
    align-items: center;
    background-color: ${({ background, theme }) => background === 'red'
        ? theme.colors.red[500] : theme.colors.purple[500]};
    border-radius: 6px;
    height: 40px;
    justify-content: center;
    width: 150px;
`;

export const ButtonModalText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const ButtonModalConfirm = styled.View`
    /* align-self: flex-end; */
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 12px;
`;

export const ButtonModalAlign = styled.View`
    /* align-self: flex-end; */
    flex-direction: row;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
`;

export const WhatsappContainer = styled.View<ResponsiveProps>`
    padding: ${({ width }) => width < 800 ? 0 : '0px 68px'};
    margin-bottom: 20px;
    width: ${({ width }) => width <= 1071 ? '100%' : '50%'};
`;

export const WhatsappContainerTitle = styled.Text`
    font-size: 26px;
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    color: ${({ theme }) => theme.colors.gray[800]};
`;

export const WhatsappContainerDataContainer = styled.View<ResponsiveProps>`
    margin-bottom: 80px;
`;

export const WhatsappContainerLabel = styled.Text`
    font-size: 20px;
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    color: ${({ theme }) => theme.colors.gray[800]};
    margin-bottom: 7px;
`;

export const WhatsappContainerDataText = styled.Text`
    font-size: 20px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    color: ${({ theme }) => theme.colors.gray[800]};
`;

export const HeaderInputMessage = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px
`;

export const HeaderInputText = styled.Text`
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    color: ${({ theme }) => theme.colors.gray[150]};
`;

export const InputDescriptionWhatsapp = styled.TextInput.attrs({
    placeholderTextColor: Theme.colors.gray[150]
})`
    height: 80px;
    background-color: ${({ theme }) => theme.colors.gray[70]};
    margin-top: 16px;
    padding: 12px;
    font-size: 12px;
`;

export const FooterInputMessage = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
`;

export const AdjustLoading = styled.View`
    height: 490px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
`;

export const ButtonUpdate = styled.TouchableOpacity`
    width: 72px;
    height: 30px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    align-items: center;
    justify-content: center;
`;

export const ButtonUpdateText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
`;

export const ButtonWhatsapp = styled.TouchableOpacity`
    width: 160px;
    padding: 6px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.green[470]};
    border-radius: 6px;
    flex-direction: row;
`;

export const ContainerButton = styled.View`
`;

export const ContainerButtonsActions = styled.View`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

export const ButtonWhatsappText = styled.Text`
    font-size: 18px;
    color: ${(props) => props.theme.colors.white};
    margin-left: 12.5px;
`;

export const ButtonWhatsappIcon = styled(WhatsappIcon)``;

export const ImageCardContainerView = styled.View`
    align-items: center;
    margin-bottom: 10px;
    height: 370px;
    /* justify-content: center; */
    margin-top: 10px;
    width: 100%;
`;

export const ImageCard = styled.Image<ResponsiveProps>`
    height: ${({ width }) => width <= 696 ? (width <= 423 ? (width <= 317 ? '70px' : '100px') : '150px') : '200px'};
    width: ${({ width }) => width <= 696 ? (width <= 423 ? (width <= 317 ? '70px' : '100px') : '150px') : '200px'};
    margin: 20px;
    margin-bottom: 10px;
`;

export const AdjustButtons = styled.View`
    display: flex;
`;

export const ContainerPurple = styled.View`
    max-height: 70px;
    max-width: 70px;
    min-height: 70px;
    min-width: 70px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 5px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
`;

export const ContainerPurpleButton = styled.Pressable`
    max-height: 70px;
    max-width: 70px;
    min-height: 70px;
    min-width: 70px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 5px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    position: relative;
    z-index: 1000;
`;

export const TextFrame = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    /* max-width: 50%; */
    color: white;
    font-size: 24px;
    text-align: center;
`;

export const TextFrameTexts = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    max-width: 50%;
`;

export const FrameCard = styled.Image`
    height: 150px;
    width: 150px;
    /* margin: 10px 20px; */
    /* margin-bottom: 10px; */
    background-color: ${({ theme }) => theme.colors.purple[500]};
    border-radius: 5px;
`;

export const AdjustButtonEnd = styled.View`
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
`;

export const ContainerRight = styled.View`
    flex-direction: column;
`;

export const ContainerHeader = styled.View`
    background-color: #e5e5e5;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    min-height: 40px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

export const ContainerBorder = styled.View`
    border: ${({ theme }) => `1px solid ${theme.colors.lowBlack}`};
    margin-top: 10px;
    max-height: 70px;
    max-width: 70px;
    min-height: 70px;
    min-width: 70px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 5px;
    position: relative;
`;

export const Liner = styled.View`
    height: 3px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    flex: 1;
`;

export const Circle = styled.View`
    min-height: 20px;
    min-width: 20px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
`;

export const GridTitleVehicle = styled.View`
    display: grid;
    gap: 10px;
    grid-template-columns: 70% 30%;
    align-items: flex-end;
`;

export const Grid = styled.View`
    display: grid;
    gap: 2px;
    grid-template-columns: repeat(2, 1fr);
`;

export const IconCrop = styled(Feather)`
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
`;

export const ContainerArrowIndex = styled.TouchableOpacity<ResponsiveProps>`
    position: absolute;
    left: 3px;
    top: 3px;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.red[500]};
    color: white;
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 10px;
    align-items: center;
    justify-content: center;
`;

export const ContainerArrowUp = styled.TouchableOpacity<ResponsiveProps>`
    height: 16px;
    width: 16px;
    border-radius: 8px;
    margin-left: 3px;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ContainerArrow = styled.TouchableOpacity<ResponsiveProps>`
    position: absolute;
    right: -6px;
    bottom: 3px;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.purple[500]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const IconArrow = styled(Feather)<ResponsiveProps>`
    color: ${({ theme }) => theme.colors.gray[150]};
    font-size: 12px;
`;

export const ButtonToMarkContainer = styled.View`
    flex-direction: row;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const ButtonToMark = styled.TouchableOpacity`
    margin-right: 20px;
`;

export const ButtonToMarkText = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
`;
