import styled from 'styled-components/native';
import Feather from '@expo/vector-icons/Feather';

export const ButtonLogout = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    padding: 16px;
    width: 100%;
    /* position: absolute; */
    /* bottom: 42px; */
    /* left: 20px; */
`;

export const IconLogout = styled(Feather)`
    font-size: 24px;
    color: ${({ theme }) => theme.colors.red[500]};
`;

export const TextLogout = styled.Text`
    color: ${({ theme }) => theme.colors.red[500]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 16px;
    margin-left: 10px;
`;
