import styled from 'styled-components/native';

interface ButtonProps {
    buttonCodition: boolean,
}

export const ModalArea = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 16px;
    height: 300px;
    margin-bottom: 8px;
    padding: 12px;
    width: 300px;
`;

export const Text = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 16px;
`;

export const ButtonType = styled.TouchableOpacity<ButtonProps>`
    align-items: center;
    align-self: center;
    background-color: ${({ buttonCodition, theme }) => buttonCodition ? theme.colors.green[400] : theme.colors.gray[400]};
    border-radius: 6px;
    height: 40px;
    justify-content: center;
    width: 180px;
`;
