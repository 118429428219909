// @ts-nocheck
import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import * as DocumentPicker from 'expo-document-picker';

import AttachIcon from '../../../../../assets/images/svg/attachIcon';
import { getImageLink } from '../../../../../services/uploads';
import * as S from './styles';

interface DocumentProps {
    status: boolean,
    nameFile: string,
}

interface DropzoneComponentProps {
    setDocumentUrl: (documentUrl: string) => void,
    type: 'edit' | 'newArt',
    attachment: string,
    isError?: boolean,
    typeArchive?: string,
    labelButton?: string,
    labelDropzone?: string,
}

export function DropzoneComponent(props: DropzoneComponentProps) {
    const { setDocumentUrl, type, attachment, isError, typeArchive, labelButton, labelDropzone } = props;

    const [documentInfo, setDocumentInfo] = useState<DocumentProps>({
        status: type === 'edit' ? true : false,
        nameFile: type === 'edit' ? attachment : '',
    } as DocumentProps);

    function handleChangeStatus({ meta, file }, status: string) {
        setDocumentInfo({
            status: true,
            nameFile: file.name,
        });
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
            getLink({ uri: reader.result });
        });
    }

    async function getDocument() {
        const typeDocument = { type: typeArchive || 'application/zip' };
        const result = await DocumentPicker.getDocumentAsync(typeDocument);

        if (result.type === 'success') {
            setDocumentInfo({
                status: true,
                nameFile: result.file.name,
            });
            getLink(result);
        }
    }

    async function getLink(fileInfo: any) {
        setDocumentUrl(fileInfo.uri);
    }

    function RenderStatus() {
        if (!documentInfo.status) {
            return null;
        }

        return (
            <S.ButtonDropzoneOption>
                <S.UploadText numberOfLine={3}>{documentInfo.nameFile}</S.UploadText>
            </S.ButtonDropzoneOption>
        );
    }

    function RenderDropzone() {
        return (
            <S.AttachArea>
                <S.AttachDocumentArea isError={isError}>
                    <S.AttachIconArea>
                        <AttachIcon />
                    </S.AttachIconArea>
                    <S.AttachButton onPress={getDocument}>
                        <S.AttachText type='button'>{labelButton || 'Anexar documento'}</S.AttachText>
                    </S.AttachButton>
                    <S.AttachText type='text'>{labelDropzone || 'ou arraste um arquivo'}</S.AttachText>
                </S.AttachDocumentArea>
                {RenderStatus()}
            </S.AttachArea>
        );
    }

    return (
        <S.DropzoneArea>
            <Dropzone
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                accept='application/zip'
                PreviewComponent={RenderDropzone}
                InputComponent={RenderDropzone}
            />
        </S.DropzoneArea>
    );
}
