// @ts-nocheck
import styled from 'styled-components/native';
import { Picker } from '@react-native-picker/picker';
import { TextInputMask } from 'react-native-masked-text';
import { Feather } from '@expo/vector-icons';

import themeGlobal from '../../global/styles/theme';

interface PickerProps {
    currentvalue: number;
    backgroundColorInput?: string;
}

interface ContainerProps {
    width: string;
    multiline?: boolean;
    heightBoxArea?: number;
    height?: number;
    marginTop: string
}

interface InputProps {
    error: string;
    multiline?: boolean;
    heightBoxArea?: number;
    marginTop?: number;
    backgroundColorInput?: string;
    height?: number;
}

export const Container = styled.View<ContainerProps>`
    width: ${({ width }) => width || '100%'};
    height: ${({ multiline, heightBoxArea, height }) => multiline ? (heightBoxArea || 100) : height || 'auto'};
    margin-bottom: 16px;
    margin-top: ${({ marginTop }) => marginTop || '0px'};
`;

export const Label = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const InputContainer = styled.View<InputProps>`
    flex-direction: row;
    padding: 10px;
    margin-top: ${({ marginTop }) => marginTop || '6px'};
    height: ${({ multiline, heightBoxArea }) => multiline ? (heightBoxArea || 100) : 40};
    border-radius: 6px;
    height: ${({ height }) => height || 'auto'};
    border-color: ${({ theme }) => theme.colors.red[500]};
    border-width: ${({ error }) => error ? 1 : 0};
    background-color: ${({ theme, backgroundColorInput }) => backgroundColorInput || theme.colors.gray[300]};
    align-items: center;
`;

export const InputCommon = styled.TextInput.attrs({
    placeholderTextColor: themeGlobal.colors.gray[400],
})<InputProps>`
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    outline-width: 0px;
    height: ${({ multiline, heightBoxArea }) => multiline ? (heightBoxArea || 100) : 'auto'};
    width: 100%;
`;

export const InputMask = styled(TextInputMask).attrs({
    placeholderTextColor: themeGlobal.colors.gray[400],
})`
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    outline-width: 0px;
    width: 100%;
`;

export const PickerContainer = styled(Picker)<PickerProps>`
    background-color: ${({ theme, backgroundColorInput }) => backgroundColorInput || theme.colors.gray[300]};
    border-width: 0px;
    width: 100%;
    outline-width: 0px;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    ${({ theme, currentvalue }) =>
        currentvalue === '0' && `color: ${theme.colors.gray[400]}`};
`;

export const Erros = styled.Text`
    color: ${({ theme }) => theme.colors.red[300]};
    font-size: 10px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    margin-top: 2px;
`;

export const SecureButton = styled.TouchableOpacity``;

export const IconInput = styled(Feather)``;
