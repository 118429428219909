import { ResponsiveProps } from "../../../../global/types";
import styled from "styled-components/native";

interface StatusCoditionProps {
    statusCodition: boolean,
}

interface ActionsAreaProps {
    widthOptions: string,
}

export const Flatlist = styled.FlatList``;

export const TableArea = styled.View`
    flex-direction: row;
`;

export const FooterArea = styled.View`
    align-items: center;
    border-color: ${({ theme }) => theme.colors.gray[150]};
    border-top-width: 1px;
    height: 70px;
    justify-content: center;
    margin-top: 18px;
    width: 100%;
`;

export const FooterText = styled.Text`
    color: ${({ theme }) => theme.colors.lowBlack};
    font-family: ${({ theme }) => theme.font.segoeUi.bold};
    font-size: 18px;
`;

export const DropdownArea = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.gray[50]};
    padding: 10px;
    width: 99%;
`;

export const TableHeaderContainerArea = styled.View`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.purple[200]};
    flex-direction: row;
    height: 50px;
    justify-content: space-between;
    /* padding-left: 8px; */
    width: 100%;
`;

export const ActionsArea = styled.View<ActionsAreaProps>`
    align-items: center;
    flex-direction: row;
    height: 50px;
    width: ${({ widthOptions }) => widthOptions};
`;

export const ArrowPress = styled.TouchableOpacity`
    margin-left: 14px;
`;

export const OptionArea = styled.TouchableOpacity<ResponsiveProps>`
    align-items: center;
    border-color: gray;
    border-radius: 4px;
    border-width: 1px;
    height: 30px;
    justify-content: center;
    margin-left: 8px;
    width: ${({ width }) => width > 1100 ? 40 : 20};
`;

export const StatusArea = styled.View<ResponsiveProps>`
    justify-content: center;
    width: ${({ width }) => width > 1100 ? '10%' : '20%'};
`;

export const StatusCoditionArea = styled.View<StatusCoditionProps>`
    align-items: center;
    background-color: ${({ theme, statusCodition }) => statusCodition ? theme.colors.green[400] : theme.colors.red[300]};
    border-radius: 4px;
    height: 25px;
    justify-content: center;
    width: 80px;
`;

export const StatusText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
`;
