import styled from 'styled-components/native';
import Modal from 'react-native-modal';

export const ModalArea = styled(Modal)`
    margin: 0;
`;

export const ContainerModal = styled.View`
    align-items: center;
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    height: 200px;
    justify-content: center;
    width: 250px;
`;

export const TextLoading = styled.Text`
    color: ${({ theme }) => theme.colors.lowBlack};
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 14px;
`;

export const IndicatorLoading = styled.ActivityIndicator`
    color: ${({ theme }) => theme.colors.purple[500]};
    margin-bottom: 12px;
`;
