import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import * as S from './styles';
import { getDashboardInfo, getUserList } from '../../../services/dashboard';

import { DashboardHeader } from '../../../components/DashboardHeader';
import { InvoicingChart } from './component/InvoicingChart';
import { SignedPlansChart } from './component/SignedPlansChart';
import { StatiticsInfo } from './component/StatiticsInfo';
import { UsedArtsChart } from './component/UsedArtsChart';
// import { UserInfoCard } from './component/UserInfoCard';
import { FrequentBuyersChart } from './component/FrequentBuyersChart';

interface PlansProps {
    name?: string,
    total_signature?: string,
    percentage?: string,
}

interface UsedArtsProps {
    id?: number,
    title?: string,
    amount_used?: number,
}

interface BillingsProps {
    type?: string,
    price?: number,
    id_reference?: number,
}

interface FrequentBuyersProps {
    name?: string,
    purchased_arts?: string,
    photo?: string,
}

interface InfoListProps {
    downgrade?: number,
    upgrade?: number,
    total_client?: string,
    plans?: Array<PlansProps>,
    used_arts?: Array<UsedArtsProps>,
    purchased_arts?: string,
    billings?: Array<BillingsProps>,
    frequent_buyers?: Array<FrequentBuyersProps>
}

export function DashboardScreen() {
    const width = useWindowDimensions().width;
    const twoColumnWidth = width <= 900 ? '100%' : '32.5%';
    const threeColumnWidth = width <= 900 ? '100%' : '24%';
    const asideWidth = width <= 900 ? '100%' : '29%';
    const contentInfoWidth = width <= 900 ? '100%' : '100%';
    const threeColumnContainer = width <= 900 ? '100%' : 'auto';
    const marginCard = width <= 900 ? 12 : 0;

    const [infoList, setInfoList] = useState<InfoListProps>({} as InfoListProps);
    const [loading, setLoading] = useState(false);
    const [recentUsers, setRecentUsers] = useState<any[]>([]);
    const [onlineUsers, setOnlineUsers] = useState<any[]>([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getAllInfo();
    }, []);

    async function getAllInfo() {
        const { result } = await getDashboardInfo();
        setInfoList(result);
        setLoading(true);
    }

    async function getUsersInfo() {
        // const { result } = await getUserList(1, 6, 'recent');
        // setRecentUsers(result.list);
        // getUsersOnline(page);
        // setLoading(true);
    }

    async function getUsersOnline(pageNumber: number) {
        // if (pageNumber >= 1) {
        //     const { result } = await getUserList(pageNumber, 3, 'online');
        //     if (pageNumber <= result.amount) {
        //         setOnlineUsers(result.list);
        //         setPage(pageNumber);
        //     }
        // }
    }

    function addPage() {
        getUsersOnline(page + 1);
    }

    function decreasePage() {
        if (page >= 1) {
            getUsersOnline(page - 1);
        }
    }

    function RenderContentInfo() {
        if (!loading) {
            return null;
        }

        return (
            <S.ScrollView>
                <S.ContainerAlign>
                    <S.RegisterUserArea width={width}>
                        <S.ContentInfo width={contentInfoWidth}>
                            <S.ContainerCardArea width={width}>
                                <S.DefaultCard height={126} width={threeColumnWidth}>
                                    <StatiticsInfo
                                        title='Total de assinaturas'
                                        total={infoList?.total_client}
                                        type='sign'
                                    />
                                </S.DefaultCard>
                                <S.DefaultCard height={126} width={threeColumnWidth} marginTop={marginCard}>
                                    <StatiticsInfo
                                        title='Upgrade de plano'
                                        total={infoList?.upgrade}
                                        type='upgrade'
                                    />
                                </S.DefaultCard>
                                <S.DefaultCard height={126} width={threeColumnWidth} marginTop={marginCard}>
                                    <StatiticsInfo
                                        title='Downgrade de plano'
                                        total={infoList?.downgrade}
                                        type='downgrade'
                                    />
                                </S.DefaultCard>
                                <S.DefaultCard height={126} width={threeColumnWidth} marginTop={marginCard}>
                                    <StatiticsInfo
                                        title='Artes vendidas'
                                        total={infoList?.purchased_arts}
                                        type='sale'
                                    />
                                </S.DefaultCard>
                            </S.ContainerCardArea>
                            <S.ContainerCardArea marginTop={12} width={width}>
                                <S.DefaultCard height={274} width={twoColumnWidth}>
                                    <SignedPlansChart plansList={infoList?.plans} />
                                </S.DefaultCard>
                                <S.DefaultCard height={274} width={twoColumnWidth} marginTop={marginCard}>
                                    <UsedArtsChart usedArtsInfo={infoList?.used_arts} />
                                </S.DefaultCard>
                                <S.DefaultCard height={274} width={twoColumnWidth} marginTop={marginCard}>
                                    <FrequentBuyersChart frequentBuyersList={infoList?.frequent_buyers} />
                                </S.DefaultCard>
                            </S.ContainerCardArea>
                        </S.ContentInfo>
                        {/* <S.ContainerCardArea width={asideWidth}>
                            <S.DefaultCard marginTop={marginCard} width='100%'>
                                <UserInfoCard
                                    infoList={recentUsers}
                                    title='Usuários cadastrados'
                                    type='recent'
                                />
                            </S.DefaultCard>
                        </S.ContainerCardArea> */}
                    </S.RegisterUserArea>
                    <S.RegisterUserArea width={width}>
                        <S.ContainerCardArea marginTop={12} width='100%'>
                            <S.DefaultCard height={241} width='100%'>
                                <InvoicingChart invoicingList={infoList?.billings} />
                            </S.DefaultCard>
                        </S.ContainerCardArea>
                        {/* <S.ContainerCardArea marginTop={12} width={asideWidth}>
                            <S.DefaultCard height={241} width='100%'>
                                <UserInfoCard
                                    infoList={onlineUsers}
                                    title='Usuários ativos no momento'
                                    type='status'
                                    addPage={addPage}
                                    decreasePage={decreasePage}
                                />
                            </S.DefaultCard>
                        </S.ContainerCardArea> */}
                    </S.RegisterUserArea>
                </S.ContainerAlign>
            </S.ScrollView>
        );
    }

    return (
        <S.Container>
            <DashboardHeader
                title='Dashboard'
                typeHeader='home'
                disableInputSearch
            />
           {RenderContentInfo()}
        </S.Container>
    );
}
