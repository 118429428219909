import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={18.828}
        height={18.828}
        {...props}>
        <G
            data-name="x (2)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}>
            <Path data-name="Linha 44" d="m17.414 1.414-16 16" />
            <Path data-name="Linha 45" d="m1.414 1.414 16 16" />
        </G>
    </Svg>
);

export default SvgComponent;
