// @ts-nocheck
import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.828}
    height={6.414}
    {...props}
  >
    <Path
      data-name="chevron-down (1)"
      d="m1.414 1.414 4 4 4-4"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
)

export default SvgComponent
