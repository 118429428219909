import styled from 'styled-components/native';

interface DefaultCardProps {
    height: number,
    width: number | string,
    marginTop?: number,
}

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.background};
    flex: 1;
`;

export const TextDefault = styled.Text`
    margin-bottom: 10px;
`;

export const ContainerAlign = styled.View`
    align-self: center;
    margin-bottom: 20px;
    margin-top: 19px;
    width: 95%;
`;

export const DefaultCard = styled.View<DefaultCardProps>`
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    height: ${({ height }) => height || 'auto'};
    padding: 16px;
    margin-top: ${({ marginTop }) => marginTop || 0};
    width: ${({ width }) => width};
`;

export const ContainerCardArea = styled.View<DefaultCardProps>`
    flex-direction: ${({ width }) => width <= 900 ? 'column' : 'row'};
    justify-content: space-between;
    margin-top: ${({ marginTop }) => marginTop || 0};
    width: 100%;
`;

export const ScrollView = styled.ScrollView``;

export const RegisterUserArea = styled.View<DefaultCardProps>`
    flex-direction: ${({ width }) => width <= 900 ? 'column' : 'row'};
    justify-content: space-between;
`;

export const ContentInfo = styled.View<DefaultCardProps>`
    width: ${({ width }) => width};
`;
