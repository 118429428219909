import React from 'react';

import PassWordIcon from '../../../../../assets/images/svg/passwordIcon';

import * as S from './styles';

interface ModalChangePassword {
    forgotPassword: () => void;
}

export function ModalChangePassword(props: ModalChangePassword) {
    const { forgotPassword } = props;
    return (
        <S.Container>
            <PassWordIcon />
            <S.Title>Esqueceu sua senha?</S.Title>
            <S.Description>
                Enviaremos uma nova senha criptografada para o seu email.
            </S.Description>
            <S.Button onPress={forgotPassword}>
                <S.ButtonText>Enviar link</S.ButtonText>
            </S.Button>
        </S.Container>
    );
}
