// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import * as DocumentPicker from 'expo-document-picker';
import toast from 'react-hot-toast';
import { useWindowDimensions } from 'react-native';

import * as S from './styles';
import {
    getVehiclesList,
    updateRevendaPro,
    updateClienteRevendaPro,
} from '../../../../../services/vehicles';
import CopyIcon from '../../../../../assets/images/svg/copy';
import EditIcon from '../../../../../assets/images/svg/edit';
import AddIcon from '../../../../../assets/images/svg/addIcon';
import Theme from '../../../../../global/styles/theme';
import { importWithXml } from '../../../../../services/user';

import { Table } from '../../../../../components/Table';
import { InputSearch } from '../../../../../components/InputSearch';
import { LoadingModal } from '../../../../../components/LoadingModal';
import { Loading } from '../../../../../components/Loading';
import { isMobile } from 'react-device-detect';

interface VehicleListProps {
    amount: number;
    list: Array<any>;
}

interface VehicleInfoProps {
    clientInfo: any;
    typeScreen?: string;
}

export function VehicleInfo(props: VehicleInfoProps) {
    const width = useWindowDimensions().width;
    const { clientInfo, typeScreen } = props;
    const { user } = useSelector((state: any) => state.user.user);
    const isFocus = useIsFocused();
    const navigation = useNavigation();

    const [vehiclesList, setVehiclesList] = useState<VehicleListProps>({} as VehicleListProps);
    const [pagination, setPagination] = useState(0);
    const [vehicleName, setVehicleName] = useState('');
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loadingModalVisible, setLoadingModalVisible] = useState(false);

    useEffect(() => {
        if (isFocus) {
            setLoading(false);
            allVehiclesList('');
        }
    }, [isFocus, pagination]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            allVehiclesList(vehicleName);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [vehicleName]);

    async function allVehiclesList(textSearch: string, isLoading: boolean) {
        if (isLoading) {
            setLoading(false);
        }
        const { result } = await getVehiclesList(
            1,
            clientInfo?.id,
            10,
            textSearch,
        );
        setVehiclesList(result);
        setLoading(true);
    }

    function handleNewVehicle() {}

    async function handleGetDock() {
        const result = await DocumentPicker.getDocumentAsync({
            type: 'text/xml',
        });

        if (result.type === 'success') {
            setLoadingModalVisible(true);
            const hashBase64 = result.uri.split(',')[1];
            const body =
                user.type === 'users'
                    ? {
                          base64: hashBase64,
                          idUser: user.id,
                      }
                    : { base64: hashBase64 };
            const response = await importWithXml(body);
            if (response.status === 200) {
                setLoadingModalVisible(false);
                toast.success(response.message, Theme.toast);
                allVehiclesList('');
            } else {
                setLoadingModalVisible(false);
                toast.error(response.message, Theme.toast);
            }
        }
    }

    function handleButtonClick() {
        setDropdownOpen(!dropdownOpen);
    }

    // function updateWithRevendaPro() {
    //     setLoading(false);
    //     updateRevendaPro(user?.id).then(() => {
    //         allVehiclesList('');
    //     });
    //     updateClienteRevendaPro(user?.id).then(() => {
    //         setLoading(true);
    //     });
    // }

    function renderDropdown() {
        if (!dropdownOpen) {
            return null;
        }

        function getDock() {
            handleGetDock();
            setDropdownOpen(false);
        }

        return (
            <S.OptionsContainer>
                <S.SecondButtonOptions onPress={getDock}>
                    <EditIcon />
                    <S.ButtonOptionsText>Importar arquivo</S.ButtonOptionsText>
                </S.SecondButtonOptions>
            </S.OptionsContainer>
        );
    }

    function RenderTableInfo() {
        if (!loading) {
            return (
                <S.ContainerAlign>
                    <Loading />
                </S.ContainerAlign>
            );
        }

        return (
            <S.ContainerAlign>
                <Table
                    data={vehiclesList.list}
                    type="vehicles"
                    getAllClient={allVehiclesList}
                    totalItem={vehiclesList.amount}
                    qtdPage={vehiclesList.qtdPages}
                    currentPage={pagination}
                    setCurrentPage={setPagination}
                    widthTable={width < 800 ? 1200 : undefined}
                />
            </S.ContainerAlign>
        );
    }

    function RenderNewVehicleCodition() {
        if (typeScreen === 'viewClient') {
            return null;
        }

        // function renderButtonRevendaPro() {
        //     if (user?.platform !== 'revenda_prod') {
        //         return null;
        //     }

        //     return (
        //         <S.ButtonContainer width={width}>
        //             <S.NewClientButton
        //                 width={width}
        //                 onPress={updateWithRevendaPro}>
        //                 <CopyIcon stroke={Theme.colors.white} />
        //                 <S.AddClientText width={width}>
        //                     Sincronizar veículos e clientes RevendaPro
        //                 </S.AddClientText>
        //             </S.NewClientButton>
        //         </S.ButtonContainer>
        //     );
        // }

        return (
            <S.ButtonContainer width={width}>
                {isMobile ? (
                    <S.NewClientButton
                        width={width}
                        onPress={() => allVehiclesList(vehicleName, true)}
                        style={{ width: width < 650 ? '100%' : 200 }}
                    >
                        {width < 550 ? null : <AddIcon />}
                        <S.AddClientText width={width}>
                            Atualizar dados
                        </S.AddClientText>
                    </S.NewClientButton>
                ) : null}
                <S.NewClientButton
                    width={width}
                    onPress={handleButtonClick}
                    style={{ width: width < 650 ? '100%' : 200 }}
                >
                    {width < 550 ? null : <AddIcon />}
                    <S.AddClientText width={width}>
                        Novo veículo
                    </S.AddClientText>
                </S.NewClientButton>
                {renderDropdown()}
            </S.ButtonContainer>
        );
    }

    return (
        <S.Form>
            <S.InputSearchArea width={width}>
                <InputSearch
                    value={vehicleName}
                    setValue={setVehicleName}
                    placeholder="Buscar veículo"
                    marginLeft={0}
                    widthArea={width < 500 ? '49%' : 235}
                />
                {RenderNewVehicleCodition()}
            </S.InputSearchArea>
            {RenderTableInfo()}
            <LoadingModal
                visible={loadingModalVisible}
                titleLoading="Importando veículo(s)..."
            />
        </S.Form>
    );
}
