import { api } from './api';

interface CreateDescriptionProps {
    description: string,
}

interface CreateDescriptionUserProps {
    description: string,
    idUser: number,
}

interface CreateDescriptionUserPublicateProps {
    idDescription: number,
    idVehicle: number,
}

export async function getDescription() {
    try {
        const { data } = await api.get('/descriptions');
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function createDescription(body: CreateDescriptionProps) {
    try {
        const { data } = await api.post('/descriptions', body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function editDescription(idDescription: number, body: CreateDescriptionProps) {
    try {
        const { data } = await api.put(`/descriptions?idDescription=${idDescription}`, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function deleteDescription(idDescription: number) {
    try {
        const { data } = await api.delete(`/descriptions?idDescription=${idDescription}`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function getDescriptionUser(idUser: number) {
    try {
        const { data } = await api.get(`/descriptionsUsers?idUser=${idUser}`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function createDescriptionUser(body: CreateDescriptionUserProps) {
    try {
        const { data } = await api.post('/descriptionsUsers', body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function generateDescriptionUser(body: CreateDescriptionUserPublicateProps) {
    try {
        const { data } = await api.patch('/descriptionsUsers/generate', body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function editDescriptionUser(idDescription: number, body: CreateDescriptionUserProps) {
    try {
        const { data } = await api.put(`/descriptionsUsers?idDescription=${idDescription}`, body);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}

export async function deleteDescriptionUser(idDescription: number) {
    try {
        const { data } = await api.delete(`/descriptionsUsers?idDescription=${idDescription}`);
        return data;
    } catch ({ response: { data } }) {
        return data;
    }
}
