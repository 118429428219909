import styled from "styled-components/native";

import { ResponsiveProps } from "../../global/types";

interface TableArea extends ResponsiveProps {
    type: 'header' | 'table',
}

export const TableHeaderContainerArea = styled.View<TableArea>`
    align-items: center;
    background-color: ${({ theme, type }) => type === 'header' ? theme.colors.purple[500] : 'transparent'};
    flex-direction: row;
    height: 50px;
    justify-content: space-between;
    /* padding-left: 8px; */
    width: 100%;
`;

export const ContainerTable = styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.gray[150]};
    border-width: 1px;
`;


export const Error = styled.Text`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray[600]};
`;
