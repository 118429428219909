export const headerConfigs = {
    opened: [
        {
            width: '30%',
            title: 'Revenda',
            id: 1,
        },
        {
            width: '25%',
            title: 'Plano',
            id: 2,
        },
        {
            title: 'Valor Plano',
            width: '25%',
            id: 3,
        },
        {
            title: 'Total em aberto',
            width: '10%',
            id: 4,
        },
        {
            title: 'Ações',
            width: '10%',
            id: 5,
        },
    ],
    payment: [
        {
            width: '40%',
            title: 'Revenda',
            id: 1,
        },
        {
            width: '40%',
            title: 'Data',
            id: 2,
        },
        {
            width: '10%',
            title: 'Valor Pago',
            id: 3,
        },
        {
            width: '10%',
            title: 'Ações',
            id: 4,
        },
    ],
    paymentControl: [
        {
            width: '80%',
            title: 'Referência',
            id: 1,
        },
        {
            width: '10%',
            title: 'Valor',
            id: 2,
        },
        {
            width: '10%',
            title: 'Ações',
            id: 3,
        },
    ],
    logsCard: [
        {
            width: '25%',
            title: 'Data',
            id: 1,
        },
        {
            width: '25%',
            title: 'Hora',
            id: 2,
        },
        {
            width: '25%',
            title: 'Rede social',
            id: 3,
        },
        {
            width: '25%',
            title: 'Status',
            id: 4,
        },
    ],
};
