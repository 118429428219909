// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={17.157}
        height={17.157}
        {...props}>
        <G
            fill="none"
            stroke="#6746c8"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}>
            <Path
                data-name="Caminho 95"
                d="M7.709 2.738H2.491A1.491 1.491 0 0 0 1 4.229v10.437a1.491 1.491 0 0 0 1.491 1.491h10.437a1.491 1.491 0 0 0 1.491-1.491V9.447"
            />
            <Path
                data-name="Caminho 96"
                d="M13.301 1.62a1.581 1.581 0 1 1 2.236 2.236l-7.082 7.082-2.982.746.745-2.982Z"
            />
        </G>
    </Svg>
);

export default SvgComponent;
