import styled, { css } from 'styled-components/native';

import { ResponsiveProps } from '../../../global/types/index';

interface ButtonOptionProps {
    option: 'generalInfo' | 'vehicle';
    actualOption: 'generalInfo' | 'vehicle';
    width: number,
    type: string,
}

export const Container = styled.ScrollView`
    background-color: ${({ theme }) => theme.colors.background};
    flex: 1;
`;

export const ContainerAlign = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    margin: 40px 0 20px;
    width: 95%;
`;

export const HeaderOptionArea = styled.View`
    border: 2px solid #f2f2f2;
    border-radius: 6px;
    flex-direction: row;
    height: 60px;
`;

export const ButtonOptionArea = styled.TouchableOpacity<ButtonOptionProps>`
    ${({ actualOption, type }) =>
        type === 'newClient' ? css`border-top-left-radius: 6px; border-top-right-radius: 6px`
        : actualOption === 'vehicle'
        ? css`
                border-top-right-radius: 6px;
            `
        : css`
                border-top-left-radius: 6px;
            `
    }
    background-color: ${({ theme, option, actualOption }) =>
        option === actualOption
            ? theme.colors.purple[500]
            : theme.colors.white};
    flex: 1;
    align-items: center;
    opacity: ${({ theme, option, actualOption }) =>
        option === actualOption ? 0.5 : 1};
    justify-content: center;
`;

export const ButtonOptionText = styled.Text<ButtonOptionProps>`
    color: ${({ theme, option, actualOption }) =>
        option === actualOption ? theme.colors.white : theme.colors.black};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: ${({ width }) => width < 600 ? '16px' : '20px'};
`;

export const TextDefault = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 22px;
`;

export const ScrollView = styled.ScrollView``;

export const ContainerInfo = styled.View<ResponsiveProps>`
    ${({ width }) => width >= 800 ?
        css`margin: 40px 16px 34px 60px;` : css`margin: 20px 20px;`}
`;

