// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory';

import theme from '../../../../../global/styles/theme';
import * as S from './styles';
import { getArtsDashboard } from '../../../../../services/dashboard';

import { PopupOption } from '../PopupOption';

interface UsedArtsProps {
    id?: number,
    title?: string,
    amount_used?: number,
}

interface UsedArtsChartProps {
    usedArtsInfo?: Array<UsedArtsProps>,
}

interface PopupOptionProps {
    nameOption: string,
    option: 'today' | 'week' | 'month',
}

export function UsedArtsChart(props: UsedArtsChartProps) {
    const { usedArtsInfo } = props;
    const graphInfo = usedArtsInfo?.map((el, index) => {
        const newArray = { x: `${el.title}`, y: Number(el.amount_used) };
        return newArray;
    });

    const VictoryChartPadding = { left: 20, bottom: 20, right: 20, top: 20 };
    const colorScale = ['#4453B5', '#E34850', '#DEE348', '#F29423', '#F2238B'];
    const styleBar = { data: { fill: (d: any) => colorScale[d.index] }};
    const yAxisStyle = {
        axis: {stroke: "transparent"},
        grid: {stroke: theme.colors.gray[25]},
        tickLabels: { fontFamily: theme.font.segoeUi.regular, fontSize: 10 }
    };
    const xAxisStyle = {
        tickLabels: { fontFamily: theme.font.segoeUi.regular, fontSize: 10 }
    };
    const domaingPadding = { x: 50 };

    const [popupOption, setPopupOption] = useState<PopupOptionProps>({} as PopupOptionProps);
    const [artsPeriodList, setArtsPeriodList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (popupOption.option) {
            setLoading(false);
            getArtsPeriod();
        } else {
            setArtsPeriodList(graphInfo);
        }
    }, [popupOption]);

    async function getArtsPeriod() {
        const { result } = await getArtsDashboard(popupOption.option);
        const newArrayPlans = (result ? result?.used_arts : []).map((el) => {
            const newArray = { x: `${el.title}`, y: Number(el.amount_used) };
            return newArray;
        });
        setArtsPeriodList(newArrayPlans);
        setLoading(true);
    }

    function RenderOptionName() {
        if (!popupOption) {
            return null;
        }

        return (
            <S.OptionNameArea>
                <S.TextDefault
                    fontSize={10}
                    fontFamily='regular'
                >
                    {popupOption.nameOption}
                </S.TextDefault>
            </S.OptionNameArea>
        );
    }

    function RenderCoditionInfo() {
        if (!loading) {
            return (
                <S.Loading />
            );
        }

        return RenderChart();
    }

    function RenderChart() {
        const coditionBarChart = artsPeriodList.filter((el) => el.y > 0);

        if (!artsPeriodList.length || !coditionBarChart.length) {
            return (
                <S.VoidTextArea>
                    <S.TextDefault
                        fontSize={14}
                        fontFamily='semi'
                    >
                        Não há dados!
                    </S.TextDefault>
                </S.VoidTextArea>
            );
        }

        return (
            <S.ChartArea>
                <VictoryChart domainPadding={domaingPadding} height={220}>
                    <VictoryBar
                        data={artsPeriodList}
                        barWidth={15}
                        padding={VictoryChartPadding}
                        style={styleBar}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={yAxisStyle}
                    />
                    <VictoryAxis
                        style={xAxisStyle}
                    />
                </VictoryChart>
            </S.ChartArea>
        );
    }

    return (
        <S.SignedChartArea>
            <S.TitleArea>
                <S.TextDefault
                    fontSize={14}
                    fontFamily='semi'
                >
                    Artes usadas
                </S.TextDefault>
                <S.OptionArea>
                    {RenderOptionName()}
                    <PopupOption setOption={setPopupOption} />
                </S.OptionArea>
            </S.TitleArea>
            {RenderCoditionInfo()}
        </S.SignedChartArea>
    );
}
