export const allTagsFixed = {
    headers: [
        {
            id: 'default_text',
            name: 'Inserir texto padrão',
        },
        {
            id: 'title',
            name: 'Título Mídia',
        },
    ],
    vehicle: [
        {
            id: 'board',
            name: 'Placa',
        },
        {
            id: 'board_mask',
            name: 'Placa com máscara',
        },
        {
            id: 'kmV',
            name: 'Quilometragem',
        },
        {
            id: 'condition',
            name: 'Condição',
        },
        {
            id: 'type',
            name: 'Tipo',
        },
        {
            id: 'bodywork',
            name: 'Carroceria',
        },
        {
            id: 'brand',
            name: 'Marca',
        },
        {
            id: 'model',
            name: 'Modelo',
        },
        {
            id: 'version',
            name: 'Versão',
        },
        {
            id: 'year_manufacturer',
            name: 'Ano de Fabricação',
        },
        {
            id: 'year_model',
            name: 'Ano do Modelo',
        },
        {
            id: 'fuel',
            name: 'Combustível',
        },
        {
            id: 'doors',
            name: 'Portas',
        },
        {
            id: 'gearshift',
            name: 'Câmbio',
        },
        {
            id: 'color',
            name: 'Cor',
        },
        {
            id: 'traction',
            name: 'Tração',
        },
        {
            id: 'description',
            name: 'Descrição',
        },
    ],
    sellers: [
        {
            id: 'seller_name',
            name: 'Nome do vendedor',
        },
        {
            id: 'seller_whatsapp',
            name: 'Whatsapp do vendedor',
        },
    ],
    values: [
        {
            id: 'price',
            name: 'Preço da venda',
        },
        {
            id: 'exchange_price',
            name: 'Preço da troca',
        },
        {
            id: 'promotional_price',
            name: 'Preço promocional',
        },
        {
            id: 'type_financing',
            name: 'Tipo de financiamento',
        },
        {
            id: 'input_value',
            name: 'Valor de entrada',
        },
        {
            id: 'installment_value',
            name: 'Valor da parcela',
        },
        {
            id: 'number_installments',
            name: 'Número de parcelas',
        },
        {
            id: 'pac_price',
            name: 'Pacote de preço',
        },
        {
            id: 'pac_discount',
            name: 'Pacote de preço desconto',
        },
        {
            id: 'pac_financing',
            name: 'Pacote de financiamento',
        },
    ],
    info: [
        {
            id: 'is_trade',
            name: 'Aceita troca',
        },
        {
            id: 'is_pay_ipva',
            name: 'IPVA pago',
        },
        {
            id: 'is_owner_unique',
            name: 'Único dono',
        },
        {
            id: 'is_full_tank',
            name: 'Tanque cheio',
        },
        {
            id: 'is_free_transfer',
            name: 'Transferência grátis',
        },
        {
            id: 'is_abaixo_da_fipe',
            name: 'Abaixo da fipe',
        },
        {
            id: 'is_alienado',
            name: 'Alienado/Financiado',
        },
        {
            id: 'is_entrega_a_domicilio',
            name: 'Entrega a domocílio',
        },
        {
            id: 'is_garantia_de_fabrica',
            name: 'Garantia de fábrica',
        },
        {
            id: 'is_licenciado',
            name: 'Licenciado',
        },
        {
            id: 'is_quitado',
            name: 'Quitado',
        },
        {
            id: 'is_revisoes_em_concessionaria',
            name: 'Revisões em concessionária',
        },
        {
            id: 'is_test_drive_a_domicilio',
            name: 'Test drive a domicílio',
        },
    ],
    optionals: [
        {
            id: 'opcionais_inline',
            name: 'Opcionais',
        },
        {
            id: 'opcionais_list',
            name: 'Opcionais em modo lista',
        },
    ],
};
