import styled, { css } from 'styled-components/native';

import { ResponsiveProps } from '../../../global/types';

interface TabBarButtonProps {
    isSelected: boolean;
    page: 'profile' | 'password';
    type: string,
}

interface TextProps {
    fontSize: number;
    color: string;
    fontFamily: 'bold' | 'regular';
    marginTop: number;
    margin: number;
}

interface ButtonModalProps {
    background: string,
}

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.background};
    flex: 1;
`;

export const TabBar = styled.View`
    height: 60px;
    flex-direction: row;
`;

export const TabBarButton = styled.TouchableOpacity<TabBarButtonProps>`
    ${({ theme, isSelected, page, type }) =>
        type === 'adm' ? css`border-top-left-radius: 6px; border-top-right-radius: 6px; background-color: ${theme.colors.purple[500]}}`
            : isSelected
            ? css`
                    background-color: ${theme.colors.purple[500]}};
                    ${
                        page === 'profile'
                            ? 'border-top-left-radius: 6px;'
                            : 'border-top-right-radius: 6px;'
                    }
                `
            : css`
                    border-bottom-width: 1px;
                    border-bottom-color: ${theme.colors.gray[300]};
            `
    };
    opacity: 0.5;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const Test = styled.Text`
    color: green;
    max-width: 200px;
`;

export const ButtonModalArea = styled.TouchableOpacity<ButtonModalProps>`
    align-items: center;
    background-color: ${({ background, theme }) => background === 'red'
        ? theme.colors.red[500] : theme.colors.purple[500]};
    border-radius: 6px;
    height: 50px;
    justify-content: center;
    width: 150px;
    margin: 5px;
`;

export const ButtonModalText = styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
`;

export const ButtonModalConfirm = styled.View`
    /* align-self: flex-end; */
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 12px;
    flex-wrap: wrap-reverse;
    padding: 5px;
`;


export const TextDefault = styled.Text<TextProps>`
    color: ${({ color }) => color};
    font-family: ${({ fontFamily, theme }) =>
        fontFamily === 'bold'
            ? theme.font.segoeUi.bold
            : theme.font.segoeUi.regular};
    font-size: ${({ fontSize }) => fontSize};
    ${({ marginTop }) => `margin-top: ${marginTop || 0}px`}
    ${({ margin }) => `margin: ${margin || 0}px`}
`;

export const ContainerModalConfirm = styled.View<ResponsiveProps>`
    height: 200px;
    border-radius: 5px;
    background-color: white;
    width: ${({ width }) => width < 600 ? '80%' : 450};
    justify-content: space-around;
    align-items: center;
`;

export const TabBarButtonText = styled.Text<TabBarButtonProps>`
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 20px;
    color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.white : theme.colors.gray[900]};
`;

export const ScrollView = styled.ScrollView``;

export const ContainerAlign = styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    margin-top: 44px;
    width: 95%;
`;

export const TextTitle = styled.Text`
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
    font-size: 22px;
    margin-bottom: 20px;
`;

export const AddPhotoArea = styled.View`
    align-items: center;
    width: 100%;
`;

export const AddButtonPhoto = styled.TouchableOpacity``;

export const PhotoUser = styled.Image`
    width: 112px;
    height: 112px;
    border-radius: 6px;
    border: 1px ${({ theme }) => theme.colors.gray[150]};
`;

export const AddPhotoText = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 10px;
    margin-top: 6px;
`;

export const PhotoArea = styled.View`
    align-items: center;
    margin-bottom: 70px;
`;

export const InputContainer = styled.View<ResponsiveProps>`
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: ${({ width }) => (width < 800 ? 'column' : 'row')};
    flex-wrap: wrap;
`;

export const InputEmailArea = styled.View`
    flex-direction: row;
    padding: 10px;
    margin-top: 6px;
    height: 40px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[25]};
    width: 100%;
`;

export const ButtonForgotPassword = styled.TouchableOpacity`
    margin-top: 14px;
`;

export const TextButtonForgotPassword = styled.Text`
    color: ${({ theme }) => theme.colors.gray[400]};
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    font-size: 12px;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray[400]}; ;
`;

export const ButtonArea = styled.View`
    margin-top: 94px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ButtonConfirm = styled.TouchableOpacity`
    height: 40px;
    width: 300px;
    border-width: 1px;
    border-radius: 6px;
    border-color: ${({ theme }) => theme.colors.gray[150]};
    background-color: ${({ theme }) => theme.colors.purple[500]};
    align-items: center;
    justify-content: center;
    margin-left: 16px;
`;

export const EmailArea = styled.View`
    width: 100%;
`;

export const EditInfoArea = styled.View<ResponsiveProps>`
    width: 100%;
    padding: 20px;
    flex-wrap: wrap;
    max-width: 100%;
`;

export const InputArea = styled.TextInput<ResponsiveProps>`
    height: 80px;
    width: ${({ width }) => width <= 800 ? '100%' : '50%'};
`;

export const FacebookButtonArea = styled.TouchableOpacity`
    align-self: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.blue[500]};
    border-radius: 6px;
    flex-direction: row;
    justify-content: center;
    height: 50px;
    width: 250px;
`;

export const AlignIcon = styled.View`
    position: absolute;
    left: 12px;
`;

export const FacebookButtonText = styled.Text`
    align-self: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
`;

interface FacebookIsLinkInterfaces {
    color?: string;
}

export const FacebookIsLink = styled.Text<FacebookIsLinkInterfaces>`
    align-self: center;
    color: ${({ theme, color }) => (color || theme.colors.gray[700])};
    margin-top: 20px;
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.segoeUi.semiBold};
`;

export const AlertText = styled.Text`
    align-self: center;
    color: ${({ theme }) => theme.colors.lowBlack};
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.segoeUi.regular};
    margin-bottom: 12px;
`;
