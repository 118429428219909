// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useWindowDimensions, Animated } from 'react-native';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import { HeaderWithGoback } from '../../../components/HeaderWithGoback';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import carImage from '../../../assets/images/pexels-albin-berlin-919073@2x.png';

import { clientsRecover } from '../../../services/clients';
import { schemaForgotPassword } from '../../../global/utils/formsSchems';
import themeGlobal from '../../../global/styles/theme';
import * as S from './styles';

import { LoadingModal } from '../../../components/LoadingModal';
import { CoditionModal } from '../../../components/CoditionModal';

interface ModalInfoProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    buttonFunction: () => void,
}

export function ForgotPasswordScreen() {
    const resolver = {
        resolver: yupResolver(schemaForgotPassword),
    };
    const {
        control,
        handleSubmit,
        setError,
        reset,
        getValues,
        formState: { errors },
    } = useForm(resolver);
    const window = useWindowDimensions().width;
    const valueFadeInicial = new Animated.Value(0);

    const [loading, setLoading] = useState(false);
    const [fadeAnim] = useState(valueFadeInicial);
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);

    const animated = {
        opacity: fadeAnim,
    };

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    async function handleForgotPassword(data: any) {
        const emailConfig = {
            type: 'custom',
            message: 'Digite o e-mail corretamente.',
        };
        if (data.email) {
            setLoading(true);
            const response = await clientsRecover(data);

            if (response.status === 201 || response.status === 200) {
                setLoading(false);
                const modalProps: ModalInfoProps = {
                    typeModal: 'success',
                    titleModal: 'Sucesso',
                    descriptionModal: response.message,
                    buttonText: 'Continuar',
                    modalVisible: true,
                    setModalVisible: changeModalInfoVisible,
                    buttonFunction: changeModalInfoVisible,
                };

                setTimeout(() => {
                    setModalInfo(modalProps);
                }, 500);
            } else {
                setLoading(false);
                const modalProps: ModalInfoProps = {
                    typeModal: 'error',
                    titleModal: 'Erro',
                    descriptionModal: response.message,
                    buttonText: 'Fechar',
                    modalVisible: true,
                    setModalVisible: changeModalInfoVisible,
                    buttonFunction: changeModalInfoVisible,
                };

                setTimeout(() => {
                    setModalInfo(modalProps);
                }, 500);
            }
            setLoading(false);
        } else {
            setError('email', emailConfig);
        }
    }

    function animation() {
        const config = {
            toValue: 1,
            duration: 1000,
        };
        Animated.timing(fadeAnim, config).start();
    }

    useEffect(() => {
        animation();
    });

    function keyPress(keyPress: any) {
        const data = getValues();
        if (keyPress.code === 'Enter' || keyPress.code === 'NumpadEnter') {
            handleForgotPassword(data);
        }
    }

    return (
        <S.Container onContextMenu={(e) => e.preventDefault()} source={carImage}>
            <S.ContainerShawdow>
                <S.ContentBox width={window} style={animated}>
                    <HeaderWithGoback label="Recuperar Senha" />
                    <S.Form>
                        <Input
                            label="Email"
                            placeholder="Digite seu email"
                            control={control}
                            name="email"
                            errors={errors}
                            onKeyPress={keyPress}
                            autoFocus
                        />
                    </S.Form>
                    <Button
                        label="Salvar"
                        onPress={handleSubmit(handleForgotPassword)}
                    />
                </S.ContentBox>
                <LoadingModal
                    visible={loading}
                    titleLoading='Enviando nova senha...'
                />
                <CoditionModal
                    titleModal={modalInfo.titleModal}
                    descriptionModal={modalInfo.descriptionModal}
                    typeModal={modalInfo.typeModal}
                    buttonText={modalInfo.buttonText}
                    modalVisible={modalInfo.modalVisible}
                    setModalVisible={modalInfo.setModalVisible}
                    buttonFunction={modalInfo.buttonFunction}
                />
            </S.ContainerShawdow>
        </S.Container>
    );
}
